import { DraasApiRecoveryJobExecutionsSummary } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryRecoveryJobSummary {
    readonly recovered: number;
    readonly failed: number;
    readonly notTested: number;
    readonly total: number;

    constructor(json: DraasApiRecoveryJobExecutionsSummary) {
        this.recovered = json.recovered;
        this.failed = json.failed;
        this.notTested = json.not_tested;
        this.total = json.total;
    }
}
