import { range, unionBy } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { DataPage, ListParams, CollectionList } from '@pure1/data';

/** How many items requested for each request batch (page) by recursiveListItems() */
export const RECURSIVE_LIST_ITEMS_BATCH = 990; // Make slightly less than the normal default of 1000 just to help devs differentiate compared to default limits (makes it easier to know where we're handling "get more")

/**
 * Wrapper to .list() that will make multiple requests if the items do not fit within a single response page.
 * The returned observable emits only once then completes.
 *
 * @param params The request params. sort, pageStart, and pageSize are all ignored.
 * @param sortKey Sort key to use when making the api requests. Must be a deterministic, and ideally unique key (such as an id) to prevent missing results.
 * @param uniqueIdSelector Used to check for (and remove) duplicate items in the response.
 * @param itemCount The total items to fetch, if already known. If specified, can avoid having to wait on making an initial single request to fetch the item count.
 */
export function recursiveListItems<T>(
    service: CollectionList<T>,
    params: ListParams<T>,
    sortKey: string,
    uniqueIdSelector: _.ValueIteratee<T>,
    itemCount?: number,
): Observable<T[]> {
    const fetchBatch = (offset: number): Observable<DataPage<T>> => {
        return service
            .list({
                ...params,
                pageStart: offset,
                pageSize: RECURSIVE_LIST_ITEMS_BATCH,
                sort: { key: sortKey, order: 'asc' },
            })
            .pipe(take(1));
    };

    // Fetch when the item count is not known
    const fetchUnknownItemCount: () => Observable<DataPage<T>[]> = () => {
        return fetchBatch(0) // Fetch the first batch, which will also give us the item count
            .pipe(
                switchMap(firstPage => {
                    if (firstPage.total == null || firstPage.total < RECURSIVE_LIST_ITEMS_BATCH) {
                        // All items fit in first request, no need for additional calls
                        return of([firstPage]);
                    } else {
                        // More items are required. Now that we know how many items there are, split up into
                        // multiple batches and send them all at once.
                        // For the first page, use the results we already have instead of making another request.
                        return forkJoin(
                            range(0, Math.ceil(firstPage.total / RECURSIVE_LIST_ITEMS_BATCH)).map(index =>
                                index === 0 ? of(firstPage) : fetchBatch(index * RECURSIVE_LIST_ITEMS_BATCH),
                            ),
                        );
                    }
                }),
            );
    };

    // Fetch when the item count is known
    const fetchKnownItemCount: () => Observable<DataPage<T>[]> = () => {
        return forkJoin(
            range(0, Math.ceil(itemCount / RECURSIVE_LIST_ITEMS_BATCH)).map(index =>
                fetchBatch(index * RECURSIVE_LIST_ITEMS_BATCH),
            ),
        );
    };

    // Take all the datapages and merge the items together into a flat list of unique items
    return (itemCount == null ? fetchUnknownItemCount() : fetchKnownItemCount()).pipe(
        map(dataPages => dataPages.map(dp => dp.response)),
        map(responseSets => {
            // The code would ideally just be the following, but a typescript parsing issue prevents it from working:
            //     unionBy(...responseSets, uniqueIdSelector);
            return unionBy.apply(this, [...responseSets, uniqueIdSelector]);
        }),
    );
}

/**
 * Checks if two arrays contain the same values (but does not need to be in the same order).
 * Null/undefined arrays are treated as equal to an empty array.
 */
export function arraysContainSameItems<T>(a: T[] | null, b: T[] | null): boolean {
    if (a === b) {
        return true;
    }

    // Normalize null/undefined to empty array
    a = a || [];
    b = b || [];

    if (a.length !== b.length) {
        return false; // If length is not equal, we immediately know the contents are not same
    }

    if (a.length === 0 && b.length === 0) {
        return true; // If both lengths are 0, then obviously the (non-existant) content is same
    }

    // Create map of items, with the number of occurrences as count (in case there are duplicates)
    const bmap = new Map<T, number>();
    b.forEach(key => {
        const count = bmap.get(key) || 0;
        bmap.set(key, count + 1);
    });

    // Reduce the count for each item as we come across it. If we ever try to reduce a count below 0,
    // we know collection "a" contains more of that item than "b", and can abort.
    // Since we know the lengths are equal, we don't need to look that every value in the "b" map has been reduced.
    return a.every(key => {
        const newCount = (bmap.get(key) || 0) - 1;
        bmap.set(key, newCount);
        return newCount >= 0;
    });
}
