import moment, { Moment } from 'moment';
import { Resource } from '../interfaces/resource';

export enum UniversalCreditsStatus {
    ACTIVE = 'Active',
    EXPIRING_SOON = 'Expiring Soon',
    EXPIRED = 'Expired',
}

export class UniversalCreditsCreditPool implements Resource {
    readonly id: string;
    readonly name: string;
    readonly startDate: Moment;
    readonly endDate: Moment;
    readonly status: UniversalCreditsStatus;
    readonly usablePercent: number;
    readonly usableAmount: number;
    readonly originalAmount: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.startDate = json.start_date ? moment(json.start_date) : null;
        this.endDate = json.end_date ? moment(json.end_date) : null;
        this.status = json.credit_pool_status;
        this.usablePercent = json.available_percent;
        this.usableAmount = json.available_credits;
        this.originalAmount = json.total_credits;
    }
}
