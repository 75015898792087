import { Component, Input, OnInit } from '@angular/core';
import { FeatureFlagStatus } from '@pure/pure1-ui-platform-angular';
import {
    FeatureFlagDxpService,
    formatCapacity,
    GenealogyEvent,
    GenealogySubscriptionCurrentState,
    getSubscriptionGenealogyEventColor,
    getSubscriptionGenealogyEventDisplayName,
    getSubscriptionGenealogyEventLegendIconUrl,
    getSubscriptionGenealogyEventTimelineIconUrl,
    SubscriptionGenealogy,
    SubscriptionGenealogyEventType,
} from '@pure1/data';
import {
    AxisLabelsFormatterContextObject,
    PointLabelObject,
    PointOptionsObject,
    SeriesOptionsType,
} from 'highcharts/highstock';
import { sortBy } from 'lodash';
import moment from 'moment';
import { take } from 'rxjs';
import { FeatureNames } from '../../../model/FeatureNames';
import { NAME_KEY, SUBSCRIPTION_ENTITY } from '../../../redux/actions';
import { CYBER_RECOVERY_TOOLTIP_SUBSCRIPTIONS } from '../../../subscription/evergreen-summary/evergreen-summary.component';
import { getSvgUrl } from '../../../ui/components/pure-svg.component';
import { TODAY_COLOR } from '../../genealogy-chart/genealogy-chart.component';
import { SubscriptionNameOption } from '../subscription-genealogy-view.component';

const EVENT_SIZE = 24;
const UPCOMING_SIZE = 20;
const STATE_SIZE = 10;
const MAX_LICENSE_AMOUNT = 5;

interface SubscriptionNameCtx {
    subscriptionName: string;
    contractType: string;
    isRansomwareProtectionEnabled: boolean;
}

function getSubscriptionGenealogyEventSize(eventType: SubscriptionGenealogyEventType): number {
    switch (eventType) {
        case 'upcomingRenewal':
            return UPCOMING_SIZE;
        case 'currentSubscriptionState':
            return STATE_SIZE;
        default:
            return EVENT_SIZE;
    }
}

@Component({
    selector: 'subscription-genealogy-chart',
    templateUrl: './subscription-genealogy-chart.component.html',
})
export class SubscriptionGenealogyChartComponent implements OnInit {
    @Input() readonly genealogies: SubscriptionGenealogy[];
    @Input() readonly selectedSubscriptionName: string;

    genealogyChartConfig = {
        buildEvents: this.buildEvents.bind(this),
        buildTimeline: this.buildTimeline.bind(this),
        eventLabelFormatter: this.eventLabelFormatter,
        getEarliestDate: this.getEarliestDate,
        getDisplayName: getSubscriptionGenealogyEventDisplayName,
        getIconUrl: getSubscriptionGenealogyEventTimelineIconUrl,
        getSubscriptionName: this.getSubscriptionNameSelection,
        getTooltipContent: this.getTooltipContent,
        yAxisLabelsFormatter: this.yAxisLabelsFormatter.bind(this),
    };
    isFollowupEnabled = false;
    isSafemodeEnabled = false;

    constructor(private featureFlagDxpService: FeatureFlagDxpService) {}

    ngOnInit(): void {
        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.GENEALOGY_FOLLOWUP)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isFollowupEnabled = feature?.enabled === true;
            });

        this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.RANSOMWARE_SAFEMODE)
            .pipe(take(1))
            .subscribe((feature: FeatureFlagStatus) => {
                this.isSafemodeEnabled = feature?.enabled === true;
            });
    }

    getEarliestDate(genealogies: SubscriptionGenealogy[]): moment.Moment {
        let earliestDate = moment.utc();
        genealogies.forEach(genealogy => {
            if (earliestDate.isAfter(genealogy.startEvent?.date)) {
                earliestDate = genealogy.startEvent?.date.clone();
            }
        });

        return earliestDate;
    }

    buildEvents(genealogy: SubscriptionGenealogy, index: number): SeriesOptionsType {
        const events: GenealogyEvent[] = [
            genealogy.startEvent,
            ...genealogy.plannedRampEvents,
            ...genealogy.updateRequestEvents,
            ...genealogy.renewalEvents,
            genealogy.expirationEvent,
            genealogy.terminationEvent,
            ...(this.isFollowupEnabled ? [genealogy.upcomingRenewalEvent, genealogy.currentState] : []),
        ].filter(event => event != null);
        const eventMarks: PointOptionsObject[] = events.map(event => {
            const isCurrentState = event.eventType === 'currentSubscriptionState';
            const markerConfig = isCurrentState
                ? {
                      radius: 5,
                      lineWidth: 1,
                      fillColor: 'white',
                      lineColor: '#8d8d8d',
                  }
                : {
                      symbol: `url(${getSvgUrl(getSubscriptionGenealogyEventTimelineIconUrl(event.eventType as SubscriptionGenealogyEventType))})`,
                  };
            return {
                name: genealogy.name,
                x: event.date.valueOf(),
                y: index,
                marker: {
                    ...markerConfig,
                    height: getSubscriptionGenealogyEventSize(event.eventType as SubscriptionGenealogyEventType),
                    width: getSubscriptionGenealogyEventSize(event.eventType as SubscriptionGenealogyEventType),
                    states: {
                        hover: {
                            enabled: isCurrentState,
                            radius: 5,
                            fillColor: TODAY_COLOR,
                            lineWidth: 0,
                        },
                    },
                },
                custom: {
                    event: event,
                },
            };
        });

        // Highcharts doesn't like it if the data isn't sorted
        const sortedEvents = sortBy(eventMarks, 'x');

        return {
            name: 'events',
            data: sortedEvents,
            type: 'scatter',
            dataLabels: {
                y: 10,
            },
            showInNavigator: false,
            lineWidth: 0,
        };
    }

    buildTimeline(
        genealogy: SubscriptionGenealogy,
        index: number,
        defaultEndDate: moment.Moment = moment.utc().endOf('year'),
    ): SeriesOptionsType {
        const startDate = genealogy.startEvent?.date;

        let endDate: moment.Moment;
        if (genealogy.terminationEvent?.date) {
            endDate = genealogy.terminationEvent.date;
        } else if (genealogy.expirationEvent?.date) {
            endDate = genealogy.expirationEvent.date;
        } else if (genealogy.upcomingRenewalEvent?.date && this.isFollowupEnabled) {
            endDate = genealogy.upcomingRenewalEvent.date;
        } else {
            endDate = defaultEndDate;
        }

        return {
            data: [
                {
                    name: genealogy.name,
                    x: startDate.valueOf(),
                    x2: endDate.valueOf(),
                    y: index,
                    color: getSubscriptionGenealogyEventColor('start'),
                    marker: {
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                },
            ],
            color: getSubscriptionGenealogyEventColor('start'),
            pointWidth: 1,
            borderWidth: 0,
            grouping: false,
            type: 'xrange',
            dataLabels: {
                enabled: false,
            },
            showInNavigator: false,
        };
    }

    eventLabelFormatter(pointLabel: PointLabelObject, _isTooltip: boolean): string | null {
        const event = pointLabel.point.options.custom?.event;
        const subscriptionName = event?.newName || event?.name;
        const subscriptionCapacity = event?.newCapacity || event?.totalCapacity;

        if (event.eventType === 'upcomingRenewal') {
            return `<div class="event-label">Upcoming Renewal</div>`;
        }

        if (event.eventType === 'termination') {
            return `<div class="event-label">Terminated</div>`;
        }

        if (event.eventType === 'currentSubscriptionState') {
            return null;
        }

        return `<div class="event-description">${subscriptionName} - ${formatCapacity(subscriptionCapacity as number)}T</div>`;
    }

    getSubscriptionNameSelection(genealogies: SubscriptionGenealogy[], selectedSubscriptionName: string): string[] {
        return genealogies.map(genealogy => {
            const subscriptionName =
                selectedSubscriptionName === SubscriptionNameOption.INITIAL_SUBSCRIPTION
                    ? genealogy.initialSubscriptionName
                    : genealogy?.currentSubscriptionName;
            if (genealogy?.currentState?.contractType) {
                // Encode the information we care about in a string
                return JSON.stringify({
                    subscriptionName,
                    contractType: genealogy.currentState.contractType,
                    isRansomwareProtectionEnabled: genealogy.isRansomwareProtectionEnabled,
                } as SubscriptionNameCtx);
            }
            return JSON.stringify({ subscriptionName });
        });
    }

    getTooltipContent(pointLabel: PointLabelObject): string {
        const pointMoment = moment.utc(pointLabel.x);
        const formattedDate = pointMoment.year() + '-Q' + pointMoment.quarter();
        const subscriptionName = pointLabel.key;
        const event = pointLabel.point.options.custom?.event;

        //Different kinds of events for subscription genealogy
        const addedEvents = event?.added;
        const capacityIncreasedEvents = event?.capacityIncrease;
        const removedEvents = event?.removed;
        const capacityDecreasedEvents = event?.capacityDecrease;
        const newLicenses = event?.newLicenses;

        if (!event) {
            return '';
        }

        const capacityHeaderTemplate = `<div class="subscription-tooltip-header">
                <div class="tooltip-left-header">${subscriptionName}</div>
                <div class="tooltip-right-header">${formatCapacity(event?.totalCapacity as number)}T</div>
            </div>`;

        let headerTemplate = `<div class="subscription-tooltip-header">
                <div class="tooltip-left-header">${subscriptionName}</div>
                <div class="tooltip-right-header">${formatCapacity(event?.originalCapacity as number)}T <i class="fa fa-arrow-right"></i> ${formatCapacity(event?.newCapacity as number)}T</div>
            </div>`;
        let bodyTemplate = `<div class="date-info">${formattedDate} (${pointMoment.format('MM-DD-YYYY')})</div>`;

        //generate Tooltip template body for different events
        switch (event.eventType) {
            case 'renewal':
                headerTemplate = `<div class="subscription-tooltip-header">
                        <div class="tooltip-left-header">${subscriptionName}</div>
                        <div class="tooltip-right-header">${formatCapacity(event?.newCapacity as number)}T</div>
                    </div>`;
                bodyTemplate += `
                    <div class="subscription-event-description">
                        <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                        <div class="option-text">Contract Renewal</div>
                    </div>
                    <div class="subscription-event-info">
                        <div class="event-detail">${event.originalName} <i class="fa fa-arrow-right"></i> ${event.newName}</div>
                        <div class="event-detail">${event.newSubscriptionTerm} Month</div>
                    </div>`;
                break;
            case 'expiration':
                headerTemplate = capacityHeaderTemplate;
                bodyTemplate += `
                    <div class="subscription-event-description">
                        <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                        <div class="option-text">Contract Expired</div>
                    </div>
                    <div class="subscription-event-info">Contact CSM to renew</div>`;
                break;
            case 'termination':
                headerTemplate = capacityHeaderTemplate;
                bodyTemplate += `
                    <div class="subscription-event-description">
                        <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                        <div class="option-text">Contract Terminated</div>
                    </div>`;
                break;
            case 'upcomingRenewal':
                headerTemplate = `<div class="subscription-tooltip-header">
                        <div class="tooltip-left-header">${subscriptionName}</div>
                    </div>`;
                bodyTemplate += `
                    <div class="subscription-event-description">
                        <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl('renewal')}" />
                        <div class="option-text">Upcoming Renewal</div>
                    </div>`;

                return `<div class="genealogy-chart-tooltip subscription-genealogy-chart-tooltip subscription-upcoming-renewal-tooltip">
                    ${headerTemplate}
                    <div class="subscription-tooltip-body">
                        ${bodyTemplate}
                    </div>
                </div>`;
            case 'currentSubscriptionState': {
                const currentState: GenealogySubscriptionCurrentState = event;
                headerTemplate = capacityHeaderTemplate;
                bodyTemplate += `
                    <div class="subscription-event-info">
                        <h2 class="option-text">Contract Type</h1>
                        <div class="option-text">${currentState.contractType}</div>
                    </div>`;
                bodyTemplate += `
                    <div class="subscription-event-info">
                        <h2 class="option-text">Number of Licenses</h2>
                        <div class="option-text">${currentState.licenses.length}</div>
                    </div>`;
                const licenses = currentState.licenses.slice(0, MAX_LICENSE_AMOUNT);
                bodyTemplate += `<table class="subscription-events">`;
                licenses.forEach(license => {
                    bodyTemplate += `
                        <tr class="subscription-event-info">
                            <td class="event-detail">${license.name}</td>
                            <td class="event-detail">${license.licenseType ?? ``}</td>
                            <td class="event-detail">${formatCapacity(license.totalCapacity as number)}T</td>
                        </tr>`;
                });
                if (currentState.licenses.length > MAX_LICENSE_AMOUNT) {
                    bodyTemplate += `
                        <tr class="subscription-event-info">
                            <td class="event-detail">+ ${currentState.licenses.length - MAX_LICENSE_AMOUNT} more...</td>
                            <td class="event-detail"></td>
                            <td class="event-detail"></td>
                        </tr>`;
                }
                bodyTemplate += `</table>`;
                break;
            }
            default:
                if (newLicenses?.length > 0) {
                    headerTemplate = capacityHeaderTemplate;
                    bodyTemplate += `
                        <div class="subscription-event-description">
                            <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                            <div class="option-text">Contract Start</div>
                        </div>`;
                    bodyTemplate += `<table class="subscription-events">`;
                    newLicenses?.forEach(newLicense => {
                        bodyTemplate += `
                            <tr class="subscription-event-info">
                                <td class="event-detail">${newLicense?.name}</td>
                                <td class="event-detail">${newLicense?.licenseType ?? ``}</td>
                                <td class="event-detail">${formatCapacity(newLicense?.newCapacity as number)}T</td>
                            </tr>`;
                    });
                    bodyTemplate += `</table>`;
                }
                if (addedEvents?.length > 0) {
                    bodyTemplate += `
                        <div class="subscription-event-description">
                            <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                            <div class="option-text">${addedEvents.length} New License Added</div>
                        </div>`;
                    bodyTemplate += `<table class="subscription-events">`;
                    addedEvents.forEach(addedEvent => {
                        bodyTemplate += `
                            <tr class="subscription-event-info">
                                <td class="event-detail">${addedEvent.name}</td>
                                <td class="event-detail">${addedEvent.licenseType ?? ``}</td>
                                <td class="event-detail">${formatCapacity(addedEvent.originalCapacity as number)}T <i class="fa fa-arrow-right"></i> ${formatCapacity(addedEvent.newCapacity as number)}T</td>
                            </tr>`;
                    });
                    bodyTemplate += `</table>`;
                }
                if (capacityIncreasedEvents?.length > 0) {
                    bodyTemplate += `
                        <div class="subscription-event-description">
                            <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                            <div class="option-text">Capacity Increase</div>
                        </div>`;
                    bodyTemplate += `<table class="subscription-events">`;
                    capacityIncreasedEvents.forEach(capacityIncreasedEvent => {
                        bodyTemplate += `
                            <tr class="subscription-event-info">
                                <td class="event-detail">${capacityIncreasedEvent.name}</tc>
                                <td class="event-detail">${capacityIncreasedEvent.licenseType ?? ``}</tc>
                                <td class="event-detail">${formatCapacity(capacityIncreasedEvent.originalCapacity as number)}T <i class="fa fa-arrow-right"></i> ${formatCapacity(capacityIncreasedEvent.newCapacity as number)}T</tc>
                            </tr>`;
                    });
                    bodyTemplate += `</table>`;
                }
                if (removedEvents?.length > 0) {
                    bodyTemplate += `
                        <div class="subscription-event-description">
                            <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                            <div class="option-text">${removedEvents.length} License Removed</div>
                        </div>`;
                    bodyTemplate += `<table class="subscription-events">`;
                    removedEvents?.forEach(removedEvent => {
                        bodyTemplate += `
                            <tr class="subscription-event-info">
                                <td class="event-detail">${removedEvent.name}</td>
                                <td class="event-detail">${removedEvent.licenseType ?? ``}</td>
                                <td class="event-detail">${formatCapacity(removedEvent.originalCapacity as number)}T <i class="fa fa-arrow-right"></i> ${formatCapacity(removedEvent.newCapacity as number)}T</td>
                            </tr>`;
                    });
                    bodyTemplate += `</table>`;
                }
                if (capacityDecreasedEvents?.length > 0) {
                    bodyTemplate += `
                        <div class="subscription-event-description">
                            <img class="event-icon" src="/images/${getSubscriptionGenealogyEventLegendIconUrl(event.eventType)}" />
                            <div class="option-text">Capacity Decrease</div>
                        </div>`;
                    bodyTemplate += `<table class="subscription-events">`;
                    capacityDecreasedEvents?.forEach(capacityDecreasedEvent => {
                        bodyTemplate += `
                            <tr class="subscription-event-info">
                                <td class="event-detail">${capacityDecreasedEvent.name}</td>
                                <td class="event-detail">${capacityDecreasedEvent?.licenseType ?? ``}</td>
                                <td class="event-detail">${formatCapacity(capacityDecreasedEvent.originalCapacity as number)}T <i class="fa fa-arrow-right"></i> ${formatCapacity(capacityDecreasedEvent.newCapacity as number)}T</td>
                            </tr>`;
                    });
                    bodyTemplate += `</table>`;
                }
                break;
        }

        return `
            <div class="genealogy-chart-tooltip subscription-genealogy-chart-tooltip">
                ${headerTemplate}
                <div class="subscription-tooltip-body">
                    ${bodyTemplate}
                </div>
            </div>`;
    }

    yAxisLabelsFormatter(ctx: AxisLabelsFormatterContextObject): string {
        // Since the subscription name is the category grouped for the y-axis, we need to split the value as strings
        let { subscriptionName, contractType, isRansomwareProtectionEnabled }: SubscriptionNameCtx = {
            subscriptionName: '',
            contractType: '',
            isRansomwareProtectionEnabled: false,
        };

        try {
            ({ subscriptionName, contractType, isRansomwareProtectionEnabled } = JSON.parse(String(ctx.value)));
        } catch (e) {
            subscriptionName = String(ctx.value);
        }

        const subscriptionFilter = {
            entity: SUBSCRIPTION_ENTITY,
            key: NAME_KEY,
            value: subscriptionName,
        };
        const filterString = JSON.stringify([subscriptionFilter]);
        const url = `/fleet/subscriptions/evergreen-one?filter=${encodeURIComponent(filterString)}`;

        const showSafemodeShield = this.isSafemodeEnabled && isRansomwareProtectionEnabled;

        return `
            <div class="subscription-genealogy-label">
                <a href="${url}">${subscriptionName}</a>
                <div class="subscription-genealogy-name">
                    ${contractType ? `<div class="contract-type">${contractType}</div>` : ''}
                    ${
                        showSafemodeShield
                            ? `<span class="safemode-shield-icon safemode-tooltip">
                        <img class="subscription-genealogy-action-icon" src="${getSvgUrl('safemode-shield-grey.svg')}" />
                        <span class="safemode-tooltip-text">${CYBER_RECOVERY_TOOLTIP_SUBSCRIPTIONS}</span>
                    </span>`
                            : ''
                    }
                </div>
            </div>`;
    }
}
