<!-- Column header templates -->
<ng-template #headerWithInformationIcon let-name="headerName" let-text="tooltipText">
    <div class="column-header-with-icon">
        {{ name }}
        <pureui-svg
            class="header-icon"
            svg="info-circle.svg"
            height="13"
            hiveTooltip
            [hiveTooltipDescription]="text"
        ></pureui-svg>
    </div>
</ng-template>

<ng-template #initialSubscriptionHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Initial Subscription',
            tooltipText: 'Contract name that initiated subscription.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #currentSubscriptionHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Current Subscription',
            tooltipText:
                'Current contract name used for usage metering and invoice. Current subscription may be the \
                same name as Initial subscription.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #initialSubscriptionStartDateHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Initial Subscription Start Date',
            tooltipText: 'Date subscription contract started.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #subscriptionLastUpdatedHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Last Updated Date',
            tooltipText: 'Date of the last amendment to your current subscription.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #subscriptionTermHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Term',
            tooltipText: 'Subscription length in months.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseActivationDateHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Activation Date',
            tooltipText:
                'License service start date. May be the same as Subscription Start Date unless new licenses \
                were added as part of an expansion.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseUsageHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'License Usage',
            tooltipText: 'Previous calendar day license usage.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseUtilizationHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'License Usage %',
            tooltipText: 'Reserved capacity utilization percentage at the license level.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseAverageOnDemandHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'QTD Average On-Demand Usage',
            tooltipText: 'Quarter to date average on-demand reserved capacity usage at the license level.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licensePreRatioHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'License PRE Ratio',
            tooltipText: 'Measure of total data reduction calculated at the license level.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseTotalOnDemandHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'QTD Total On-Demand Usage',
            tooltipText: 'Quarter to date total on-demand reserved capacity usage at the license level.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #licenseEnergyUsageHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Energy Usage',
            tooltipText: 'Previous day average of actual energy usage in watts.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #totalUsedCapacityHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Total Used Capacity',
            tooltipText: 'Applicable for E//Forever and Foundation',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #effectiveUsageCapacityHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Effective Used Capacity',
            tooltipText: 'Applicable for E//One or E//Flex',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #emuUpgradeEligibilityDateHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Upgrade Eligibility Date',
            tooltipText:
                'The date on which controllers/blades will be eligible for an Ever Modern Upgrade if you renew your E//Forever subscription to the required Forever (FVR) Commitment date.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #fvrCommitmentHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'FVR Commitment Date',
            tooltipText:
                'The date until which you need to commit to an E//Forever subscription to be eligible for the next Ever Modern Upgrade.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #emuActionNeededHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Action Needed',
            tooltipText:
                'The action required to obtain the next free upgrade under your Evergreen//Forever subscription.',
        }"
    >
    </ng-container>
</ng-template>

<ng-template #applianceEnergyUsageHeader>
    <ng-container
        [ngTemplateOutlet]="headerWithInformationIcon"
        [ngTemplateOutletContext]="{
            headerName: 'Appliance Energy Usage',
            tooltipText: 'Last 7 days average of actual energy usage in watts.',
        }"
    >
    </ng-container>
</ng-template>

<!-- Value templates -->
<ng-template #valueWithInformationIcon let-value="valueName" let-text="tooltipText">
    <div class="column-value-warn-with-icon">
        {{ value }}
        <pureui-svg
            class="value-icon"
            svg="info-icon.svg"
            height="14"
            [ngbTooltip]="text"
            container="body"
        ></pureui-svg>
    </div>
</ng-template>

<ng-template #currentSubscription let-asset>
    <ng-container *ngIf="asset.subscription.name as name; else defaultValue">
        <a
            *ngIf="asset | assetManagementRouterState as link; else subscriptionName"
            [routerLink]="link"
            [queryParams]="asset | assetManagementUrlParams"
        >
            {{ name | defaultString }}
        </a>
        <ng-template #subscriptionName>
            {{ name | defaultString }}
        </ng-template>
    </ng-container>
    <ng-template #defaultValue>
        {{ '' | defaultString }}
    </ng-template>
</ng-template>
<ng-template #startDate let-asset>
    {{ asset.subscription.startDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #renewalDate let-asset>
    {{ asset.subscription.endDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #subscriptionLastUpdated let-asset>
    {{ asset.subscription.lastUpdated | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #subscriptionDaysToRenew let-asset>
    <ng-container *ngIf="asset.subscription.endDate == null">
        {{ '' | defaultString }}
    </ng-container>
    <ng-container *ngIf="asset.subscription.endDate?.diff(TODAY, 'days') as daysToRenew">
        {{ daysToRenew > 0 ? daysToRenew : ('Past due' | defaultString) }}
    </ng-container>
</ng-template>

<ng-template #subscriptionType let-asset>
    <ng-container *ngIf="asset.subscription.programType !== ProgramType.UNKNOWN; else unknownProgramType">
        {{ asset.subscription.programType | defaultString }}
    </ng-container>
    <ng-template #unknownProgramType>
        <ng-container
            [ngTemplateOutlet]="valueWithInformationIcon"
            [ngTemplateOutletContext]="{
                valueName: 'Unknown',
                tooltipText: 'The Subscription Type data is missing. Contact the Account Team for assistance.',
            }"
        >
        </ng-container>
    </ng-template>
</ng-template>

<ng-template #licenseActivationDate let-asset>
    {{ asset.license.activationDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #licenseEndDate let-asset>
    {{ asset.license.endDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #licenseLastUpdated let-asset>
    {{ asset.license.lastUpdated | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #licenseReservedAmount let-asset>
    {{ asset.license.reservedAmount | formatSubscriptionData: asset.license.licenseType : 0 : true | defaultString }}
</ng-template>

<ng-template #licenseUsage let-asset>
    {{ asset.license.usage.current | formatSubscriptionData: asset.license.licenseType : 0 : true | defaultString }}
</ng-template>

<ng-template #licenseUtilization let-asset>
    <ng-container *ngIf="asset.license.reservedAmount > 0"></ng-container>
    {{ asset.license.usage.current / asset.license.reservedAmount | percent: '1.0-0' | defaultString }}
</ng-template>

<ng-template #licenseAverageOnDemand let-asset>
    {{
        asset.license.usage.averageOnDemand
            | formatSubscriptionData: asset.license.licenseType : 0 : true
            | defaultString
    }}
</ng-template>

<ng-template #licensePreRatio let-asset>
    {{ asset.license | formatPreRatio | defaultString }}
</ng-template>

<ng-template #licenseTotalOnDemand let-asset>
    {{
        asset.license.usage.totalOnDemand | formatSubscriptionData: asset.license.licenseType : 0 : true | defaultString
    }}
</ng-template>

<ng-template #licensePowerUsage let-asset>
    <ng-container *ngIf="asset.license.powerUsage as powerUsage; else defaultValue">
        <a routerLink="/dashboard/assessment/sustainability" [queryParams]="getUiParams(asset, ['sustainability'])">
            {{ asset.license.powerUsage | formatEnergyUsage | defaultString }}
        </a>
    </ng-container>
    <ng-template #defaultValue>
        {{ '' | defaultString }}
    </ng-template>
</ng-template>

<ng-template #applianceActivationDate let-asset>
    {{ asset.mostRecentActivationStartDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>

<ng-template #applianceEolDate let-asset>
    <div
        [ngbTooltip]="asset.eolHardware?.eolComponents?.length > 0 ? allEolTooltip : null"
        container="body"
        tooltipClass="all-eol-tooltip"
    >
        <ng-template #allEolTooltip>
            <div [innerHTML]="getEolTooltipHtml(asset)"></div>
        </ng-template>
        <span [ngClass]="shouldWarnAboutEol(asset) ? 'column-value-warn-with-icon' : null">
            {{ asset.eolHardware?.earliestEolDate?.format('yyyy-MM-DD') | defaultString }}
        </span>
        <!--Looks like a link, but not really a link. Hovering over anywhere in the table cell shows the tooltip anyway.-->
        <a *ngIf="asset.eolHardware?.eolComponents?.length > 1" href="javascript:void(0);">
            +{{ asset.eolHardware.eolComponents.length - 1 }} more
        </a>
    </div>
</ng-template>

<ng-template #effectiveUsageCapacity let-asset>
    {{
        getCapacityData(asset, asset.subscription.programType, CapacityType.EUC)
            | formatSubscriptionData: asset.license.licenseType : 0 : true
            | defaultString
    }}
</ng-template>

<ng-template #safeModeStatusIcon let-asset>
    <ng-container *ngIf="asset.arraySafeModeStatus !== SafeModeStatusEnum.NOT_AVAILABLE; else defaultValue">
        <a (click)="$event.stopPropagation()" [href]="KNOWLEDGEBASE_SAFEMODE_HELP_LINK" target="_blank">
            <safemode-shield-icon [arraySafeModeStatus]="asset.arraySafeModeStatus"></safemode-shield-icon>
        </a>
    </ng-container>
    <ng-template #defaultValue>
        {{ '' | defaultString }}
    </ng-template>
</ng-template>

<ng-template #totalUsedCapacity let-asset>
    {{
        getCapacityData(asset, asset.subscription.programType, CapacityType.TOTAL)
            | formatSubscriptionData: asset.license.licenseType : 0 : true
            | defaultString
    }}
</ng-template>

<ng-template #applianceUtilization let-asset>
    {{ asset.percentFull / 100 | percent: '1.0-0' | defaultString }}
</ng-template>

<ng-template #applianceTotalUsable let-asset>
    {{ asset.capacity | formatSubscriptionData: asset.license.licenseType : 0 : true | defaultString }}
</ng-template>

<ng-template #applianceDataReduction let-asset>
    {{ asset.dataReduction | number: '1.0-1' | defaultString }}
    <span *ngIf="asset.dataReduction"> to 1</span>
</ng-template>

<ng-template #applianceEnergyUsage let-asset>
    <ng-container *ngIf="asset.energyUsage as energyUsage; else defaultValue">
        <a
            routerLink="/dashboard/assessment/sustainability"
            [queryParams]="getUiParams(asset, ['assetSustainability'])"
        >
            {{ asset.energyUsage | formatEnergyUsage | defaultString }}
        </a>
    </ng-container>
    <ng-template #defaultValue>
        {{ '' | defaultString }}
    </ng-template>
</ng-template>

<ng-template #applianceTags let-asset>
    <ng-container *ngFor="let tag of asset.cloudTags">
        <pure-tag [tag]="tag" [editable]="false"></pure-tag>
    </ng-container>
</ng-template>

<ng-template #quoteId let-asset>
    {{ asset.quote?.id | defaultString }}
</ng-template>

<ng-template #quoteStatus let-asset>
    {{ asset.quote?.status | defaultString }}
</ng-template>

<ng-template #quoteExpirationDate let-asset>
    {{ asset.quote?.expirationDate | date: 'yyyy-MM-dd' : 'UTC' | defaultString }}
</ng-template>
