<div
    class="gfb-editor-wrapper"
    [ngClass]="{
        'has-key': currentKey,
        pinnable: currentEntity === 'arrays' || currentEntity === 'subscription license',
    }"
>
    <div *ngIf="currentKey" class="current-key">{{ currentKey.display_key }}</div>
    <input
        [placeholder]="getPlaceholder()"
        class="gfb-editor key"
        [hidden]="!completingKeys()"
        [pureAutocomplete]="autocompleteKeysOptions"
        (autocompleteSelect)="onSelectKey($event)"
        (keydown)="onValueKeyDown($event)"
        [disabled]="disabled"
        inputautoresize
        #keyInput
    />
    <input
        class="gfb-editor value"
        [hidden]="completingKeys()"
        [pureAutocomplete]="autocompleteValuesOptions"
        (autocompleteSelect)="onSelectValues($event)"
        [selectedValues]="selectedValues"
        (keydown)="onValueKeyDown($event)"
        [disabled]="disabled"
        inputautoresize
        #valueInput
    />
    <span class="pill-remove" (click)="stopEdit()" [hidden]="completingKeys()">
        <span class="pstg-action-icon pill-remove-icon">
            <pureui-svg svg="x-action.svg" [height]="8"></pureui-svg>
        </span>
    </span>
</div>
<div class="autocomplete-menu-holder keys"></div>
<div class="autocomplete-menu-holder values"></div>
