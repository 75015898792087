<pureui-spinner class="p-loading" [pureuiShow]="isLoading"></pureui-spinner>
<div class="modal-header">
    <button type="button" class="close modal-x-button pull-right" (click)="activeModal.dismiss(hasEnteredFields)">
        <pureui-svg class="pstg-action-icon" svg="x-action.svg" height="12"></pureui-svg>
    </button>
    <ng-container *ngIf="activeOrder; else newRequest">
        <div class="modal-title"> Edit Request for Quote </div>
    </ng-container>
    <ng-template #newRequest>
        <div *ngIf="serviceCatalog.serviceName === 'Evergreen//Flex'; else defaultTitle" class="modal-title">
            Request Quote: Evergreen//Flex
        </div>
        <ng-template #defaultTitle>
            <div class="modal-title"> Request for Quote </div>
        </ng-template>
    </ng-template>
</div>
<section class="modal-body">
    <div
        *ngIf="serviceCatalog.serviceName !== 'Evergreen//Flex' && serviceCatalog.productName !== 'Universal Credits'"
        class="offering-name"
    >
        {{ serviceCatalog.serviceName }} <b>{{ displayName }}</b>
    </div>

    <div *ngIf="serviceCatalog.productName === 'Universal Credits'" class="offering-name">
        <b>{{ serviceCatalog.productName }}</b>
    </div>

    <section class="badge-body">
        <div class="error-badge" *ngIf="hasError">
            <label class="error-msg"
                >We cannot complete your request right now, please try again later.<br />If this problem persists,
                please contact support.</label
            >
        </div>
        <div
            class="notification-badge"
            *ngIf="(serviceCatalog | getQuoteOrdersByCompoundName: ordersCountByCompoundName) > 0"
        >
            <label class="notification-msg"
                >You currently have
                <b>{{ serviceCatalog | getQuoteOrdersByCompoundName: ordersCountByCompoundName }}</b>
                {{
                    (serviceCatalog | getQuoteOrdersByCompoundName: ordersCountByCompoundName) > 1
                        ? 'requests'
                        : 'request'
                }}
                for this item</label
            >
        </div>
    </section>

    <section class="main-input-section">
        <!-- Pure-as-a-Service and Evergreen//One -->
        <ng-container *ngIf="isQuoteOptionsVisible()">
            <section class="top-level-quote-attributes">
                <div class="quote-summary" [ngClass]="{ 'ai-card-spacing': isAICatalogCard }">
                    <div class="term-content">
                        <label class="term">Term</label>
                        <div class="reserve-amount">
                            <select
                                class="custom-select form-control"
                                [ngModel]="termInMonths"
                                (ngModelChange)="onMonthChange($event)"
                            >
                                <option *ngFor="let month of monthOptions" [ngValue]="month">
                                    {{ month }} Months
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="total-amount performance-amount" *ngIf="isAICatalogCard">
                        <label class="amount-label">Total Provisioned Performance </label>
                        <div class="amount-value">{{ totalPerformance }} GB/s</div>
                    </div>
                    <div class="total-amount total-capacity">
                        <label class="amount-label">Total Capacity</label>
                        <div class="amount-value">{{ totalRequestedAmount }} TiB</div>
                    </div>
                    <div class="total-amount total-estimated-price" *ngIf="reserveStructuredPricing">
                        <label class="amount-label estimated-price-label">
                            Total Estimated Price (per month)
                            <pureui-svg
                                svg="info-circle.svg"
                                height="13"
                                class="pstg-action-icon header-info-icon"
                                ngbTooltip="Price is calcuated based on the Pure as-a-Service MSRP. The detailed pricing with applicable discounts and promotions will be shared after quote submission"
                                placement="top"
                                container="body"
                            >
                            </pureui-svg>
                        </label>
                        <div class="amount-value">{{ displayedEstimatedPrice }}</div>
                    </div>
                </div>
            </section>
            <div class="form-section-title">Licenses</div>
            <ng-container *ngFor="let site of newSites; index as i; trackBy: 'id' | trackByProperty">
                <new-site-entry
                    [serviceCatalog]="serviceCatalog"
                    [workloadStats]="workloadStats"
                    [analyticsLabel]="analyticsLabel"
                    [updatedLicense]="$any(activeOrder?.licenseUpdates ? activeOrder?.licenseUpdates[i] : null)"
                    (siteInfoChanged)="onSiteInfoChanged($event, i)"
                    (deleteTriggered)="onDeleteTriggered(i)"
                ></new-site-entry>
            </ng-container>
            <div
                class="add-site-panel"
                (click)="allCurrentFieldsFilled && addSite()"
                [ngClass]="{ disabled: !allCurrentFieldsFilled }"
            >
                <span class="add-sign">+</span>
                <label class="add-sign-label">Add License</label>
            </div>
        </ng-container>

        <!-- Evergreen//Flex -->
        <ng-container *ngIf="serviceCatalog.serviceName === 'Evergreen//Flex'">
            <section class="top-level-quote-attributes">
                <div class="term-content flex-top-level-left">
                    <label class="term">Term</label>
                    <div class="reserve-amount">
                        <select
                            class="custom-select form-control"
                            [ngModel]="termInMonths"
                            (ngModelChange)="onMonthChange($event)"
                        >
                            <option *ngFor="let month of monthOptions" [ngValue]="month"> {{ month }} Months </option>
                        </select>
                    </div>
                </div>

                <div class="total-amount flex-top-level-right">
                    <label class="amount-label">
                        Total Reserved Capacity ({{ (isUUCEnabled$ | async) ? 'Usable' : 'EUC' }})
                    </label>
                    <div class="amount-value">{{ totalRequestedAmount }} TiB</div>
                </div>
            </section>
            <div class="form-section-title">Licenses</div>
            <ng-container *ngFor="let site of newSites; index as i; trackBy: 'id' | trackByProperty">
                <flex-new-site-entry
                    [serviceCatalog]="serviceCatalog"
                    [analyticsLabel]="analyticsLabel + ' - //FLEX'"
                    [workloadStats]="workloadStats"
                    (siteInfoChanged)="onSiteInfoChanged($event, i)"
                    (deleteTriggered)="onDeleteTriggered(i)"
                >
                </flex-new-site-entry>
            </ng-container>
            <div
                class="add-site-panel"
                (click)="allCurrentFieldsFilled && addSite()"
                [ngClass]="{ disabled: !allCurrentFieldsFilled || newSites.length >= 10 }"
            >
                <span class="add-sign">+</span>
                <label class="add-sign-label">Add License</label>
            </div>
        </ng-container>

        <!-- Professional Services -->
        <ng-container *ngIf="serviceCatalog.serviceName === 'Professional Services' && options?.length > 1">
            <label class="option-label">Options</label>
            <select class="options-select form-control" [ngModel]="selectedOption">
                <option *ngFor="let option of options" [ngValue]="option">
                    {{ option.name }}
                </option>
            </select>
            <label class="option-description">{{ selectedOption?.description }}</label>
        </ng-container>

        <!-- Universal Credits -->
        <ng-container *ngIf="serviceCatalog.productName === 'Universal Credits'">
            <form class="universal-credit-section" [formGroup]="ucForm">
                <hive-dropdown class="uc-form-input" label="Contract Term" formControlName="termsInMonths">
                    <hive-selection-menu-item
                        *ngFor="let month of ucMonthOptions"
                        [value]="month"
                        [label]="month + ' Months'"
                    >
                    </hive-selection-menu-item>
                </hive-dropdown>
                <hive-text-field
                    class="uc-form-input"
                    label="Number of Credits per Year"
                    type="number"
                    formControlName="creditAmount"
                    assistanceText="Minimum credits per year {{ CREDIT_MIN | number }}"
                >
                </hive-text-field>
                <div class="total-credits">
                    <hive-label label="Total Credits"></hive-label>
                    <div class="total-amount">
                        {{ totalCreditValue | number | defaultString }}
                    </div>
                </div>
                <div class="credit-datepicker-container uc-form-input">
                    <hive-label class="credit-datepicker-label" label="Start date" [required]="true"> </hive-label>
                    <input
                        class="form-control"
                        placeholder="Choose a date"
                        formControlName="startDate"
                        ngbDatepicker
                        [minDate]="minDate"
                        [firstDayOfWeek]="SUNDAY"
                        readonly="true"
                        container="body"
                        [markDisabled]="disableWeekends"
                        #siteDatePicker="ngbDatepicker"
                        (click)="siteDatePicker.toggle()"
                    />
                </div>
            </form>
        </ng-container>

        <!-- Partner section -->
        <partner-info
            *ngIf="
                serviceCatalog.serviceName !== 'Professional Services' &&
                serviceCatalog.productName !== 'Universal Credits'
            "
            [partnersInfos]="partnersInfos"
            [activeOrder]="activeOrder"
            [isEditOrder]="isEditOrder"
            (partnerRequestChanged)="onPartnerRequestChanged($event)"
        >
        </partner-info>

        <!-- So far, every offering has a description section. So, we'll include it on its own -->
        <section
            *ngIf="serviceCatalog.productName !== 'Universal Credits'; else ucDescriptionArea"
            class="description-section"
        >
            <label class="description-label" for="quote-description">Describe your use cases (optional):</label>
            <textarea
                class="description-content form-control"
                id="quote-description"
                [formControl]="moreDetailsDescription"
                [maxlength]="DESCRIPTION_MAX_LENGTH"
                rows="9"
            ></textarea>
            <div class="description-limitation"
                >{{ moreDetailsDescription.value.length }}/{{ DESCRIPTION_MAX_LENGTH }} characters</div
            >
        </section>
        <!-- Hive details to fit Universal Credit hive formatting -->
        <ng-template #ucDescriptionArea>
            <hive-text-area
                [formControl]="moreDetailsDescription"
                [maxlength]="DESCRIPTION_MAX_LENGTH"
                label="Describe your use cases (optional):"
                rows="9"
                assistanceText="{{ moreDetailsDescription.value.length }}/{{ DESCRIPTION_MAX_LENGTH }} characters"
            >
            </hive-text-area>
        </ng-template>

        <!-- Post description sections... -->
        <ng-container
            *ngIf="
                (serviceCatalog.serviceName === 'Pure as-a-Service' ||
                    serviceCatalog.serviceName === 'Evergreen//One' ||
                    serviceCatalog.serviceName === 'Evergreen//Flex') &&
                !activeOrder
            "
        >
            <section class="email-notification-section">
                <label class="additional-emails-label">
                    Email Notifications (optional):
                    <pureui-svg
                        svg="info-circle.svg"
                        height="13"
                        class="pstg-action-icon header-info-icon"
                        ngbTooltip="Add a list of comma separated email addresses for notifications."
                        placement="right"
                        container="body"
                    >
                    </pureui-svg>
                </label>
                <input
                    class="email-input form-control"
                    [formControl]="emailInput"
                    [maxlength]="EMAIL_MAX_LENGTH"
                    [ngbTooltip]="emailInput.invalid && emailInput.touched && getValidationError()"
                    container="body"
                />
            </section>
        </ng-container>
    </section>
</section>

<div class="modal-footer">
    <section class="footer-buttons">
        <button type="button" id="cancel-button" class="btn btn-info" (click)="activeModal.dismiss(hasEnteredFields)">
            Cancel
        </button>
        <div
            *ngIf="isSubmitButtonDisabled(); else enabledSubmit"
            class="btn-tooltip-wrapper"
            ngbTooltip="Complete the required fields to submit the request"
            placement="top"
            container="body"
        >
            <button id="disabled-submit-button" type="button" class="btn btn-primary" disabled>Submit</button>
        </div>
        <!-- NOTE: submitting will still be disabled if user doesn't have OPA Resource access (impersonating, for example) -->
        <ng-template #enabledSubmit>
            <button
                type="button"
                id="submit-button"
                class="btn btn-primary"
                [disabled]="isValidPermission() | isNotAllowedImpure"
                (click)="requestAQuote()"
            >
                <ng-container *ngIf="!isLoading">Submit</ng-container>
                <ng-container *ngIf="isLoading">Submitting...</ng-container>
            </button>
        </ng-template>
    </section>
</div>

<ng-template #requestConfirmedModal let-modal>
    <request-confirmed-modal [activeModal]="modal"> </request-confirmed-modal>
</ng-template>
