/**
 * These are the features that get enabled using FeatureFlags.next UI at
 * https://dev-console.pstg-stg.net/ for Staging or
 * https://dev-console.pstg-prd.net/ for Production
 */
export enum FeatureNames {
    ARRAY_ADDRESS = 'array-address',
    EVERGREEN_REBRAND = 'evergreen_rebrand',
    METRIC_GROUPS = 'metric-groups',
    SERVICE_CATALOG = 'service-catalog',
    REQUEST_PARTNER = 'request-partner',
    /** only disabled for PURE orgs, i.e: users in PURE orgs will see the normal card even for expired arrays */
    VIEW_SUPPORT_EXPIRED_APPLIANCE_WARNING_CARD = 'view-support-expired-appliance-warning-card',
    /** Display the warnings that collector versions are deprecated */
    VMA_CAPACITY_METRICS = 'vma-capacity-metrics',
    VMA_EXTENDED_TIME_SELECTION = 'vma-extended-time-selection',
    VMA_REPORTS = 'vma-reports',
    VMA_SEARCH = 'vma-search',
    VMA_TOPOLOGY_GET_STARTED_BOX = 'vma-topology-get-started-box',
    VMA_VERSION_WARNINGS = 'vma-version-warnings',
    DATA_PROTECTION_V2 = 'data-protection-v2',
    RECOMMENDATIONS = 'recommendations',
    SUSTAINABILITY = 'sustainability',
    SECURITY_ASSESSMENT = 'security-assessment',
    DISABLE_EDGE_SERVICE_STEP_UP_AUTH = 'disable-edge-service-step-up-auth', // disable 2FA modal for ems if the flag is enabled
    PROACTIVE_RECOMMENDATIONS = 'proactive-recommendations',
    PROACTIVE_RECOMMENDATIONS_CAPACITY_SETTINGS = 'proactive-recommendations-capacity-settings',
    PROACTIVE_CONTROLLER_UPGRADE_RECOMMENDATIONS = 'controller-upgrade-recommendations',
    PURITY_OPTIMIZATION = 'purity-optimization',
    V3_RAISE_VOLUME_LIMIT = 'v3-raise-volume-limit',
    PRE_CATALOG = 'pre-catalog',
    LICENSE_EUC_BREAKDOWN_CHART = 'capacity-breakdown-chart',
    SAFE_MODE_MULTIPARTY_AUTH = 'safe-mode-multiparty-auth',
    LICENSE_LATENCY_CHART = 'license-latency-chart',
    LICENSE_BANDWIDTH_CHART = 'license-bandwidth-chart',
    REMOVE_PAAS_HARDWARE_SIMULATION = 'remove-paas-hardware-simulation',
    CAPACITY_DOWN_LICENSING_APPLIANCE = 'capacity-down-licensing-appliance',
    CAPACITY_DOWN_LICENSING_PLANNER = 'capacity-down-licensing-planner',
    DRAAS_SUBSCRIPTION = 'draas-subscription',
    DRAAS_REVAMP = 'draas-revamp',
    DRAAS_ENHANCED_CLUSTER_INFO = 'draas-enhanced-cluster',
    DRAAS_PRESIGNED_FAILBACK_OVA_LINKS = 'draas-presigned-failback-ova-links',
    DRAAS_SELFSERVICE_ASSESSMENT = 'draas-selfservice-assessment',
    DRAAS_V2_PAGES = 'draas-v2-pages',
    DRAAS_VMA_INTEGRATION = 'draas-vma-integration',
    DRAAS_DEBUG = 'draas-debug', // used by developers to enable debug features
    DRAAS_V2_SMALLER_VPC = 'draas-v2-smaller-vpc',
    DRAAS_FA_SUPPORT = 'draas-fa-support',
    SIGN_SAML_REQUEST = 'sign_saml_request',
    ASSET_MANAGEMENT_TOP_SUMMARY = 'asset-management-top-summary',
    APPLIANCE_GENEALOGY = 'appliance-genealogy',
    SUBSCRIPTION_GENEALOGY = 'subscription-genealogy',
    GENEALOGY_FOLLOWUP = 'genealogy-followup',
    QUOTE_MANAGEMENT = 'quote-management',
    PARTNER_INVOICE = 'partner-invoice',
    ASSET_PARTNER_VISIBILITY = 'asset_partner_visibility',
    PAAS_SLA_INDICATORS = 'paas-sla-indicators',
    PLANNER_HW_EOL = 'planner-hw-eol',
    PLANNER_GEN2_FA_E_SUPPORT = 'include_gen2_chassis_in_orderable_config_list',
    V7_CAPACITY_PROJECTION = 'v7-capacity-projection',
    V7_CAPACITY_PROOF_OF_CONCEPT = 'v7-capacity-proof-of-concept',
    METRICS_V3 = 'metrics-v3',
    FLEX_LICENSES_QUOTE_SUPPORT_IN_PLANNER = 'flex-licenses-quote-support-in-planner',
    FB_SAFEMODE_SNAPSHOT_POLICY = 'fb-safemode-snapshot-policy',
    SSU_IGNORE_EXPIRED_CONTRACT = 'ssu-ignore-expired-contract',
    SSU_FLASH_BLADE_SUPPPORT = 'ssu-flash-blade-support',
    SSU_CBS_SUPPPORT = 'ssu-cbs-support',
    SSU_PIAM_2_2 = 'ssu-piam-2-2',
    FB_EOL = 'fb-eol',
    ORDER_TRACKING = 'order-tracking',
    ORDERS_V2 = 'orders-v2',
    PLANNER_NEW_FILE_WORKLOAD_SIMULATION = 'planner-new-file-workload-simulation',
    PLANNER_HARDWARE_SIMULATION_C20_SUPPORT = 'planner_hardware_simulation_C20_support',
    PLANNER_RC20_SUPPORT = 'planner-rc20-support',
    PHANTOM_FB_E_150TB = 'phantom-fb-e-150tb',
    FA_E_WORKLOAD_AND_CLONE_SIMULATIONS = 'fa-e-workload-and-clone-simulations',
    COPILOT = 'pure4sight',
    RANSOMWARE_SAFEMODE = 'ransomware-safemode',
    ESG_SCOPE3 = 'esg-scope3',
    VM_ASSESSMENT = 'vm-assessment',
    SUPPORT_CENTER = 'support-center',
    ORDER_NEW_COLUMN = 'order-new-column',
    EOC = 'eoc',
    EOSC_REPORT = 'eosc_report',
    SECURITY_ASSESSMENT_REPORT = 'security-assessment-report',
    VMA_1_MONTH_RANGE = 'vma-1-month-range',
    VM_ASSESSMENT_BETA_BANNER = 'vm-assessment-beta-banner',
    ORG_SWITCHING = 'org-switching',
    ALERTS_SEVERITY = 'global-notifications-alerts-severity',
    ASSET_MANAGEMENT_TAGGING = 'asset-management-tagging',
    LOG_CENTER = 'log-center',
    UNIVERSAL_CREDIT_EXPANSION = 'universal-credit-expansion',
    UNIVERSAL_CREDIT_DASHBOARD = 'universal-credit-dashboard',
    UNIVERSAL_CREDIT_TRANSACTIONS = 'universal-credit-transactions',
    SECURITY_PATCH_FB_2025_A = 'security-patch-fb-2025-a',
    SECURITY_ASSESSMENT_ADMIN_OPS = 'security-assessment-admin-ops',
    REMOVE_VIEWS = 'remove-views',
    PURE_SKILL_DASHBOARD = 'pure-skill-dashboard',
    WORKFLOW_AUTOMATION = 'workflow_automation',
    E_FLEX_UUC = 'e-flex-uuc',
}
