import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isXCR4 } from '../../../utils/marketing';

type ModelKey =
    | 'cbs'
    | 'fa-300'
    | 'fa-405'
    | 'fa-420'
    | 'fa-450'
    | 'fa-c'
    | 'fa-cbs-azure'
    | 'fa-m'
    | 'fa-rc'
    | 'fa-st'
    | 'fa-x'
    | 'fa-xl'
    | 'fa-x-c-e'
    | 'flashblade'
    | 'flashblade//s'
    | 'flashblade//e'
    | 'unknown';

const IMAGE_MAP: Readonly<Map<ModelKey, string>> = Object.freeze(
    new Map([
        ['cbs', 'cbs-min.png'],
        ['fa-300', 'fa-300-min.png'],
        ['fa-405', 'fa-405-min.png'],
        ['fa-420', 'fa-420-min.png'],
        ['fa-450', 'fa-450-min.png'],
        ['fa-c', 'fa-c-min.png'],
        ['fa-cbs-azure', 'fa-cbs-azure-min.png'],
        ['fa-m', 'fa-m-min.png'],
        ['fa-rc', 'fa-rc-min.png'],
        ['fa-st', 'fa-xl-min.png'],
        ['fa-x', 'fa-x-min.png'],
        ['fa-xl', 'fa-xl-min.png'],
        ['fa-x-c-e', 'fa-x-c-e-min.png'],
        ['flashblade', 'fb-min.png'],
        ['flashblade//s', 'fb-s-min.png'],
        ['flashblade//e', 'fb-e-min.png'],
        ['unknown', 'generic-min.png'],
    ] as [ModelKey, string][]),
);

@Component({
    selector: 'appliance-model-image',
    templateUrl: 'appliance-model-image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplianceModelImageComponent implements OnChanges {
    @Input() readonly array: { model: string };
    @Input() readonly height: string | number = 100;
    @Input() readonly width: string | number = null;
    @Input() readonly showModel: boolean = false;

    imageName: string = IMAGE_MAP.get('unknown');
    style = {};

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            const modelKey = this.getModelKey();
            this.imageName = IMAGE_MAP.get(modelKey);
        }

        if (changes.width || changes.height) {
            this.style = {
                width: this.getDimensionValue(this.width),
                height: this.getDimensionValue(this.height),
            };
        }
    }

    private getDimensionValue(value: string | number): string {
        if (value === null) {
            return null;
        }

        if (typeof value === 'number') {
            return value + 'px';
        }

        return value;
    }

    private getModelKey(): ModelKey {
        /**
         * if you are adding a new model + image combination, also remember to update forecast/model-selector
         */
        const lowerCaseModel = (this.array?.model || '').toLowerCase();
        if (lowerCaseModel.startsWith('fa-cbs-azure')) {
            return 'fa-cbs-azure';
        }
        if (lowerCaseModel.startsWith('fa-c')) {
            return isXCR4(lowerCaseModel) ? 'fa-x-c-e' : 'fa-c';
        }
        if (lowerCaseModel.startsWith('fa-m')) {
            return 'fa-m';
        }
        if (lowerCaseModel.startsWith('fa-st')) {
            return 'fa-st';
        }
        // FA-XL check needs to come before FA-X check
        if (lowerCaseModel.startsWith('fa-xl')) {
            return 'fa-xl';
        } else if (lowerCaseModel.startsWith('fa-x')) {
            return isXCR4(lowerCaseModel) ? 'fa-x-c-e' : 'fa-x';
        }
        if (lowerCaseModel.startsWith('fa-e')) {
            return 'fa-x-c-e';
        }
        if (lowerCaseModel.startsWith('cbs-')) {
            return 'cbs';
        }
        if (lowerCaseModel.startsWith('fa-rc')) {
            return 'fa-rc';
        }

        switch (lowerCaseModel) {
            case 'fa-300':
                return 'fa-300';
            case 'fa-405':
                return 'fa-405';
            case 'fa-420':
                return 'fa-420';
            case 'fa-450':
                return 'fa-450';
            case 'flashblade':
                return 'flashblade';
            case 'flashblade//s200':
            case 'flashblade//s500':
                return 'flashblade//s';
            case 'flashblade//e':
                return 'flashblade//e';
            default:
                return 'unknown';
        }
    }
}
