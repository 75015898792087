import { Resource } from '../interfaces/resource';
import moment from 'moment';

export class UniversalCreditsTransaction implements Resource {
    id: string;
    name: string;
    contract: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    term: number;
    comment: string;
    credits: number;
    creditBalance: number;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.contract = json.contract;
        this.startDate = json.start_date ? moment(json.start_date) : null;
        this.endDate = json.end_date ? moment(json.end_date) : null;
        this.term = json.term;
        this.comment = json.comment;
        this.credits = json.credits;
        this.creditBalance = json.credit_balance;
    }
}
