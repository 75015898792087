import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';
import { delay } from 'rxjs/operators';
import { DisasterRecoveryAwsSubnet } from '../models/disaster-recovery-aws-subnet';
import { DraasApiConfig } from './disaster-recovery-constants';
import { IRestResponse } from '@pure1/data';
import {
    DraasApiAwsSecurityGroup,
    DraasApiAwsSubnet,
    DraasApiSiteExtended,
    DraasApiVmWareNetworkSwitch,
    DraasApiVmWarePortGroup,
} from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryAwsSecurityGroup } from '../models/disaster-recovery-aws-security-group';
import { DisasterRecoveryVmwareNetworkSwitch } from '../models/disaster-recovery-vmware-network-switch';
import { DisasterRecoveryVmwarePortGroup } from '../models/disaster-recovery-vmware-port-group';
import { DisasterRecoverySiteExtended } from '../models/disaster-recovery-site';

export class AwsScalingConfiguration {
    vCpu: number;
    memory: number;
}

@Injectable({ providedIn: 'root' })
export class DisasterRecoverySiteConfigurationService {
    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    private getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters`;
    }

    getVmWareVSwitches(clusterId: string, providerId: string): Observable<DisasterRecoveryVmwareNetworkSwitch[]> {
        const queryParams = ['page_number=0', 'page_size=1000'];
        const url = `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/providers/vmware/${providerId}/network-switches?${queryParams.join('&')}`;

        return this.http
            .get<IRestResponse<DraasApiVmWareNetworkSwitch>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryVmwareNetworkSwitch(it))));
    }

    getVmWarePortGroups(clusterId: string, providerId: string): Observable<DisasterRecoveryVmwarePortGroup[]> {
        const queryParams = ['page_number=0', 'page_size=1000'];
        const url = `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/providers/vmware/${providerId}/port-groups?${queryParams.join('&')}`;

        return this.http
            .get<IRestResponse<DraasApiVmWarePortGroup>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryVmwarePortGroup(it))));
    }

    getAwsSubnets(clusterId: string, providerId: string): Observable<DisasterRecoveryAwsSubnet[]> {
        const queryParams = ['page_number=0', 'page_size=1000'];

        const url = `${this.getEndpoint()}/${clusterId}/providers/aws/${providerId}/subnets?${queryParams.join('&')}`;

        return this.http
            .get<IRestResponse<DraasApiAwsSubnet>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryAwsSubnet(it))));
    }

    getAwsSecurityGroups(clusterId: string, providerId: string): Observable<DisasterRecoveryAwsSecurityGroup[]> {
        const queryParams = ['page_number=0', 'page_size=1000'];

        const url = `${this.getEndpoint()}/${clusterId}/providers/aws/${providerId}/security-groups?${queryParams.join('&')}`;

        return this.http
            .get<IRestResponse<DraasApiAwsSecurityGroup>>(url)
            .pipe(map(response => response.items.map(it => new DisasterRecoveryAwsSecurityGroup(it))));
    }

    getSitesExtended(clusterId: string): Observable<DisasterRecoverySiteExtended[]> {
        const url = `${this.getEndpoint()}/${clusterId}/sites/extended`;

        return this.http.get<DraasApiSiteExtended[]>(url).pipe(
            map(response =>
                response.map(it => {
                    return new DisasterRecoverySiteExtended(it);
                }),
            ),
        );
    }

    getSiteExtended(clusterId: string, siteId: string): Observable<DisasterRecoverySiteExtended> {
        const url = `${this.getEndpoint()}/${clusterId}/sites/${siteId}/extended`;

        return this.http
            .get<DraasApiSiteExtended>(url)
            .pipe(map(response => new DisasterRecoverySiteExtended(response)));
    }

    // TODO: CLOUD-146042 - Implement the actual methods
    getAwsSiteScalingConfiguration(clusterId: string, siteId: string): Observable<AwsScalingConfiguration> {
        return of({ vCpu: 16, memory: 32 }).pipe(delay(1000));
    }

    updateAwsSiteScalingConfiguration(
        clusterId: string,
        siteId: string,
        scalingConfiguration: AwsScalingConfiguration,
    ): Observable<void> {
        return of(null).pipe(delay(1000));
    }
}
