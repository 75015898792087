import {
    DraasApiClusterDatastoreUsageStatus,
    DraasApiAWSDatastoreUsageStatus,
    DraasApiVSphereDatastoreUsageStatus,
    DraasApiProviderDatastoreUsageStatus,
} from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryStorageUsageStatus {
    readonly vSphere: DisasterRecoveryVSphereStorageUsageStatus;
    readonly aws: DisasterRecoveryAwsStorageUsageStatus;

    constructor(json: DraasApiClusterDatastoreUsageStatus) {
        this.vSphere = json.vsphere ? new DisasterRecoveryVSphereStorageUsageStatus(json.vsphere) : null;
        this.aws = json.aws ? new DisasterRecoveryAwsStorageUsageStatus(json.aws) : null;
    }
}

export class DisasterRecoveryAwsStorageUsageStatus {
    readonly consumedBytes: number;

    constructor(json: DraasApiAWSDatastoreUsageStatus) {
        this.consumedBytes = json.consumed_bytes;
    }
}

export class DisasterRecoveryVSphereStorageUsageStatus {
    readonly consumedBytes: number;
    readonly quotaBytes: number;
    readonly availableBytes: number;
    readonly totalBytes: number;
    readonly dataStoresUsed: number;
    readonly status: 'HEALTHY' | 'DISK_FREESPACE_LOW' | 'DATASTORE_HARD_QUOTA' | 'UNKNOWN';

    constructor(json: DraasApiVSphereDatastoreUsageStatus) {
        this.consumedBytes = json.consumed_bytes;
        this.quotaBytes = json.quota_bytes;
        this.availableBytes = json.available_bytes;
        this.totalBytes = json.total_bytes;
        this.dataStoresUsed = json.data_stores_used ?? 1;
        this.status = json.status;
    }
}

export class DisasterRecoveryProviderStorageUsage {
    readonly providerId: string;
    readonly providerName?: string;
    readonly consumedBytes: number;
    readonly quotaBytes: number;

    constructor(json: DraasApiProviderDatastoreUsageStatus) {
        this.providerId = json.provider_id;
        this.providerName = json.provider_name;
        this.consumedBytes = json.consumed_bytes;
        this.quotaBytes = json.quota_bytes;
    }
}
