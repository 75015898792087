import { Injectable } from '@angular/core';
import { DraasApiConfig } from './disaster-recovery-constants';
import { catchError, EMPTY, map, Observable, of } from 'rxjs';
import { DraasApiPublicKeySecret } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryEncryptionKey } from '../models/disaster-recovery-encryption-key';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoverySecretsService {
    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {}

    private getEndpoint(): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/secrets`;
    }

    // retrieves UI encryption public key or empty if the endpoint is not present (on an outdated environment)
    getEncryptionKey(clusterId: string): Observable<DisasterRecoveryEncryptionKey> {
        const url = `${this.getEndpoint()}/${clusterId}/encryption-key`;
        return this.http.get<DraasApiPublicKeySecret>(url).pipe(
            map(secret => new DisasterRecoveryEncryptionKey(secret)),
            catchError(error => {
                if (error.status === 404) {
                    return EMPTY;
                }
                throw new Error('Unable to retrieve encryption key: ' + error.statusText);
            }),
        );
    }
}
