import { Pipe, PipeTransform } from '@angular/core';
import { LicenseType } from '@pure1/data';

@Pipe({
    name: 'licenseReservedUnit',
})
export class LicenseReservedUnitPipe implements PipeTransform {
    transform(type: LicenseType): string {
        switch (type) {
            case LicenseType.VMA:
                return 'VMs';
            default:
                return 'T';
        }
    }
}
