<section class="entry-row">
    <div class="site-name-form">
        <label class="site-name-label" for="site-name-input">
            License Description
            <pureui-svg
                svg="info-circle.svg"
                height="13"
                ngbTooltip="The name of the site/location where you want this service deployed."
                placement="top"
                container="body"
            >
            </pureui-svg>
        </label>
        <input
            id="site-name-input"
            class="form-control"
            [maxlength]="NAME_MAX_LENGTH"
            [ngModel]="siteName"
            (ngModelChange)="onNameChange($event)"
        />
    </div>
    <div class="delete-button-container">
        <button type="button" class="close modal-x-button pull-right" (click)="deleteEntry()">
            <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" height="24"></pureui-svg>
        </button>
    </div>
</section>
<section class="entry-row">
    <div *ngIf="serviceCatalog.licenseType === LicenseType.E_ONE_FOR_AI" class="performance-content">
        <label class="performance">Performance</label>
        <div class="performance-amount">
            <div class="pure1-common-slider">
                <ngx-slider
                    [value]="increasePerformanceInSlider"
                    (valueChange)="onPerformanceChange($event)"
                    [options]="performanceSliderOptions"
                ></ngx-slider>
            </div>
            <input
                #performance
                type="number"
                [maxlength]="CAPACITY_MAX_LENGTH"
                class="form-control"
                (blur)="onInputPerformanceBlur(performance)"
                [ngModel]="increasePerformance"
                (ngModelChange)="onInputPerformanceChange($event)"
            />
            <span class="performance-unit"> GB/s </span>
        </div>
    </div>
    <div class="capacity-content">
        <label class="capacity">Reserved (TiB Effective)</label>
        <div class="reserve-amount">
            <div class="pure1-common-slider">
                <ngx-slider
                    [value]="increaseAmountInSlider"
                    (valueChange)="onAmountChange($event)"
                    [options]="reserveSliderOptions"
                ></ngx-slider>
            </div>
            <input
                #amount
                type="number"
                [maxlength]="CAPACITY_MAX_LENGTH"
                class="form-control"
                (blur)="onInputAmountBlur(amount)"
                [ngModel]="increaseAmount"
                (ngModelChange)="onInputAmountChange($event)"
            />
            <span class="reserve-unit"> TiB </span>
        </div>
    </div>
</section>
<section class="entry-row">
    <div class="selector-container" *ngIf="serviceCatalog.preAllowed">
        <label for="pre-type">Pre Compressed Data (optional)</label>
        <select id="pre-type" [ngModel]="selectedPre" (ngModelChange)="onPreChange($event)" class="custom-select">
            <option [ngValue]="undefined" hidden>--Select--</option>
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
        </select>
    </div>

    <div class="selector-container">
        <label for="workload-type">Workload (optional)</label>
        <select
            id="workload-type"
            [ngModel]="selectedWorkload"
            (ngModelChange)="onWorkloadChange($event)"
            class="custom-select"
            ngbTooltip="For custom workloads, please use the description box below to add details such as type of workload & performance characteristics."
            container="body"
            triggers="manual"
            #tooltip="ngbTooltip"
        >
            <option [ngValue]="undefined" hidden>--Select--</option>
            <option *ngFor="let opt of workloadStats; trackBy: 'name' | trackByProperty" [ngValue]="opt">
                {{ opt.name }}
            </option>
        </select>
    </div>

    <div class="site-datepicker-container">
        <label class="site-datepicker-label">
            <span>Requested Activation Date (optional)</span>
            <pureui-svg
                svg="info-circle.svg"
                height="13"
                ngbTooltip="Select the date you would like the capacity available for consumption."
                placement="top"
                container="body"
            >
            </pureui-svg>
        </label>
        <input
            class="form-control"
            placeholder="Choose a date"
            [ngModel]="siteStartDate"
            (ngModelChange)="onStartDateChange($event)"
            ngbDatepicker
            [minDate]="minDate"
            [firstDayOfWeek]="SUNDAY"
            readonly="true"
            container="body"
            [markDisabled]="disableWeekends"
            #siteDatePicker="ngbDatepicker"
            (click)="siteDatePicker.toggle()"
        />
    </div>
</section>
