import { Resource } from '../interfaces/resource';
import {
    DraasApiDisk,
    DraasApiNetworkInterface,
    DraasApiVirtualMachine,
    DraasApiVmNetworkConfiguration,
} from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryNetworkConfiguration } from './disaster-recovery-protection-group';

export class DisasterRecoveryVirtualMachine implements Resource {
    id: string;
    name: string;
    ephemeral: boolean;
    parentId: string;
    managedObjectReference?: string;
    powerState?: 'ON' | 'OFF' | 'SUSPENDED' | 'UNKNOWN';
    platform?: string;
    memoryMb: number;
    numOfCpus: number;
    ip?: string;
    agentVersion?: string;
    isTemplate: boolean;
    eligibility?: 'ELIGIBLE' | 'WARNING' | 'INELIGIBLE';
    ineligibilityReason?: string;
    vmwareToolsInstalled: boolean;
    vmwareToolsVersion?: string;
    disks?: DraasApiDisk[];
    diskTotalSize: number;
    networkInterfaces?: DraasApiNetworkInterface[];
    firmwareType?: string;
    instanceType?: string;
    displayProtectionGroupId?: string;
    homeProviderId?: string;
    managedInfrastructureVirtualMachineId?: string;
    currentDatacenterName?: string;
    currentHostSystemName?: string;
    protectionGroupId?: string;
    protectionGroupName?: string;
    deleted?: boolean;
    enrollmentStatus?:
        | 'ENROLL_PENDING'
        | 'ENROLLED'
        | 'ENROLL_FAILED'
        | 'REMOVAL_PENDING'
        | 'REMOVED'
        | 'REMOVAL_FAILED';
    unenrollDisabledReason?: 'NONE' | 'INGEST' | 'PROTECTION' | 'REPLICATION' | 'PRECONVERSION';
    tagNames: string[];
    // This field is populated only when requested through specific endpoint
    // GET /api/2.0/clusters/{clusterId}/recovery-plans/{planId}/protection-groups/{protectionGroupId}/vms
    networkConfiguration?: DisasterRecoveryVmNetworkConfiguration;

    constructor(json: DraasApiVirtualMachine) {
        this.id = json.id;
        this.name = json.name;
        this.ephemeral = json.ephemeral;
        this.parentId = json.parent_id;
        this.managedObjectReference = json.managed_object_reference;
        this.powerState = json.power_state;
        this.platform = json.platform;
        this.memoryMb = json.memory_mb;
        this.numOfCpus = json.num_of_cpus;
        this.ip = json.ip;
        this.agentVersion = json.agent_version;
        this.isTemplate = json.is_template;
        this.eligibility = json.eligibility;
        this.ineligibilityReason = json.ineligibility_reason;
        this.vmwareToolsInstalled = json.vmware_tools_installed;
        this.vmwareToolsVersion = json.vmware_tools_version;
        this.disks = json.disks;
        this.diskTotalSize = json.disk_total_size;
        this.networkInterfaces = json.network_interfaces;
        this.firmwareType = json.firmware_type;
        this.instanceType = json.instance_type;
        this.displayProtectionGroupId = json.display_protection_group_id;
        this.homeProviderId = json.home_provider_id;
        this.managedInfrastructureVirtualMachineId = json.managed_infrastructure_virtual_machine_id;
        this.currentDatacenterName = json.current_datacenter_name;
        this.currentHostSystemName = json.current_host_system_name;
        this.protectionGroupId = json.protection_group_id;
        this.protectionGroupName = json.protection_group_name;
        this.deleted = json.deleted;
        this.enrollmentStatus = json.enrollment_status;
        this.unenrollDisabledReason = json.unenroll_disabled_reason;
        this.tagNames = json.tag_names || [];
        this.networkConfiguration = json.network_configuration
            ? new DisasterRecoveryVmNetworkConfiguration(json.network_configuration)
            : null;
    }
}

export class DisasterRecoveryVmNetworkConfiguration {
    source?: DisasterRecoveryNetworkConfiguration;
    target?: DisasterRecoveryNetworkConfiguration;
    mapping?: DisasterRecoveryNetworkConfiguration;

    constructor(json: DraasApiVmNetworkConfiguration) {
        this.source = json.source ? new DisasterRecoveryNetworkConfiguration(json.source) : null;
        this.target = json.target ? new DisasterRecoveryNetworkConfiguration(json.target) : null;
        this.mapping = json.mapping ? new DisasterRecoveryNetworkConfiguration(json.mapping) : null;
    }
}
