import moment from 'moment';
import { UnifiedArrayBusymeterAggregation } from './unified-array-busymeter-aggregation';
import { UnifiedArrayPerformanceAggregation } from './unified-array-performance-aggregation';
import { ResourceStatus } from './license';
import { ArraySafeModeStatus, ArraySafeMode, toSafeModeStatus } from './array-safemode';
import { ArraySubscriptionLicense } from './ArraySubscriptionLicense';

export const enum ArrayContractStatus {
    ACTIVE = 'Active',
    ACTIVE_WITH_GRACE_PERIOD = 'Active with Grace Period',
    EXPIRED = 'Expired',
    ACTIVE_EXTENDED_MAINTENANCE = 'Active - Extended Maintenance',
}

export const enum Product {
    FA = 'FlashArray',
    FB = 'FlashBlade',
    UNKNOWN = 'Unknown',
}

export const enum SupportContractRenewalStatus {
    NONRENEWABLE = 'Nonrenewable',
    RENEWABLE_AND_UNACKNOWLEDGED = 'Renewable and Unacknowledged',
    RENEWABLE_AND_ACKNOWLEDGED = 'Renewable and Acknowledged',
}

export const enum SubscriptionType {
    FOREVER = '//Forever',
    FOUNDATION = '//Foundation',
}

export const enum ServiceTier {
    BASIC = 'Basic',
    ADVANCED = 'Advanced',
    PREMIUM = 'Premium',
}

export interface IArrayAddress {
    geolocation: IGeolocation;
    street_address: string;
}

export interface IArrayInstallAddress extends IArrayAddress {
    updated: number;
}

export interface IArrayRequestedAddress extends IArrayAddress {
    requested: number;
}

export interface IGeolocation {
    latitude: number;
    longitude: number;
}

export interface IOrganization {
    id: string;
}

const NA = '–';

const SAFE_MODE_DISABLED_WEIGHT = 2; // weight assigned to 'Disabled' status. Important bc we default to this on unknown status

interface IConstructorJson {
    _as_of: number;
    active_status?: ResourceStatus; // Only useful for CBS arrays
    aggregation?: UnifiedArrayPerformanceAggregation;
    busymeter_aggregation?: UnifiedArrayBusymeterAggregation;
    appliance_id: string;
    array_id: string;
    contract_expiration_date?: number;
    contract_last_renew_requested?: number;
    contract_start_date?: number;
    contract_status?: ArrayContractStatus;
    contract_renewal_status?: SupportContractRenewalStatus;
    fqdn: string;
    flashArray: boolean;
    flashBlade: boolean;
    flashBladeNorway?: boolean;
    storReduce: boolean;
    helium: boolean;
    cbs: boolean;
    hostname?: string;
    id: string;
    install_address: IArrayInstallAddress;
    install_address_requested: IArrayRequestedAddress;
    is_current: boolean;
    name: string;
    org_id: number;
    model: string;
    os: string;
    product: 'flash-array' | 'flash-blade';
    version: string;
    safe_mode_array_status: string;
    safe_mode_global: { status: string; eradication_timer: number };
    safe_mode_object_store: { status: string; eradication_timer: number };
    safe_mode_file_systems: { status: string; eradication_timer: number };
    safe_mode_status_weight: number;
    installed_agent_ids?: string[];
    last_updated?: number;

    // For Evergreen Forever//Foundation
    evergreen_tier?: SubscriptionType;
    support_tier?: ServiceTier;
    chassis_serial_number?: string;
    shelf_serial_number?: string[];
    city?: string;
    country?: string;
    purearray_list_space?: any;
    load_analysis?: any;
    capacity_analysis?: any;

    //EOC
    support_expiration_date?: number;

    // These two are only valid for granular SafeMode
    number_of_volumes: number;
    number_of_protected_volumes: number;

    // EOL
    has_end_of_life_hardware?: boolean;
    end_of_life_hardware: IPureArrayEndOfLifeHardware;

    // Available Pre-Installed Capacity
    capacity_expandable?: boolean;

    // Subscription related information
    subscription_license?: ArraySubscriptionLicense;
}

export function getProduct(product: string): Product {
    if (!product) {
        return Product.UNKNOWN;
    } else if (product.match(/flash-?array/i)) {
        return Product.FA;
    } else if (product.match(/flash-?blade/i)) {
        return Product.FB;
    }

    return Product.UNKNOWN;
}

function toProduct(product: Product): string {
    if (!product) {
        return null;
    }
    switch (product) {
        case Product.FA:
            return 'flash-array';
        case Product.FB:
            return 'flash-blade';
        default:
            return null;
    }
}

function mapSubscriptionType(evergreenTier: string): SubscriptionType {
    if (!evergreenTier) {
        return null;
    }
    switch (evergreenTier) {
        case 'Gold':
            return SubscriptionType.FOREVER;
        case 'Silver':
            return SubscriptionType.FOUNDATION;
        default:
            return null;
    }
}

export class UnifiedArray {
    _as_of: moment.Moment;
    aggregation: UnifiedArrayPerformanceAggregation;
    busymeter_aggregation: UnifiedArrayBusymeterAggregation;
    fqdn: string;
    flashArray: boolean;
    flashBlade: boolean;
    flashBladeNorway?: boolean;
    storReduce: boolean;
    helium: boolean;
    cbs: boolean;
    id: string;
    install_address: IArrayInstallAddress;
    install_address_requested: IArrayRequestedAddress;
    isCurrent: boolean;
    name: string;
    organization: IOrganization;
    model: string;
    os: string;
    product: Product;
    version: string;
    cloud_array_id: string;
    appliance_id: string;
    contract_expiration: moment.Moment | null;
    contract_last_renew_requested: number;
    contract_start: moment.Moment | null;
    contract_status: ArrayContractStatus;
    contract_renewal_status: SupportContractRenewalStatus;
    active_status: ResourceStatus; // Only useful for CBS arrays
    safeModeStatus: ArraySafeModeStatus;
    safeMode: ArraySafeMode;
    safe_mode_status_weight: number;
    lastUpdated: string;

    // For Evergreen Forever//Foundation
    subscription_type: SubscriptionType;
    service_tier: ServiceTier;
    chassis_serial_number: string;
    shelf_serial_number: string[];
    city: string;
    country: string;
    capacity: number;
    total_usage: number;
    utilization: number;
    data_reduction: number;
    load_3months_max: number;
    capacity_full100_days: number;
    subscription_license?: ArraySubscriptionLicense;

    // These two are only valid for granular SafeMode
    numberOfVolumes: number;
    numberOfProtectedVolumes: number;

    // Hardware EOL
    has_end_of_life_hardware?: boolean;
    end_of_life_hardware?: IPureArrayEndOfLifeHardware;

    // Expiration date for checking whether the appliance is End of Contract
    // When isUnderSupport() is true and contract_expiration_date is in the past,  support_expiration_date will be now + 1 day.
    // otherwise support_expiration_date is the same as contract_expiration_date.
    support_expiration_date: moment.Moment | null;

    // Available Pre-Installed Capacity
    capacity_expandable?: boolean;
    instant_capacity?: number;

    constructor(json: IConstructorJson) {
        this._as_of = json._as_of ? moment(json._as_of) : null;
        this.aggregation = json.aggregation ? new UnifiedArrayPerformanceAggregation(json.aggregation) : null;
        this.busymeter_aggregation = json.busymeter_aggregation
            ? new UnifiedArrayBusymeterAggregation(json.busymeter_aggregation)
            : null;
        this.fqdn = json.fqdn;
        this.flashArray = json.flashArray;
        this.flashBlade = json.flashBlade;
        this.flashBladeNorway = json.flashBladeNorway;
        this.storReduce = json.storReduce;
        this.helium = json.helium;
        this.cbs = json.cbs;
        this.id = json.id || json.appliance_id || json.array_id;
        this.install_address = json.install_address || null;
        this.install_address_requested = json.install_address_requested || null;
        this.isCurrent = Boolean(json.is_current);
        this.name = json.hostname || json.name || '';
        this.organization = json.org_id ? { id: String(json.org_id) } : null;
        this.model = json.model || '';
        this.os = json.os || '';
        this.product = json.product && getProduct(json.product);
        this.version = json.version || '';
        this.cloud_array_id = json.array_id;
        this.appliance_id = json.appliance_id;
        this.contract_expiration = json.contract_expiration_date ? moment(json.contract_expiration_date) : null;
        this.contract_last_renew_requested = json.contract_last_renew_requested;
        this.contract_start = json.contract_start_date ? moment(json.contract_start_date) : null;
        this.contract_status = json.contract_status || ArrayContractStatus.ACTIVE;
        this.active_status = json.active_status || ResourceStatus.ACTIVE;
        this.safeModeStatus = json.safe_mode_array_status ? toSafeModeStatus(json.safe_mode_array_status) : '-';
        this.safe_mode_status_weight = json.safe_mode_status_weight || SAFE_MODE_DISABLED_WEIGHT;
        this.lastUpdated = !json.last_updated ? NA : moment(json.last_updated * 1000).fromNow();
        this.has_end_of_life_hardware = json.has_end_of_life_hardware;
        this.end_of_life_hardware = json.end_of_life_hardware;
        this.capacity_expandable = json.capacity_expandable;
        this.support_expiration_date = json.support_expiration_date ? moment(json.support_expiration_date) : null;
        this.subscription_license = json.subscription_license
            ? new ArraySubscriptionLicense(json.subscription_license)
            : null;

        this.safeMode = new ArraySafeMode(this.product, json);
        this.contract_renewal_status = json.contract_renewal_status || null;
        this.numberOfVolumes = json.number_of_volumes;
        this.numberOfProtectedVolumes = json.number_of_protected_volumes;

        // For Evergreen Forever//Foundation
        this.subscription_type = mapSubscriptionType(json.evergreen_tier);
        this.service_tier = json.support_tier || null;
        this.chassis_serial_number = json.chassis_serial_number || null;
        this.shelf_serial_number = json.shelf_serial_number || null;
        this.city = json.city || null;
        this.country = json.country || null;
        this.capacity = json.purearray_list_space?.capacity || null;
        this.total_usage = json.purearray_list_space?.total || null;
        this.utilization = json.purearray_list_space?.percent_full || null;
        this.data_reduction = json.purearray_list_space?.data_reduction || null;
        this.instant_capacity = json.purearray_list_space?.instant_capacity || null;
        this.load_3months_max = json.load_analysis?.forecast_3months_max || null;
        this.capacity_full100_days = json.capacity_analysis?.forecast?.linear?.trained_90days?.full100_days || null;
    }

    toRequestBody(): string {
        return JSON.stringify(
            {
                _as_of: this._as_of ? this._as_of.valueOf() : null,
                aggregation: this.aggregation,
                busymeter_aggregation: this.busymeter_aggregation,
                fqdn: this.fqdn,
                id: this.id,
                install_address: this.install_address,
                install_address_requested: this.install_address_requested,
                hostname: this.name !== '' ? this.name : null,
                name: this.name !== '' ? this.name : null,
                org_id: this.organization ? this.organization.id : null,
                model: this.model !== '' ? this.model : null,
                os: this.os !== '' ? this.os : null,
                product: toProduct(this.product),
                version: this.version !== '' ? this.version : null,
                array_id: this.cloud_array_id,
                appliance_id: this.appliance_id,
                contract_expiration_date: this.contract_expiration ? this.contract_expiration.valueOf() : null,
                contract_last_renew_requested: this.contract_last_renew_requested
                    ? this.contract_last_renew_requested.valueOf()
                    : null,
                contract_start_date: this.contract_start ? this.contract_start.valueOf() : null,
                contract_status: this.contract_status,
                contract_renewal_status: this.contract_renewal_status ? this.contract_renewal_status : null,
                active_status: this.active_status !== ResourceStatus.ACTIVE ? this.active_status : null,
                support_expiration_date: this.support_expiration_date ? this.support_expiration_date.valueOf() : null,
            },
            (_key, value) => {
                if (value !== null) {
                    return value;
                } else {
                    return undefined;
                }
            },
        );
    }
}
