import { DraasApiProtectionJobExecutionsSummary } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryProtectionJobSummary {
    readonly total: number;
    readonly completed: number;

    constructor(json: DraasApiProtectionJobExecutionsSummary) {
        this.total = json.total;
        this.completed = json.completed;
    }
}
