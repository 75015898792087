import { Component, Input, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import {
    DataPage,
    FlexHardwareModelsService,
    LicenseUpdate,
    ServiceCatalogOffering,
    WorkloadStats,
    FlexHardwareCapacity,
    FlexHardwareModel,
    FlexProductType,
    FeatureFlagDxpService,
} from '@pure1/data';
import { WINDOW } from '../../../../app/injection-tokens';
import { Angulartics2 } from 'angulartics2';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import moment from 'moment/moment';
import { NgbDateStruct, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { convertToDate } from '../../../../subscription/subscription-utils/subscription-utils';
import { catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService, ToastType } from '../../../../services/toast.service';
import { uniqBy } from 'lodash';
import { FeatureNames } from '../../../../model/FeatureNames';

const SATURDAY = 6;
const SUNDAY = 7;

@Component({
    selector: 'flex-new-site-entry',
    templateUrl: './flex-new-site-entry.component.html',
})
export class FlexNewSiteEntryComponent implements OnInit {
    @Input() readonly serviceCatalog: ServiceCatalogOffering;
    @Input() readonly analyticsLabel: string;
    @Input() readonly workloadStats: WorkloadStats[];
    @Output() readonly siteInfoChanged = new EventEmitter<LicenseUpdate>();
    @Output() readonly deleteTriggered = new EventEmitter<void>();

    @ViewChild('tooltip') readonly tooltip: NgbTooltip;

    // Licenses change between FA or FB flex quote request
    productType: FlexProductType;
    capacitiesLoading: boolean;

    increaseAmount: number;
    increaseAmountInSlider: number; // Separate slider amount makes it so the slider doesn't jump when entering numbers
    sliderOptions: Options;
    siteName: string;
    siteStartDate: NgbDateStruct;
    minDate: NgbDateStruct;
    selectedModel: string; // AKA license type
    selectedWorkload: WorkloadStats;
    selectedPre: boolean;

    flexHardwareModelNames: string[] = [];
    flexHardwareModelsMap = new Map<string, FlexHardwareModel>();
    licenseArrayCapacities: FlexHardwareCapacity[] = [];
    isFeatureEnabled = false; // UUC

    private minReserve = 0;
    private maxReserve = 0;
    private increment: number;
    private needToOffsetInput = true;
    private hasCapacityChanged = false; // For analytics

    readonly CAPACITY_MAX_LENGTH = 5;
    readonly NAME_MAX_LENGTH = 80;
    readonly SUNDAY = SUNDAY;

    constructor(
        @Inject(WINDOW) private window: Window,
        private featureFlagService: FeatureFlagDxpService,
        private flexHardwareModelsService: FlexHardwareModelsService,
        private angulartics2: Angulartics2,
        private toast: ToastService,
    ) {}

    // Used only for NgbDatePicker. NgbDatePicker prefers static binding.
    // You can convert this into the regular functionName(): type {}, but arrow function makes it clear to not have
    // any dynamic bindings
    readonly disablePastAndWeekends = (date: NgbDateStruct) => {
        const day = moment(convertToDate(date));
        const dayOfWeek = day.isoWeekday();
        return day.isSameOrBefore() || dayOfWeek === SATURDAY || dayOfWeek === SUNDAY;
    };

    ngOnInit(): void {
        this.productType = this.serviceCatalog.productName === 'FlashArray' ? 'FA' : 'FB';

        this.featureFlagService
            .getFeatureFlag(FeatureNames.E_FLEX_UUC)
            .subscribe(status => (this.isFeatureEnabled = status?.enabled === true));

        this.flexHardwareModelsService
            .list({ extra: 'product_type=' + this.productType })
            .pipe(
                take(1),
                catchError(error => {
                    const errorMsg = error && (error.data?.message || error.statusText);
                    console.error('Error getting flex hardware capacities: ' + errorMsg);
                    this.toast.add(ToastType.error, 'Error fetching //Flex hardware information. Please try again.');
                    this.capacitiesLoading = false;
                    return of<DataPage<FlexHardwareModel>>(null);
                }),
            )
            .subscribe(dataPage => {
                const rawModelsResponse = dataPage.response;
                rawModelsResponse.forEach(modelObj => {
                    if (modelObj.model) {
                        // In the future, we want to allow for selection between FC/ETH configurations for FlashArray,
                        // but for now we default to FC, so as not to show repeated capacity elements with the same size.
                        if (this.productType === 'FA') {
                            // Sort the array to prefer FC over ETH
                            modelObj.capacity.sort((a, b) => a.name.localeCompare(b.name)).reverse();
                            modelObj.capacity = uniqBy(modelObj.capacity, cap => {
                                return `${cap.rawTB}-${cap.usableTiB}-${cap.maximumEUCTiB}`;
                            });
                        }

                        // Don't show models with no capacity
                        if (modelObj.capacity.length === 0) {
                            return;
                        }

                        // Also ensure capacities are shown in ascending order
                        modelObj.capacity.sort((a, b) => a.rawTB - b.rawTB);
                        this.flexHardwareModelNames.push(this.getModelName(modelObj.model));
                        this.flexHardwareModelsMap.set(this.getModelName(modelObj.model), modelObj);
                    }
                });
                this.flexHardwareModelNames.sort();
                this.capacitiesLoading = false;
            });
        this.increaseAmount = 0;
        this.setSliderOptions(0, 0); // default for initialization, floor and ceil overridden once model changes
    }

    addLicenseArray(): void {
        this.licenseArrayCapacities.push(this.flexHardwareModelsMap.get(this.selectedModel).capacity[0]);
        // shallow copy to ensure object ref is modified for Angular detection after .push()
        this.licenseArrayCapacities = this.licenseArrayCapacities.slice();
        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel,
            properties: { category: 'Action', label: 'License array added' },
        });
        this.updateSliderOptions();
    }

    deleteEntry(): void {
        this.deleteTriggered.emit();
    }

    deleteLicenseArray(index: number): void {
        if (this.licenseArrayCapacities.length > 1) {
            this.licenseArrayCapacities.splice(index, 1);
            // shallow copy to ensure object ref is modified for Angular detection after .push()
            this.licenseArrayCapacities = this.licenseArrayCapacities.slice();

            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'License array deleted' },
            });

            this.updateSliderOptions();
        }
    }

    onCapacityAmountChange(increaseAmountInUnit: number): void {
        // Check to see if this has ever been changed
        if (!this.hasCapacityChanged) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Reserved Capacity Slider changed' },
            });

            this.hasCapacityChanged = true;
        }

        if (this.needToOffsetInput) {
            increaseAmountInUnit = Math.ceil(increaseAmountInUnit / this.increment) * this.increment || 0;
        }
        this.increaseAmountInSlider = increaseAmountInUnit;
        this.increaseAmount = increaseAmountInUnit;
        this.needToOffsetInput = true;

        this.emitUpdateRequest();
    }

    // Flow is user types input -> triggers onInputAmountChange ->
    // user clicks out of box   -> final input gets checked and used
    onCapacityInputAmountChange(amountInUnitText: string): void {
        // Verify input has numbers only and is valid
        const isNumbersOnly = /^[0-9]*$/.test(amountInUnitText);
        if (
            isNumbersOnly &&
            Number(amountInUnitText) >= this.minReserve &&
            Number(amountInUnitText) <= this.maxReserve
        ) {
            if (!this.hasCapacityChanged) {
                this.angulartics2.eventTrack.next({
                    action: this.analyticsLabel,
                    properties: { category: 'Action', label: 'Reserved Capacity Input changed' },
                });

                this.hasCapacityChanged = true;
            }

            this.increaseAmount = Number(amountInUnitText);
            // When the user changes the input using the box, we don't want to offset it
            // The reason for this one-time variable is that (valueChanges) of ngx-slider will trigger
            // even if [increaseAmountInSlider] is changed programmatically
            this.needToOffsetInput = false;
        }
    }

    onCapacityInputBlur(reserveAmountInputElement: HTMLInputElement): void {
        if (reserveAmountInputElement.value === '') {
            this.increaseAmount = this.minReserve;
            this.needToOffsetInput = true;
        } else {
            if (this.increaseAmount < this.minReserve) {
                this.increaseAmount = this.minReserve;
                this.needToOffsetInput = true;
            } else if (this.increaseAmount > this.maxReserve) {
                this.increaseAmount = this.maxReserve;
                this.needToOffsetInput = true;
            }
        }
        this.increaseAmountInSlider = this.increaseAmount;
        reserveAmountInputElement.value = this.increaseAmount.toString();

        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel,
            properties: { category: 'Action', label: 'Reserved Capacity Input changed' },
        });

        this.emitUpdateRequest();
    }

    onFlexHardwareCapacityChange(flexHardwareCapacity: FlexHardwareCapacity, index: number): void {
        this.licenseArrayCapacities[index] = flexHardwareCapacity;
        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel,
            properties: { category: 'Action', label: 'Capacity selection changed' },
        });
        this.updateSliderOptions();
    }

    onModelChange(model: string): void {
        this.selectedModel = model;
        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel,
            properties: { category: 'Action', label: 'License Type (model) changed' },
        });
        this.resetArraysForm();
    }

    onNameChange(newName: string): void {
        // Check to see if this has ever been changed
        if (this.siteName === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'License Details added' },
            });
        }

        this.siteName = newName;

        this.emitUpdateRequest();
    }

    onPreChange(newlySelectedPre: boolean): void {
        this.selectedPre = newlySelectedPre;
        this.angulartics2.eventTrack.next({
            action: this.analyticsLabel,
            properties: { category: 'Action', label: 'Pre-compressed option added' },
        });
        this.emitUpdateRequest();
    }

    onStartDateChange(newStartDate: NgbDateStruct): void {
        // Check to see if this has ever been changed
        if (this.siteStartDate === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Start Date added' },
            });
        }

        this.siteStartDate = newStartDate;

        this.emitUpdateRequest();
    }

    onWorkloadChange(newWorkload: WorkloadStats): void {
        // Check to see if this has ever been changed
        if (this.selectedWorkload === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Workload added' },
            });
        }

        this.selectedWorkload = newWorkload;

        if (this.selectedWorkload?.name === 'Custom') {
            // Open it and have it close after 5 seconds. Since our closeDelay is 5 seconds, we just open and close it
            // Store tooltip locally just in case it gets replaced before closing
            const tooltip = this.tooltip;
            tooltip.open();
            this.window.setTimeout(() => {
                tooltip.close();
            }, moment.duration(5, 'seconds').asMilliseconds());
        }

        this.emitUpdateRequest();
    }

    trackByIndex(index: number, licenseArray: FlexHardwareCapacity): number {
        return index;
    }

    private emitUpdateRequest(): void {
        const currentModel = this.flexHardwareModelsMap.get(this.selectedModel);
        const newSiteInfo: LicenseUpdate = new LicenseUpdate({
            license_id: null,
            site_name: this.siteName,
            license_type: this.selectedModel,
            additional_amount: this.increaseAmount,
            new_site: true,
            start_date: convertToDate(this.siteStartDate),
            workload: {
                name: this.selectedWorkload?.name,
            },
            is_pre: this.selectedPre || false,
            subscription_product_id: currentModel?.id,
            product_ids: this.licenseArrayCapacities?.map(cap => cap.id),
            // TODO: will be removed when we fully switch to use only productIDs
            license_array_capacities: this.licenseArrayCapacities?.map(cap => {
                return {
                    raw: cap.rawTB,
                    usable: cap.usableTiB,
                    euc: cap.minimumEUCTiB,
                };
            }),
        });

        this.siteInfoChanged.emit(newSiteInfo);
    }

    private getModelName(modelName: string): string {
        if (this.isFeatureEnabled || modelName.startsWith('//Flex')) {
            return modelName;
        }

        if (this.productType === 'FA') {
            return '//Flex ' + modelName?.replace('FA-', 'FlashArray ');
        } else {
            return '//Flex ' + modelName?.replace('FB-', 'FlashBlade ');
        }
    }

    private resetArraysForm(): void {
        this.licenseArrayCapacities = [];
        this.increaseAmount = 0;
        this.addLicenseArray();
    }

    private setSliderOptions(floor: number, ceil: number): void {
        this.sliderOptions = {
            floor: floor,
            ceil: ceil,
            showSelectionBar: true,
            hidePointerLabels: true,
            disabled: floor >= ceil,
            translate: (value: number, label: LabelType): string => {
                switch (label) {
                    case LabelType.Floor:
                        return `<div class="slider-custom-label left">MIN ` + value + `</div>`;
                    case LabelType.Ceil:
                        return `<div class="slider-custom-label right">MAX ` + value + `</div>`;
                    default:
                        return String(value);
                }
            },
        };
    }

    private updateSliderOptions(): void {
        let minReserveSum = 0;
        let maxReserveSum = 0;
        this.licenseArrayCapacities?.forEach(licenseArray => {
            minReserveSum += licenseArray.minimumEUCTiB;
            maxReserveSum += licenseArray.maximumEUCTiB;
        });
        this.minReserve = Math.round(minReserveSum);
        this.maxReserve = Math.round(maxReserveSum);

        if (!this.increaseAmount || this.increaseAmount <= this.minReserve) {
            this.increaseAmount = this.minReserve;
        } else if (this.increaseAmount >= this.maxReserve) {
            this.increaseAmount = this.maxReserve;
        }
        this.increaseAmountInSlider = this.increaseAmount;
        this.increment = 1;
        this.setSliderOptions(this.minReserve, this.maxReserve);

        if (this.licenseArrayCapacities != null) {
            this.emitUpdateRequest();
        }
    }
}
