import { Inject, Injectable, OnDestroy } from '@angular/core';
import { datadogRum, RumEvent, RumEventDomainContext } from '@datadog/browser-rum';

import { WINDOW } from './injection-tokens';
import { isPure1Production } from '../utils/url';
import { FeatureFlagService } from '@pure/pure1-ui-platform-angular';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { ActivatedRoute, Data } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DatadogRumConfigService implements OnDestroy {
    private readonly destroy$ = new Subject<void>();
    private performanceMeasured = false;

    constructor(
        @Inject(WINDOW) private window: Window,
        private readonly route: ActivatedRoute,
        private readonly featureFlagService: FeatureFlagService,
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    getCurrentRouteData(): Data {
        let data = {};
        let route: ActivatedRoute = this.route;
        while (route.firstChild) {
            data = { ...data, ...route.snapshot.data };
            route = route.firstChild;
        }
        return data;
    }

    measureMenuLoadingTime(): void {
        if (this.performanceMeasured) {
            return;
        }

        try {
            // Handle old browsers not supporting performance API
            const navigation = this.window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
            datadogRum.addDurationVital('custom__dom_content_loaded', {
                duration: navigation.domContentLoadedEventEnd,
                startTime: performance.timeOrigin,
            });
            datadogRum.addDurationVital('custom__window_loaded', {
                duration: navigation.loadEventEnd,
                startTime: performance.timeOrigin,
            });
            datadogRum.addDurationVital('custom__menu_initialized', {
                duration: new Date().getTime() - performance.timeOrigin,
                startTime: performance.timeOrigin,
            });
        } catch (e) {
            // Satisfy eslint
        }

        this.performanceMeasured = true;
    }

    run(): void {
        datadogRum.init({
            applicationId: '4f097489-1366-4512-b0ea-2e75c1901db1',
            clientToken: 'pub04087cebe3ff8136a59635be8089716c',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: 'pure1-gui',
            env: isPure1Production(this.window.location) ? 'prod' : 'not-prod',
            // Specify a version number to identify the deployed version of your application in Datadog
            version: environment.buildVersion,
            enableExperimentalFeatures: ['feature_flags'],
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            allowedTracingUrls: [this.window.location.origin],
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask',
            beforeSend: (event: RumEvent, context: RumEventDomainContext): boolean => {
                if (event.type === 'error') {
                    const data = this.getCurrentRouteData();
                    const pageOwner = data.ownedBy;

                    if (pageOwner) {
                        event.context.pageOwner = pageOwner;
                    }
                }

                return true;
            },
        });

        this.featureFlagService
            .getFeatureFlagSubscriber()
            .pipe(takeUntil(this.destroy$))
            .subscribe(featureFlags => {
                Object.keys(featureFlags).forEach(key => {
                    const ff = featureFlags[key];

                    datadogRum.addFeatureFlagEvaluation(ff.name, ff.enabled);
                });
            });
    }
}
