import {
    DraasApiPlanRecoverySummary,
    DraasApiRecoveryPlanExtended,
    DraasApiTargetSiteDetails,
} from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';
import { DisasterRecoveryPlan } from '@pure1/data';
import { DisasterRecoverySiteStatus, DisasterRecoverySiteType } from './disaster-recovery-site';

export class DisasterRecoveryPlanExtended {
    recoveryPlan: DisasterRecoveryPlan;
    totalVms: number;
    recoverySummary: DisasterRecoveryPlanSummary;
    targetSiteDetails: DisasterRecoveryTargetSiteDetails;

    constructor(json: DraasApiRecoveryPlanExtended) {
        this.recoveryPlan = new DisasterRecoveryPlan(json.recovery_plan);
        this.totalVms = json.total_vms;
        this.recoverySummary = new DisasterRecoveryPlanSummary(json.recovery_summary);
        this.targetSiteDetails = json.target_site_details
            ? new DisasterRecoveryTargetSiteDetails(json.target_site_details)
            : null;
    }
}

export class DisasterRecoveryPlanSummary {
    success: number;
    failed: number;
    neverRun: number;
    lastRto?: moment.Duration;

    constructor(json: DraasApiPlanRecoverySummary) {
        this.success = json.success;
        this.failed = json.failed;
        this.neverRun = json.never_run;
        this.lastRto = json.last_rto ? moment.duration(json.last_rto) : null;
    }
}

export class DisasterRecoveryTargetSiteDetails {
    id: string;
    name: string;
    type: DisasterRecoverySiteType;
    status: DisasterRecoverySiteStatus;
    version: number;

    constructor(json: DraasApiTargetSiteDetails) {
        this.id = json.id;
        this.name = json.name;
        this.type = json.type;
        this.status = json.status;
        this.version = json.version;
    }
}
