import moment from 'moment';
import {
    DraasApiProtectionGroupExecutionInfo,
    DraasApiRecoveryPlan,
    DraasApiRecoveryPlanExecutionInfo,
} from '@pure/paas-api-gateway-client-ts';

// this enum aggregates multiple enums used in different API endpoints, that's why it contains duplicities
export enum DisasterRecoveryJobExecutionStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESSFUL = 'SUCCESSFUL',
    FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
    FINISHED_PARTIALLY_SUCCESSFULLY = 'FINISHED_PARTIALLY_SUCCESSFULLY',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
    CREATED = 'CREATED',
    BLOCKED = 'BLOCKED',
    CANCELLED = 'CANCELLED',
    CANCELED = 'CANCELED',
    CANCELLING = 'CANCELING',
    COMPLETE = 'COMPLETE', // v1
    COMPLETED = 'COMPLETED', // v2
    MIXED = 'MIXED',
    PAUSED = 'PAUSED',
    RUNNING = 'RUNNING',
    VOLATILE = 'VOLATILE',
    NOT_PROTECTED = 'NOT_PROTECTED',
    UNKNOWN = 'UNKNOWN',
}

export enum DisasterRecoveryJobExecutionPurpose {
    PROTECTION = 'PROTECTION',
    FAILOVER = 'FAILOVER',
    TEARDOWN = 'TEARDOWN',
    FAILBACK = 'FAILBACK',
    UNKNOWN = 'UNKNOWN',
}

export type DraasApiProtectionOrRecoveryJobExecutionInfo =
    | DraasApiProtectionGroupExecutionInfo
    | DraasApiRecoveryPlanExecutionInfo;

export class DisasterRecoveryJobExecution {
    id: string;
    clusterId: string;
    name: string;
    type?: DraasApiRecoveryPlan['plan_type'];
    groupId?: string;
    policy?: string;
    executedOn?: moment.Moment;
    status: DisasterRecoveryJobExecutionStatus;
    start: moment.Moment;
    end?: moment.Moment;
    groupNames?: Map<string, string>;
    purpose?: DisasterRecoveryJobExecutionPurpose;
    description?: string;
    hasPreConversion?: boolean;
    hasLocalRepoAsCache?: boolean;
    sourceProviderId?: string;

    constructor(json: DraasApiProtectionOrRecoveryJobExecutionInfo) {
        this.id = json.execution_info.id;
        this.executedOn = json.execution_info.executed_on ? moment.utc(json.execution_info.executed_on) : null;
        this.status =
            DisasterRecoveryJobExecutionStatus[json.execution_info.status] ||
            DisasterRecoveryJobExecutionStatus.UNKNOWN;
        this.start = moment.utc(json.execution_info.start);
        this.end = json.execution_info.end ? moment.utc(json.execution_info.end) : null;
        this.purpose =
            DisasterRecoveryJobExecutionPurpose[json.execution_info.purpose] ||
            DisasterRecoveryJobExecutionPurpose.UNKNOWN;
        this.description = json.execution_info.description;
    }
}
