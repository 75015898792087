import {
    DraasApiAssetId,
    DraasApiAWSProvider,
    DraasApiAWSRecoveryConfig,
    DraasApiAWSRecoveryNetworkConfig,
    DraasApiAWSRecoveryNetworkEntry,
    DraasApiExtendedAWSNetwork,
    DraasApiVSphereDatastore,
    DraasApiVSphereNetwork,
    DraasApiVSphereProvider,
    DraasApiVSphereRecoveryConfig,
    DraasApiVSphereRecoveryNetworkConfig,
    DraasApiVSphereRecoveryNetworkEntry,
    DraasApiVSphereRecoveryStorageConfig,
    DraasApiVSphereRecoveryStorageEntry,
} from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';
import { DisasterRecoveryNode } from '@pure1/data';

export class DisasterRecoveryProvider {
    id: string;
    clusterId: string;
    name: string;
    type: 'VSPHERE' | 'AWS';
    state: 'PENDING' | 'VALID' | 'INVALID' | 'UNKNOWN';
    lastUpdatedAt: moment.Moment;
    providerVersion: number;

    constructor(json: DraasApiVSphereProvider | DraasApiAWSProvider) {
        this.id = json.provider_id;
        this.clusterId = json.cluster_id;
        this.type = json.provider_type;
        this.name = json.name ?? `${json.provider_type}:${json.provider_id}`;
        this.state = json.status;
        this.lastUpdatedAt = json.provider_last_updated_at_utc ? moment.utc(json.provider_last_updated_at_utc) : null;
        this.providerVersion = json.provider_version;
    }
}

export class DisasterRecoveryVSphereProvider extends DisasterRecoveryProvider {
    hostname: string;
    network: VSphereProviderNetwork;
    storage: VSphereProviderStorage;
    recoveryConfig: DisasterRecoveryVSphereRecoveryConfig;

    constructor(json: DraasApiVSphereProvider) {
        super(json);
        this.hostname = json.hostname;
        this.network = json.network ? new VSphereProviderNetwork(json.network) : null;
        this.storage = json.data_storage ? new VSphereProviderStorage(json.data_storage) : null;
        this.recoveryConfig = json.recovery_config
            ? new DisasterRecoveryVSphereRecoveryConfig(json.recovery_config)
            : null;
    }

    isComplete(nodes: DisasterRecoveryNode[]): boolean {
        return (
            this.state === 'VALID' &&
            this.network != null &&
            this.storage != null &&
            nodes.length > 0 &&
            nodes.every(node => node.status === 'HEALTHY' || node.status === 'READY')
            // Why the fuck doesn't API return this
        );
    }
}

export class VSphereProviderNetwork {
    gatewayIp: string | null;
    managementCidr: string | null;
    dnsIps: string[] | null;
    domainNames: string[] | null;
    externalCidr: string | null;
    internalCidr: string | null;

    constructor(json: DraasApiVSphereNetwork) {
        this.gatewayIp = json.gateway_ip ?? null;
        this.managementCidr = json.management_cidr ?? null;
        this.dnsIps = json.dns_ips ?? null;
        this.domainNames = json.domain_names ?? null;
        this.externalCidr = null;
        this.internalCidr = null;
    }
}

export class VSphereProviderStorage {
    datastores: VSphereProviderDatastore[];

    constructor(json: any) {
        this.datastores = json.datastores.map(datastore => new VSphereProviderDatastore(datastore));
    }
}

export class VSphereProviderDatastore {
    dataStoreId: string;
    currentBytes: number | null;
    quotaBytes: number;
    used: boolean;

    constructor(json: DraasApiVSphereDatastore) {
        this.dataStoreId = json.datastore;
        this.currentBytes = json.current_bytes ?? null;
        this.quotaBytes = json.quota_bytes;
        this.used = json.used;
    }
}

export class DisasterRecoveryAwsProvider extends DisasterRecoveryProvider {
    network: AwsProviderNetwork | null;
    recoveryConfig: DisasterRecoveryAwsProviderRecoveryConfig | null;

    constructor(json: DraasApiAWSProvider) {
        super(json);
        this.network = json.network ? new AwsProviderNetwork(json.network) : null;
        this.recoveryConfig = json.recovery_config
            ? new DisasterRecoveryAwsProviderRecoveryConfig(json.recovery_config)
            : null;
    }

    isComplete(nodes: DisasterRecoveryNode[]): boolean {
        return (
            this.network != null &&
            nodes.length > 0 &&
            nodes.every(node => node.status === 'HEALTHY' || node.status === 'READY')
            // Why the fuck doesn't API return this
        );
    }
}

export class AwsProviderNetwork {
    existingVpcId: string | null;
    vpcCidr: string | null;

    constructor(json: DraasApiExtendedAWSNetwork) {
        this.existingVpcId = json.existing_vpc_id;
        this.vpcCidr = json.vpc_cidr;
    }
}

export class DisasterRecoveryVSphereRecoveryConfig {
    storageConfig: DisasterRecoveryVSphereRecoveryStorageConfig;
    networkConfig: DisasterRecoveryVSphereRecoveryNetworkConfig;

    constructor(json: DraasApiVSphereRecoveryConfig) {
        this.storageConfig = json.storage_config
            ? new DisasterRecoveryVSphereRecoveryStorageConfig(json.storage_config)
            : null;
        this.networkConfig = json.network_config
            ? new DisasterRecoveryVSphereRecoveryNetworkConfig(json.network_config)
            : null;
    }
}

export class DisasterRecoveryVSphereRecoveryStorageConfig {
    entries: DisasterRecoveryVSphereRecoveryStorageEntry[];

    constructor(json: DraasApiVSphereRecoveryStorageConfig) {
        this.entries = json.entries?.map(it => new DisasterRecoveryVSphereRecoveryStorageEntry(it));
    }
}

export class DisasterRecoveryVSphereRecoveryStorageEntry {
    datastoreId: DisasterRecoveryAssetId;

    constructor(json: DraasApiVSphereRecoveryStorageEntry) {
        this.datastoreId = new DisasterRecoveryAssetId(json.datastore_id);
    }
}

export class DisasterRecoveryAssetId {
    id: string;
    platformId: string;

    constructor(json: DraasApiAssetId) {
        this.id = json.id;
        this.platformId = json.platform_id;
    }
}

export class DisasterRecoveryAwsProviderRecoveryConfig {
    networkConfig: DisasterRecoveryAwsProviderNetworkConfig;

    constructor(json: DraasApiAWSRecoveryConfig) {
        this.networkConfig = json.network_config
            ? new DisasterRecoveryAwsProviderNetworkConfig(json.network_config)
            : null;
    }
}

export class DisasterRecoveryAwsProviderNetworkConfig {
    entries: DisasterRecoveryAwsRecoveryNetworkEntry[];

    constructor(json: DraasApiAWSRecoveryNetworkConfig) {
        this.entries = json.entries?.map(it => new DisasterRecoveryAwsRecoveryNetworkEntry(it));
    }
}

export class DisasterRecoveryAwsRecoveryNetworkEntry {
    subnetId: DisasterRecoveryAssetId;
    securityGroupId: DisasterRecoveryAssetId;

    constructor(json: DraasApiAWSRecoveryNetworkEntry) {
        this.subnetId = json.subnet_id ? new DisasterRecoveryAssetId(json.subnet_id) : null;
        this.securityGroupId = json.security_group_id ? new DisasterRecoveryAssetId(json.security_group_id) : null;
    }
}

export class DisasterRecoveryVSphereRecoveryNetworkConfig {
    entries: DisasterRecoveryVSphereRecoveryNetworkEntry[];

    constructor(json: DraasApiVSphereRecoveryNetworkConfig) {
        this.entries = json.entries?.map(it => new DisasterRecoveryVSphereRecoveryNetworkEntry(it));
    }
}

export class DisasterRecoveryVSphereRecoveryNetworkEntry {
    portGroupId: DisasterRecoveryAssetId;
    switchId: DisasterRecoveryAssetId;
    ipRange: string;

    constructor(json: DraasApiVSphereRecoveryNetworkEntry) {
        this.portGroupId = json.port_group_id ? new DisasterRecoveryAssetId(json.port_group_id) : null;
        this.switchId = json.vswitch_id ? new DisasterRecoveryAssetId(json.vswitch_id) : null;
        this.ipRange = json.ip_range;
    }
}
