import moment from 'moment';
import { QuoteRequestedProperties, UsageMetricsTypeSwitchedProperties } from '../../ampli';
import { Metric } from './metrics-history';
import { QuoteType } from './service-catalog-quote';

export enum ProgramType {
    PURE_AS_A_SERVICE = 'Pure as-a-Service', // soon to be deprecated
    FLASH_STACK_AS_A_SERVICE = 'FlashStack as a Service',
    EVERGREEN_FLEX = 'Evergreen//Flex',
    EVERGREEN_FOREVER = 'Evergreen//Forever',
    EVERGREEN_FOUNDATION = 'Evergreen//Foundation',
    EVERGREEN_ONE = 'Evergreen//One',
    PURE_PROTECT = 'Pure Protect',
    UNIVERSAL_CREDITS = 'Universal Credits',
    PURE_SKILL = 'Pure Skill',
    PURE_SKILL_ON_DEMAND = 'Pure Skill On-Demand',
    PAAS_BLOCK_STORAGE_SERVICE = 'PaaS (Block Storage Service)', // deprecated
    PAAS_FILE_OBJECT_STORAGE_SERVICE = 'PaaS (File and Object Storage Service)', // deprecated
    PAAS_DATA_PROTECTION_SERVICE = 'PaaS (Data Protection Service)', // deprecated
    PURE1_SUBSCRIPTION = 'Pure1 Subscription', // deprecated
    UNKNOWN = 'UNKNOWN',
}

export function convertToAmpliQuoteRequestConfig(
    programType: ProgramType,
    quoteType: QuoteType,
    page?: string,
): QuoteRequestedProperties['requested configuration'] {
    if (programType === null) {
        //If the call comes from catalog modal and programType is null, it is for Professional Service requests.
        //Because for Professional Service, it doesn't have any programType and LicenseType on SFDC
        if (page === 'catalog detail page') {
            return ('Professional Service ' +
                mappingToAmpliQuoteType(quoteType)) as QuoteRequestedProperties['requested configuration'];
        }
        console.error(`Unhandled Program Type, Program type: ${programType}`);
        return 'unknown';
    }
    if (quoteType === QuoteType.SUPPORT_CONTRACT_RENEWAL) {
        return 'evergreen//forever&foundation renewal';
    } else if (quoteType !== QuoteType.UNKNOWN) {
        return (programType.toLowerCase() +
            ' ' +
            mappingToAmpliQuoteType(quoteType)) as QuoteRequestedProperties['requested configuration'];
    } else {
        console.error(`Unhandled Quote Type, Quote type: ${quoteType}`);
        return 'unknown';
    }
}

function mappingToAmpliQuoteType(quoteType: QuoteType): string {
    if (quoteType === QuoteType.FLEX || quoteType === QuoteType.REGULAR_QUOTE) {
        return 'new';
    } else {
        return String(quoteType).toLowerCase();
    }
}

export enum SubscriptionStatus {
    PENDING_EMAIL_CONFIRMATION = 'Pending Email Confirmation',
    ACTIVE = 'Active',
    TERMINATED = 'Terminated',
    EXPIRED = 'Expired',
    UNDER_CONTRACT_WITH_GRACE_PERIOD = 'Under Contract with Grace Period',
    UNDER_CONTRACT_EXTENDED_MAINTENANCE = 'Under Contract - Extended Maintenance',
    UNDER_CONTRACT = 'Under Contract',
}

export function isSubscriptionActive(status: SubscriptionStatus): boolean {
    return (
        status === SubscriptionStatus.ACTIVE ||
        status === SubscriptionStatus.UNDER_CONTRACT ||
        status === SubscriptionStatus.UNDER_CONTRACT_WITH_GRACE_PERIOD ||
        status === SubscriptionStatus.UNDER_CONTRACT_EXTENDED_MAINTENANCE
    );
}

export function convertToAmpliSubscriptionProgramTypeConfig(
    programType: ProgramType,
): any['subscription program type'] {
    return String(programType);
}

export function convertToAmpliUsageMetricsTypeConfig(
    metric: Metric,
): UsageMetricsTypeSwitchedProperties['subscription usage metrics types'] {
    switch (metric) {
        case Metric.LICENSE_EFFECTIVE_USED_SPACE:
        case Metric.LICENSE_VM_INSTANCE_COUNT:
            return 'License Usage Summary';
        case Metric.LICENSE_LOGICAL_MANAGED_CAPACITY:
            return 'Logical Managed Capacity License';
        case Metric.LICENSE_EUC_BREAKDOWN:
            return 'License Usage Details';
        case Metric.DRAAS_CLUSTER_LOGICAL_MANAGED_CAPACITY:
            return 'Logical Managed Capacity DRaaS Cluster';
        case Metric.ARRAY_EFFECTIVE_USED_SPACE:
            return 'Appliance Usage Summary';
        case Metric.VM_CLUSTER_VM_INSTANCE_COUNT:
            return 'Cluster Usage Summary';
        default:
            return 'Unknown';
    }
}

export class Subscription {
    readonly id: string;
    readonly name: string;
    readonly orgId: number;
    readonly orgName: string;
    readonly programType: ProgramType;
    readonly startDate: moment.Moment;
    readonly endDate: moment.Moment;
    readonly lastUpdated: moment.Moment;
    readonly status: SubscriptionStatus;
    readonly lastUpgradeRequested: moment.Moment;
    readonly upgradable: boolean;
    readonly isPoc: boolean;
    readonly subscriptionDeliveryPartner?: string;
    readonly darkSite: boolean;
    readonly subscriptionPartnerOrg: number;
    readonly subscriptionBillingPartnerContactName: string;
    readonly subscriptionBillingPartnerContactEmail: string;
    readonly subscriptionPartnerAeContact: string;
    readonly subscriptionPartnerAeEmail: string;
    readonly partnerAccountName: string;
    readonly isRansomwareProtectionEnabled: boolean;
    readonly usingUniversalCredits: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.orgId = json.org_id || null;
        this.orgName = json.org_name;
        this.programType = json.program_type;
        this.startDate = moment.utc(json.start_date);
        this.endDate = moment.utc(json.end_date);
        this.lastUpdated = moment.utc(json.last_updated);
        this.status = json.status;
        this.lastUpgradeRequested = json.last_upgrade_requested ? moment.utc(json.last_upgrade_requested) : null;
        this.upgradable = json.upgradable;
        this.isPoc = json.is_poc;
        this.subscriptionDeliveryPartner = json.subscription_delivery_partner;
        this.darkSite = json.dark_site;
        this.subscriptionPartnerOrg = json.subscription_partner_org;
        this.subscriptionBillingPartnerContactName = json.subscription_billing_partner_contact_name || null;
        this.subscriptionBillingPartnerContactEmail = json.subscription_billing_partner_contact_email || null;
        this.subscriptionPartnerAeContact = json.subscription_partner_ae_contact || null;
        this.subscriptionPartnerAeEmail = json.subscription_partner_ae_email || null;
        this.partnerAccountName = json.partner_account_name || null;
        this.isRansomwareProtectionEnabled = json.ransomware || false;
        this.usingUniversalCredits = json.using_universal_credits || false;
    }

    isTerminated(): boolean {
        // We treat subscriptions that are pending email confirmation as if they are terminated.
        // i.e. They are not active, but are still visible in the GUI.
        return (
            this.status === SubscriptionStatus.TERMINATED ||
            this.status === SubscriptionStatus.PENDING_EMAIL_CONFIRMATION
        );
    }

    isExpired(): boolean {
        return this.status === SubscriptionStatus.EXPIRED;
    }
}
