import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrderInstallation, OrderInstallationRequestedTimeRangeDate, OrderInstallationType } from '../index';

export type UpdateOrderScheduleAddressRequest = {
    address_lines: string[];
    postal_code: string;
    locality: string;
    administrative_area: string;
    region_code: string;
};

export type UpdateOrderInstallationRequestedTimeRangeTimeRequest = {
    day_of_week: string;
    start_time: string;
    end_time: string;
};

export type UpdateOrderInstallationRequestedTimeRangeRequest = {
    time_zone: string;
    start_date: OrderInstallationRequestedTimeRangeDate;
    end_date: OrderInstallationRequestedTimeRangeDate;
    time_ranges: UpdateOrderInstallationRequestedTimeRangeTimeRequest[];
};

export type UpdateScheduleRequest = {
    opportunity_id: string;
    order_number: string;
    install_type: OrderInstallationType;
    address?: UpdateOrderScheduleAddressRequest;
    requested_time_range?: UpdateOrderInstallationRequestedTimeRangeRequest;
    additional_request?: string;
    rescheduling_reason?: string;
    rescheduling_message?: string;
};

export type UpdateInstallationScheduleRequest = {
    update_schedules: UpdateScheduleRequest[];
};

export type CancelScheduleRequest = {
    opportunity_id: string;
    order_number: string;
    install_type: OrderInstallationType;
    additional_request?: string;
};

export type CancelInstallationScheduleRequest = {
    cancel_schedules: CancelScheduleRequest[];
};

@Injectable({ providedIn: 'root' })
export class InstallationSchedulesService {
    private readonly baseUrl = '/api/1.0/installation-schedule';

    constructor(private http: HttpClient) {}

    getInstallationSchedules(referenceId: string): Observable<OrderInstallation> {
        return this.http
            .get<any>(`${this.baseUrl}/get-installation-schedules`, { params: { opportunity_id: referenceId } })
            .pipe(map(response => new OrderInstallation(response)));
    }

    updateInstallationSchedule(updateRequest: UpdateInstallationScheduleRequest): Observable<void> {
        return this.http
            .patch<any>(`${this.baseUrl}/update-installation-schedules`, updateRequest)
            .pipe(map(_response => void 0));
    }

    cancelInstallationSchedule(cancelRequest: CancelInstallationScheduleRequest): Observable<void> {
        return this.http
            .patch(`${this.baseUrl}/cancel-installation-schedules`, cancelRequest)
            .pipe(map(_response => void 0));
    }
}
