<div id="arrays-list">
    <table
        class="list-view with-preambule arrays-list-view table table-hover table-bordered"
        [ngClass]="'entity-type-' + mode"
    >
        <thead class="thead-default">
            <tr>
                <ng-container *ngFor="let cell of tableConfig">
                    <th
                        *ngIf="checkShowColumn(cell.showColumn)"
                        (click)="clickHeading(cell)"
                        [class]="'weight-' + cell.sizeWeight"
                        [ngClass]="{
                            'manual-sort': cell.sortRuleId != null,
                            'st-sort-ascent': cell.sortRuleId === sortRuleId && !sortDesc,
                            'st-sort-descent': cell.sortRuleId === sortRuleId && sortDesc,
                        }"
                    >
                        {{ cell.title }}
                        <svg
                            *ngIf="cell.title === 'Instant Capacity'"
                            [height]="13"
                            hive-svg="info-circle.svg"
                            hiveTooltip
                            hiveTooltipDescription="Additional pre-installed Usable TiB available for expansion."
                            hiveTooltipPosition="bottomLeft"
                        ></svg>
                    </th>
                </ng-container>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of items; trackBy: 'id' | trackByProperty">
                <!-- Non-Expired OR user part of 'PURE' org category, else show expired template -->
                <ng-container *ngIf="canShowArrayInfo(item); else expiredApplianceTableRowView">
                    <!-- Current -->
                    <ng-container *ngIf="item.isCurrent && item.active_status !== ResourceStatus.SUSPENDED">
                        <ng-container *ngFor="let cell of tableConfig">
                            <td
                                *ngIf="checkShowColumn(cell.showColumn)"
                                [class]="'array-name weight-' + cell.sizeWeight"
                            >
                                <!-- Alert -->
                                <div *ngIf="cell.type === 'alert'">
                                    <a
                                        [ngbPopover]="alertPopoverTemplate"
                                        popoverClass="{{
                                            hasEolComponents(item) ? 'array-alerts-popover-eol' : 'array-alerts-popover'
                                        }}"
                                        triggers="click"
                                        placement="right"
                                        container="body"
                                        autoClose="outside"
                                    >
                                        <pure-alert-indicator
                                            class="alert-indicator list-alert-indicator"
                                            [iconHeight]="16"
                                            [alerts]="getArrayAlerts(item)"
                                            [array]="item"
                                        />
                                    </a>
                                    <ng-template #alertPopoverTemplate>
                                        <array-alerts-popover [array]="item" [alerts]="getArrayAlerts(item)" />
                                    </ng-template>
                                </div>

                                <!-- array name link -->
                                <div
                                    *ngIf="cell.type === 'array-name'"
                                    class="array-name-cell"
                                    data-toggle="tooltip"
                                    data-delay="5"
                                    title="{{ $any(item).hostname }}"
                                >
                                    <a
                                        [arrayHref]="$any(item).arrayId"
                                        [ngbTooltip]="item.fqdn || $any(item).hostname"
                                        placement="top"
                                        container="body"
                                    >
                                        {{ getCellValue(cell, item) }}
                                    </a>
                                    <capacity-down-licensing-icon
                                        *ngIf="
                                            $any(item).capacityData?.capacityExpandable && capacityDownLicensingEnabled
                                        "
                                        [applianceId]="$any(item).arrayId"
                                    ></capacity-down-licensing-icon>
                                </div>

                                <!-- Capacity -->
                                <div
                                    *ngIf="cell.type === 'capacity'"
                                    class="right-align"
                                    title="Total used: {{
                                        $any(item).capacityData.capacityUsedStr.value +
                                            ' ' +
                                            $any(item).capacityData.capacityUsedStr.unit
                                    }}"
                                >
                                    <capacity-progress-bar
                                        [percent]="$any(item).capacityData.normalizedPercFull"
                                        percentagePosition="lower-left"
                                    ></capacity-progress-bar>
                                </div>

                                <!-- eol link -->
                                <div *ngIf="cell.type === 'eol'">
                                    <ng-container *ngIf="item.has_end_of_life_hardware; else nonEol">
                                        <div [ngSwitch]="hwEolRequestStatus.get(item.cloud_array_id)">
                                            <div *ngSwitchCase="'not_started'">
                                                <span
                                                    class="eol-contact-link"
                                                    [ngClass]="{ 'no-access': !hasContactAccountTeamAccess }"
                                                    *ngIf="
                                                        !hwEolIncidentOpened.get(item.cloud_array_id);
                                                        else viewRecommendation
                                                    "
                                                    (click)="clickContactAccountTeam(item)"
                                                >
                                                    Contact Account Team
                                                </span>
                                                <ng-template #viewRecommendation>
                                                    <span
                                                        class="eol-view-recommendation-link"
                                                        [ngClass]="{
                                                            'no-access': !hasPermissionToViewRecommendation(),
                                                        }"
                                                        (click)="clickViewRecommendation(item)"
                                                    >
                                                        View Recommendation
                                                    </span>
                                                </ng-template>
                                            </div>
                                            <div *ngSwitchCase="'in_progress'" class="eol-contact-in-progress">
                                                <pureui-spinner
                                                    class="eol-spinner"
                                                    [pureuiShow]="true"
                                                ></pureui-spinner>
                                            </div>
                                            <span *ngSwitchCase="'done'" class="eol-contact-done">
                                                Account Team Contacted
                                            </span>
                                            <span *ngSwitchCase="'error'" class="eol-contact-error">
                                                An error occurred, please try again.
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #nonEol>-</ng-template>
                                </div>

                                <!-- All others NOTE: must leave this on one line in case of the pre-formatted class -->
                                <div
                                    [ngClass]="{
                                        'array-list-table-value-pre-formatted': cell.title === 'Data Stores (deduped)',
                                    }"
                                    *ngIf="cell.type === 'value'"
                                    >{{ getCellValue(cell, item) }}</div
                                >
                            </td>
                        </ng-container>
                    </ng-container>

                    <!-- Not current -->
                    <ng-container
                        *ngIf="!item.isCurrent || item.active_status === ResourceStatus.SUSPENDED"
                        class="no-results-row"
                    >
                        <ng-container class="capacity-down-disabled">
                            <td [class]="'weight-' + tableConfig[0].sizeWeight"></td>
                            <td [class]="'array-name weight-' + tableConfig[1].sizeWeight">
                                {{ item.name }}
                            </td>
                        </ng-container>
                        <td
                            [class]="'array-disconnected-message-wrapper weight-' + getDisconnectedCellWeight()"
                            [attr.colspan]="getFullColumnWidth()"
                        >
                            <div class="array-disconnected-message">
                                <ng-container
                                    *ngIf="item.active_status === ResourceStatus.SUSPENDED; else disconnectedCloud"
                                >
                                    <pureui-svg class="suspended-cbs-icon" svg="suspended.svg" height="18"></pureui-svg>
                                    Suspended
                                </ng-container>
                                <ng-template #disconnectedCloud>
                                    <pureui-reporting-icon></pureui-reporting-icon>
                                    <span>Last Updated {{ $any(item).lastUpdated }}</span>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
                <ng-template #expiredApplianceTableRowView>
                    <td [class]="'weight-' + tableConfig[0].sizeWeight">
                        <pure-alert-indicator
                            class="alert-indicator list-alert-indicator"
                            [iconHeight]="16"
                            [alerts]="null"
                        ></pure-alert-indicator>
                    </td>
                    <ng-container class="capacity-down-enabled" *ngIf="capacityDownLicensingEnabled">
                        <td [class]="'array-name weight-' + tableConfig[2].sizeWeight">
                            <div data-toggle="tooltip" data-delay="5" title="{{ $any(item).hostname }}">
                                <a
                                    [arrayHref]="$any(item).arrayId"
                                    [ngbTooltip]="item.fqdn || $any(item).hostname"
                                    placement="top"
                                    container="body"
                                >
                                    {{ item.name }}
                                </a>
                            </div>
                        </td>
                        <td [class]="'weight-' + tableConfig[3].sizeWeight">
                            {{ item.model }}
                        </td>
                        <td [class]="'weight-' + tableConfig[4].sizeWeight">
                            {{ item.version }}
                        </td>
                    </ng-container>
                    <ng-container class="capacity-down-disabled" *ngIf="!capacityDownLicensingEnabled">
                        <td [class]="'array-name weight-' + tableConfig[1].sizeWeight">
                            <div data-toggle="tooltip" data-delay="5" title="{{ $any(item).hostname }}">
                                <a
                                    [arrayHref]="$any(item).arrayId"
                                    [ngbTooltip]="item.fqdn || $any(item).hostname"
                                    placement="top"
                                    container="body"
                                >
                                    {{ item.name }}
                                </a>
                            </div>
                        </td>
                        <td [class]="'weight-' + tableConfig[2].sizeWeight">
                            {{ item.model }}
                        </td>
                        <td [class]="'weight-' + tableConfig[3].sizeWeight">
                            {{ item.version }}
                        </td>
                    </ng-container>
                    <td [class]="'array-disconnected-message-wrapper weight-' + getExpiredCellWeight()" colspan="14">
                        <ng-container
                            *ngIf="
                                arraysManager.isEndOfContractCardViewEnabled(eocEnabled, item.support_expiration_date);
                                else expiredView
                            "
                        >
                            <appliance-out-of-contract-template
                                [selectedArray]="item"
                            ></appliance-out-of-contract-template>
                        </ng-container>
                        <ng-template #expiredView>
                            <appliance-expired-template
                                [selectedArray]="item"
                                [analyticsHeader]="'Appliances - Table'"
                                [supportIconSize]="25"
                                [renewingIconSize]="25"
                                [selectedArrayExistingQuote]="selectedArrayExistingQuote"
                            >
                            </appliance-expired-template>
                        </ng-template>
                    </td>
                </ng-template>
            </tr>
        </tbody>
    </table>
</div>
