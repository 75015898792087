import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { DataPage } from '../interfaces/data-page';
import { ListParams } from '../interfaces/list-params';
import { ArrayData } from '../models/assessment-array';
import { GenericService } from './generic.service';
import { DataProtectionV2ChartDrrData, DataProtectionV2DrrVolume } from '../models/data-protection-v2-drr-volume';

interface ChartDataWithTimestamp {
    data: DataProtectionV2ChartDrrData;
    asOf: moment.Moment;
}

const CHART_DATA_CACHE_LIFETIME = moment.duration(30, 'minutes');

@Injectable({
    providedIn: 'root',
})
export class DataProtectionV2DrrChartDataService
    extends GenericService<DataProtectionV2ChartDrrData>
    implements OnDestroy
{
    private cachedChartData: Map<string, ChartDataWithTimestamp> = new Map();

    constructor(protected readonly http: HttpClient) {
        super({
            resourceClass: DataProtectionV2ChartDrrData,
            endpoint: '/rest/v1/dpa/drr-chart',
        });
    }

    list(
        params?: ListParams<ArrayData>,
        volumes?: DataProtectionV2DrrVolume[],
    ): Observable<DataPage<DataProtectionV2ChartDrrData>> {
        const listFunc = (
            params?: {},
            volumes?: DataProtectionV2DrrVolume[],
            cachedData?: DataProtectionV2ChartDrrData[],
        ): Observable<DataPage<DataProtectionV2ChartDrrData>> => {
            const extra = volumes?.length
                ? {
                      extra: `applianceID=${volumes[0].applianceID}&orgID=${volumes[0].orgID}${volumes.map(v => '&volumeIDs=' + v.volumeID).join('')}`,
                  }
                : {};
            return super.list({ ...extra, sort: undefined }).pipe(
                tap(data => {
                    data.response.forEach(resp => this.addCachedValue(resp.volumeID, resp));
                    data.response.push(...cachedData);
                }),
            );
        };
        return this.listWithCachedValues(volumes, listFunc);
    }

    ngOnDestroy(): void {
        this.cachedChartData.clear();
    }

    private listWithCachedValues(
        volumes: DataProtectionV2DrrVolume[],
        next: (
            params?: {},
            volumes?: DataProtectionV2DrrVolume[],
            cachedData?: DataProtectionV2ChartDrrData[],
        ) => Observable<DataPage<DataProtectionV2ChartDrrData>>,
    ): Observable<DataPage<DataProtectionV2ChartDrrData>> {
        const notCachedVolumes: DataProtectionV2DrrVolume[] = [];
        const cachedData: DataProtectionV2ChartDrrData[] = [];
        volumes?.forEach(volume => {
            if (this.isCachedValue(volume.volumeID)) {
                cachedData.push(this.cachedChartData.get(volume.volumeID).data);
            } else {
                // volume data should be requested from BE
                notCachedVolumes.push(volume);
            }
        });

        if (notCachedVolumes.length === 0 && volumes?.length) {
            return of({ response: cachedData, asOf: Date.now(), total: cachedData.length });
        } else {
            // call list without list parameters, chart data shouldn't be sorted/filtered etc.
            return next({}, notCachedVolumes, cachedData);
        }
    }

    private isCachedValue(volumeID: string): boolean {
        const value = this.cachedChartData.get(volumeID);
        // if data in cache are longer than its lifetime => should be overrided
        return value && moment().diff(value.asOf) <= CHART_DATA_CACHE_LIFETIME.asMilliseconds();
    }

    private addCachedValue(volumeID: string, data: DataProtectionV2ChartDrrData): void {
        this.cachedChartData.set(volumeID, { data, asOf: moment() });
    }
}
