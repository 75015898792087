import { DraasApiPublicKeySecret } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryEncryptionKey {
    readonly id: string;
    readonly publicKeyB64: string;

    constructor(json: DraasApiPublicKeySecret) {
        this.id = json.id;
        this.publicKeyB64 = json.public_key_b64;
    }
}
