import { Injectable } from '@angular/core';
import { DraasApiConfig } from './disaster-recovery-constants';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { DraasApiCredentialsChangeRequest, DraasApiVSphereCredentials } from '@pure/paas-api-gateway-client-ts';
import { DisasterRecoveryCredentialsChangeRequest } from '../models/disaster-recovery-credentials-change-request';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';
import { DisasterRecoverySecretsService } from './disaster-recovery-secrets.service';
import { DisasterRecoveryEncryptionService } from './disaster-recovery-encryption.service';
import { DisasterRecoveryEncryptionKey } from '../models/disaster-recovery-encryption-key';
import { defaultIfEmpty } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryDeploymentsV3Service {
    constructor(
        protected http: DisasterRecoveryThrottlingHttpClient,
        private secretsService: DisasterRecoverySecretsService,
        private encryptionService: DisasterRecoveryEncryptionService,
    ) {}

    // tries to encrypt credentials (if an environment supports that) and submits them to the new endpoint
    // otherwise, submits plain credentials to the old endpoint
    saveVSphereCredentials(
        clusterId: string,
        providerId: string,
        plainCredentials: DraasApiVSphereCredentials,
    ): Observable<DisasterRecoveryCredentialsChangeRequest> {
        return this.encryptCredentials(clusterId, plainCredentials)
            .pipe(
                map(encryptedCredentials => ({
                    apiVersion: '3.0',
                    credentials: encryptedCredentials,
                })),
                defaultIfEmpty({
                    apiVersion: '2.0',
                    credentials: plainCredentials,
                }),
                switchMap(result =>
                    this.http.post<DraasApiCredentialsChangeRequest>(
                        `${DraasApiConfig.getUrlPrefix()}/api/${result.apiVersion}/deployments/${clusterId}/vsphere/${providerId}/config/credentials`,
                        result.credentials,
                    ),
                ),
            )
            .pipe(map(changeRequest => new DisasterRecoveryCredentialsChangeRequest(changeRequest)));
    }

    private encryptCredentials(
        clusterId: string,
        credentials: DraasApiVSphereCredentials,
    ): Observable<DraasApiVSphereCredentials> {
        return this.secretsService.getEncryptionKey(clusterId).pipe(
            switchMap(encryptionKey =>
                forkJoin({
                    username$: this.encryptValue(credentials.username, encryptionKey),
                    password$: this.encryptValue(credentials.password, encryptionKey),
                }),
            ),
            map(encrypted => ({
                hostname: credentials.hostname,
                username: encrypted.username$,
                password: encrypted.password$,
            })),
        );
    }

    private encryptValue(value: string, encryptionKey: DisasterRecoveryEncryptionKey): Observable<string> {
        return this.encryptionService.encryptWithRSA(value, encryptionKey.publicKeyB64);
    }
}
