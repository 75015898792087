<div
    *ngIf="alwaysVisible || !drawerClosed"
    class="drawer-root"
    [@openCloseAnimation]="{
        value: drawerClosed ? 'closed' : 'open',
        params: { width: calculatedAnimationWidth + 'px' },
    }"
>
    <button class="close-drawer-button" (click)="closeDrawer()">
        <pureui-svg svg="detailed_cancel_cross.svg" height="16"></pureui-svg>
    </button>

    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>
