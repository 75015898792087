import { DraasApiAwsSecurityGroup } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryAwsSecurityGroup {
    id: string;
    clusterId: string;
    providerId: string;
    platformId: string;
    vpcId: string;
    groupName: string;

    constructor(json: DraasApiAwsSecurityGroup) {
        this.id = json.id;
        this.clusterId = json.cluster_id;
        this.providerId = json.provider_id;
        // Intentionally using platformId instead of securityGroupId as it would be confusing
        this.platformId = json.security_group_id;
        this.vpcId = json.vpc_id;
        this.groupName = json.group_name;
    }
}
