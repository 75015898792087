import { DraasApiAwsSubnet } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryAwsSubnet {
    id: string;
    clusterId: string;
    providerId: string;
    arn: string;
    platformId: string;
    cidr: string;
    vpcId: string;
    availableIpAddressCount: number;
    availabilityZone: string;

    constructor(json: DraasApiAwsSubnet) {
        this.id = json.id;
        this.clusterId = json.cluster_id;
        this.providerId = json.provider_id;
        this.arn = json.arn;
        this.platformId = json.platform_id;
        this.cidr = json.cidr;
        this.vpcId = json.vpc_id;
        this.availableIpAddressCount = json.available_ip_address_count;
        this.availabilityZone = json.availability_zone;
    }
}
