import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlohaArray } from '../models/aloha-array';
import { GenericService } from './generic.service';
import { unifiedArrayDefaultParams } from './unified-array.service';

@Injectable({ providedIn: 'root' })
export class SecurityPatchFb2025AService extends GenericService<AlohaArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: AlohaArray,
            defaultParams: {
                ...unifiedArrayDefaultParams,
                pageSize: 1000, // Keep the original 1000 page size limit, don't inherit the higher limit from the /arrays API
            },
            endpoint: '/rest/v1/security-patch-fb-2025-a',
            create: true,
        });
    }
}
