import { Observable } from 'rxjs';
import { FeatureNames } from '../../model/FeatureNames';

export interface TabConfig {
    title: string;
    /**
     * This is used by {@link NavMenuIdPipe} to generate a unique id for the tab.
     * If not specified, the path or title will be used instead.
     * If your path is dynamic, you should specify this to ensure the id is stable. (e.g. DRaaS cluster-specific tabs)
     */
    navMenuId?: string;
    icon?: string;
    path?: PathExpr;
    condition?: ConditionExpr;
    submenu?: 'draas';
    options?: {
        /**
         * If true, the tab will be shown even if no subtabs render.
         */
        showIfEmpty?: boolean;
    };

    items?: SubTabConfig[];
}

export interface SubTabConfig {
    title: string;
    navMenuId?: string;
    path?: PathExpr;
    condition?: ConditionExpr;
    options?: {};
}

export type PathExpr = string | { type: 'url'; href: string } | { type: 'app'; appId: string; path?: string };

export type ConditionExpr =
    | { check: 'every' | 'some'; conditions: ConditionExpr[] }
    | { check: 'isAllowed'; permission: string }
    | { check: 'featureEnabled'; featureName: FeatureNames }
    | { check: 'featureDisabled'; featureName: FeatureNames }
    | CustomPredicate;

export const ExternalUrl = (href: string) => ({ type: 'url', href }) as const;

export type CustomPredicate = () => Observable<boolean>;

export const IsAllowed = (permission: string) =>
    ({
        check: 'isAllowed',
        permission,
    }) as const;

export const IsFeatureEnabled = (featureName: FeatureNames) =>
    ({
        check: 'featureEnabled',
        featureName,
    }) as const;

export const IsFeatureDisabled = (featureName: FeatureNames) =>
    ({
        check: 'featureDisabled',
        featureName,
    }) as const;

export const AllOf = (...conditions: ConditionExpr[]) =>
    ({
        check: 'every',
        conditions,
    }) as const;

export const SomeOf = (...conditions: ConditionExpr[]) =>
    ({
        check: 'some',
        conditions,
    }) as const;

/**
 * Title to use for a menu item when you want a horizontal separator.
 * Separators are automatically deduped (multiple separators in a row will only show 1),
 * and are removed if they are at the end of the list.
 */
export const MENU_SEPARATOR_TITLE = '---';
