<div class="autocomplete-menu inverted-scrollbar">
    <ng-template
        ngFor
        let-namedValue
        [ngForOf]="namedValues?.slice(0, MAX_ITEMS)"
        let-i="index"
        [ngForTrackBy]="trackByNamedValue"
    >
        <div *ngIf="categoryFirsts.has(i)" class="category-label">
            <pureui-svg
                *ngIf="namedValue.category.icon"
                [svg]="namedValue.category.icon"
                [height]="16"
                [width]="16"
                class="category-icon"
            ></pureui-svg>
            <div class="category-name">{{ namedValue.category.displayName }}</div>
        </div>
        <div
            class="autocomplete-suggestion"
            [ngClass]="{ 'keyboard-selected' : i === selectedIndex,
                         'with-category' : namedValue.category }"
            (click)="onClick(namedValue, $event)"
        >
            <label *ngIf="multiSelect; else autocompleteMatch">
                <input type="checkbox" class="autocomplete-checkbox" [checked]="isSelected(namedValue)" />
                <ng-container *ngTemplateOutlet="autocompleteMatch"></ng-container>
            </label>
            <ng-template #autocompleteMatch>
                <autocomplete-match [value]="namedValue.name" [matchRegex]="matchRegex"></autocomplete-match>
            </ng-template>
        </div>
    </ng-template>
    @if (namedValues?.length > MAX_ITEMS) {
    <div class="plus-more">
        <span>(Plus {{ namedValues.length - MAX_ITEMS }} more)</span>
    </div>
    }
</div>
