/* This files define global variables expected by array_health */

window.pure = {};

pure.Context = {};
pure.Context.operations = {};
pure.Context.contentPath = function(path) { return path; };

pure.defined = function(oObj) {
    return !(oObj === undefined || oObj === null);
};

pure.mvc = {};
pure.mvc.framework = {};
pure.mvc.framework.util = {};
pure.mvc.framework.util.Sizes = {};
pure.mvc.framework.util.Sizes.getAppropriateSizeFromBytes = function(bytes, decimal) {
    var formatNumber = function(value, decimal) {
        var r = Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
        r = "" + r;
        var i = r.indexOf("."), count = 0;
        if (decimal && decimal > 0) {
            if (i < 0 ) {
                count = decimal;
                r = r + ".";
            } else {
                count = decimal - (r.length - i - 1);
            }
        }
        for (i = 0; i < count; i++) {
            r = r + "0";
        }

        return r;
    };
    if (bytes === null || bytes === undefined) {
        return "-";
    }
    if (!decimal) {
        decimal = 0;
    }
    var units = [ "B", "K", "M", "G", "T" ];
    var index = 0;
    while (bytes / 1024 >= 1 && index < 5) {
        bytes = bytes / 1024;
        index++;
    }

    return {
        toString: function() {
            return formatNumber(bytes, decimal) + " " + (bytes > 0 ? units[index] : "G");
        }
    }
};

pure.namespace = function() {};

pure.res = {};
pure.res.getString = function() {};

pure.util = {};
pure.util.getBoxFullName = function (box)
{
    var name = "";

    if (box.type == "controller")
    {
        name = "Controller " + box.index;
    }
    else if (box.type == "storage_shelf")
    {
        name = "Shelf " + box.index;
    }
    else if (box.type == "chassis")
    {
        name = "Chassis " + box.index;
    }
    return name;
};

pure.web = {};
pure.web.jsp = {};
pure.web.jsp.array = {};
pure.web.jsp.array.DialogUtil = function () {};

pure.namespace('pure.web.jsp.array');

pure.web.jsp.array.DataUtil = function () {};

// To-do: PURE-318671 make enums for model data

pure.web.jsp.array.DataUtil.prototype.isXSeries = function (data) {
    return (
        !this.isOxygen(data) && data && data.model && data.model.toLowerCase().startsWith('fa-x')
    );
};

pure.web.jsp.array.DataUtil.prototype.isHelium = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-c');
};

pure.web.jsp.array.DataUtil.prototype.isRC = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-rc');
};

pure.web.jsp.array.DataUtil.prototype.isComet = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-e');
};

pure.web.jsp.array.DataUtil.prototype.isMSeries = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-m');
};

pure.web.jsp.array.DataUtil.prototype.isOxygen = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-xl');
};

pure.web.jsp.array.DataUtil.prototype.isOxygenShelf = function (data) {
    return data && data.type === 'storage_shelf' && data.model === 'OXYGEN';
};

pure.web.jsp.array.DataUtil.prototype.isTachyon = function (data) {
    return data && data.model && data.model.toLowerCase().startsWith('fa-st');
};

pure.web.jsp.array.DataUtil.prototype.isTachyonShelf = function (data) {
    return data && data.type === 'storage_shelf' && data.model === 'TACHYON';
};

pure.web.jsp.array.DataUtil.prototype.isXenonShelf = function (data) {
    return data && data.type === 'storage_shelf' && data.model === 'DFSC1';
};

pure.web.jsp.array.DataUtil.prototype.isXenonShelfComponent = function (data) {
    return data && data.parent && data.model === 'DFSC1';
};

pure.web.jsp.array.DataUtil.prototype.isNeonShelf = function (data) {
    return data && data.type === 'storage_shelf' && data.model === 'DFSC2';
};

pure.web.jsp.array.DataUtil.prototype.isNeonShelfComponent = function (data) {
    return data && data.parent && data.model === 'DFSC2';
};

pure.web.jsp.array.DataUtil.prototype.isNickelChassis = function (data) {
    return data && data.parent && data.model === 'NICKEL';
};

pure.web.jsp.array.DataUtil.prototype.isCBS = function (data) {
    return data && data.model && /CBS-V[x\d]+[A|M]/i.test(data.model);
};

pure.web.jsp.array.DataUtil.prototype.isCBSShelf = function (data) {
    return (
        data &&
        data.type === 'storage_shelf' &&
        data.rootControllerModel &&
        /CBS-V[x\d]+[A|M]/i.test(data.rootControllerModel)
    );
};

pure.web.jsp.array.DataUtil.prototype.isPAZShelf = function (data) {
    return (
        data &&
        data.type === 'storage_shelf' &&
        data.rootControllerModel &&
        /CBS-V[x\d]+M/i.test(data.rootControllerModel)
    );
};

pure.web.jsp.array.DataUtil.prototype.getCBSProvider = function (data) {
    var cbsProvider = null;
    if (data && data.boxes) {
        for (var i = 0; i < data.boxes.length; i++) {
            var box = data.boxes[i];
            if (box && box.type === 'controller' && box.model) {
                if (/CBS-V[x\d]+A/i.test(box.model)) {
                    cbsProvider = 'AWS';
                    break;
                } else if (/CBS-V[x\d]+M/i.test(box.model)) {
                    cbsProvider = 'Azure';
                    break;
                }
            }
        }
    }
    return cbsProvider;
};

pure.web.jsp.array.DataUtil.prototype.createPAWSBoxes = function (boxes) {
    if (
        boxes.length === 0 ||
        !(this.isCBS(boxes[0]) || this.isCBS(boxes[1]) || this.isCBSShelf(boxes[0]))
    ) {
        return boxes;
    }

    var nvramBays = [];
    var ssdBays = [];
    var pawsBoxes = [];

    var model = '';
    for (var i = 0; i < boxes.length; i++) {
        var boxData = boxes[i];
        if (boxData.type === 'controller' && boxData.model && boxData.model.length > 0) {
            model = boxData.model;
        }
    }

    var cacheInfo;
    var primaryController = '';
    for (var i = 0; i < boxes.length; i++) {
        var boxData = boxes[i];
        if (boxData.type === 'controller') {
            // controllers
            boxData.model = model;
            pawsBoxes.push(boxData);
            for (var j = 0; j < boxData.driveBayList.length; j++) {
                var driveData = boxData.driveBayList[j];
                if (driveData.pureDrive && driveData.pureDrive.type === 'cache') {
                    if (!cacheInfo) {
                        cacheInfo = {};
                    }
                    cacheInfo.capacity = driveData.pureDrive.capacity;
                    primaryController = boxData.name;
                }
            }
        } else {
            // shelves
            for (var j = 0; j < boxData.driveBayList.length; j++) {
                var driveData = boxData.driveBayList[j];
                if (driveData.pureDrive.type === 'virtual NVRAM') {
                    nvramBays.push(driveData);
                } else if (driveData.pureDrive.type !== 'cache') {
                    // Not NVRAM or cache: must be some kind of SSD.
                    ssdBays.push(driveData);
                }
            }
        }
    }

    if (cacheInfo) {
        for (var i = 0; i < pawsBoxes.length; i++) {
            var boxData = pawsBoxes[i];
            if (boxData.type === 'controller' && boxData.name === primaryController) {
                boxData.cacheEnabled = true;
                boxData.cacheCapacity = cacheInfo.capacity;
                break;
            }
        }
    }

    if (nvramBays.length > 0) {
        var shelfBox = {
            name: 'SH RAM',
            type: 'storage_shelf',
            rootControllerModel: model,
            driveType: 'nvram',
            driveBayList: [],
        };
        for (i = 0; i < nvramBays.length; i++) {
            shelfBox.driveBayList.push(nvramBays[i]);
        }
        pawsBoxes.push(shelfBox);
    }

    for (i = 0; i < ssdBays.length; i += 12) {
        var shelfBox = {
            name: 'SH' + i / 12,
            type: 'storage_shelf',
            rootControllerModel: model,
            driveType: 'ssd',
            driveBayList: [],
        };
        var driveBayCount = Math.min(12, ssdBays.length - i);
        for (var j = 0; j < driveBayCount; j++) {
            shelfBox.driveBayList.push(ssdBays[i + j]);
        }
        pawsBoxes.push(shelfBox);
    }
    return pawsBoxes;
};

pure.web.jsp.array.DataUtil.prototype.updateNDUBoxes = function (boxes) {
    const nduBoxes = new Map();
    for (var i = 0; i < boxes.length; i++) {
        if (
            boxes[i].controllerList &&
            boxes[i].controllerList.length == 2 &&
            boxes[i].controllerList[0].model !== boxes[i].controllerList[1].model
        ) {
            nduBoxes.set(boxes[i].controllerList[0].serial, [i, 0, boxes[i].controllerList[0]]);
            nduBoxes.set(boxes[i].controllerList[1].serial, [i, 1, boxes[i].controllerList[1]]);
        }
    }

    if (nduBoxes.size === 0) return boxes;

    for (i = 0; i < boxes.length; i++) {
        if (boxes[i].type === 'storage_shelf' && boxes[i].controllerList) {
            for (var j = 0; j < boxes[i].controllerList.length; j++) {
                if (nduBoxes.has(boxes[i].controllerList[j].serial)) {
                    var nduBox = nduBoxes.get(boxes[i].controllerList[j].serial);
                    var emptyBox = boxes[i].controllerList[j];
                    boxes[i].controllerList[j] = nduBox[2];
                    boxes[nduBox[0]].controllerList[nduBox[1]] = emptyBox;
                }
            }
        }
    }

    return boxes;
};

pure.namespace('pure.web.jsp.array');

/**
 * Array Data util object to provide data generic methods
 */
pure.web.jsp.array.Data = function () {
    this.dataUtil = new pure.web.jsp.array.DataUtil();
    this.dialogUtil = new pure.web.jsp.array.DialogUtil();

    // These statuses must be translated from the backend
    this.statusMap = {
        ok: 'Healthy',
        disconnect: 'Disconnected',
        disconnect_bad: 'Disconnected',
        not_installed: 'Not Installed',
        not_present: 'Not Present',
        critical: 'Failed',
    };
};

pure.web.jsp.array.Data.prototype.getCoolingDiv = function (list) {
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    var i;

    for (i = 0; i < list.length; i++) {
        this.addStatusRow(table, list[i].status, 'Fan ' + list[i].index);
        this.addDetailsRow(table, list[i], 2);
    }

    ret.append(table);
    return ret;
};

pure.web.jsp.array.Data.prototype.getPowerSupplyDiv = function (root, data, direction) {
    var ret = $('<div></div>');
    var locationMap = {
        vertical: ['Top', 'Bottom'],
        horizontal: ['Left', 'Right'],
        top4: ['Top Left', 'Top Left', 'Top Right', 'Top Right'],
    };

    direction = direction || 'horizontal'; // Horizontal by default
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    var location = root + ', ' + locationMap[direction][data.index];

    this.addStatusRow(table, data.status);
    this.addRow(table, 'Location', location);
    this.addDetailsRow(table, data);

    ret.append(table);
    return ret;
};

pure.web.jsp.array.Data.prototype.getControllerDiv = function (root, data) {
    var _this = this;
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);

    if (!data) {
        this.addStatusRow(table, 'not_present');
        ret.append(table);
    } else {
        this.addStatusRow(table, data.overallStatus);
        var location = root;


        this.addRow(table, 'Model', data.model);
        var versionLabel = (this.dataUtil.isXenonShelfComponent(data) || this.dataUtil.isNeonShelfComponent(data))
            ? "Purity//DFS Version" : "Purity//FA Version"
        this.addRow(table, versionLabel, data.version);
        this.addRow(table, 'Location', location);
        this.addRow(table, 'Serial', data.serial);
        this.addDetailsRow(table, data);
        ret.append(table);

        if (
            data.overallStatus !== 'updating' &&
            data.overallStatus !== 'missing' &&
            data.overallStatus !== 'not_installed' &&
            data.overallStatus !== 'not_present' &&
            data.overallStatus !== 'offline'
        ) {
            var ledTitle = 'Turn On ID Light';
            if (data.identifyEnabled === true) {
                ledTitle = 'Turn Off ID Light';
            }
            var btn = this.createBtn(ledTitle, function () {
                _this.toggleIdentify(data);
            });
            ret.append(btn);
        }
    }

    return ret;
};

pure.web.jsp.array.Data.prototype.getIOModuleDiv = function (root, data) {
    var _this = this;
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);

    if (!data) {
        this.addStatusRow(table, 'not_installed');
        ret.append(table);
    } else {
        this.addStatusRow(table, data.status);
        var location = root;
        if (data.slot !== -1) {
            location += ', Slot ' + data.slot;
        }

        this.addRow(table, 'Location', location);
        this.addDetailsRow(table, data);
        var ledTitle = 'Turn On ID Light';
        if (data.identifyEnabled === true) {
            ledTitle = 'Turn Off ID Light';
        }
        var btn = this.createBtn(ledTitle, function () {
            _this.toggleIdentify(data);
        });
        ret.append(table);
        ret.append(btn);
    }

    return ret;
};

pure.web.jsp.array.Data.prototype.getTemperatureDiv = function (box, isXenonOrNeon) {
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    var i;

    if (isXenonOrNeon) {
        // there is only one temperature for xenon and neon shelves
        var temperature =
            box['temperature'] || box['temperature'] === 0 ? box['temperature'] + 'C' : '-';
        this.addRow(table, '', temperature);
    } else {
        var list = box['tempSensorList'];
        for (i = 0; i < list.length; i++) {
            if (list[i].status !== 'not_installed') {
                var temp = list[i]['temperature'] + 'C';
                if (list[i].status === 'not_installed') {
                    temp = '-';
                }
                var statusValue =
                    list[i].status in this.statusMap
                        ? this.statusMap[list[i].status]
                        : list[i].status;
                statusValue = statusValue.charAt(0).toUpperCase() + statusValue.slice(1);
                this.addRow2(table, 'Location ' + list[i].index, statusValue, temp, list[i].status);
            }
            this.addDetailsRow(table, list[i], 2);
        }
    }

    ret.append(table);
    return ret;
};

/**
 * Generate disk data type popup content
 * @param root
 * @param data
 * @param hideButtons
 * @param isHelium
 */
pure.web.jsp.array.Data.prototype.getDiskPopupDiv = function (
    root,
    data,
    hideButtons,
    hideLocation,
    isHelium,
    isComet,
    isTachyon,
    isRC
) {
    var _this = this;
    var drive = data['pureDrive'];
    var ret = $('<div></div>');

    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    if (!drive) {
        var status = data && data.overallStatus ? data.overallStatus : 'not_installed';
        this.addStatusRow(table, status);
    } else {
        if (data.overallStatus.startsWith('evac')) {
            this.addStatusRow(table, drive.status);
        } else {
            this.addStatusRow(table, data.overallStatus);
        }

        if (!hideLocation) {
            this.addRow(table, 'Location', root + ', Bay ' + data.index);
        }

        if (
            drive['protocol'] &&
            drive['protocol'] === 'NVMe' &&
            ((drive['type'] === 'SSD' && !isTachyon) || (drive['type'] === 'DNVR' && isTachyon))
        ) {
            this.addRow(table, 'Type', 'DirectFlash' + ((isHelium || isRC) ? ' (c)' : isComet ? ' (e)' : ''));
        } else if (drive['type'] === 'cache') {
            this.addRow(table, 'Type', 'Cache');
        }

        // See CLOUD-145615 and hardware update wiki for details
        if (drive.capacityInstalled != null && drive.capacityInstalled !== drive.capacityLabel) {
            this.addRow(table, 'Installed Capacity', this.getStorageSize(drive.capacityInstalled, 2));
            this.addRow(table, 'Capacity', this.getStorageSize(drive.capacityLabel, 2));
        } else {
            this.addRow(table, 'Capacity', this.getStorageSize(drive.capacity, 2));
        }
    }

    this.addDetailsRow(table, data);
    ret.append(table);

    if (
        !hideButtons &&
        drive &&
        data.overallStatus !== 'not_installed' &&
        data.overallStatus !== 'unadmitted'
    ) {
        var onOrOff = data.identifyEnabled === true ? 'Off' : 'On';
        var btn = this.createBtn('Turn ' + onOrOff + ' ID Light', function () {
            _this.toggleIdentify(data);
        });

        ret.append(btn);
    } else if (data.overallStatus === 'unadmitted') {
        var title = 'Admit all unadmitted modules';
        btn = this.createBtn(title, function () {
            _this.admitAllDrives();
        });
        ret.append(btn);
    }

    return ret;
};

pure.web.jsp.array.Data.prototype.getPAWSDriveBayPopupDiv = function (root, data) {
    var drive = data['pureDrive'];
    var ret = $('<div></div>');

    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    if (!drive) {
        var status = data && data.overallStatus ? data.overallStatus : 'not_installed';
        this.addStatusRow(table, status);
    } else {
        this.addStatusRow(table, data.overallStatus);
        this.addRow(table, 'Capacity', this.getStorageSize(drive.capacity, 2));
    }

    this.addDetailsRow(table, data);
    ret.append(table);
    return ret;
};

pure.web.jsp.array.Data.prototype.toggleIdentify = function (data) {
    var params = {
        name: data.name,
        identify_enabled: !data.identifyEnabled,
    };
    this.dialogUtil.showSplash();
    var _this = this;
    pure.ajax.doAjax(
        'array.query',
        'setHwAttr',
        params,
        function (/*data*/) {
            $('.qtip.ui-tooltip').qtip('hide');
            _this.dialogUtil.hideSplash();
            pure.page && pure.page.selectSystemHealth();
        },
        this.handleError(_this),
        120000
    );
};

pure.web.jsp.array.Data.prototype.admitAllDrives = function () {
    this.dialogUtil.showSplash();
    var _this = this;

    var params = {
        url: 'drives/internal?admit=true',
    };

    pure.ajax.doAjax(
        'middleware',
        'PATCH',
        params,
        function () {
            $('.qtip.ui-tooltip').qtip('hide');
            _this.dialogUtil.hideSplash();
            pure.page && pure.page.selectSystemHealth();
        },
        this.handleError(_this),
        120000
    );
};

pure.web.jsp.array.Data.prototype.getPortDiv = function (root, data) {
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    if (data.speed === 0) {
        this.addRow(table, 'Link', this.statusMap[data.status], data.status); // data.status here either "disconnect" or "disconnect_bad"
    } else {
        this.addStatusRow(table, data.status);
        this.addRow(table, 'Link', this.getNetworkSpeed(data.speed), data.status);

        // TODO: have a better way here. ??? what does this mean
        if (this.dataUtil.isXenonShelfComponent(data) || this.dataUtil.isNeonShelfComponent(data)) {
            if (!data.services) {
                data.services = ['DirectFlash'];
            } else {
                data.services.push('DirectFlash');
            }
        }
    }

    if (data.services) {
        this.addRow(table, 'Service', this.formatServices(data.services));
    }

    var location = root;
    if (data.slot !== -1 && typeof data.slot !== 'string') {
        location += ', Slot ' + data.slot;
    }

    location += ', Port ' + data.index;
    this.addRow(table, 'Location', location);

    if (data['ports']) {
        for (var i = 0; i < data['ports'].length; i++) {
            var port = data['ports'][i];
            this.addRow(
                table,
                port.type.toUpperCase(),
                port.formattedPortString ? port.formattedPortString : port.port
            );
        }
    }

    this.addDetailsRow(table, data);

    ret.append(table);
    return ret;
};

pure.web.jsp.array.Data.prototype.getDcaDiv = function (root, data) {
    var ret = $('<div></div>');
    var table = $('<table></table>').attr('cellspacing-bottom', 5).attr('cellpadding', 5);
    this.addStatusRow(table, data[0].status);

    var location = root;
    if (data[0].slot !== -1 && typeof data.slot !== 'string') {
        location += ', Slot ' + data[0].slot;
        location += ', Index ' + data[0].index;
    }

    this.addRow(table, 'Location', location);

    this.addDetailsRow(table, data[0]);

    ret.append(table);
    return ret;
};

pure.web.jsp.array.Data.prototype.getBoxDiv = function (data) {
    var _this = this;
    var ret = $('<div></div>');

    var table = $('<table></table>')
        .attr('cellspacing-bottom', 5)
        .attr('cellpadding', 5)
        .width(220);
    this.addStatusRow(table, data.overallStatus);
    ret.append(table);

    // ID Light Button
    if (
        data.overallStatus !== 'updating' &&
        data.overallStatus !== 'missing' &&
        data.overallStatus !== 'not_installed' &&
        data.overallStatus !== 'not_present' &&
        data.overallStatus !== 'offline'
    ) {
        var ledTitle = 'Turn On ID Light';
        if (data.identifyEnabled === true) {
            ledTitle = 'Turn Off ID Light';
        }
        btn = this.createBtn(ledTitle, function (/*event*/) {
            _this.toggleIdentify(data);
        });
        ret.append(btn);
    }
    if (data.overallStatus !== 'critical') {
        var btn = null;
        if (data.type === 'storage_shelf') {
            var indexContainer = $('<div></div>');
            indexContainer.append(data.index);
            this.addRow(table, 'ID', indexContainer);
            btn = this.createBtn('Change ID', function (/*event*/) {
                $('.qtip.ui-tooltip').qtip('hide');
                _this.openSetIdDialog(data);
            });

            ret.append(btn);
        } else if (data.type === 'controller') {
            this.addRow(table, 'Model', data.model);
            this.addRow(table, 'Purity//FA Version', data.version);
        }
        data.serial && this.addRow(table, 'Serial', data.serial);
        this.addDetailsRow(table, data);
    }
    return ret;
};

pure.web.jsp.array.Data.prototype.getPAWSTooltipBoxDiv = function (data) {
    var ret = $('<div></div>');

    var table = $('<table></table>')
        .attr('cellspacing-bottom', 5)
        .attr('cellpadding', 5)
        .width(225);
    this.addStatusRow(table, data.overallStatus);
    ret.append(table);

    if (data.overallStatus !== 'critical') {
        if (data.type === 'controller') {
            this.addRow(table, 'Model', data.model);
            this.addRow(table, 'Purity//FA Version', data.version);
            if (data.cacheEnabled) {
                if (data.cacheCapacity) {
                    this.addRow(
                        table,
                        'Cache',
                        'Enabled (' + this.getStorageSize(data.cacheCapacity, 2) + ')'
                    );
                } else {
                    this.addRow(table, 'Cache', 'Enabled');
                }
            }
        }
        data.serial && this.addRow(table, 'Serial', data.serial);
        this.addDetailsRow(table, data);
    }
    return ret;
};

pure.web.jsp.array.Data.prototype.openSetIdDialog = function (data) {
    $('#set-shelf-id').trigger('setShelfId', { name: data.handle, id: data.index });
};

/**
 * Common addRow method for all data type
 * @param table
 * @param name
 * @param value
 */
pure.web.jsp.array.Data.prototype.addRow = function (table, name, value) {
    if (!value) {
        return;
    }
    var row = $('<tr></tr>');
    if (name) {
        var cell1 = $('<td></td>').append(name);
        var cell2 = $('<td></td>').css('padding', '0 10px').css('width', 10);
        row.append(cell1).append(cell2);
    }
    var cell3 = $('<td></td>').append(value).css('padding-left', 0).css('text-align', 'left');
    row.append(cell3);

    table.append(row);
};

/**
 * Adding a row with a status square and label
 * @param table
 * @param status
 * @param label
 */
pure.web.jsp.array.Data.prototype.addStatusRow = function (table, status, label) {
    if (status) {
        // Transforming status string if needed and capitalizing
        var value = status in this.statusMap ? this.statusMap[status] : status;
        value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    var row = $('<tr></tr>');
    var cell1 = $('<td></td>').append(label ? label : 'Status');
    var cell2 = this.createStatusColumn(status);
    var cell3 = $('<td></td>').append(value).css('padding-left', 0).css('text-align', 'left');
    row.append(cell1).append(cell2).append(cell3);
    table.append(row);
};

/**
 * Create a status table column.
 * @param status
 */
pure.web.jsp.array.Data.prototype.createStatusColumn = function (status) {
    var td = $('<td></td>').css('padding', '0 10px').css('width', 10);
    if (status) {
        var color = pure.theme.component[status];
        if (color) {
            var div = $('<div>&nbsp;</div>')
                .css('background-color', color)
                .css('height', 10)
                .css('width', 10);
            td.append(div);
        }
    }
    return td;
};

/**
 * Add 4 columns row
 * @param table
 * @param name
 * @param value
 * @param value2
 * @param status
 */
pure.web.jsp.array.Data.prototype.addRow2 = function (table, name, value, value2, status) {
    var row = $('<tr></tr>');
    var cell1 = $('<td></td>').append(name);
    var cell2 = this.createStatusColumn(status);
    var cell3 = $('<td></td>')
        .append(value)
        .css('padding-left', 0)
        .css('text-align', 'left')
        .css('min-width', 80);
    var cell4 = $('<td></td>').append(value2).css('text-align', 'right');

    row.append(cell1).append(cell2).append(cell3).append(cell4);
    table.append(row);
};

/**
 * Add optional details row to table.
 * @param table
 * @param data
 * @param colSpan
 */
pure.web.jsp.array.Data.prototype.addDetailsRow = function (table, data, colSpan) {
    if (data.details && data.details.length > 0) {
        var details = data.details.charAt(0).toUpperCase() + data.details.slice(1);

        var row = $('<tr></tr>');
        var cell1 = $('<td></td>').text('Details').css('vertical-align', 'top');
        var cell2 = this.createStatusColumn(null);
        var cell3 = $('<td></td>')
            .append(details)
            .css('padding-left', 0)
            .css('max-width', '150px')
            .css('white-space', 'wrap');
        //.css("white-space", "nowrap").css("overflow-x", "hidden").css("text-overflow", "ellipsis").attr("title", value);
        if (colSpan) {
            cell3.attr('colspan', colSpan);
        }
        row.append(cell1).append(cell2).append(cell3);
        table.append(row);
    }
};

pure.web.jsp.array.Data.prototype.getStorageSize = function (bytes, decimal) {
    return pure.mvc.framework.util.Sizes.getAppropriateSizeFromBytes(bytes, decimal).toString();
};

pure.web.jsp.array.Data.prototype.getNetworkSpeed = function (bits, decimal) {
    if (!decimal) {
        decimal = 0;
    }
    var units = ['b/s', 'Kb/s', 'Mb/s', 'Gb/s'];
    var index = 0;
    while (bits / 1000 >= 1 && index < 4) {
        bits = bits / 1000;
        index++;
    }

    return Math.round(bits * Math.pow(10, decimal)) / Math.pow(10, decimal) + ' ' + units[index];
};

pure.web.jsp.array.Data.prototype.formatServices = function (services) {
    if (services.length > 0) {
        var formattedServices = [];
        $.each(services, function (i, m) {
            if (m.toLowerCase() === 'iscsi') {
                formattedServices[i] = 'iSCSI'; // Special case
            } else {
                formattedServices[i] = m.charAt(0).toUpperCase() + m.slice(1);
            }
        });
        return formattedServices.join(', ');
    }
    return 'None';
};

/**
 * Create a btn to be used inside array graph popup.
 * @param title
 * @param clickCb
 * @return {*}
 */
pure.web.jsp.array.Data.prototype.createBtn = function (title, clickCb) {
    var btn = $('<button></button>')
        .append(title)
        .css('float', 'right')
        .addClass('small')
        .height(25)
        .css('font-size', '11px')
        .css('color', 'black');
    btn.click(clickCb);
    if (!pure.Context.operations['generic-system-modify']) {
        btn.css('cursor', 'default');
        btn.prop('disabled', true);
        btn.css('opacity', 0.8);
    } else {
        btn.css('cursor', 'pointer');
    }
    return btn;
};

pure.web.jsp.array.Data.prototype.handleError = function (self) {
    return function (errorMsg, _textStatus, jqXHR) {
        if (errorMsg) {
            alert(errorMsg);
        } else {
            alert(jqXHR.responseJSON.error);
        }
        self.dialogUtil.hideSplash();
    };
};

/**
 * This file contains common code for both array graphs.
 *
 */

pure.namespace('pure.web.jsp');

pure.web.jsp.ArrayGraphCommon = function () {
    this.dataUtil = new pure.web.jsp.array.DataUtil();
};

pure.web.jsp.ArrayGraphCommon.prototype.getBoxHeight = function (box, graph) {
    graph = graph ? graph : this.graph;
    if (box) {
        if (box.model === 'FA-405') {
            return graph.boxHeight1U;
        } else if (this.dataUtil.isCBS(box)) {
            return graph.cloudInstanceHeight;
        } else if (
            this.dataUtil.isMSeries(box) ||
            this.dataUtil.isXSeries(box) ||
            this.dataUtil.isHelium(box) ||
            this.dataUtil.isRC(box) ||
            this.dataUtil.isXenonShelf(box) ||
            this.dataUtil.isNeonShelf(box) ||
            this.dataUtil.isComet(box) ||
            this.dataUtil.isNickelChassis(box)
        ) {
            return graph.boxHeight3U;
        } else if (
            this.dataUtil.isOxygen(box) ||
            this.dataUtil.isOxygenShelf(box) ||
            this.dataUtil.isTachyon(box) ||
            this.dataUtil.isTachyonShelf(box)
        ) {
            return graph.boxHeight5U;
        } else if (this.dataUtil.isCBSShelf(box)) {
            if (box.driveType === 'nvram') {
                return graph.cloudInstanceHeight * 0.75;
            } else {
                return graph.cloudInstanceHeight;
            }
        } else {
            return graph.boxHeight;
        }
    }
    return 0;
};

pure.web.jsp.ArrayGraphCommon.prototype.getBoxDashboardHeight = function (box, graph) {
    graph = graph ? graph : this.graph;
    if (box) {
        if (this.dataUtil.isXenonShelf(box) || this.dataUtil.isNeonShelf(box)) {
            return graph.xenonShelfHeight;
        } else if (this.dataUtil.isCBS(box)) {
            return graph.boxHeight;
        } else if (this.dataUtil.isCBSShelf(box)) {
            return graph.cloudInstanceHeight + 5 * graph.cloudInstanceSpace;
        } else {
            return this.getBoxHeight(box, graph);
        }
    }
    return 0;
};

pure.web.jsp.ArrayGraphCommon.prototype.updateData = function (data) {
    this.data = data;
};

pure.namespace("pure.web.jsp.array");

/**
 * Constructor of Array Information Panel
 * @param selector
 * @param options
 */
pure.web.jsp.array.ArrayHealth = function (selector, options) {
    this.selector = selector;
    this.options = {};
    $.extend(this.options, options);
    this.tooltipDelay = 500;
    this.timer = null;
    this.randomFailure = false;
    this.updateFrequency = 30000;
    this.dialogUtil = new pure.web.jsp.array.DialogUtil();
};

/**
 * To prepare the UI and all one-time tasks
 */
pure.web.jsp.array.ArrayHealth.prototype.init = function () {
    this.getArrayHealth(false);
};

pure.web.jsp.array.ArrayHealth.prototype.select = function (background) {
    this.getArrayHealth(background);

    if (this.intervalTimer) {
        clearTimeout(this.intervalTimer);
        this.intervalTimer = null;
    }

    this.setTimer();
};

/**
 * Get array hardware information, the background parameter indicates if it is a background process which just to update the status on nav-tree
 * @param background
 */
pure.web.jsp.array.ArrayHealth.prototype.getArrayHealth = function (background) {
    var _this = this;
    if (!background) {
        this.options.nav.showLoader();
    }

    // ignore if the reload is triggered by timer
    var failCb = null;
    if (background) {
        failCb = function () {};
    }
    // TODO: the timeout is 5 minutes due to backend slow issue, we should change it back to 30 seconds once backend issue is fixed
    this.panelXhr = pure.ajax.doAjax("array.query", "getArray", {randomFailure:this.randomFailure},
        function (data) {
            _this.options.nav.hideLoader();
            if (!background) {
                _this.dialogUtil.hideSplash($("body"));
            }
            _this.initArrayHealth(data);
        }, failCb, 30000
    );
};

pure.web.jsp.array.ArrayHealth.prototype.refreshStatus = function (data) {
    var failedDrive = false;
    var i, j;

    for (i = 0; i < data.boxes.length; i++) {
        var box = data.boxes[i];
        if (box.type === 'storage_shelf') {
            for (j = 0; j < box.driveBayList.length; j++) {
                var bay = box.driveBayList[j];
                if (!bay['pureDrive']) {
                    failedDrive = true;
                    break;
                }
            }
        }
    }
    // update the status on nav-tree
    var statusMap = {
        critical:false,
        degraded:false,
        missing:false,
        not_installed:false,
        not_present:false,
        offline:false,
        updating:false,
        unknown:false
    };

    for (i = 0; i < data.boxes.length; i++) {
        statusMap[data.boxes[i].overallStatus] = true;
        if (data.boxes[i].type === 'chassis') {
            for (j = 0; j < data.boxes[i].controllerList.length; j++) {
                statusMap[data.boxes[i].controllerList[j].overallStatus] = true;
            }
        }
    }

    var status = "ok";

    if (statusMap.critical || statusMap.not_present || statusMap.offline || statusMap.not_installed || failedDrive) {
        status = "critical";
    } else if (statusMap.degraded || statusMap.missing || statusMap.updating) {
        status = "degraded";
    } else if (statusMap.unknown) {
        status = "unknown";
    }

    $('.viewLoadedElmTestId').html('loaded');
    return status;
};

/**
 * Initial Array Health Panel (start calculating proper graph size and layout)
 * @param data
 */
pure.web.jsp.array.ArrayHealth.prototype.initArrayHealth = function (data) {
    var _this = this;
    var arrayData = this.options.arrayData;
    var dataUtil = new pure.web.jsp.array.DataUtil();
    this.refreshStatus(data);
    this.data = data;
    // don't update any content if current panel is not system health
    if (pure.page && pure.page['rightPanelTarget'] && pure.page['rightPanelTarget'] !== "0") {
        return;
    }

    // calculate total capacity
    var boxes = data.boxes;
    var graph = null;

    var initArrayHealth = function () {
        _this.arch = null;
        _this.arrayGraph = null;
        _this.initArrayHealth(data);
    };

    var box = null;
    for (var i = 0; i < boxes.length; i++) {
        if (_this.arch && _this.arch.boxes) {
            var arch = this.arch.boxes[i];
            if (arch && box && box.type === 'controller') {
                if (box.status === 'missing' && arch.infoIcon.css('visibility') === 'visible') {
                    setTimeout(initArrayHealth, 200);
                    return;
                }
            }
        }
    }

    var getImageTag = function (relativePath) {
        var path = pure.Context.contentPath('images/' + relativePath);
        return $("<img src='" + path + "'/>");
    };

    var createChassisOrXenonShelf = function (titlePanel, titleList, box, boxHeight, arch) {
        var chassisTitlePanel = $('<div></div>').css('height', boxHeight + 12);
        addChassisOrXenonLeftNav(chassisTitlePanel, [], box, boxHeight / 3, arch);

        arch.controllerList = [{}, {}];
        addBoxLeftNav(chassisTitlePanel, [], box.controllerList[0], boxHeight / 3, arch.controllerList[0], true);
        addBoxLeftNav(chassisTitlePanel, [], box.controllerList[1], boxHeight / 3, arch.controllerList[1], true);

        titlePanel.append(chassisTitlePanel);
        titleList.push(chassisTitlePanel);
    };

    var updateChassis = function (arch, box) {
        updateBox(arch, box);
        updateBox(arch.controllerList[0], box.controllerList[0]);
        updateBox(arch.controllerList[1], box.controllerList[1]);
    };

    var updateBox = function(boxArch, box) {
        if (!box) {return;}

        if(boxArch.tempIcon) boxArch.tempIcon.find('img').attr('src', pure.Context.contentPath('images/app/health/' + getTempIconName(box)));
        if(boxArch.fanIcon) boxArch.fanIcon.find('img').attr('src', pure.Context.contentPath('images/app/health/' + getFanIconName(box)));
        if(boxArch.title) boxArch.title.empty().append( getBoxTitleName(box) );

        if (box.status !== 'missing') {
            if (boxArch && boxArch.infoIcon) boxArch.infoIcon.css('visibility', 'visible');
            if (boxArch && boxArch.tempIcon) boxArch.tempIcon.css('visibility', 'visible');
            if (boxArch && boxArch.fanIcon) boxArch.fanIcon.css('visibility', 'visible');
        }

        var name = getBoxFullName(box);

        if (_this.tooltipTitle === 'Temperature - ' + box.name) {
            var isXenonOrNeon = dataUtil.isXenonShelf(box) || dataUtil.isXenonShelfComponent(box)
                                || dataUtil.isNeonShelf(box) || dataUtil.isNeonShelfComponent(box);
            _this.tooltipContent.empty().append(arrayData.getTemperatureDiv(box, isXenonOrNeon));
        } else {
            if (_this.tooltipTitle === 'Fans - ' + box.name) {
                _this.tooltipContent.empty().append(arrayData.getCoolingDiv(box.coolingList));
            } else {
                if (_this.tooltipTitle === name + ' - ' + box.name) {
                    _this.tooltipContent.empty().append(arrayData.getBoxDiv(box));
                }
            }
        }

        // When the dashboard updates (i.e. at this point in the code), the
        // gear icon's `click` handler still has the old data (`box`) that
        // had been captured in a closure as one of the arguments to
        // `createBox`. Anything but a hack to fix this shortcoming will
        // require a rewrite, so this is a hack to replace the `click`
        // handler with the new data.

        _this.showTooltip(
            boxArch.infoIcon, (box && box.name) ? name + ' - ' + box.name : name,
            // Use `bind` to lock in `box` specific to this loop iteration.
            arrayData.getBoxDiv.bind(arrayData, box));
    };

    var addBoxLeftNav = function (titlePanel, titleList, box, boxHeight, arch, insideChassis) {
        if (!box || dataUtil.isCBS(box) || dataUtil.isCBSShelf(box)) { return; }

        if (!arch.title || !arch.info || !arch.tempIcon || !arch.fanIcon) {
            var titleDiv = $("<div></div>").css("height", boxHeight);
            if (!insideChassis) {
                titleDiv.css("height", boxHeight + 10);
            }

            var titleRow = $("<div></div>").css("padding-top", 10).css("font-weight", "bold").css("border-bottom", "1px solid #b0b0b0");

            var infoRow = $("<div></div>").css("padding-top", 10).css("margin-right", 5).css("width", 80);
            var infoRowCenteringDiv = $("<div></div>").css("margin", "0 auto");
            var infoRowWidth = 0;

            var fullName = getBoxFullName(box);

            var infoIcon = $("<span></span>").append(getImageTag("app/health/gear.svg").css("width", "16px").css("height", "16px"));
            infoIcon.css("padding-left", 2).css("cursor", "pointer").css("width", 20);

            if (!dataUtil.isCBSShelf(box)) {
                infoRowWidth += 25;
                infoRowCenteringDiv.append(infoIcon);
            }

            /* fans and temps, not needed for PAWS */
            if (!dataUtil.isCBS(box) && !dataUtil.isCBSShelf(box)) {
                addBoxLeftNavTempIcon(box, arch, infoRowCenteringDiv);
                addBoxLeftNavFanIcon(box, arch, infoRowCenteringDiv);
            }
            infoRowWidth += 25;
            infoRowWidth += 25;
            infoRow.append(infoRowCenteringDiv.css("width", infoRowWidth));

            var title = $("<span></span>").append(getBoxTitleName(box)).css("margin-right", 5).css("margin-left", 0);
            titleRow.append(title);

            _this.showTooltip(infoIcon, (box && box.name) ? fullName + ' - ' + box.name : fullName, function () {
                return arrayData.getBoxDiv(box);
            });
            titleDiv.append(titleRow).append($("<div></div>").css("clear", "both"));
            titleDiv.append(infoRow);
            titlePanel.append(titleDiv);
            titleList.push(titleDiv);

            arch.title = title;
            arch.infoIcon = infoIcon;
            if (box.status === 'missing' || box.status === 'not_installed') {
                if (boxArch && boxArch.infoIcon) arch.infoIcon.css('visibility', 'hidden');
                if (boxArch && boxArch.tempIcon) arch.tempIcon.css('visibility', 'hidden');
                if (boxArch && boxArch.fanIcon) arch.fanIcon.css('visibility', 'hidden');
            }
        }
    };

    var addChassisOrXenonLeftNav = function (titlePanel, titleList, box, boxHeight, arch) {
        if (!box) { return; }

        if (!arch.title || !arch.info ) {
            var titleDiv = $('<div></div>').css('height', boxHeight - 10);
            var titleRow = $('<div></div>').css('font-weight', 'bold').css('border-bottom', '1px solid #b0b0b0');

            var infoRow = $('<div></div>').css('padding-top', 10).css('margin-right', 5).css('width', 80);
            var infoRowCenteringDiv = $('<div></div>').css('margin', '0 auto');

            var infoIcon = $('<span></span>').append(getImageTag('app/health/gear.svg').css('width', '16px').css('height', '16px'));
            infoIcon.css('padding-left', 2).css('cursor', 'pointer').css('width', 20);

            var fullName = getBoxFullName(box);

            infoRowCenteringDiv.append(infoIcon);
            if (dataUtil.isXenonShelf(box) || dataUtil.isNeonShelf(box)) {
                addBoxLeftNavTempIcon(box, arch, infoRowCenteringDiv);
            }

            infoRow.append(infoRowCenteringDiv.css('width', 75));

            var title = $('<span></span>').append(getBoxTitleName(box)).css('margin-right', 5).css('margin-left', 0);
            titleRow.append(title);

            var toolTipName = (box && (dataUtil.isXenonShelf(box) || dataUtil.isNeonShelf(box))) ? 'EC2 Instance' :
                ((box && box.name) ? fullName + ' - ' + box.name : fullName);
            _this.showTooltip(infoIcon, toolTipName, function () {
                return arrayData.getBoxDiv(box);
            });
            titleDiv.append(titleRow).append($('<div></div>').css('clear', 'both'));
            titleDiv.append(infoRow);
            titlePanel.append(titleDiv);
            titleList.push(titleDiv);

            arch.title = title;
            arch.infoIcon = infoIcon;
            if (box.status === 'missing') {
                arch.infoIcon.css('visibility', 'hidden');
                if (arch && arch.tempIcon) arch.tempIcon.css('visibility', 'hidden');
            }
        }
    };

    var getFanIconName = function(box) {
        var fanIconName = "fan.svg";
        var fanList = box['coolingList'];
        for (var i = 0; i < fanList.length; i++) {
            if (fanList[i].status !== 'ok') {
                fanIconName = 'fan_red.svg';
                break;
            }
        }
        return fanIconName;
    };

    var addBoxLeftNavFanIcon = function (box, arch, infoRow) {
        var fanIconPath = getFanIconName(box);

        var fanIcon = $('<span></span>').append(getImageTag('app/health/' + fanIconPath).css('width', '16px').css('height', '16px'))
                    .css('padding-left', 5).css('cursor', 'pointer').css('width', 20).css('margin', '0 auto');

        _this.showTooltip(fanIcon, (box && box.name) ? 'Fans' + ' - ' + box.name : 'Fans', function () {
            return arrayData.getCoolingDiv(box['coolingList']);
        });

        infoRow.append(fanIcon);
        arch.fanIcon = fanIcon;
    };

    var getTempIconName = function(box) {
        var tempIconName = 'thermometer.svg';
        var tempList = box['tempSensorList'];

        for (var i = 0; i < tempList.length; i++) {
            if (tempList[i].status !== 'ok' && tempList[i].status !== 'not_installed') {
                tempIconName = 'thermometer_red.svg';
                break;
            }
        }
        return tempIconName;
    };

    var addBoxLeftNavTempIcon = function (box, arch, infoRow) {
        var tempIconName = getTempIconName(box);

        var tempIcon = $('<span></span>').append(getImageTag('app/health/' + tempIconName).css('width', '16px').css('height', '16px'))
                    .css('padding-left', 5).css('cursor', 'pointer').css('width', 20).css('margin', '0 auto');

        _this.showTooltip(tempIcon, (box && box.name) ? 'Temperature - ' + box.name : 'Temperature', function () {
            return arrayData.getTemperatureDiv(box, (dataUtil.isXenonShelf(box) || dataUtil.isXenonShelfComponent(box)
                                                    || dataUtil.isNeonShelf(box) || dataUtil.isNeonShelfComponent(box)));
        });

        infoRow.append(tempIcon);
        arch.tempIcon = tempIcon;
    };

    var getBoxTitleName = function (box) {
        var name = '';

        if (box.type === 'controller') {
            name = 'Controller ' + box.index;
        } else if (box.type === 'storage_shelf') {
            if (dataUtil.isCBSShelf(box)) {
                name = 'Drives';
            } else {
                name = 'Shelf ' + box.index;
            }
        } else if (box.type === 'chassis') {
            name = 'Chassis ' + box.index;
        }
        return name;
    };

    var getBoxFullName = function (box) {
        if (dataUtil.isXenonShelf(box) || dataUtil.isXenonShelfComponent(box)
            || dataUtil.isNeonShelf(box) || dataUtil.isNeonShelfComponent(box)) {
            return 'DirectFlash ' + getBoxTitleName(box);
        } else {
            return getBoxTitleName(box);
        }
    };

    var isPAWS = false;
    if (boxes && boxes.length !== 0) {
        for (var i = 0; i < boxes.length; i++) {
            if (dataUtil.isCBS(boxes[i])) {
                isPAWS = true;
                break;
            }
        }
    }
    if (!this.arrayGraph || (this.arch && this.arch.boxes && this.arch.boxes.length !== data.boxes.length)) {
        this.arch = {boxes:[]};
        for (i = 0; i < data.boxes.length; i++) {
            this.arch.boxes.push({});
        }

        var container = $("<div></div>").css("margin-left", 5).css("margin-top", 15).css("overflow", "hidden").width(1000);
        this.selector.empty().append(container);

        // the left hand side title panel
        var titlePanel = $("<div></div>").css("float", "left");

        var titleList = [];
        var ele = document.createElement("div");
        this.arrayGraph = new pure.web.jsp.ArrayGraph(ele, data, this.options.arrayData);
        graph = this.arrayGraph.graph;

        $(ele).attr("id", "arrayGraph").css("cursor", "pointer").css("paddingBottom", 20);
        $(ele).css("width", graph.totalWidth).css("float", "left");

        if (!isPAWS) {
            this.front = $("<div>Front</div>").css("float", "left").css("margin-left", 90).css("margin-bottom", 7).css("color", "#717171");
            this.rear = $("<div>Rear</div>").css("float", "left").css("margin-left", graph.boxWidth - 20).css("margin-bottom", 7).css("color", "#717171");
            container.append(this.front);
            container.append(this.rear);
        }
        container.append($("<div></div>").css("clear", "both"));
        // left panel which showing controller or shelf name, info, temp and fan status
        container.append(titlePanel);
        // middle panel which showing the graph
        container.append($(ele));
        container.append($("<div></div>").css("clear", "both"));
        this.titleList = titleList;
        this.arrayGraph.drawGraph();

        for (i = 0; i < boxes.length; i++) {
            box = boxes[i];
            if (box.type === 'chassis' || dataUtil.isXenonShelf(box) || dataUtil.isNeonShelf(box)) {
                createChassisOrXenonShelf(titlePanel, titleList, box, this.arrayGraph.getBoxHeight(box), this.arch.boxes[i]);
            } else {
                addBoxLeftNav(titlePanel, titleList, box, this.arrayGraph.getBoxHeight(box), this.arch.boxes[i]);
            }
        }
    } else {
        for (i = 0; i < data.boxes.length; i++) {
            box = data.boxes[i];
            var boxArch = this.arch.boxes[i];

            if (box.type === 'chassis') {
                updateChassis(boxArch, box);
            } else {
                updateBox(boxArch, box);
            }
        }

        this.updateData(data);
        this.arrayGraph.checkGraph();
        if (!isPAWS) {
            this.rear.css("margin-left", this.arrayGraph.graph.boxWidth - 20);
        }
    }

    $(window).resize(function () {
        _this.arrayGraph.checkGraph();
    });
};

pure.web.jsp.array.ArrayHealth.prototype.setTimer = function ()
{
    var _this = this;
    this.intervalTimer = setTimeout(function ()
    {
        if (_this.data)
        {
            if (!pure.Context.isPlayback)
            {
                _this.getArrayHealth(true);
                _this.setTimer();
            }
        }
        else
        {
            // Keep trying until _this.data is set.
            _this.setTimer();
        }
    }, this.updateFrequency);
};

pure.web.jsp.array.ArrayHealth.prototype.resize = function ()
{
    var titleList = this.titleList;
    var i = 0;
    if (this.arrayGraph)
    {
        var graph = this.arrayGraph.graph;
        var boxes = this.arrayGraph.data.boxes;
        for (i = 0; i < titleList.length; i++)
        {
            this.titleList[i].css("height", this.arrayGraph.getBoxHeight(boxes[i]) + 10);
        }
        this.rear.css("margin-left", graph.boxWidth - 20);
    }
};

/**
 *
 * @param data
 */
pure.web.jsp.array.ArrayHealth.prototype.updateData = function (data) {
    this.data = data;
    this.arrayGraph.updateData(data);
};

/**
 * @param element
 * @param title
 * @param contentCb
 */
pure.web.jsp.array.ArrayHealth.prototype.showTooltip = function (element, title, contentCb) {
    if (!element) return;

    var _this = this;
    var timer = null;
    var openTooltip = function () {
        // calculate where to place the tooltip
        var eleOffset = element.offset();
        var eleX = eleOffset.left;
        var eleY = eleOffset.top;
        var eleW = element.width();
        var eleH = element.height();

        var content = $('<div></div>').append(contentCb());
        _this.tooltipContent = content;
        _this.tooltipTitle = title;
        _this.qtipContent = content;
        var body = $('body');
        // we can only calcuate the width and height of the element only if it is really part of document.  Create a hidden element with fixed position alone with body
        var temp = $('#temp');
        temp.empty().append(content);

        var contentW = temp.width();
        var contentH = temp.height();

        var bodyW = body.width();
        var bodyH = body.height();
        var my = 'left top';
        var at = 'right center';
        if (eleY + eleH / 2 + contentH + 40 > bodyH) // 40 is a magic number which add up the title bar & border of the tooltip
        {
            if (eleY - (eleH / 2 + contentH + 40) < 0) {
                my = 'left middle';
            } else {
                my = 'left bottom';
            }
        }
        if (eleX + eleW + contentW + 60 > bodyW) {
            at = 'left center';
            my = my.replace('left', 'right');
        }

        element.qtip({
                content: {
                    title: {
                        text:title,
                        button:true
                    },
                    text:content
                },
                position: {
                    my: my,
                    at: at
                },
                show: {
                    ready: true,
                    event: false
                },
                hide: {
                    fixed: true,
                    event: 'mouseout'
                }
            }
        );
    };

    element.mouseover(function () {
        timer = setTimeout(function () {
            openTooltip();
            timer = null;
        }, _this.tooltipDelay);
    });

    element.mouseout(function () {
        clearTimeout(timer);
        timer = null;
    });
};

pure.web.jsp.array.ArrayHealth.prototype.destroy = function () {
    if (this.panelXhr) {
        this.panelXhr.abort();
    }
    this.options.nav.hideLoader();
    this.arrayGraph = null;
};

/**
 * This file contains basic drawing functions that are shared by all
 * components -- controllers, shelves, cards, etc. These functions
 * should not depend on the parent component's type or model.
 *
 */

pure.web.jsp.GraphComponent = function () {};

pure.web.jsp.GraphComponent.prototype.setPaper = function (paper) {
    this.paper = paper;
};

pure.web.jsp.GraphComponent.prototype.getBoxHeight = function (graph) {
    return graph.boxHeight;
};

pure.web.jsp.GraphComponent.prototype.initEmptyArch = function (numObj) {
    var archComponent = [];
    for (var i = 0; i < numObj; i++) {
        archComponent.push({});
    }
    return archComponent;
};

pure.web.jsp.GraphComponent.prototype.drawIdentify = function (x, y, w, h, isOn) {
    var identify = this.paper.rect(x, y, w, h);
    identify.attr('stroke-width', 0);
    identify.attr('fill', 'yellow');
    this.updateIdentify(identify, isOn);
    return identify;
};

pure.web.jsp.GraphComponent.prototype.updateIdentify = function (identify, isOn) {
    if (isOn) {
        identify.show();
    } else {
        identify.hide();
    }
};

pure.web.jsp.GraphComponent.prototype.drawLayer = function (x, y, w, h, title, content, component, stroke, hstroke,
                                                            arch, cornerRadius) {
    var layer = this.paper.rect(x, y, w, h, cornerRadius ? cornerRadius : 0);
    arch.layerComponent = layer;
    this.updateLayerMouseover(arch, x, y, w, h, title, content, component, stroke, hstroke);
    return layer;
};

pure.web.jsp.GraphComponent.prototype.updateLayerMouseover = function (arch, x, y, w, h, title, content, component,
                                                                       stroke, hstroke) {
    var _this = this;
    var timer = null;
    var layer = arch.layerComponent;
    layer.attr('fill', '#ffffff');
    layer.attr('fill-opacity', '0');
    layer.attr('stroke-opacity', '0');
    layer.unmouseover(arch.mouseoverHandler);
    layer.unmouseout(arch.mouseoutHandler);

    if (content) {
        if (title && this.tooltipTitle === title) {
            this.tooltipContent.empty().append(content());
        }
        arch.mouseoverHandler = function () {
            layer.attr('stroke-opacity', '1');
            layer.attr('stroke', hstroke);
            timer = setTimeout(function () {
                _this.showTooltipForGraph(x + w, y + h / 2, w, h, title, content());
                timer = null;
            }, _this.graph.tooltipDelay);
        };
        arch.mouseoutHandler = function () {
            clearTimeout(timer);
            timer = null;
            layer.attr('stroke-opacity', '0');
            layer.attr('stroke', stroke);
        };
        layer.mouseover(arch.mouseoverHandler);
        layer.mouseout(arch.mouseoutHandler);
    } else {
        if (this.tooltipContent) {
            this.tooltipContent.empty().append('');
        }
    }
};

pure.web.jsp.GraphComponent.prototype.drawComponent = function (x, y, w, h, fill, stroke, strokeWidth, cornerRadius) {
    var component = this.paper.rect(x, y, w, h, cornerRadius ? cornerRadius : 0);
    component.attr('fill', fill);
    component.attr('stroke', stroke);

    if (strokeWidth) {
        component.attr('stroke-width', strokeWidth);
    }

    return component;
};

pure.web.jsp.GraphComponent.prototype.updateComponent = function (component, fill, stroke) {
    component.attr('fill', fill);
    if (stroke) {
        component.attr('stroke', stroke);
    }
};

pure.web.jsp.GraphComponent.prototype.drawStatus = function (x, y, w, h, color, strokeWidth, cornerRadius) {
    var status = this.paper.rect(x, y, w, h, cornerRadius ? cornerRadius : 0);
    status.attr('stroke', color);
    status.attr('fill', color);

    if (strokeWidth || strokeWidth === 0) {
        status.attr('stroke-width', strokeWidth);
    }

    return status;
};

pure.web.jsp.GraphComponent.prototype.drawStatusBar = function (graph, x, y, boxHeight, box, arch, statusPadding, cornerRadius) {
    var statusBarWidth = graph.statusBarWidth;
    var status = box ? box['overallStatus'] : 'not_installed';
    statusPadding = statusPadding ? statusPadding : graph.statusPadding;

    if (!arch.statusComponent) {
        arch.statusComponent = this.drawStatus(x + statusPadding, y + statusPadding, statusBarWidth,
                boxHeight - 2 * statusPadding, pure.theme.component[status], null, cornerRadius);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[status]);
    }
};

pure.web.jsp.GraphComponent.prototype.updateStatus = function (status, color) {
    status.attr('stroke', color);
    status.attr('fill', color);
};

pure.web.jsp.GraphComponent.prototype.drawRect = function (x, y, dx, dy, fillColor, strokeColor) {
    if (!strokeColor) {
        strokeColor = fillColor;
    }
    var rect = this.paper.rect(x, y, dx, dy);
    rect.attr('fill', fillColor);
    rect.attr('stroke', strokeColor);
    return rect;
};

pure.web.jsp.GraphComponent.prototype.drawCard = function (x, y, w, h, backgroundColor, labelColor, labelPosition) {
    if (labelPosition === 'left') {
        this.drawRect(x, y, 8, h, labelColor);
    } else if (labelPosition === 'top') {
        this.drawRect(x + 1, y, (w / 2 - 6), 8, labelColor);
    } else {
        this.drawRect(x, y + h - 8, w, 8, labelColor);
    }
};

pure.web.jsp.GraphComponent.prototype.drawEmptyCard = function (x, y, w, h, color) {
    color = color ? color : '#363636';
    this.drawRect(x, y, w, h, color, color);
};

/**
 * Util to display a tooltip (supported by qTip), it is different from regular qtip
 * usage that I will need to calculate the position of the element manually based
 * on the location of the component in the raphael graph
 */
pure.web.jsp.GraphComponent.prototype.showTooltipForGraph = function (x, y, w, h, title, content) {
    var graph = this.graph;
    var totalWidth = graph.totalWidth;

    // calculate where to place the tooltip
    var ele = this.parent.ele;
    var eleOffset = $(ele).offset();
    var eleX = eleOffset.left;
    var eleY = eleOffset.top;

    var contentDiv = $('<div></div>').append(content);

    this.tooltipContent = contentDiv;
    this.tooltipTitle = title;
    var body = $('body');
    // we can only calculate the width and height of the element if it is really part of document.  Create a hidden element with fixed position alone with body
    var temp = $('#temp');
    temp.empty().append(contentDiv);

    var contentW = temp.width();
    var contentH = temp.height();

    var bodyW = body.width();
    var bodyH = body.height();
    var my = 'left top';
    var at = 'right top';

    if (y + eleY + contentH + 40 > bodyH) { // 40 is a magic number which add up the title bar & border of the tooltip
        if (y + eleY - (contentH + 40) > 0) {
            my = 'left bottom';
        } else {
            my = 'left middle';
        }
    }

    if (eleX + x + contentW + 60 > bodyW) {
        my = my.replace('left', 'right');
        x = x - w;
    }

    if (pure.theme['app_name'] === 'Pure1 Manage') {
        // increase scale down for oxygen graphics see https://wiki.purestorage.com/pages/viewpage.action?pageId=163166311
        var scaleTo =
            pure.web.jsp.array.DataUtil.prototype.isOxygen(this.box) ||
            pure.web.jsp.array.DataUtil.prototype.isTachyon(this.box)
                ? 0.7
                : 0.9;
        if (pure.web.jsp.array.DataUtil.prototype.isCBS(this.box) || pure.web.jsp.array.DataUtil.prototype.isCBSShelf(this.box)) {
            /**
             * Not sure why tooltip placement is so complex, but we can simplify this for Pure1 by changing the "my"
             * value to left top. This means that we align the left top of the tooltip box with the left top of the
             * container per qtip docs. Then, we adjust for the x and y of the CBS component (these are already aligned
             * to the middle right of the component). This puts the top left of the tooltip and the middle right of the component
             */
            at = 'left top';
        } else {
            x = (x - graph.boxWidth) * scaleTo;
        }
        y = y * scaleTo;
    } else {
        x = x - totalWidth;
    }

    $(ele).qtip({
            content: {
                title: {
                    text: title,
                    button: true
                },
                text: contentDiv
            },
            position: {
                my: my,
                at: at,
                adjust: {x: x, y: y}
            },
            show: {
                ready: true,
                event: false
            },
            hide: {
                fixed: true,
                event: 'mouseout'
            }
        }
    );
};

pure.web.jsp.GraphComponent.prototype.drawPsu = function (x, y, w, h, data, arch, direction) {
    if (!data) {
        this.drawComponent(x, y, w, h, '#363636', '#363636');
        return;
    }
    var root = this.root;
    var graph = this.graph;
    var statusWidth = graph.statusWidth;
    var arrayData = this.parent.arrayData;
    if (!arch.statusComponent || !arch.layerComponent) {
        arch.mainComponent = this.drawComponent(x, y, w, h, '#707070', '#707070');
        arch.statusComponent = this.drawStatus(x + 3, y + 3, statusWidth, statusWidth, pure.theme.component[data.status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'Power Supply - ' + data.name, function () {
            return arrayData.getPowerSupplyDiv(root, data, direction);
        }, arch.mainComponent, '#707070', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateLayerMouseover(arch, x, y, w, h, 'Power Supply - ' + data.name, function () {
            return arrayData.getPowerSupplyDiv(root, data, direction);
        }, arch.mainComponent, '#707070', '#ffffff');
    }
};

/**
 * This file contains controller-specific drawing functions.
 */

pure.web.jsp.DefaultController = function (box, graph, parent, archive) {
    if (!box) { return; }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? 'Controller ' + box.index : 'Controller';
};

// Inheriting from the base component class
pure.web.jsp.DefaultController.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DefaultController.prototype.constructor = pure.web.jsp.DefaultController;

pure.web.jsp.DefaultController.prototype.draw = function ( y ) {
    this.drawFront(0.5, y);
    this.drawBack(this.graph.boxWidth + 10 + 0.5, y);
};

pure.web.jsp.DefaultController.prototype.drawFront = function ( x, y )
{
    var data = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var statusWidth = graph.statusWidth;
    var boxHeight =  this.getBoxHeight(graph);
    var boxWidth = graph.boxWidth;
    var arrayData = this.parent.arrayData;

    if (!arch.statusComponent || !arch.identifyComponent)
    {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#000000");
        var logoWidth = (boxHeight - 20) * 3 / 5;
        var logo = this.paper.image(pure.Context.contentPath(pure.theme.images.gray_logo),
            x + (boxWidth - logoWidth) / 2, y + (boxHeight - logoWidth) / 2, logoWidth, logoWidth * 7 / 8);
        var statusX = x + boxWidth - 2 - statusWidth, statusY = y + 11;
        arch.identifyComponent = this.drawIdentify(statusX, statusY, barWidth / 2, barWidth / 2, data.identifyEnabled === true);

        var name = (data.name ? (" - " + data.name) : "");
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, "Controller" + name, function ()
        {
            return arrayData.getBoxDiv(data);
        }, arch.mainComponent, "#000000", "#707070", arch);
    } else {
        this.updateIdentify(arch.identifyComponent, data.identifyEnabled === true);

        var name = (data.name ? (" - " + data.name) : "");
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, "Controller" + name, function ()
        {
            return arrayData.getBoxDiv(data);
        }, arch.mainComponent, "#000000", "#707070");
    }
    this.drawStatusBar(graph, x, y, boxHeight, data, arch);
};

pure.web.jsp.DefaultController.prototype.drawBack = function ( x, y )
{
    var data = this.box;
    var arch = this.arch.front;
    var i = 0;
    var graph = this.graph;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight;
    var cardWidth = graph.cardWidth;
    var cardHeight = barHeight * 4 / 5;

    var slotMap = [
        [x + 10, y + 3], //1
        [x + 10, y + (cardWidth + 3) + 3], //2
        [x + 10, y + (cardWidth + 3) * 2 + 3], //3
        [x + (barHeight + 15) + 10, y + 3], //4
        [x + (barHeight + 15) + 10, y + (cardWidth + 3) + 3], //5
        [x + (barHeight + 15) * 2 + 10, y + 3, cardHeight], //6
        [x + (barHeight + 15) * 2 + 10, y + (cardWidth + 3) + 3],   //7
        [x + cardHeight + 30, y + boxHeight - cardWidth - 5] //ethernet
    ];

    if (!arch.psuList || !arch.sasCardList || !arch.fibreCardList || !arch.managementCard
        || data.status === "missing" || data.status === "offline" || data.status === "not_present")
    {
        this.drawRect(x, y, boxWidth, barHeight + 20, "#000000", "#000000");

        // goes in order of PSUs, InfibandCards, SasCards, FibreCards, EthernetCards, ManagementCards, DcaCards
        var numComponents = [2, 1, 4, 4, 4, 1, 2];

        arch.psuList = this.initEmptyArch(numComponents[0]);
        arch.infibandCard = this.initEmptyArch(numComponents[1]);
        arch.sasCardList = this.initEmptyArch(numComponents[2]);
        arch.fibreCardList = this.initEmptyArch(numComponents[3]);
        arch.ethernetCardList = this.initEmptyArch(numComponents[4]);
        arch.managementCard = this.initEmptyArch(numComponents[5]);
        arch.dcaCardList = this.initEmptyArch(numComponents[6]);

        for (i = 0; i < slotMap.length; i++)
        {
            this.drawEmptyCard(slotMap[i][0], slotMap[i][1], cardHeight, cardWidth);
        }
        // Ethernet is twice as long
        this.drawEmptyCard(slotMap[slotMap.length-1][0], slotMap[slotMap.length-1][1], cardHeight * 2, cardWidth);
    }

    var psuHeight = boxHeight / 2 - 15;
    var psuWidth = cardWidth * 2;
    this.drawPsu(x + boxWidth - (psuWidth + 15), y + boxHeight - (psuHeight + 5), psuWidth, psuHeight, data.powerSupplyList[1], arch.psuList[1]);
    this.drawPsu(x + boxWidth - (psuWidth + 15) * 2, y + boxHeight - (psuHeight + 5), psuWidth, psuHeight, data.powerSupplyList[0], arch.psuList[0]);

    var position;
    if (data.ibPortList.length > 0)
    {
        position = slotMap[data.ibPortList[0].slot - 1];
        this.drawInfinibandCard(position[0], position[1], cardHeight, cardWidth, data.ibPortList, arch.infibandCard);
    }

    var numPortsFound = 0;
    while (data.sasPortList.length > numPortsFound) // 2 ports in one card
    {
        var data1Index = numPortsFound;
        var data2Index = numPortsFound + 1;
        var cardIndex = numPortsFound/2; // 2 ports in one card
        position = slotMap[data.sasPortList[numPortsFound].slot - 1];
        this.drawSasCard(position[0], position[1], cardHeight, cardWidth, [data.sasPortList[data1Index],
            data.sasPortList[data2Index]], arch.sasCardList[cardIndex]);
        numPortsFound += 2;
    }

    numPortsFound = 0;
    while (data.fcPortList.length > numPortsFound)
    {
        var data1Index = numPortsFound;
        var data2Index = numPortsFound + 1;
        var cardIndex = numPortsFound/2; // 2 ports in one card
        position = slotMap[data.fcPortList[numPortsFound].slot - 1];
        this.drawFibreCard(position[0], position[1], cardHeight, cardWidth, data.fcPortList[data1Index],
            data.fcPortList[data2Index], arch.fibreCardList[cardIndex]);
        numPortsFound += 2; // 2 ports in one card
    }

    if (data['ethernetList'].length > 0) {
        position = slotMap[slotMap.length - 1];
        this.drawManagementCard(position[0], position[1], cardHeight * 2, cardWidth, data['ethernetList'], arch.managementCard);
    }

    numPortsFound = 4; // skipping 4 management ports
    while (data.ethernetList.length > numPortsFound)
    {
        var data1Index = numPortsFound;
        var data2Index = numPortsFound + 1;
        var cardIndex = (numPortsFound-4)/2; // Different for ethernet since first 4 ports are in arch.managementCard
        position = slotMap[data.ethernetList[numPortsFound].slot - 1];

        this.drawEthernetCard(position[0], position[1], cardHeight, cardWidth, data.ethernetList[data1Index],
            data.ethernetList[data2Index], arch.ethernetCardList[cardIndex]);

        numPortsFound += 2;
    }
};

pure.web.jsp.DefaultController.prototype.getNumCardsForPortList = function ( portList )
{
    var slots = [];
    var numUniqueSlots = 0;
    for(var i = 0; i < portList.length; i++)
    {
        if (pure.defined(portList[i].slot) && !pure.defined(slots[portList[i].slot]))
        {
            numUniqueSlots += 1;
            slots[portList[i].slot] = true;
        }
    }

    return numUniqueSlots;
};

pure.web.jsp.DefaultController.prototype.initArch = function ( arch, options )
{
    if (options.numPSU)
    {
        arch.psuList = this.initEmptyArch(options.numPSU);
    }
    arch.infibandCard = this.initEmptyArch(options.numInfiniband);
    arch.sasCardList = this.initEmptyArch(options.numSAS);
    arch.fibreCardList = this.initEmptyArch(options.numFC);
    arch.ethernetCardList = this.initEmptyArch(options.numEthernet);
    arch.managementCard = this.initEmptyArch(options.numManagement);
    arch.dcaCardList = this.initEmptyArch(options.numDca);

    return arch;
};

pure.web.jsp.DefaultController.prototype.drawEthernetCard = function (x, y, w, h, data1, data2, arch)
{
    if (!arch.ethernetPortList)
    {
        arch.ethernetPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    this.drawEthernetPort(x + 15, y + 3, h - 6, h - 6, data1, arch.ethernetPortList[0]);
    this.drawEthernetPort(x + 15 + h, y + 3, h - 6, h - 6, data2, arch.ethernetPortList[1]);
};

pure.web.jsp.DefaultController.prototype.drawEthernetPort = function (x, y, w, h, data, arch)
{
    if (!data)
    {
        return;
    }
    var _this = this;
    var graph = this.graph;

    var arrayData = this.parent.arrayData;
    var title = "Ethernet Port - " + data.name;

    if (!arch.statusComponent)
    {
        var statusWidth = graph.statusWidth;
        if (statusWidth > (w - 5)) {
            statusWidth = w * 0.6;
        }
        var space = (w - statusWidth) / 2;
        arch.mainComponent = this.drawComponent(x, y, w, h, "#707070", "#707070");
        arch.statusComponent = this.drawStatus(x + space, y + space, statusWidth, statusWidth, pure.theme.component[data.status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, title, function ()
        {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, "#707070", "#ffffff", arch);
    }
    else
    {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateLayerMouseover(arch, x, y, w, h, title, function ()
        {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, "#707070", "#ffffff");
    }
};

pure.web.jsp.DefaultController.prototype.drawFibreCard = function (x, y, w, h, data1, data2, arch)
{
    if (!arch.fibrePortList)
    {
        arch.fibrePortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#f8941d", (w > h) ? "left" : undefined);
    }

    this.drawFibrePort(x + 15, y + 3, h - 6, h - 6, data1, arch.fibrePortList[0]);
    this.drawFibrePort(x + 15 + h, y + 3, h - 6, h - 6, data2, arch.fibrePortList[1]);
};

pure.web.jsp.DefaultController.prototype.drawFibrePort = function (x, y, w, h, data, arch) {
    if (!data) {return;}

    var _this = this;
    var graph = this.graph;
    var arrayData = this.parent.arrayData;
    if (!arch.statusComponent) {
        var statusWidth = graph.statusWidth;
        if (statusWidth > (w - 5)) {
            statusWidth = w * 0.6;
        }
        arch.mainComponent = this.drawComponent(x, y, w, h, '#707070', '#707070');
        var space = (w - statusWidth) / 2;
        arch.statusComponent = this.drawStatus(x + space, y + space, statusWidth, statusWidth, pure.theme.component[data.status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'Fibre Channel Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateLayerMouseover(arch, x, y, w, h, 'Fibre Channel Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff');
    }
};

/*
 * The management card is the long row of ethernet ports used for management
 * or replication.
 */
pure.web.jsp.DefaultController.prototype.drawManagementCard = function (x, y, w, h, data, arch) {
    var graph = this.graph;
    var cardWidth = graph.cardWidth;
    if (!arch.portList) {
        arch.portList = this.initEmptyArch(4);
        this.drawCard(x, y, w, h, '#363636', '#a67c52', (w > h) ? 'left' : undefined);
    }

    var indexList = [0, 1, 2, 3];
    for (var i = 0; i < indexList.length; i++) {
        this.drawEthernetPort(x + ((w - 8) / 4 - 2) * i + 3 + 12, y + 3, cardWidth - 6, cardWidth - 6, data[indexList[i]], arch.portList[indexList[i]]);
    }
};

pure.web.jsp.DefaultController.prototype.drawInfinibandCard = function (x, y, w, h, list, arch) {
    if (!arch.ibPortList) {
        arch.ibPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, '#363636', '#662d91', (w > h) ? 'left' : undefined);
    }

    this.drawInfinibandPort(x + 15, y + 3, h - 6, h - 6, list[0], arch.ibPortList[0]);
    this.drawInfinibandPort(x + 15 + h, y + 3, h - 6, h - 6, list[1], arch.ibPortList[1]);
};

pure.web.jsp.DefaultController.prototype.drawInfinibandPort = function (x, y, w, h, data, arch) {
    if (!data) {return;}
    var _this = this;
    var graph = this.graph;
    var arrayData = this.parent.arrayData;

    var status = data.status;
    if (status === 'disconnect') {
        // An IB Port disconnect is only "bad" if we're on an HA testbed
        status = data.status = (this.parent.isHA())? 'disconnect_bad' : status;
    }

    if (!arch.statusComponent) {
        var statusWidth = graph.statusWidth;
        if (statusWidth > (w - 5)) {
            statusWidth = w * 0.6;
        }
        arch.mainComponent = this.drawComponent(x, y, w, h, '#707070', '#707070');
        var space = (w - statusWidth) / 2;
        arch.statusComponent = this.drawStatus(x + space, y + space, statusWidth, statusWidth, pure.theme.component[status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'Infiniband Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[status]);
        this.updateLayerMouseover(arch, x, y, w, h, 'Infiniband Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff');
    }
};

pure.web.jsp.DefaultController.prototype.drawDcaCard = function (x, y, w, h, data, arch) {
    if (!arch.dcaPortList)
    {
        arch.dcaPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#713A5C", (w > h) ? "left" : undefined);
    }

    for (var i = 0; i < arch.dcaPortList.length; ++i) {
        this.drawDcaPort(x + h - 7, y + 2, w - 12, h - 4, data, arch.dcaPortList[i]);
    }
}

pure.web.jsp.DefaultController.prototype.drawDcaPort = function ( x, y, w, h, data, arch) {
    if (!data)
    {
        return;
    }
    var _this = this;
    var graph = this.graph;

    var arrayData = this.parent.arrayData;
    var title = "DirectCompress Accelerator ";
    title += data[0].model === "v2" ? "V2 - " : "- ";
    title += data[0].name;

    if (!arch.statusComponent)
    {
        var statusWidth = w - 6;
        var statusHeight = (h - 6) / 2;
        arch.mainComponent = this.drawComponent(x, y, w, h, "#707070", "#707070");
        arch.statusComponent = this.drawStatus(x + 3, y + 5, statusWidth, statusHeight, pure.theme.component[data[0].status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, title, function ()
        {
            return arrayData.getDcaDiv(_this.root, data);
        }, arch.mainComponent, "#707070", "#ffffff", arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data[0].status]);
        this.updateLayerMouseover(arch, x, y, w, h, title, function ()
        {
            return arrayData.getDcaDiv(_this.root, data);
        }, arch.mainComponent, "#707070", "#ffffff");
    }
}

pure.web.jsp.DefaultController.prototype.drawSasCard = function (x, y, w, h, data, arch) {
    if (!arch.sasPortList) {
        arch.sasPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, '#363636', '#2d95dd', (w > h) ? 'left' : undefined);
    }

    this.drawSasPort(x + 15, y + 3, h - 6, h - 6, data[0], arch.sasPortList[0]);
    this.drawSasPort(x + 15 + h, y + 3, h - 6, h - 6, data[1], arch.sasPortList[1]);
};

pure.web.jsp.DefaultController.prototype.drawSasPort = function ( x, y, w, h, data, arch) {
    if (!data) {return;}

    var _this = this;
    var arrayData = this.parent.arrayData;

    if (!arch.statusComponent) {
        var graph = this.graph;
        var statusWidth = graph.statusWidth;

        if (statusWidth > (w - 5)) {
            statusWidth = w * 0.6;
        }
        var space = (w - statusWidth) / 2;

        arch.mainComponent = this.drawComponent(x, y, w, h, '#707070', '#707070');
        arch.statusComponent = this.drawStatus(x + space, y + space, statusWidth, statusWidth, pure.theme.component[data.status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'SAS Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateLayerMouseover(arch, x, y, w, h, 'SAS Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff');
    }
};

/**
 * This file contains all the drawing functions for the
 * Platinum chassis.
 */

pure.web.jsp.FA500Chassis = function (box, graph, parent, archive) {
    if (!box) {
        return;
    }

    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = box.index != null ? 'Chassis ' + box.index : 'Chassis';
    this.type = box.type;
    this.model = box.model;

    // Chassis-specific
    this.shelf = new pure.web.jsp.FA500Shelf(box, graph, parent, archive);
    let c0 = box.controllerList[0],
        c1 = box.controllerList[1];

    if (c0.model) {
        if (c0.model.endsWith('R4') || c0.model.startsWith('FA-E')) {
            this.ct0 = new pure.web.jsp.CobaltController(
                c0,
                graph,
                parent,
                this.arch.back.ct0,
                this.root + ', Back',
                'CT0'
            );
        } else {
            this.ct0 = new pure.web.jsp.FA500Controller(
                c0,
                graph,
                parent,
                this.arch.back.ct0,
                this.root + ', Back',
                'CT0'
            );
        }
    }

    if (c1.model) {
        if (c1.model.endsWith('R4') || c1.model.startsWith('FA-E')) {
            this.ct1 = new pure.web.jsp.CobaltController(
                c1,
                graph,
                parent,
                this.arch.back.ct1,
                this.root + ', Back',
                'CT1'
            );
        } else {
            this.ct1 = new pure.web.jsp.FA500Controller(
                c1,
                graph,
                parent,
                this.arch.back.ct1,
                this.root + ', Back',
                'CT1'
            );
        }
    }
};

// Inheriting from the base controller class
pure.web.jsp.FA500Chassis.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.FA500Chassis.prototype.constructor = pure.web.jsp.FA500Chassis;

pure.web.jsp.FA500Chassis.prototype.draw = function (y) {
    this.drawFront(0, y);
    this.drawBack(this.graph.boxWidth + 10 + 0.5, y);
};

pure.web.jsp.FA500Chassis.prototype.setPaper = function (paper) {
    this.paper = paper;
    this.shelf.setPaper(paper);
    if (this.ct0) {
        this.ct0.setPaper(paper);
    }
    if (this.ct1) {
        this.ct1.setPaper(paper);
    }
};

pure.web.jsp.FA500Chassis.prototype.getBoxHeight = function (graph) {
    return graph.boxHeight3U;
};

pure.web.jsp.FA500Chassis.prototype.drawFront = function (x, y) {
    this.shelf.draw(y);
};

pure.web.jsp.FA500Chassis.prototype.drawBack = function (x, y) {
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var psuWidth = Math.ceil(graph.boxHeight3U / 2 - 26);
    var psuHeight = graph.boxHeight3U / 3;

    var elementPadding = 5;
    var controllerHeight = (graph.boxHeight3U - 3 * elementPadding) / 2;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.psuList) {
        var numComponents = {
            numPSU: 2,
        };

        arch.psuList = this.initEmptyArch(numComponents.numPSU);

        // Drawing chassis background
        this.drawRect(x, y, graph.boxWidth, graph.boxHeight3U, '#000000', '#000000');
    }

    this.drawPsu(
        x + 8,
        y + elementPadding + controllerHeight - psuHeight - 6,
        psuWidth,
        psuHeight,
        data.powerSupplyList[0],
        arch.psuList[0],
        'vertical'
    );
    this.drawPsu(
        x + 8,
        y + 2 * controllerHeight + 2 * elementPadding - psuHeight - 6,
        psuWidth,
        psuHeight,
        data.powerSupplyList[1],
        arch.psuList[1],
        'vertical'
    );

    var ctXOffset = psuWidth + 2 * elementPadding + 8;
    if (this.ct0) {
        this.ct0.draw(
            x + ctXOffset,
            y + elementPadding,
            graph.boxWidth - (ctXOffset + elementPadding + 2),
            controllerHeight,
            arch.ct0
        );
    }
    if (this.ct1) {
        this.ct1.draw(
            x + ctXOffset,
            y + controllerHeight + 2 * elementPadding,
            graph.boxWidth - (ctXOffset + elementPadding + 2),
            controllerHeight,
            arch.ct1
        );
    }
};

/**
 * This file contains all the drawing functions for the
 * Oxygen chassis.
 */

pure.web.jsp.OxygenChassis = function (box, graph, parent, archive) {
    if (!box) { return; }

    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Chassis " + box.index : "Chassis";
    this.type = box.type;

    // Chassis-specific
    this.shelf = new pure.web.jsp.OxygenShelf(box, graph, parent, archive);
    this.ct0 = new pure.web.jsp.OxygenController(box.controllerList[0], graph, parent,
        this.arch.back.ct0, this.root + ", Back", "CT0");
    this.ct1 = new pure.web.jsp.OxygenController(box.controllerList[1], graph, parent,
        this.arch.back.ct1, this.root + ", Back", "CT1");
};

// Inheriting from the base controller class
pure.web.jsp.OxygenChassis.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.OxygenChassis.prototype.constructor = pure.web.jsp.OxygenChassis;

pure.web.jsp.OxygenChassis.prototype.draw = function ( y )
{
    this.drawFront(0, y);
    this.drawBack(this.graph.boxWidth + 10 + 0.5, y);
};

pure.web.jsp.OxygenChassis.prototype.setPaper = function ( paper )
{
    this.paper = paper;
    this.shelf.setPaper(paper);
    this.ct0.setPaper(paper);
    this.ct1.setPaper(paper);
};

pure.web.jsp.OxygenChassis.prototype.getBoxHeight = function ( graph )
{
    return graph.boxHeight5U;
};

pure.web.jsp.OxygenChassis.prototype.drawFront = function (x, y)
{
    this.shelf.draw(y);
};

pure.web.jsp.OxygenChassis.prototype.drawBack = function (x, y) {
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var psuWidth = Math.ceil(graph.boxWidth / 4 - 20);
    var psuHeight = graph.boxHeight5U / 5;

    var elementPadding = 5;
    var controllerHeight = (graph.boxHeight5U - 6 * elementPadding - psuHeight) / 2;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.psuList) {
        var numComponents = {
            numPSU: 4
        };

        arch.psuList = this.initEmptyArch(numComponents.numPSU);

        // Drawing chassis background
        this.drawRect(x, y, graph.boxWidth, graph.boxHeight5U, "#000000", "#000000");
    }

    this.drawPsu(x + 8, y + 10, psuWidth, psuHeight, data.powerSupplyList[0], arch.psuList[0], 'top4');
    this.drawPsu(x + 8 + psuWidth + 3 * elementPadding, y + 10, psuWidth, psuHeight, data.powerSupplyList[1], arch.psuList[1], 'top4');
    this.drawPsu(x + 8 + 2 * psuWidth + 9 * elementPadding, y + 10, psuWidth, psuHeight, data.powerSupplyList[2], arch.psuList[2], 'top4');
    this.drawPsu(x + 8 + 3 * psuWidth + 12 * elementPadding, y + 10, psuWidth, psuHeight, data.powerSupplyList[3], arch.psuList[3], 'top4');

    this.ct0.draw(x + 5, y + 4 * elementPadding + psuHeight, graph.boxWidth - 2 * elementPadding, controllerHeight, arch.ct0);
    this.ct1.draw(x + 5, y + psuHeight + controllerHeight + 5 * elementPadding, graph.boxWidth - 2 * elementPadding, controllerHeight, arch.ct1);
};

/**
 * This file contains drawing functions specific to the FA-3xx family
 * of controllers.
 */

pure.web.jsp.FA300Controller = function  (box, graph, parent, archive)
{
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Controller " + box.index : "Controller";
};

// Inheriting from the base controller class
pure.web.jsp.FA300Controller.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.FA300Controller.prototype.constructor = pure.web.jsp.FA300Controller;

pure.web.jsp.FA300Controller.prototype.drawBack = function (x, y)
{
    var i = 0;
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var cardWidth = graph.cardWidth;
    var cardHeight = graph.barHeight;
    var cardSpacing = graph.cardSpacing;

    var slotMap = new pure.web.jsp.SlotMap({
        model: "FA-300",
        x: x,
        y: y,
        boxWidth: graph.boxWidth,
        boxHeight: graph.boxHeight,
        cardWidth: cardWidth,
        cardSpacing: cardSpacing});

    if (!arch.psuList || !arch.sasCardList || !arch.fibreCardList || !arch.managementCard || !arch.ethernetCardList
        || data.status === "missing" || data.status === "offline" || data.status === "not_present")
    {

        arch = {};
        var numComponents = {   numPSU: 2,
            numInfiniband: 1,
            numSAS: 2,
            numFC: 2,
            numEthernet: 2,
            numManagement: 1 };

        this.initArch(arch, numComponents);

        // Drawing controller and card backgrounds
        this.drawRect(x, y, graph.boxWidth, graph.barHeight + 20, "#000000", "#000000");
        this.drawEmptyCard(slotMap.getX("management"), slotMap.getY("management"), cardWidth * 2, cardWidth * 2);
        for (i = 0; i < 7; i++)
        {
            this.drawEmptyCard(slotMap.getX(i), slotMap.getY(i), cardWidth, cardHeight);
        }

    }

    this.drawPsu(slotMap.getX("psu0"), slotMap.getY("psu0"), graph.cardWidth * 2, graph.boxHeight / 2 - 15, data.powerSupplyList[0], arch.psuList[0], 'vertical');
    this.drawPsu(slotMap.getX("psu1"), slotMap.getY("psu1"), graph.cardWidth * 2, graph.boxHeight / 2 - 15, data.powerSupplyList[1], arch.psuList[1], 'vertical');

    if (data.ibPortList.length >= 2) // 1 card * 2 ports per card
    {
        var position = slotMap.get(data.ibPortList[1].slot);
        this.drawInfinibandCard(position.x, position.y, cardWidth, cardHeight, data.ibPortList, arch.infibandCard[0]);
    }

    if (data.sasPortList.length  >= 2) // 1 card * 2 ports per card
    {
        var position = slotMap.get(data.sasPortList[1].slot);
        this.drawSasCard(position.x, position.y, cardWidth, cardHeight, data.sasPortList, arch.sasCardList[0]);
    }

    if (data.sasPortList.length  >= 4) // 2nd card * 2 ports per card
    {
        var position = slotMap.get(data.sasPortList[3].slot);
        this.drawSasCard(position.x, position.y, cardWidth, cardHeight, data.sasPortList.slice(2, 4), arch.sasCardList[1]);
    }

    if (data.fcPortList.length >= 2) // 1 card * 2 ports per card
    {
        var position = slotMap.get(data.fcPortList[1].slot);
        this.drawFibreCard(position.x, position.y, cardWidth, cardHeight, data.fcPortList, arch.fibreCardList[0].fcPortList,
            {color: "#f8941d", drawPort: this.drawFibrePort});
    }

    if (data.fcPortList.length >= 4) // 1 card * 2 ports per card
    {
        var position = slotMap.get(data.fcPortList[3].slot);
        this.drawFibreCard(position.x, position.y, cardWidth, cardHeight, [data.fcPortList[2], data.fcPortList[3]], arch.fibreCardList[1].fcPortList);
    }

    if (data.ethernetList.length >= 2) // First two ethernet ports are always the management card
    {
        var position = slotMap.get("management");
        this.drawManagementCard(position.x, position.y, cardWidth * 2, cardWidth * 2, data.ethernetList, arch.managementCard);
    }

    if (data.ethernetList.length >= 4) // 2nd card * 2 ports per card
    {
        var position = slotMap.get(data.ethernetList[3].slot);
        this.drawEthernetCard(position.x, position.y, cardWidth, cardHeight, data.ethernetList[2], data.ethernetList[3], arch.ethernetCardList[0]);

    }

    if (data.ethernetList.length >= 6)  // 3rd card * 2 ports per card
    {
        var position = slotMap.get(data.ethernetList[5].slot);
        this.drawEthernetCard(position.x, position.y, cardWidth, cardHeight, data.ethernetList[4], data.ethernetList[5], arch.ethernetCardList[1]);
    }
};


pure.web.jsp.FA300Controller.prototype.drawEthernetCard = function ( x, y, w, h, data1, data2, arch )
{
    if (!arch.ethernetPortList)
    {
        arch.ethernetPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    this.drawEthernetPort(x + 3, y + 10, w - 6, (h - 30) / 2, data1, arch.ethernetPortList[0]);
    this.drawEthernetPort(x + 3, y + h / 2, w - 6, (h - 30) / 2, data2, arch.ethernetPortList[1]);
};

pure.web.jsp.FA300Controller.prototype.drawFibreCard = function (x, y, w, h, data, arch)
{
    if (!arch)
    {
        arch = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#f8941d", (w > h) ? "left" : undefined);
    }

    this.drawFibrePort(x + 3, y + 10, w - 6, (h - 30) / 2, data[0], arch[0]);
    this.drawFibrePort(x + 3, y + h / 2, w - 6, (h - 30) / 2, data[1], arch[1]);
};

pure.web.jsp.FA300Controller.prototype.drawInfinibandCard = function (x, y, w, h, data, arch)
{
    if (!arch.ibPortList)
    {
        arch.ibPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#662d91", (w > h) ? "left" : undefined);
    }

    this.drawInfinibandPort(x + 3, y + 10, w - 6, (h - 30) / 2, data[0], arch.ibPortList[0]);
    this.drawInfinibandPort(x + 3, y + h / 2, w - 6, (h - 30) / 2, data[1], arch.ibPortList[1]);
};

pure.web.jsp.FA300Controller.prototype.drawManagementCard = function ( x, y, w, h, data, arch )
{
    var graph = this.graph;
    var cardWidth = graph.cardWidth;
    if (!arch.portList)
    {
        arch.portList = this.initEmptyArch(4);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    this.drawEthernetPort(x + 3, y + 3, cardWidth - 6, cardWidth - 6, data[1], arch.portList[1]);
    this.drawEthernetPort(x + (w / 2) + 3, y + 3, cardWidth - 6, cardWidth - 6, data[0], arch.portList[0]);
};

pure.web.jsp.FA300Controller.prototype.drawSasCard = function (x, y, w, h, data, arch)
{

    if (!arch.sasPortList)
    {
        arch.sasPortList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#2d95dd", (w > h) ? "left" : undefined);
    }

    this.drawSasPort(x + 3, y + 10, w - 6, (h - 30) / 2, data[0], arch.sasPortList[0]);
    this.drawSasPort(x + 3, y + h / 2, w - 6, (h - 30) / 2, data[1], arch.sasPortList[1]);
};

/**
 * This file contains drawing functions specific to the FA-405 controller.
 * The FA
 */

pure.web.jsp.FA405Controller = function (box, graph, parent, archive)
{
    this.box = box;
    this.graph = graph;
    this.parent = parent;
    this.arch = archive;

    this.cardWidth = graph.cardWidth;
    this.cardHeight = graph.barHeight * 4 / 5;
    this.root = (box.index != null) ? "Controller " + box.index : "Controller";
};

// Inheriting from the base controller class
pure.web.jsp.FA405Controller.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.FA405Controller.prototype.constructor = pure.web.jsp.FA405Controller;

pure.web.jsp.FA405Controller.prototype.getBoxHeight = function ( graph )
{
    return graph.boxHeight1U;
};

pure.web.jsp.FA405Controller.prototype.drawBack = function (x, y)
{
    var i = 0;
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = barHeight / 2 + 25;
    var cardWidth = graph.cardWidth;
    var cardHeight = barHeight * 4 / 5;
    var psuHeight = boxHeight - 20;
    var psuWidth = cardWidth * 2 + cardWidth / 2;

    var slotMap = new pure.web.jsp.SlotMap({
        model: data.model,
        x: x,
        y: y,
        boxWidth: boxWidth,
        boxHeight: boxHeight,
        barHeight: barHeight,
        cardWidth: cardWidth,
        cardHeight: cardHeight,
        psuHeight: psuHeight,
        psuWidth: psuWidth
    });

    barHeight = graph.barHeight / 2 + 5;

    if (!arch.psuList || !arch.sasCardList || !arch.fibreCardList || !arch.managementCard
        || data.status === "missing" || data.status === "offline" || data.status === "not_present")
    {
        var numComponents = {   numPSU: 2,
            numInfiniband: 1,
            numSAS: 1,
            numFC: 1,
            numEthernet: 1,
            numManagement: 1 };

        this.initArch(arch, numComponents);

        // Drawing controller and card backgrounds
        this.drawRect(x, y, boxWidth, barHeight + 20, "#000000", "#000000");
        this.drawEmptyCard(slotMap.getX("management"), slotMap.getY("management"), cardHeight * 2, cardWidth);
        for (i = 1; i <= 3; i++)
        {
            this.drawEmptyCard(slotMap.getX(i), slotMap.getY(i), cardHeight, cardWidth);
        }
    }

    this.drawPsu(slotMap.getX("psu0"), slotMap.getY("psu0"), psuWidth, psuHeight, data.powerSupplyList[1], arch.psuList[1]);
    this.drawPsu(slotMap.getX("psu1"), slotMap.getY("psu1"), psuWidth, psuHeight, data.powerSupplyList[0], arch.psuList[0]);

    var position;
    if(data.ibPortList.length > 0)
    {
        position = slotMap.get(data.ibPortList[0].slot);
        this.drawInfinibandCard(position.x, position.y, cardHeight, cardWidth, data.ibPortList, arch.infibandCard);
    }

    if(data.sasPortList.length > 0)
    {
        position = slotMap.get(data.sasPortList[0].slot);
        this.drawSasCard(position.x, position.y, cardHeight, cardWidth, data.sasPortList, arch.sasCardList[0]);
    }

    if (data.fcPortList.length > 0)
    {
        position = slotMap.get(data.fcPortList[0].slot);
        this.drawFibreCard(position.x, position.y, cardHeight, cardWidth, data.fcPortList[0], data.fcPortList[1], arch.fibreCardList[0]);
    }

    if(data.ethernetList.length > 0)
    {
        position = slotMap.get("management");
        this.drawManagementCard(position.x, position.y, cardHeight * 2, cardWidth, data.ethernetList, arch.managementCard);
    }

    if (data.ethernetList.length > 4)
    {
        position = slotMap.get(data.ethernetList[4].slot);
        this.drawEthernetCard(position.x, position.y, cardHeight, cardWidth, data.ethernetList[4], data.ethernetList[5], arch.ethernetCardList);
    }

};

/*
 * The management card is the long row of ethernet ports used for management
 * or replication. For the FA-405, the order of the ports is different.
 */
pure.web.jsp.FA405Controller.prototype.drawManagementCard = function (x, y, w, h, data, arch)
{
    var i;
    var graph = this.graph;
    var cardWidth = graph.cardWidth;
    if (!arch.portList)
    {
        arch.portList = this.initEmptyArch(4);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    var indexList = [2, 3, 0, 1];
    for(i=0; i<indexList.length; i++)
    {
        this.drawEthernetPort(x + ((w - 8) / 4 - 2) * i + 3 + 12, y + 3, cardWidth - 6, cardWidth - 6, data[indexList[i]], arch.portList[indexList[i]]);
    }
};

/**
 * This file contains drawing functions specific to the FA-3xx family
 * of controllers.
 */

pure.web.jsp.FA450Controller = function (box, graph, parent, archive)
{
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Controller " + box.index : "Controller";
};

// Inheriting from the base controller class
pure.web.jsp.FA450Controller.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.FA450Controller.prototype.constructor = pure.web.jsp.FA450Controller;

/*
 * The management card is the long row of ethernet ports used for management
 * or replication.
 */
pure.web.jsp.FA450Controller.prototype.drawManagementCard = function (x, y, w, h, data, arch)
{
    var i;
    var graph = this.graph;
    var cardWidth = graph.cardWidth;
    if (!arch.portList)
    {
        arch.portList = this.initEmptyArch(4);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    var indexList= [2, 3, 0, 1];
    for(i=0; i<indexList.length; i++)
    {
        this.drawEthernetPort(x + ((w - 8) / 4 - 2) * i + 3 + 12, y + 3, cardWidth - 6, cardWidth - 6, data[indexList[i]], arch.portList[indexList[i]]);
    }
};

/**
 * This file contains drawing functions for the controllers on the back
 * of the Platinum chassis.
 */

pure.web.jsp.FA500Controller = function (box, graph, parent, archive, location, name) {
    if (!box) {
        return;
    }
    this.box = box;
    this.graph = graph;
    this.parent = parent;
    this.arch = archive;
    this.location = location;
    this.progressBarId = 'progress-bar-' + name;

    this.cardWidth = graph.cardWidth;
    this.cardHeight = (graph.barHeight * 4) / 5;
    this.root = box && (box.index || box.index === 0) ? 'Controller ' + box.index : 'Controller';
    this.dataUtil = new pure.web.jsp.array.DataUtil();
};

// Inheriting from the base controller class
pure.web.jsp.FA500Controller.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.FA500Controller.prototype.constructor = pure.web.jsp.FA500Controller;

pure.web.jsp.FA500Controller.prototype.draw = function (x, y, w, h, arch) {
    if (this.box && this.box['overallStatus'] === 'updating') {
        this.drawBackDuringFirmwareUpdate(x, y, w, h, arch);
    } else {
        $('#' + this.progressBarId).remove();
        this.drawBack(x, y, w, h, arch);
    }
};

pure.web.jsp.FA500Controller.prototype.drawBack = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var cardWidth = graph.barHeight - 8;
    var cardHeight = graph.cardWidth / 1.5;
    var cardSpacing = graph.cardSpacing;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;

    var slotMap = (this.slotMap = new pure.web.jsp.SlotMap({
        model: data && data.model ? data.model : 'FA-m50',
        x: x,
        y: y,
        boxWidth: w,
        boxHeight: h,
        barHeight: graph.barHeight / 1.25,
        cardHeight: cardHeight,
        cardWidth: cardWidth,
        statusWidth: graph.statusWidth,
        cardSpacing: cardSpacing,
    }));

    if (data) {
        // These ports are at fixed locations
        // for Mercury or Quicksilver and Helium, mezz slot (SAS slot for Tungsten or before) could contain a SASCard or a nvmef EthernetCard.
        // setSlot will not overwrite the slot name of a port
        if (
            (this.isMercuryOrQuicksilver() || this.dataUtil.isHelium(data)) &&
            this.hasMezzEthPorts(data['ethernetList'])
        ) {
            this.setSlot(6, 10, data['ethernetList'], 'mezz');
        } else if (data['sasPortList']) {
            this.setSlot(0, 4, data['sasPortList'], 'mezz');
        }
        this.setSlot(0, 2, data['ethernetList'], 'management0');
        this.setSlot(2, -1, data['ethernetList'], 'management1');
    }

    var numComponents = {
        numInfiniband: data && data.ibPortList ? this.getNumCardsForPortList(data.ibPortList) : 1,
        numSAS: data && data.sasPortList ? this.getNumCardsForPortList(data.sasPortList) : 1,
        numMezz: 1,
        numFC: data && data['fcPortList'] ? this.getNumCardsForPortList(data['fcPortList']) : 3,
        numEthernet:
            data && data['ethernetList'] ? this.getNumCardsForPortList(data['ethernetList']) : 3,
        numManagement: 2,
        numDca: data && data['dcaList'] ? this.getNumCardsForPortList(data['dcaList']) : 0,
    };

    if (
        !arch.mezzCardList ||
        !arch.fibreCardList ||
        !arch.managementCard ||
        !arch.ethernetCardList ||
        !arch.mainComponent ||
        data.status === 'missing' ||
        data.status === 'offline' ||
        data.status === 'not_present' ||
        !arch.numComponents ||
        JSON.stringify(arch.numComponents) !== JSON.stringify(numComponents)
    ) {
        arch = {};
        arch.numComponents = numComponents;
        this.initArch(arch, numComponents);
        arch.mezzCardList = this.initEmptyArch(numComponents.numMezz); // also init mezz card

        // Drawing controller and card backgrounds
        this.drawControllerBG(x, y, w, h, data, arch);
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        for (var i in slotMap.getSlotMap()) {
            if (i !== 'psu0' && i !== 'psu1') {
                // FA-m10r2 does not have SAS port, so not drawing empty card for it.
                if (i === 'mezz' && data.model && data.model.toLowerCase().indexOf('fa-m10') > -1)
                    continue;
                var position = slotMap.get(i);
                this.drawEmptyCard(position.x, position.y, position.width, position.height);
            }
        }

        // We want to return at this point if we don't have data
        if (!data) {
            return;
        }

        arch.identifyComponent = this.drawIdentify(
            Math.ceil(x + w - (graph.statusWidth + 4)),
            Math.ceil(y + h - (graph.statusWidth + 4)),
            graph.identityWidth,
            graph.identityWidth,
            data.identifyEnabled === true
        );
    } else {
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        if (!data) {
            return;
        }

        this.updateLayerMouseover(
            arch,
            x,
            y,
            w,
            h,
            root,
            function () {
                return arrayData.getControllerDiv(location, data);
            },
            arch.mainComponent,
            '#363636',
            '#ffffff'
        );
    }

    // for Mercury or Quicksilver, mezz slot (SAS slot for Tungsten or before) could contain a SASCard or an EthernetCard.
    var mezzPortList = [];
    var portList;
    var isMezzSas;

    if (this.isMercuryOrQuicksilver() || this.dataUtil.isHelium(data)) {
        if (this.hasMezzEthPorts(data['ethernetList'])) {
            portList = data['ethernetList'];
            isMezzSas = false;
        } else {
            portList = data['sasPortList'];
            isMezzSas = true;
        }

        for (i = 0; i < portList.length; i++) {
            if (portList[i].slot === 'mezz') {
                mezzPortList.push(portList[i]);
                portList.splice(i, 1);
                i--;
            }
        }

        this.drawSlotCards(
            mezzPortList,
            arch.mezzCardList,
            this.drawMezzCard.bind(this, isMezzSas)
        );
    }

    this.drawSlotCards(data['sasPortList'], arch.sasCardList, this.drawSasCard.bind(this));
    this.drawSlotCards(data['fcPortList'], arch.fibreCardList, this.drawFibreCard.bind(this));
    this.drawSlotCards(
        data['ethernetList'],
        arch.ethernetCardList,
        this.drawEthernetCard.bind(this)
    );
    this.drawSlotCards(data['ibPortList'], arch.infibandCard, this.drawInfinibandCard.bind(this));
    this.drawSlotCards(data['dcaList'], arch.dcaCardList, this.drawDcaCard.bind(this));
};

pure.web.jsp.FA500Controller.prototype.drawBackDuringFirmwareUpdate = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;
    var progressBarId = this.progressBarId;

    if (!arch.progress) {
        // Drawing controller background
        this.drawControllerBG(x, y, w, h, data, arch);
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        // Clearing possible controller div
        $('#' + progressBarId).remove();

        var arrayGraphDiv = $('#arrayGraph');
        var controllerDiv = $(
            "<div id='" + progressBarId + "' style='position: absolute; z-index: 998;'></div>"
        );
        controllerDiv.css('width', w).css('height', h);
        controllerDiv.css('top', y + 75).css('left', x + 90);

        controllerDiv.mouseover(arch.mouseoverHandler);
        controllerDiv.mouseout(arch.mouseoutHandler);

        if (!data) {
            return;
        }

        /**
         * This most likely never worked, and only recently was discovered to be throwing errors ('progress.progressbar is not a function')
         * Skipping the progressbar drawing for now to prevent this (admittedly rare) error case
         * see: CLOUD-67158
         */
        // arch.progress = this.drawProgressBar(controllerDiv, w, h, data.progress);

        arrayGraphDiv.append(controllerDiv);

        // Changing progress bar to green
        $('.ui-progressbar-value')
            .css('background-image', 'url("")')
            .css('background-color', '#A3E249');
        $('.ui-progressbar').css('background-image', 'url("")').css('background-color', '#f5f5f5');
    } else {
        if (!data) {
            return;
        }
        this.updateStatus(arch.statusComponent, pure.theme.component[data.overallStatus]);
        this.updateLayerMouseover(
            arch,
            x,
            y,
            w,
            h,
            root,
            function () {
                return arrayData.getControllerDiv(location, data);
            },
            arch.mainComponent,
            '#363636',
            '#ffffff'
        );

        if (pure.defined(data.progress) && arch.progress.progressbar('value') !== data.progress) {
            arch.progress.progressbar('value', data.progress);
        }
    }
};

pure.web.jsp.FA500Controller.prototype.drawControllerBG = function (x, y, w, h, data, arch) {
    var arrayData = this.parent.arrayData;
    var location = this.location;
    var root = this.root;

    arch.mainComponent = this.drawComponent(x, y, w, h, '#000000', '#000000');
    arch.layerComponent = this.drawLayer(
        x,
        y,
        w,
        h,
        root,
        function () {
            return arrayData.getControllerDiv(location, data);
        },
        arch.mainComponent,
        '#363636',
        '#ffffff',
        arch
    );
};

pure.web.jsp.FA500Controller.prototype.drawProgressBar = function (div, w, h, value) {
    var progressDiv = $("<div style='margin: 0 10px 0 20px'></div>")
        .css('margin-top', (h - 23) / 2)
        .appendTo(div);
    var progress = $("<div id='progress'></div>").appendTo(progressDiv);
    var progressLabel = $(
        "<div style='position: absolute; left: 18%; margin-top: 4px; font-weight: bold; '></div>"
    ).appendTo(progress);

    var labelFunc = function () {
        var valuePresent = pure.defined(value) ? progress.progressbar('value') + '% complete' : '';
        progressLabel.text('Updating firmware... ' + valuePresent);
    };

    progress.progressbar({
        value: value,
        create: labelFunc,
        change: labelFunc,
    });

    return progress;
};

pure.web.jsp.FA500Controller.prototype.drawSlotCards = function (
    portListData,
    arch,
    cardDrawingFunc
) {
    if (!portListData) {
        return;
    }

    // Mapping a slot to all of the ports with that slot
    var slotPortMap = {};
    for (var i = 0; i < portListData.length; i++) {
        var slot = portListData[i].slot;
        if (!slotPortMap[slot]) {
            slotPortMap[slot] = [];
        }
        slotPortMap[slot].push(portListData[i]);
    }

    // Loop through slots to create cards
    var numCards = 0;
    for (slot in slotPortMap) {
        var position = this.slotMap.get(slot);
        if (position && position.width && position.height) {
            cardDrawingFunc(
                position.x,
                position.y,
                position.width,
                position.height,
                slotPortMap[slot],
                arch[numCards]
            );
            numCards += 1;
        }
    }
};

pure.web.jsp.FA500Controller.prototype.drawEthernetCard = function (x, y, w, h, data, arch) {
    var cardColor = '#a67c52';
    for (var i = 0; i < data.length; i++) {
        if (
            data[i]['services'] &&
            data[i]['services'].length > 0 &&
            data[i]['services'].indexOf('directflash') !== -1
        ) {
            cardColor = '#2d95dd';
            break;
        }
    }

    if (!arch.ethernetPortList) {
        arch.ethernetPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', cardColor, w > h ? 'left' : undefined);
    }

    if (
        (this.isMercuryOrQuicksilver() || this.dataUtil.isHelium(this.box)) &&
        data.length > 0 &&
        data[0]['slot'] === 'management1'
    ) {
        h = h / 2;
        this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[0]);
        this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[2], arch.ethernetPortList[1]);
        this.drawEthernetPort(x + 12, y + 1 + h, h - 3, h - 3, data[1], arch.ethernetPortList[2]);
        this.drawEthernetPort(
            x + 12 + h,
            y + 1 + h,
            h - 3,
            h - 3,
            data[3],
            arch.ethernetPortList[3]
        );
    } else {
        this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[0]);
        this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ethernetPortList[1]);
        this.drawEthernetPort(
            x + 12 + 2 * h,
            y + 1,
            h - 3,
            h - 3,
            data[2],
            arch.ethernetPortList[2]
        );
        this.drawEthernetPort(
            x + 12 + 3 * h,
            y + 1,
            h - 3,
            h - 3,
            data[3],
            arch.ethernetPortList[3]
        );
    }
};

pure.web.jsp.FA500Controller.prototype.drawFibreCard = function (x, y, w, h, data, arch) {
    if (!arch.fibrePortList) {
        arch.fibrePortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', '#f8941d', w > h ? 'left' : undefined);
    }

    this.drawFibrePort(x + 12, y + 1, h - 3, h - 3, data[0], arch.fibrePortList[0]);
    this.drawFibrePort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.fibrePortList[1]);
    this.drawFibrePort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.fibrePortList[2]);
    this.drawFibrePort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.fibrePortList[3]);
};

pure.web.jsp.FA500Controller.prototype.drawInfinibandCard = function (x, y, w, h, data, arch) {
    if (!arch.ibPortList) {
        arch.ibPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', '#662d91', w > h ? 'left' : undefined);
    }

    this.drawInfinibandPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ibPortList[0]);
    this.drawInfinibandPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ibPortList[1]);
    this.drawInfinibandPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.ibPortList[2]);
    this.drawInfinibandPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.ibPortList[3]);
};

pure.web.jsp.FA500Controller.prototype.drawManagementCard = function (x, y, w, h, data, arch) {
    if (!arch.portList) {
        arch.portList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, '#363636', '#a67c52', w > h ? 'left' : undefined);
    }

    this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.portList[0]);
    this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.portList[1]);
};

pure.web.jsp.FA500Controller.prototype.drawSasCard = function (x, y, w, h, data, arch) {
    if (!arch.sasPortList) {
        arch.sasPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', '#2d95dd', w > h ? 'left' : undefined);
    }

    this.drawSasPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.sasPortList[0]);
    this.drawSasPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.sasPortList[1]);
    this.drawSasPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.sasPortList[2]);
    this.drawSasPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.sasPortList[3]);
};

pure.web.jsp.FA500Controller.prototype.drawMezzCard = function (isMezzSas, x, y, w, h, data, arch) {
    if (!arch.mezzPortList) {
        arch.mezzPortList = this.initEmptyArch(data.length);
        // mezz card could be either a SAS card or a nvmef ethernet card. Both of them should be drawn as blue
        this.drawCard(x, y, w, h, '#363636', '#2d95dd', w > h ? 'left' : undefined);
    }

    if (isMezzSas) {
        this.drawSasPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.mezzPortList[0]);
        this.drawSasPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.mezzPortList[1]);
        this.drawSasPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.mezzPortList[2]);
        this.drawSasPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.mezzPortList[3]);
    } else {
        this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.mezzPortList[0]);
        this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.mezzPortList[1]);
        this.drawEthernetPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.mezzPortList[2]);
        this.drawEthernetPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.mezzPortList[3]);
    }
};

pure.web.jsp.FA500Controller.prototype.setSlot = function (
    portIndexStart,
    portIndexEnd,
    portListData,
    slot
) {
    if (!portIndexEnd || portIndexEnd === -1) {
        portIndexEnd = portListData.length;
    }

    if (!portListData || portListData.length < portIndexEnd - 1) {
        return;
    }
    for (var i = portIndexStart; i < portIndexEnd; i++) {
        var port = portListData[i];
        port['slot'] = port['slot'] < 0 ? slot : port['slot'];
    }
};

pure.web.jsp.FA500Controller.prototype.isMercuryOrQuicksilver = function () {
    var box = this.box;
    if (box && box.model) {
        var model = box.model.toLowerCase();
        return (
            (model.startsWith('fa-x') &&
            !model.startsWith('fa-xl') &&
            (model.endsWith('r2') || model.endsWith('r3'))) ||
            model.startsWith('fa-rc20')
        );
    }
    return false;
};

// For Mercury or Quicksilver and Helium, it may have Eth card in Mezz card. And the names of the ports are fixed to be ETH6, ETH7, ETH8 and ETH9.
pure.web.jsp.FA500Controller.prototype.hasMezzEthPorts = function (ethernetList) {
    if (!ethernetList) {
        return false;
    }

    for (var i = 6; i < 10; i++) {
        if (!ethernetList[i]) {
            return false;
        }

        var portName = ethernetList[i]['name'];
        if (
            portName.endsWith('ETH6') ||
            portName.endsWith('ETH7') ||
            portName.endsWith('ETH8') ||
            portName.endsWith('ETH9')
        ) {
            return true;
        }
    }
    return false;
};

/**
 * This file contains drawing functions specific to the CBS family (AKA PAWS)
 * of controllers.
 */

pure.web.jsp.CBSController = function (box, graph, parent, archive) {
    if (!box) { return; }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = 'EC2 Instance';
};

// Inheriting from the base component class
pure.web.jsp.CBSController.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.CBSController.prototype.constructor = pure.web.jsp.CBSController;

pure.web.jsp.CBSController.prototype.draw = function (x, y) {
    this.drawFront(x, y);
};

pure.web.jsp.CBSController.prototype.drawFront = function (x, y) {
    var data = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var boxWidth = graph.cloudBoxWidth;
    var boxHeight = graph.cloudInstanceHeight;
    var arrayData = this.parent.arrayData;

    if (!arch.statusComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#ffffff', '#c5c5c5', 1.75, 6);
        this.drawCloudIcon(x, y, boxWidth, boxHeight);
        this.drawName(x, y);
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, 'Controller - ' + data.index, function () {
            return arrayData.getPAWSTooltipBoxDiv(data);
        }, arch.mainComponent, "#ffffff", "#707070", arch, 6);
    } else {
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, 'Controller - ' + data.index, function () {
            return arrayData.getPAWSTooltipBoxDiv(data);
        }, arch.mainComponent, "#ffffff", "#707070");
    }
    this.drawStatusBar(graph, x, y, boxHeight, data, arch, false, 4);
};

pure.web.jsp.CBSController.prototype.drawCloudIcon = function(x, y, boxWidth, boxHeight) {
    var iconHeight = boxHeight * 0.6;
    var iconWidth = iconHeight * 2.5;
    var ec2X = x + (boxWidth - iconWidth) / 2;
    var ec2Y = y + (boxHeight - iconHeight) / 2;
    this.paper.image(pure.Context.contentPath(pure.theme.images.cbs_logo), ec2X, ec2Y, iconWidth, iconHeight);
};

pure.web.jsp.CBSController.prototype.drawName = function(x, y) {
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var space = barWidth / 2;
    
    /**
     * Note that there are two changes here:
     *  1. reduced font size from 13 to 10 because our graphics are smaller
     *  2. Scale Raphael seems to double the y value of `text` (it puts the value in the y of the text, and the dy of the tspan inside)
     *  looks like this issue: https://github.com/DmitryBaranovskiy/raphael/issues/491
     */

    setTimeout(
        function () {
            this.paper
                .text(x + space * 4.5, y + space, this.box.name)
                .attr({ fill: '#8d8d8d', 'font-size': 10, 'font-family': 'Arial, Helvetica, sans-serif' });
        }.bind(this),
    );
};

pure.web.jsp.CBSController.prototype.getBoxHeight = function(graph) {
    return graph.cloudInstanceHeight;
};
/**
 * This file contains drawing functions for the controllers on the back
 * of the Cobalt chassis.
 */

pure.web.jsp.CobaltController = function (box, graph, parent, archive, location, name) {
    if (!box) { return; }
    this.box = box;
    this.graph = graph;
    this.parent = parent;
    this.arch = archive;
    this.location = location;
    this.progressBarId = 'progress-bar-' + name;

    this.cardWidth = graph.cardWidth;
    this.cardHeight = graph.barHeight * 4 / 5;
    this.root = (box && (box.index || box.index === 0 ) ) ? 'Controller ' + box.index : 'Controller';
    this.dataUtil = new pure.web.jsp.array.DataUtil();
}

// Inheriting from the base controller class
pure.web.jsp.CobaltController.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.CobaltController.prototype.constructor = pure.web.jsp.CobaltController;

pure.web.jsp.CobaltController.prototype.draw = function ( x, y, w, h, arch ) {
    if (this.box && (this.box['overallStatus'] === 'updating')) {
        this.drawBackDuringFirmwareUpdate(x, y, w, h, arch);
    } else {
        $("#" + this.progressBarId).remove();
        this.drawBack(x, y, w, h, arch);
    }
};

pure.web.jsp.CobaltController.prototype.drawBack = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var cardWidth = graph.barHeight - 8;
    var cardHeight = graph.cardWidth / 1.5;
    var cardSpacing = graph.cardSpacing;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;
    
    var slotMap = this.slotMap = new pure.web.jsp.SlotMap({
        model: ( data && data.model ) ? data.model : 'FA-XC50R4',
        x: x,
        y: y,
        boxWidth: w,
        boxHeight: h,
        barHeight: graph.barHeight / 1.25,
        cardHeight: cardHeight,
        cardWidth: cardWidth,
        statusWidth: graph.statusWidth,
        cardSpacing: cardSpacing
    });

    if (data) {
        this.setSlot(0, 4, data['ethernetList'], 'ethernet');
        this.setSlot(4, data['ethernetList'].length, data['ethernetList'], 'management');
    }

    var numComponents = {
        numInfiniband: (data && data.ibPortList) ? this.getNumCardsForPortList(data.ibPortList) : 1,
        numSAS: (data && data.sasPortList) ? this.getNumCardsForPortList(data.sasPortList) : 1,
        numFC: (data && data['fcPortList']) ? this.getNumCardsForPortList(data['fcPortList']) : 0,
        numEthernet: (data && data['ethernetList']) ? this.getNumCardsForPortList(data['ethernetList']) : 4,
        numManagement: 2,
        numDca: (data && data['dcaList']) ? this.getNumCardsForPortList(data['dcaList']) : 0
    };

    if (!arch || !arch.fibreCardList || !arch.managementCard || !arch.ethernetCardList || !arch.mainComponent
        || data.status === "missing" || data.status === "offline" || data.status === "not_present"
        || !arch.numComponents || JSON.stringify(arch.numComponents) !== JSON.stringify(numComponents)) {
        arch = {};
        arch.numComponents = numComponents;
        this.initArch(arch, numComponents);

        if (numComponents.numManagement > 0) {
            // Drawing controller and card backgrounds
            this.drawControllerBG(x, y, w, h, data, arch);
            this.drawStatusBar(graph, x, y, h, data, arch, 3);
        }

        for (var i in slotMap.getSlotMap()) {
            if (i !== 'psu0' && i !== 'psu1') {
                var position = slotMap.get(i);
                this.drawEmptyCard(position.x, position.y, position.width, position.height);
            }
        }

        // We want to return at this point if we don't have data
        if (!data) {
            return;
        }

        arch.identifyComponent = this.drawIdentify(Math.ceil(x + w - (graph.cardWidth * 4 + graph.statusWidth + 4)),
            Math.ceil(y + h - (graph.statusWidth + 4)), graph.identityWidth, graph.identityWidth, data.identifyEnabled === true);
    } else {
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        if (!data) {
            return;
        }

        this.updateLayerMouseover(arch, x, y, w, h, root, function () {
            return arrayData.getControllerDiv(location, data);
        }, arch.mainComponent, "#363636", "#ffffff");
    }

    this.drawSlotCards(data['sasPortList'], arch.sasCardList, this.drawSasCard.bind(this));
    this.drawSlotCards(data['fcPortList'], arch.fibreCardList, this.drawFibreCard.bind(this));
    this.drawSlotCards(data['ethernetList'], arch.ethernetCardList, this.drawEthernetCard.bind(this));
    this.drawSlotCards(data['ibPortList'], arch.infibandCard, this.drawInfinibandCard.bind(this));
    this.drawSlotCards(data['dcaList'], arch.dcaCardList, this.drawDcaCard.bind(this));
};

pure.web.jsp.CobaltController.prototype.drawBackDuringFirmwareUpdate = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;
    var progressBarId = this.progressBarId;

    if (!arch.progress) {
        // Drawing controller background
        this.drawControllerBG(x, y, w, h, data, arch);
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        // Clearing possible controller div
        $("#" + progressBarId).remove();

        var arrayGraphDiv = $("#arrayGraph");
        var controllerDiv = $("<div id='" + progressBarId + "' style='position: absolute; z-index: 998;'></div>");
        controllerDiv.css("width", w).css("height", h);
        controllerDiv.css("top", y + 75).css("left", x + 90);

        controllerDiv.mouseover(arch.mouseoverHandler);
        controllerDiv.mouseout(arch.mouseoutHandler);

        if (!data) {
            return;
        }

        /**
         * This most likely never worked, and only recently was discovered to be throwing errors ('progress.progressbar is not a function')
         * Skipping the progressbar drawing for now to prevent this (admittedly rare) error case
         * see: CLOUD-67158
         */
        // arch.progress = this.drawProgressBar(controllerDiv, w, h, data.progress);

        arrayGraphDiv.append(controllerDiv);

        // Changing progress bar to green
        $(".ui-progressbar-value").css('background-image', 'url("")').css("background-color", "#A3E249");
        $(".ui-progressbar").css('background-image', 'url("")').css("background-color", "#f5f5f5");
    } else {
        if (!data) {
            return;
        }
        this.updateStatus(arch.statusComponent, pure.theme.component[data.overallStatus]);
        this.updateLayerMouseover(arch, x, y, w, h, root, function () {
            return arrayData.getControllerDiv(location, data);
        }, arch.mainComponent, "#363636", "#ffffff");

        if (pure.defined(data.progress) && (arch.progress.progressbar("value") !== data.progress)) {
            arch.progress.progressbar("value", data.progress);
        }
    }
};

pure.web.jsp.CobaltController.prototype.drawControllerBG = function (x, y, w, h, data, arch) {
    var arrayData = this.parent.arrayData;
    var location = this.location;
    var root = this.root;

    arch.mainComponent = this.drawComponent(x, y, w, h, "#000000", "#000000");
    arch.layerComponent = this.drawLayer(x, y, w, h, root, function () {
        return arrayData.getControllerDiv(location, data);
    }, arch.mainComponent, "#363636", "#ffffff", arch);

};

pure.web.jsp.CobaltController.prototype.drawProgressBar = function (div, w, h, value) {
    var progressDiv = $("<div style='margin: 0 10px 0 20px'></div>").css("margin-top", (h - 23)/2).appendTo(div);
    var progress = $("<div id='progress'></div>").appendTo(progressDiv);
    var progressLabel = $("<div style='position: absolute; left: 18%; margin-top: 4px; font-weight: bold; '></div>").appendTo(progress);

    var labelFunc = function () {
        var valuePresent = pure.defined(value) ? progress.progressbar( "value" ) + "% complete" : "";
        progressLabel.text( "Updating firmware... " + valuePresent );
    };

    progress.progressbar({
        value: value,
        create: labelFunc,
        change: labelFunc
    });

    return progress;
};

pure.web.jsp.CobaltController.prototype.drawSlotCards = function (portListData, arch, cardDrawingFunc) {
    if (!portListData) { return; }

    // Mapping a slot to all of the ports with that slot
    var slotPortMap = {};
    for (var i = 0; i < portListData.length; i++) {
        var slot = portListData[i].slot;
        if (!slotPortMap[slot]) {
            slotPortMap[slot] = [];
        }
        slotPortMap[slot].push(portListData[i]);
    }

    // Loop through slots to create cards
    var numCards = 0;
    for (slot in slotPortMap) {
        var position = this.slotMap.get(slot);
        if (position && position.width && position.height) {
            cardDrawingFunc(position.x, position.y, position.width, position.height,
                slotPortMap[slot], arch[numCards]);
            numCards += 1;
        }
    }
};

pure.web.jsp.CobaltController.prototype.drawEthernetCard = function (x, y, w, h, data, arch) {
    var cardColor = '#a67c52';
    for (var i = 0; i < data.length; i++) {
        if (data[i]['services'] && data[i]['services'].length > 0 && data[i]['services'].indexOf('directflash') !== -1) {
            cardColor = '#2d95dd';
            break;
        }
    }

    if (!arch.ethernetPortList && data[0]['slot'] === 'management') {
        arch.ethernetPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, h, w, '#363636', cardColor, 'top');
    } else if (!arch.ethernetPortList) {
        arch.ethernetPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', cardColor, (w > h) ? 'left' : undefined);
    }

    if (data[0]['slot'] === 'management') {
        this.drawEthernetPort(x + 2, y + 11, w - 3, w - 3, data[0], arch.ethernetPortList[0]);
        this.drawEthernetPort(x + 2, y + 11 + w, w - 3, w - 3, data[1], arch.ethernetPortList[1]);
    } else {
        this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[0]);
        this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ethernetPortList[1]);
        this.drawEthernetPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.ethernetPortList[2]);
        this.drawEthernetPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.ethernetPortList[3]);
    }
};

pure.web.jsp.CobaltController.prototype.drawFibreCard = function (x, y, w, h, data, arch) {
    if (!arch.fibrePortList) {
        arch.fibrePortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, "#363636", "#f8941d", (w > h) ? "left" : undefined);
    }

    this.drawFibrePort(x + 12, y + 1, h - 3, h - 3, data[0], arch.fibrePortList[0]);
    this.drawFibrePort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.fibrePortList[1]);
    this.drawFibrePort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.fibrePortList[2]);
    this.drawFibrePort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.fibrePortList[3]);

};

pure.web.jsp.CobaltController.prototype.drawInfinibandCard = function (x, y, w, h, data, arch) {
    if (!arch.ibPortList) {
        arch.ibPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, "#363636", "#662d91", (w > h) ? "left" : undefined);
    }

    this.drawInfinibandPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ibPortList[0]);
    this.drawInfinibandPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ibPortList[1]);
    this.drawInfinibandPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.ibPortList[2]);
    this.drawInfinibandPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.ibPortList[3]);
};

pure.web.jsp.CobaltController.prototype.drawManagementCard = function (x, y, w, h, data, arch) {
    if (!arch.ethernetPortList) {
        arch.etherportList = this.initEmptyArch(2);
        this.drawCard(x, y, w, h, "#363636", "#a67c52", (w > h) ? "left" : undefined);
    }

    this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[4]);
    this.drawEthernetPort(x + 12, y + 1 + h, h - 3, h - 3, data[1], arch.ethernetPortList[5]);
};

pure.web.jsp.CobaltController.prototype.drawSasCard = function (x, y, w, h, data, arch) {
    if (!arch.sasPortList) {
        arch.sasPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, "#363636", "#2d95dd", (w > h) ? "left" : undefined);
    }

    this.drawSasPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.sasPortList[0]);
    this.drawSasPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.sasPortList[1]);
    this.drawSasPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.sasPortList[2]);
    this.drawSasPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.sasPortList[3]);
};

pure.web.jsp.CobaltController.prototype.setSlot = function (portIndexStart, portIndexEnd, portListData, slot) {
    if (!portIndexEnd || portIndexEnd === -1) {
        portIndexEnd = portListData.length;
    }

    if (!portListData || portListData.length < (portIndexEnd - 1)) {
        return;
    }

    for (var i = portIndexStart; i < portIndexEnd; i++) {
        var port = portListData[i];
        port['slot'] = (port['slot'] < 0) ? slot : port['slot'];
    }
};

/**
 * This file contains drawing functions for the controllers on the back
 * of the Oxygen chassis.
 */

pure.web.jsp.OxygenController = function (box, graph, parent, archive, location, name) {
    if (!box) { return; }
    this.box = box;
    this.graph = graph;
    this.parent = parent;
    this.arch = archive;
    this.location = location;
    this.progressBarId = 'progress-bar-' + name;

    this.cardWidth = graph.cardWidth;
    this.cardHeight = graph.barHeight * 4 / 5;
    this.root = (box && (box.index || box.index === 0 ) ) ? 'Controller ' + box.index : 'Controller';
    this.dataUtil = new pure.web.jsp.array.DataUtil();
};

// Inheriting from the base controller class
pure.web.jsp.OxygenController.prototype = new pure.web.jsp.DefaultController();
pure.web.jsp.OxygenController.prototype.constructor = pure.web.jsp.OxygenController;

pure.web.jsp.OxygenController.prototype.draw = function ( x, y, w, h, arch ) {
    if (this.box && (this.box['overallStatus'] === 'updating')) {
        this.drawBackDuringFirmwareUpdate(x, y, w, h, arch);
    } else {
        $("#" + this.progressBarId).remove();
        this.drawBack(x, y, w, h, arch);
    }
};

pure.web.jsp.OxygenController.prototype.drawBack = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var cardWidth = graph.barHeight - 8;
    var cardHeight = graph.cardWidth / 1.5;
    var cardSpacing = graph.cardSpacing;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;

    var slotMap = this.slotMap = new pure.web.jsp.SlotMap({
        model: ( data && data.model ) ? data.model : 'FA-XL170',
        x: x,
        y: y,
        boxWidth: w,
        boxHeight: h,
        barHeight: graph.barHeight / 1.25,
        cardHeight: cardHeight,
        cardWidth: cardWidth,
        statusWidth: graph.statusWidth,
        cardSpacing: cardSpacing
    });

    if (data) {
        this.setSlot(0, 2, data['ethernetList'], 'management');
    }

    var numComponents = {
        numFC: (data && data['fcPortList']) ? this.getNumCardsForPortList(data['fcPortList']) : 3,
        numEthernet: (data && data['ethernetList']) ? this.getNumCardsForPortList(data['ethernetList']) : 3,
        numManagement: (data && data.model === 'OXYGEN') ? 0 : 2,
        numDca: (data && data['dcaList']) ? this.getNumCardsForPortList(data['dcaList']) : 0
    };

    if (!arch || !arch.fibreCardList || !arch.managementCard || !arch.ethernetCardList || !arch.mainComponent
        || data.status === "missing" || data.status === "offline" || data.status === "not_present"
        || !arch.numComponents || JSON.stringify(arch.numComponents) !== JSON.stringify(numComponents)) {
        arch = {};
        arch.numComponents = numComponents;
        this.initArch(arch, numComponents);

        if (numComponents.numManagement > 0) {
            // Drawing controller and card backgrounds
            this.drawControllerBG(x, y, w, h, data, arch);
            this.drawStatusBar(graph, x, y, h, data, arch, 3);
        }

        for (var i in slotMap.getSlotMap()) {
            if (i !== 'psu0' && i !== 'psu1') {
                var position = slotMap.get(i);
                this.drawEmptyCard(position.x, position.y, position.width, position.height);
            }
        }

        // We want to return at this point if we don't have data
        if (!data) {
            return;
        }

        arch.identifyComponent = this.drawIdentify(Math.ceil(x + w - (graph.cardWidth * 4 + graph.statusWidth + 4)),
            Math.ceil(y + h - (graph.statusWidth + 4)), graph.identityWidth, graph.identityWidth, data.identifyEnabled === true);
    } else {
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        if (!data) {
            return;
        }

        this.updateLayerMouseover(arch, x, y, w, h, root, function () {
            return arrayData.getControllerDiv(location, data);
        }, arch.mainComponent, "#363636", "#ffffff");
    }

    this.drawSlotCards(data['fcPortList'], arch.fibreCardList, this.drawFibreCard.bind(this));
    this.drawSlotCards(data['ethernetList'], arch.ethernetCardList, this.drawEthernetCard.bind(this));
    this.drawSlotCards(data['dcaList'], arch.dcaCardList, this.drawDcaCard.bind(this));
};

pure.web.jsp.OxygenController.prototype.drawBackDuringFirmwareUpdate = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;
    var progressBarId = this.progressBarId;

    if (!arch.progress) {
        // Drawing controller background
        this.drawControllerBG(x, y, w, h, data, arch);
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        // Clearing possible controller div
        $("#" + progressBarId).remove();

        var arrayGraphDiv = $("#arrayGraph");
        var controllerDiv = $("<div id='" + progressBarId + "' style='position: absolute; z-index: 998;'></div>");
        controllerDiv.css("width", w).css("height", h);
        controllerDiv.css("top", y + 75).css("left", x + 90);

        controllerDiv.mouseover(arch.mouseoverHandler);
        controllerDiv.mouseout(arch.mouseoutHandler);

        if (!data) {
            return;
        }

        /**
         * This most likely never worked, and only recently was discovered to be throwing errors ('progress.progressbar is not a function')
         * Skipping the progressbar drawing for now to prevent this (admittedly rare) error case
         * see: CLOUD-67158
         */
        // arch.progress = this.drawProgressBar(controllerDiv, w, h, data.progress);

        arrayGraphDiv.append(controllerDiv);

        // Changing progress bar to green
        $(".ui-progressbar-value").css('background-image', 'url("")').css("background-color", "#A3E249");
        $(".ui-progressbar").css('background-image', 'url("")').css("background-color", "#f5f5f5");
    } else {
        if (!data) {
            return;
        }
        this.updateStatus(arch.statusComponent, pure.theme.component[data.overallStatus]);
        this.updateLayerMouseover(arch, x, y, w, h, root, function () {
            return arrayData.getControllerDiv(location, data);
        }, arch.mainComponent, "#363636", "#ffffff");

        if (pure.defined(data.progress) && (arch.progress.progressbar("value") !== data.progress)) {
            arch.progress.progressbar("value", data.progress);
        }
    }
};

pure.web.jsp.OxygenController.prototype.drawControllerBG = function (x, y, w, h, data, arch) {
    var arrayData = this.parent.arrayData;
    var location = this.location;
    var root = this.root;

    arch.mainComponent = this.drawComponent(x, y, w, h, "#000000", "#000000");
    arch.layerComponent = this.drawLayer(x, y, w, h, root, function () {
        return arrayData.getControllerDiv(location, data);
    }, arch.mainComponent, "#363636", "#ffffff", arch);

};

pure.web.jsp.OxygenController.prototype.drawProgressBar = function (div, w, h, value) {
    var progressDiv = $("<div style='margin: 0 10px 0 20px'></div>").css("margin-top", (h - 23)/2).appendTo(div);
    var progress = $("<div id='progress'></div>").appendTo(progressDiv);
    var progressLabel = $("<div style='position: absolute; left: 18%; margin-top: 4px; font-weight: bold; '></div>").appendTo(progress);

    var labelFunc = function () {
        var valuePresent = pure.defined(value) ? progress.progressbar( "value" ) + "% complete" : "";
        progressLabel.text( "Updating firmware... " + valuePresent );
    };

    progress.progressbar({
        value: value,
        create: labelFunc,
        change: labelFunc
    });

    return progress;
};

pure.web.jsp.OxygenController.prototype.drawSlotCards = function (portListData, arch, cardDrawingFunc) {
    if (!portListData) { return; }

    // Mapping a slot to all of the ports with that slot
    var slotPortMap = {};
    for (var i = 0; i < portListData.length; i++) {
        var slot = portListData[i].slot;
        if (!slotPortMap[slot]) {
            slotPortMap[slot] = [];
        }
        slotPortMap[slot].push(portListData[i]);
    }

    // Loop through slots to create cards
    var numCards = 0;
    for (slot in slotPortMap) {
        var position = this.slotMap.get(slot);
        if (position && position.width && position.height) {
            cardDrawingFunc(position.x, position.y, position.width, position.height,
                slotPortMap[slot], arch[numCards]);
            numCards += 1;
        }
    }
};

pure.web.jsp.OxygenController.prototype.drawEthernetCard = function (x, y, w, h, data, arch) {
    var cardColor = '#a67c52';
    for (var i = 0; i < data.length; i++) {
        if (data[i]['services'] && data[i]['services'].length > 0 && data[i]['services'].indexOf('directflash') !== -1) {
            cardColor = '#2d95dd';
            break;
        }
    }

    if (!arch.ethernetPortList) {
        arch.ethernetPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', cardColor, (w > h) ? 'left' : undefined);
    }

    this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[0]);
    this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ethernetPortList[1]);
    this.drawEthernetPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.ethernetPortList[2]);
    this.drawEthernetPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.ethernetPortList[3]);
};

pure.web.jsp.OxygenController.prototype.drawFibreCard = function (x, y, w, h, data, arch) {
    if (!arch.fibrePortList) {
        arch.fibrePortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, "#363636", "#f8941d", (w > h) ? "left" : undefined);
    }

    this.drawFibrePort(x + 12, y + 1, h - 3, h - 3, data[0], arch.fibrePortList[0]);
    this.drawFibrePort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.fibrePortList[1]);
    this.drawFibrePort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.fibrePortList[2]);
    this.drawFibrePort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.fibrePortList[3]);

};

pure.web.jsp.OxygenController.prototype.setSlot = function (portIndexStart, portIndexEnd, portListData, slot) {
    if (!portIndexEnd || portIndexEnd === -1) {
        portIndexEnd = portListData.length;
    }

    if (!portListData || portListData.length < (portIndexEnd - 1)) {
        return;
    }
    for (var i = portIndexStart; i < portIndexEnd; i++) {
        var port = portListData[i];
        port['slot'] = (port['slot'] < 0) ? slot : port['slot'];
    }
};

/**
 * This file contains drawing functions for the controllers on the back
 * of the NVMEf shelf.
 */

pure.web.jsp.XenonShelfController = function (box, graph, parent, archive, location, name) {
    this.box = box;
    this.graph = graph;
    this.parent = parent;
    this.arch = archive;
    this.location = location;
    this.progressBarId = 'progress-bar-' + name;

    this.cardWidth = graph.cardWidth;
    this.cardHeight = (graph.barHeight * 4) / 5;
    this.root =
        box && (box.index || box.index === 0)
            ? 'DirectFlash Shelf Controller ' + box.index
            : 'DirectFlash Shelf Controller';
};

// Inheriting from the base controller class
pure.web.jsp.XenonShelfController.prototype = new pure.web.jsp.FA500Controller();
pure.web.jsp.XenonShelfController.prototype.constructor = pure.web.jsp.XenonShelfController;

pure.web.jsp.XenonShelfController.prototype.drawBack = function (x, y, w, h, arch) {
    var data = this.box;
    var graph = this.graph;
    var cardWidth = graph.barHeight - 8;
    var cardHeight = graph.cardWidth / 1.5;
    var cardSpacing = graph.cardSpacing;
    var arrayData = this.parent.arrayData;
    var root = this.root;
    var location = this.location;

    var slotMap = (this.slotMap = new pure.web.jsp.SlotMap({
        model: data.model,
        x: x,
        y: y,
        boxWidth: w,
        boxHeight: h,
        barHeight: graph.barHeight / 1.25,
        cardHeight: cardHeight,
        cardWidth: cardWidth,
        statusWidth: graph.statusWidth,
        cardSpacing: cardSpacing,
    }));

    var numComponents = {
        numEthernet:
            data && data['ethernetList'] ? this.getNumCardsForPortList(data['ethernetList']) : 3,
        numManagement: 1,
    };

    if (data) {
        // These ports are at fixed locations, but Neon only has 2 and Xenon has 4
        if (slotMap.model === 'DFSC2') {
            this.setSlot(0, 2, data['ethernetList'], 'ethernet');
        } else {
            this.setSlot(0, 4, data['ethernetList'], 'ethernet');
        }
    }

    if (
        !arch.ethernetCardList ||
        !arch.mainComponent ||
        data.status === 'missing' ||
        data.status === 'offline' ||
        data.status === 'not_present' ||
        !arch.numComponents ||
        JSON.stringify(arch.numComponents) !== JSON.stringify(numComponents)
    ) {
        arch = {};
        arch.numComponents = numComponents;
        this.initArch(arch, numComponents);

        // Drawing controller and card backgrounds
        this.drawControllerBG(x, y, w, h, data, arch);
        this.drawStatusBar(graph, x, y, h, data, arch, 3);

        var position = slotMap.get('ethernet');
        this.drawEmptyCard(position.x, position.y, position.width, position.height);

        // We want to return at this point if we don't have data
        if (!data) {
            return;
        }
        arch.identifyComponent = this.drawIdentify(
            Math.ceil(x + w - (graph.statusWidth + 4)),
            Math.ceil(y + h - (graph.statusWidth + 4)),
            graph.identityWidth,
            graph.identityWidth,
            data.identifyEnabled === true
        );
    } else {
        this.drawStatusBar(graph, x, y, h, data, arch, 3);
        if (!data) {
            return;
        }

        this.updateLayerMouseover(
            arch,
            x,
            y,
            w,
            h,
            root,
            function () {
                return arrayData.getControllerDiv(location, data);
            },
            arch.mainComponent,
            '#363636',
            '#ffffff'
        );
    }

    this.drawSlotCards(
        data['ethernetList'],
        arch.ethernetCardList,
        this.drawEthernetCard.bind(this)
    );
};

// There is only one EthernetCard in xenon shelf controller and it should be show as blue.
// TODO: should use nvme backend service result to tell whether we should show blue or brown to an Ethernet port once PURE-93725 is done
pure.web.jsp.XenonShelfController.prototype.drawEthernetCard = function (x, y, w, h, data, arch) {
    if (!arch.ethernetPortList) {
        arch.ethernetPortList = this.initEmptyArch(data.length);
        this.drawCard(x, y, w, h, '#363636', '#2d95dd', w > h ? 'left' : undefined);
    }

    this.drawEthernetPort(x + 12, y + 1, h - 3, h - 3, data[0], arch.ethernetPortList[0]);
    this.drawEthernetPort(x + 12 + h, y + 1, h - 3, h - 3, data[1], arch.ethernetPortList[1]);
    this.drawEthernetPort(x + 12 + 2 * h, y + 1, h - 3, h - 3, data[2], arch.ethernetPortList[2]);
    this.drawEthernetPort(x + 12 + 3 * h, y + 1, h - 3, h - 3, data[3], arch.ethernetPortList[3]);
};

/**
 * This file contains shelf-specific drawing functions.
 */

pure.web.jsp.DashboardShelf = function (box, graph, parent, archive)
{
    if(!box)
    {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Shelf " + box.index : "Shelf";
};

// Inheriting from the base component class
pure.web.jsp.DashboardShelf.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardShelf.prototype.constructor = pure.web.jsp.DashboardShelf;

pure.web.jsp.DashboardShelf.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight;
    var statusWidth = graph.statusWidth;
    var statusPadding = graph.statusPadding;

    if (!arch.mainComponent)
    {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.driveBayList)
    {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;

    var drivesStartX = x + 2 * statusPadding + statusWidth + 1;

    var driveVerticalPadding = (boxHeight - barHeight) / 2 + 0.5;
    for (var i = 0; i < bayList.length; i++)
    {
        if (!arch.driveBayList[i])
        {
            arch.driveBayList.push({});
        }

        var bay = bayList[i];
        var space = ((i < bayList.length / 2) ? 0 : (barWidth + barSpace));
        this.drawDrive(drivesStartX + i * (barWidth + barSpace) + space, y + driveVerticalPadding, barWidth, barHeight, bay, arch.driveBayList[i]);
    }
};

pure.web.jsp.DashboardShelf.prototype.drawDrive = function (x, y, w, h, bay, arch)
{
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var fillColor = "#707070";

    if (bay.pureDrive && bay.pureDrive.type == "NVRAM") {
        fillColor = "#464646";
    }

    if (bay.overallStatus != "ok" && bay.overallStatus != "healthy" && bay.overallStatus != "failing"
        && bay.overallStatus != "unrecognized" && bay.overallStatus != "identifying" && bay.overallStatus != "evacuated"
        && bay.overallStatus != "evacuating" )
    {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    if (!arch.mainComponent || !arch.statusComponent)
    {
        arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, fillColor);
        arch.statusComponent = this.drawStatus(x, y, barWidth, barWidth, pure.theme.component[bay.overallStatus]);
    }
    else
    {
        this.updateComponent(arch.mainComponent, fillColor, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
    }
};

/**
 * This file contains all the drawing functions for the
 * Platinum chassis.
 */

pure.web.jsp.DashboardChassis = function (box, graph, parent, archive)
{
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Chassis " + box.index : "Chassis";
    this.type = box.type;
};

// Inheriting from the base component class
pure.web.jsp.DashboardChassis.prototype = new pure.web.jsp.DashboardShelf();
pure.web.jsp.DashboardChassis.prototype.constructor = pure.web.jsp.DashboardChassis;

pure.web.jsp.DashboardChassis.prototype.getBoxHeight = function (graph) {
    if (this.isOxygenShelf() || this.isTachyonShelf()) {
        return graph.boxHeight2U
    }
    if (this.isOxygen() || this.isTachyon()) {
        return graph.boxHeight5U
    }
    return graph.boxHeight3U;
}

pure.web.jsp.DashboardChassis.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var bezelHeight = graph.bezelHeight;
    var bezelWidth = graph.bezelWidth;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = this.getBoxHeight(graph);
    var componentPadding = graph.componentPadding;
    var controllerHeight = graph.controllerHeight;
    var controllerSpace = graph.controllerSpace;
    var nvramTopPadding = graph.nvramTopPadding;
    var nvramHeight = graph.nvramHeight;
    var nvramWidth = graph.nvramWidth;
    var statusWidth = graph.statusWidth;
    var statusPadding = graph.statusPadding;

    if (!arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.nvramBayList) {
        arch.nvramBayList = [];
    }

    var drivesStartX = x + 2 * statusPadding + statusWidth;
    if (!arch.driveBayList) {
        arch.driveBayList = this.initEmptyArch(box.driveBayList.length);
    }

    var bayList = box.driveBayList;

    if (this.isOxygen() || this.isTachyon()) {
        var bayDriveY = y;
        for (var i = 0; i < bayList.length; i++) {
            var bay = bayList[i];

            // Grouping drives 5 in a group, 20 in a line
            var location = (i % 20) + Math.floor((i % 20) / 5) * 1.5;
            var offset = location * (barWidth + barSpace);
            if (i === 20) {
                bayDriveY += (barHeight + 10);
            }
            drivesEndX = drivesStartX + offset + 1 + barWidth;
            this.drawDrive(drivesStartX + offset + 1, bayDriveY + 6, barWidth, barHeight, bay, arch.driveBayList[i]);
        }
    } else {
        var nvramBayList = box.nvramBayList;
        var nvramXOffsets = [];
        for (var i = 0; i < nvramBayList.length; i++) {
            if (!arch.nvramBayList[i]) {
                arch.nvramBayList.push({});
            }

            nvramXOffsets[i] = 4 * i * (barWidth * 2);
            this.drawNvramDrive(drivesStartX + nvramXOffsets[i], y + nvramTopPadding, nvramWidth, nvramHeight, nvramBayList[i], arch.nvramBayList[i]);
        }

        var drivesEndX = 0;
        for (var i = 0; i < bayList.length; i++) {
            var bay = bayList[i];

            var offset = nvramXOffsets[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
            if (i >= 16) {
                offset = nvramXOffsets[3] + (i % 4 + 4) * (barWidth + barSpace); // Last 8 are together in a block
            }
            drivesEndX = drivesStartX + offset + 1 + barWidth;
            this.drawDrive(drivesStartX + offset + 1, y + nvramHeight + nvramTopPadding + 4, barWidth, barHeight, bay, arch.driveBayList[i]);
        }
    }

    if (this.isOxygenShelf() || this.isTachyonShelf()) return;

    if (!arch.ct0 || !arch.ct1) {
        arch.ct0 = {};
        arch.ct1 = {};
    }

    var controllerStartY = y + boxHeight - 2 * controllerSpace - 2 * controllerHeight - componentPadding;
    var controllerWidth = drivesEndX - drivesStartX;

    if (!this.isXSeries() && !this.isHelium() && !this.isOxygen() && !this.isTachyon()) {
        this.drawController(drivesStartX, controllerStartY, bezelWidth, controllerWidth, box.controllerList[0], arch.ct0, false);
        this.drawController(drivesStartX, controllerStartY + controllerHeight + controllerSpace, bezelWidth, controllerWidth, box.controllerList[1], arch.ct1, true);
    }

    if (!arch.logo) {
        var bezelImg = pure.theme.images.bezel;
        var bezelOffset = (3 * componentPadding + 2 * controllerHeight + controllerSpace - bezelHeight) / 2;
        if (this.isXSeries()) {
            bezelImg = pure.theme.images.bezel_x_series;
            bezelWidth = controllerWidth - 0.5;
            bezelHeight = 41;
        } else if (this.isHelium()) {
            bezelImg = pure.theme.images.bezel_helium;
            bezelWidth = controllerWidth - 0.5;
            bezelHeight = 41;
        } else if (this.isOxygen() || this.isTachyon()) {
            bezelImg = pure.theme.images.bezel_oxygen;
            bezelWidth = controllerWidth - 0.5;
            bezelHeight = 80;
            bezelOffset = (3 * componentPadding + 2 * controllerHeight + controllerSpace - bezelHeight / 2) / 2;
        } else if (this.isRC()) {
            bezelImg = pure.theme.images.bezel_nickel;
            bezelWidth = controllerWidth + 21;
            bezelHeight = 55
            bezelOffset = (componentPadding - bezelHeight / 2 + 19);
        }

        arch.logo = this.paper.image(
                pure.Context.contentPath(bezelImg),
                drivesStartX + (controllerWidth - bezelWidth ) / 2,
                y + boxHeight - bezelHeight - bezelOffset,
                bezelWidth + 0.5, bezelHeight + 0.5 );
    }

    if (this.isXSeries() || this.isHelium() || this.isOxygen() || this.isTachyon()) {
        this.drawController(drivesStartX, controllerStartY, bezelWidth, controllerWidth, box.controllerList[0], arch.ct0, false);
        this.drawController(drivesStartX, controllerStartY + controllerHeight + controllerSpace, bezelWidth, controllerWidth, box.controllerList[1], arch.ct1, true);
    }
};

pure.web.jsp.DashboardChassis.prototype.drawController = function (x, y, bezelImageWidth, controllerWidth, data, arch, flip)
{
    var graph = this.graph;
    var controllerHeight = graph.controllerHeight;

    this.drawRectangleOutlineComponent(x, y, controllerWidth, controllerHeight, data, arch);

    if (!arch.bezelBG && !this.isXSeries() && !this.isHelium() && !this.isOxygen() && !this.isTachyon()) //no bezelBG needed for tungsten/mercury/helium/oxygen/tachyon
    {
        var bezelTopRectangleWidth = bezelImageWidth + 10;
        var bezelBottomRectangleWidth = bezelImageWidth - 15;
        if (flip)
        {
            this.drawComponent(x + (controllerWidth - bezelTopRectangleWidth) / 2, y + controllerHeight - 4, bezelTopRectangleWidth, 8, "#000000");
            this.drawComponent(x + (controllerWidth - bezelBottomRectangleWidth) / 2, y - 2, bezelBottomRectangleWidth, 8, "#000000");
        }
        else
        {
            this.drawComponent(x + (controllerWidth - bezelTopRectangleWidth) / 2, y - 2, bezelTopRectangleWidth, 8, "#000000");
            this.drawComponent(x + (controllerWidth - bezelBottomRectangleWidth) / 2, y + controllerHeight - 4, bezelBottomRectangleWidth, 8, "#000000");
        }
        arch.bezelBG = true;
    }
};

pure.web.jsp.DashboardChassis.prototype.drawNvramDrive = function (x, y, w, h, data, arch)
{
    var graph = this.graph;
    var statusWidth = graph.statusWidth;
    var fillColor = "#707070";

    var bay = data;
    if (bay.overallStatus != "ok" && bay.overallStatus != "healthy" && bay.overallStatus != "failing"
        && bay.overallStatus != "unrecognized" && bay.overallStatus != "identifying")
    {
        fillColor = pure.theme.component[bay.overallStatus];
    }
    if (!arch.mainComponentFG || !arch.statusComponent)
    {
        arch.mainComponentFG = this.drawComponent(x, y, w, h, fillColor);
        var statusOffset = (h - statusWidth) / 2;
        arch.statusComponent = this.drawStatus(x + w - statusWidth - statusOffset, y + statusOffset, statusWidth, statusWidth, pure.theme.component[data.overallStatus]);
    }
    else
    {
        this.updateComponent(arch.mainComponentFG, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[data.overallStatus]);
    }
};

pure.web.jsp.DashboardChassis.prototype.drawRectangleOutlineComponent = function (x, y, w, h, data, arch)
{
    var graph = this.graph;
    var statusWidth = graph.statusWidth;
    var fillColor = "#000000";
    var strokeColor = "#707070";

    var status = data ? data.overallStatus : "not_installed";

    if (!arch.statusComponent)
    {
        if (!this.isXSeries() && !this.isHelium() && !this.isOxygen() && !this.isTachyon()) { //no rectangle outline for tungsten/mercury/helium/oxygen/tachyon
            this.drawRectangleOutline(x, y, w, h, fillColor, strokeColor, arch);
        }

        var statusOffset = (h - statusWidth) / 2;
        arch.statusComponent = this.drawStatus(x + w - statusWidth - statusOffset, y + statusOffset, statusWidth, statusWidth, pure.theme.component[status]);
    }
    else
    {
        this.updateStatus(arch.statusComponent, pure.theme.component[status]);
    }
};

pure.web.jsp.DashboardChassis.prototype.drawRectangleOutline = function (x, y, w, h, fillColor, strokeColor, arch)
{
    if (!arch.mainComponentFG || !arch.mainComponentBG)
    {
        arch.mainComponentBG = this.drawComponent(x, y, w, h, strokeColor);
        arch.mainComponentFG = this.drawComponent(x+2, y+2, w-4, h-4, fillColor);
    }
    else
    {
        this.updateComponent(arch.mainComponentBG, strokeColor);
        this.updateComponent(arch.mainComponentFG, fillColor);
    }
};

pure.web.jsp.DashboardChassis.prototype.isXSeries = function () {
    var box = this.box;
    if (box && box.model) {
        return box.model.toLowerCase().startsWith('fa-x') && !box.model.toLowerCase().startsWith('fa-xl');
    }
    return false;
};

pure.web.jsp.DashboardChassis.prototype.isRC = function () {
    var box = this.box;
    if (box && box.model) {
        return box.model.toLowerCase().startsWith('fa-rc');
    }
    return false;
};

pure.web.jsp.DashboardChassis.prototype.isHelium = function () {
    var box = this.box;
    if (box && box.model) {
        return box.model.toLowerCase().startsWith('fa-c');
    }
    return false;
};

pure.web.jsp.DashboardChassis.prototype.isOxygen = function () {
    var box = this.box;
    if (this.isOxygenShelf()) return true;
    if (box && box.model) {
        return box.model.toLowerCase().startsWith('fa-xl');
    }
    return false;
};

pure.web.jsp.DashboardChassis.prototype.isOxygenShelf = function () {
    var box = this.box;
    return box && box.type === 'storage_shelf' && box.model === 'OXYGEN';
}

pure.web.jsp.DashboardChassis.prototype.isTachyon = function () {
    var box = this.box;
    if (this.isTachyonShelf()) return true;
    if (box && box.model) {
        return box.model.toLowerCase().startsWith('fa-st');
    }
    return false;
};

pure.web.jsp.DashboardChassis.prototype.isTachyonShelf = function () {
    var box = this.box;
    return box && box.type === 'storage_shelf' && box.model === 'TACHYON';
}

/**
 * This file contains shelf-specific drawing functions.
 */

pure.web.jsp.DashboardController = function (box, graph, parent, archive)
{
    if(!box)
    {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
};

// Inheriting from the base component class
pure.web.jsp.DashboardController.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardController.prototype.constructor = pure.web.jsp.DashboardController;

pure.web.jsp.DashboardController.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight;

    if (!arch.mainComponent)
    {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }
    if (!arch.logo)
    {
        var imageDim = .7 * boxHeight;
        arch.logo = this.paper.image(
            pure.Context.contentPath(pure.theme.images.array_controller),
            x + (boxWidth - imageDim) / 2,
            y + (boxHeight - imageDim) / 2,
            imageDim,
            imageDim );
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);
};
/**
 * This file contains FA-405 controller drawing functions.
 */

pure.web.jsp.FA405DashboardController = function (box, graph, parent, archive)
{
    if(!box)
    {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
};

// Inheriting from the base component class
pure.web.jsp.FA405DashboardController.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.FA405DashboardController.prototype.constructor = pure.web.jsp.FA405DashboardController;

pure.web.jsp.FA405DashboardController.prototype.getBoxHeight = function ( graph )
{
    return graph.boxHeight1U;
};

pure.web.jsp.FA405DashboardController.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight1U;

    if (!arch.mainComponent)
    {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }
    if (!arch.logo)
    {
        var imageDim = .7 * boxHeight;
        arch.logo = this.paper.image(
            pure.Context.contentPath(pure.theme.images.array_controller),
            x + (boxWidth - imageDim) / 2,
            y + (boxHeight - imageDim) / 2,
            imageDim, imageDim );
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);
};
/**
 * This file is for drawing PAWS Controller in dashboard
 */

pure.web.jsp.DashboardControllerCBS = function (box, graph, parent, archive) {
    if(!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
};

// Inheriting from the base component class
pure.web.jsp.DashboardControllerCBS.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardControllerCBS.prototype.constructor = pure.web.jsp.DashboardControllerCBS;

pure.web.jsp.DashboardControllerCBS.prototype.draw = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var boxWidth = graph.boxWidth / 2 - graph.barSpace;
    var boxHeight = graph.boxHeight;

    if (!arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#ffffff', '#c5c5c5', 1.5, 4);
        this.drawCloudIcon(x, y, boxWidth, boxHeight);
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch, false, 2);
};


pure.web.jsp.DashboardControllerCBS.prototype.drawCloudIcon = function(x, y, boxWidth, boxHeight) {
    var iconHeight = boxHeight * 0.45;
    var iconWidth = iconHeight * 2.5;
    var ec2X = x + (boxWidth - iconWidth) / 2;
    var ec2Y = y + (boxHeight - iconHeight) / 2;
    this.paper.image(pure.Context.contentPath(pure.theme.images.cbs_logo), ec2X, ec2Y, iconWidth, iconHeight);
};

/**
 * This file contains NVMEf shelf drawing functions.
 */

pure.web.jsp.DashboardXenonShelf = function (box, graph, parent, archive) {
    if (!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "DirectFlash Shelf " + box.index : "Shelf";
};

pure.web.jsp.DashboardXenonShelf.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardXenonShelf.prototype.constructor = pure.web.jsp.DashboardXenonShelf;

pure.web.jsp.DashboardXenonShelf.prototype.getBoxHeight = function ( graph ) {
    return graph.xenonShelfHeight;
};

pure.web.jsp.DashboardXenonShelf.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.xenonShelfHeight;
    var statusWidth = graph.statusWidth;
    var statusPadding = graph.statusPadding;

    if (!arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.driveBayList) {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;
    var drivesStartX = x + 2 * statusPadding + statusWidth + 1;
    var driveXOffset = [];

    for (var i = 0; i < 20; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        if (i % 4 === 0 && i < 16) {
            driveXOffset[i/4] = i * (barWidth * 2);
        }

        var offset = driveXOffset[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
        if (i >= 16) {
            offset = driveXOffset[3] + (i % 4 + 4) * (barWidth + barSpace); // Last 8 are together in a block
        }

        if (bayList[i]) {
            var bay = bayList[i];
            this.drawDrive(drivesStartX + offset, y + boxHeight - barHeight - statusPadding, barWidth, barHeight, bay, arch.driveBayList[i]);
        }
        else {
            this.drawComponent(drivesStartX + offset, y + boxHeight - barHeight - statusPadding, barWidth, barHeight, "#363636", "#363636");
        }
    }

    for (var i = 20; i < 28; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        var offsetX = driveXOffset[Math.floor((i - 20)/2)];
        var offsetY = Math.floor(i%2) * (barWidth + barSpace);

        if (bayList[i]) {
            var bay = bayList[i];
            this.drawDrive(drivesStartX + offsetX, y + statusPadding + offsetY, barWidth, barHeight, bay, arch.driveBayList[i], true);
        }
        else {
            this.drawComponent(drivesStartX + offsetX, y + statusPadding + offsetY, barHeight, barWidth, "#363636", "#363636");
        }
    }
};

pure.web.jsp.DashboardXenonShelf.prototype.drawDrive = function (x, y, w, h, bay, arch, isHorizontal) {
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var fillColor = '#707070';

    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'identifying' && bay.overallStatus !== 'evacuated'
        && bay.overallStatus !== 'evacuating') {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    if (!arch.mainComponent || !arch.statusComponent) {
        if (isHorizontal) {
            arch.mainComponent = this.drawComponent(x, y, h, w, fillColor, fillColor);
            arch.statusComponent = this.drawStatus(x + h - barWidth, y, barWidth, barWidth, pure.theme.component[bay.overallStatus]);
        } else {
            arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, fillColor);
            arch.statusComponent = this.drawStatus(x, y, barWidth, barWidth, pure.theme.component[bay.overallStatus]);
        }
    } else {
        this.updateComponent(arch.mainComponent, fillColor, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
    }
};

/**
 * This file contains PAWS shelf in dashboard drawing functions.
 */

pure.web.jsp.DashboardCBSShelf = function (box, graph, parent, archive) {
    if (!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = 'Drives';
};

pure.web.jsp.DashboardCBSShelf.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardCBSShelf.prototype.constructor = pure.web.jsp.DashboardCBSShelf;

pure.web.jsp.DashboardCBSShelf.prototype.draw = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var cloudInstanceSpace = graph.cloudInstanceSpace;
    var boxHeight = graph.cloudInstanceHeight;
    var boxWidth = graph.cloudInstanceWidth;

    if (!arch.driveBayList) {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;
    for (var i = 0; i < bayList.length; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        var bay = bayList[i];
        if (box.driveType === 'nvram') {
            this.drawDrive(x + i * (graph.boxWidth / 2), y,
                           graph.boxWidth/2 - graph.barSpace, boxHeight * 0.75, bay, arch.driveBayList[i]);
        } else {
            this.drawDrive(1 + i * (boxWidth + cloudInstanceSpace), y, boxWidth, boxHeight,
                           bay, arch.driveBayList[i]);
        }
    }
};

pure.web.jsp.DashboardCBSShelf.prototype.drawDrive = function (x, y, w, h, bay, arch) {
    var graph = this.graph;
    var statusBarWidth = graph.statusBarWidth;
    var barSpace = graph.barSpace;
    var fillColor = '#ffffff';

    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'evacuated'
        && bay.overallStatus !== 'evacuating' && bay.overStatus !== 'enabled') {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    if (!arch.mainComponent || !arch.statusComponent) {
        arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, '#c5c5c5', 1.5, 4);
        arch.statusComponent = this.drawStatus(x + w - 5 * barSpace, y + 2 * barSpace, statusBarWidth + 1, statusBarWidth,
            pure.theme.component[bay.overallStatus]);
    } else {
        this.updateComponent(arch.mainComponent, fillColor, '#c5c5c5');
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
    }
};

pure.web.jsp.DashboardCBSShelf.prototype.getBoxHeight = function(graph) {
    if (this.box.driveType === 'nvram') {
        return graph.cloudInstanceHeight * 0.75;
    } else {
        return graph.cloudInstanceHeight;
    }
};

/**
 * This file contains controller-specific drawing functions.
 */

pure.web.jsp.DefaultShelf = function (box, graph, parent, archive) {
    this.dataUtil = new pure.web.jsp.array.DataUtil();
    if(!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? 'Shelf ' + box.index : 'Shelf';
};

// Inheriting from the base component class
pure.web.jsp.DefaultShelf.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DefaultShelf.prototype.constructor = pure.web.jsp.DefaultShelf;

pure.web.jsp.DefaultShelf.prototype.draw = function ( y ) {
    this.drawFront(0.5, y);
    this.drawBack(this.graph.boxWidth + 10 + 0.5, y);
};

pure.web.jsp.DefaultShelf.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = barHeight + 20;
    var statusWidth = graph.statusWidth;
    var arrayData = this.parent.arrayData;

    if (!arch.mainComponent || !arch.identifyComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#000000', '#ffffff');
        var statusX = x + boxWidth - 2 - statusWidth, statusY = y + 11;
        arch.identifyComponent = this.drawIdentify(statusX, statusY, barWidth / 2, barWidth / 2, box.identifyEnabled === true);
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, 'Shelf - ' + box.name, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070', arch);
    } else {
        this.updateIdentify(arch.identifyComponent, box.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, 'Shelf - ' + box.name, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070');
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.driveBayList) {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;
    for (var i = 0; i < bayList.length; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        var bay = bayList[i];
        var location = ((i < bayList.length / 2) ? i : (i + 0.5)) + 1.5;
        this.drawDrive(x + location * (barWidth + barSpace), y + 10, barWidth, barHeight, bay, arch.driveBayList[i]);
    }
};

pure.web.jsp.DefaultShelf.prototype.drawBack = function (x, y) {
    var arch = this.arch.back;
    var data = this.box;
    var graph = this.graph;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight;
    var cardWidth = graph.cardWidth;

    if (!arch.psuList || !arch.sasPortList || ! arch.sasCardList) {
        arch.psuList = this.initEmptyArch(2);
        arch.sasCardList = this.initEmptyArch(2);
        arch.sasPortList = this.initEmptyArch(6);
        this.drawRect(x, y, boxWidth, barHeight + 20, "#000000", "#000000");
        this.drawEmptyCard(x + 10 + cardWidth * 2 + 10, y + boxHeight / 2 - cardWidth - 5, boxWidth - cardWidth * 4 - 40, cardWidth);
        this.drawEmptyCard(x + 10 + cardWidth * 2 + 10, y + boxHeight / 2 + 5, boxWidth - cardWidth * 4 - 40, cardWidth);
    }

    this.drawPsu(x + 10, y + 10, cardWidth * 2, barHeight, data['powerSupplyList'][0], arch.psuList[0]);
    this.drawPsu(x + boxWidth - 10 - cardWidth * 2, y + 10, cardWidth * 2, barHeight, data['powerSupplyList'][1], arch.psuList[1]);

    if(data.sasPortList.length >= 3) {
        var sasPorts = [data.sasPortList[0], data.sasPortList[1], data.sasPortList[2]];
        var sasPortArchs = [arch.sasPortList[0], arch.sasPortList[1], arch.sasPortList[2]];
        var position = [ x + 10 + cardWidth * 2 + 10, y + boxHeight / 2 - cardWidth - 5];
        this.drawShelfSasCard(position[0], position[1], boxWidth - cardWidth * 4 - 40, cardWidth, data['sasModuleList'][0], arch.sasCardList[0], sasPorts, sasPortArchs, 1);
    }
    if(data.sasPortList.length >= 6) {
        sasPorts = [data.sasPortList[3], data.sasPortList[4], data.sasPortList[5]];
        sasPortArchs = [arch.sasPortList[3], arch.sasPortList[4], arch.sasPortList[5]];
        position = [ x + 10 + cardWidth * 2 + 10, y + boxHeight / 2 + 5 ];
        this.drawShelfSasCard(position[0], position[1], boxWidth - cardWidth * 4 - 40, cardWidth, data['sasModuleList'][1], arch.sasCardList[1], sasPorts, sasPortArchs, 0);
    }
};


/**
 * Draw a shelf sas card (a bit different style than controller one)
 */
pure.web.jsp.DefaultShelf.prototype.drawShelfSasCard = function (x, y, w, h, data, arch, sasPorts, sasPortArchs, offset) {
    var _this = this;
    var arrayData = this.parent.arrayData;
    if (!arch.statusComponent || !arch.layerComponent) {
        var graph = this.graph;
        var statusWidth = graph.statusWidth;
        var barWidth = graph.barWidth;
        arch.mainComponent = this.drawComponent(x, y, w, h, '#363636', '#363636');
        this.drawRect(x, y, 7, h, '#2d95dd');
        arch.statusComponent = this.drawStatus(x + 10, y + 3, statusWidth-2, statusWidth-2, pure.theme.component[data.status]);
        arch.identifyComponent = this.drawIdentify(x + 10, y + statusWidth + 5, barWidth / 2, barWidth / 2, data.identifyEnabled === true);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'IO Module - ' + data.name, function () {
            return arrayData.getIOModuleDiv(_this.root, data);
        }, arch.mainComponent, '#363636', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateIdentify(arch.identifyComponent, data.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, w, h, 'IO Module - ' + data.name, function () {
            return arrayData.getIOModuleDiv(_this.root, data);
        }, arch.mainComponent, '#363636', '#ffffff');
    }

    var portWidth = (w-20) / 4 - 10;
    var i;
    for (i = 0; i < 3; i++) {
        if (sasPorts[i]) {
            this.drawShelfSasPort(x + (w - 24) * (i+offset) / 4 + 26,
                    y + 3, portWidth, this.graph.cardWidth - 6, sasPorts[i], sasPortArchs[i]);
        }
    }
};

/**
 * Draw a SAS port on the shelf (Wider than controller SAS ports)
 */
pure.web.jsp.DefaultShelf.prototype.drawShelfSasPort = function (x, y, w, h, data, arch) {
    var _this = this;
    var graph = this.graph;
    var statusWidth = graph.statusWidth;
    var arrayData = this.parent.arrayData;

    if (!arch.statusComponent) {
        var space = Math.floor((h - statusWidth) / 2);
        arch.mainComponent = this.drawComponent(x, y, w, h, '#707070', '#707070');
        arch.statusComponent = this.drawStatus(x + space, y + space, statusWidth, statusWidth, pure.theme.component[data.status]);
        arch.layerComponent = this.drawLayer(x, y, w, h, 'SAS Port - ' + data.name, function ()
        {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff', arch);
    } else {
        this.updateStatus(arch.statusComponent, pure.theme.component[data.status]);
        this.updateLayerMouseover(arch, x, y, w, h, 'SAS Port - ' + data.name, function () {
            return arrayData.getPortDiv(_this.root, data);
        }, arch.mainComponent, '#707070', '#ffffff');
    }
};

pure.web.jsp.DefaultShelf.prototype.drawDrive = function (x, y, w, h, bay, arch, onTop) {
    var _this = this;
    var fillColor = '#707070';

    if (bay['pureDrive'] && bay['pureDrive']['type'] === 'NVRAM') {
        fillColor = '#464646';
    }

    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'identifying' && bay.overallStatus !== 'evacuated'
        && bay.overallStatus !== 'evacuating' ) {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    var title = this.getDriveTitle(bay);

    var arrayData = this.parent.arrayData;
    if (!arch.mainComponent || !arch.statusComponent || !arch.identifyComponent) {
        this.drawDriveComponent(x, y, w, h, fillColor, title, arrayData, bay, arch, onTop);
    } else {
        this.updateComponent(arch.mainComponent, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
        this.updateIdentify(arch.identifyComponent, bay.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, w, h, title, function () {
            return arrayData.getDiskPopupDiv(_this.root, bay, null, null, _this.isHelium(), _this.isComet(), _this.isTachyon(), _this.isRC());
        }, arch.mainComponent, '#000000', '#ffffff');
    }
};

pure.web.jsp.DefaultShelf.prototype.getDriveTitle = function(bay) {
    var title;

    if (bay['pureDrive'] && bay['pureDrive']['type'] === 'cache') {
        title = 'DirectMemory Module';
    } else {
        if (this.isTachyon()) {
            title = bay['pureDrive']
                ? bay['pureDrive']['protocol']
                    ? bay['pureDrive']['type'] === 'DNVR'
                        ? 'DirectFlash'
                        : bay['pureDrive']['protocol']
                    : bay['pureDrive']['type']
                        ? bay['pureDrive']['type']
                        : ''
                : '';
        } else {
            title = bay['pureDrive']
                ? bay['pureDrive']['protocol']
                    ? bay['pureDrive']['protocol'] === 'NVMe'
                        ? 'DirectFlash'
                        : bay['pureDrive']['protocol']
                    : bay['pureDrive']['type']
                        ? bay['pureDrive']['type']
                        : ''
                : '';
        }

        if (title === 'DirectFlash') {
            title += ' Module';
            if (this.isHelium() || this.isRC()) {
                title += ' (c)';
            }
            else if (this.isComet()) {
                title += ' (e)';
            }
        } else {
            title += ' Flash Module';
        }
    }
    title += (bay && bay.name) ? ' - ' + bay.name : '';
    return title.trim();
};

pure.web.jsp.DefaultShelf.prototype.isHelium = function(){
    if (this.parent && this.parent.data && this.parent.data.controllers && this.parent.data.controllers.length) {
        if (this.dataUtil.isHelium(this.parent.data.controllers[0])) {
            return true;
        }
    }

    // Pure1 builds the HW boxes differently than on array GUI. This is needed to work around that difference
    // longer term, we need to figure out why and how to account for these diffs in a more programmatic fashion
    // Tracked by: https://jira.purestorage.com/browse/CLOUD-37568
    if (this.parent && this.parent.data && !this.parent.data.controllers && this.parent.data.boxes) {
        return this.parent.data.boxes.some((box) => this.dataUtil.isHelium(box));
    }
    return false;
};

pure.web.jsp.DefaultShelf.prototype.isRC = function () {
    if (this.parent && this.parent.data && this.parent.data.controllers && this.parent.data.controllers.length) {
        if (this.dataUtil.isRC(this.parent.data.controllers[0])) {
            return true;
        }
    }

    // Pure1 builds the HW boxes differently than on array GUI. This is needed to work around that difference
    // longer term, we need to figure out why and how to account for these diffs in a more programmatic fashion
    // Tracked by: https://jira.purestorage.com/browse/CLOUD-37568
    if (this.parent && this.parent.data && !this.parent.data.controllers && this.parent.data.boxes) {
        return this.parent.data.boxes.some(box => this.dataUtil.isRC(box));
    }
    return false;
};

pure.web.jsp.DefaultShelf.prototype.isComet = function(){
    if (this.parent && this.parent.data && this.parent.data.controllers && this.parent.data.controllers.length) {
        if (this.dataUtil.isComet(this.parent.data.controllers[0])) {
            return true;
        }
    }

    // Pure1 builds the HW boxes differently than on array GUI. This is needed to work around that difference
    // longer term, we need to figure out why and how to account for these diffs in a more programmatic fashion
    // Tracked by: https://jira.purestorage.com/browse/CLOUD-37568
    if (this.parent && this.parent.data && !this.parent.data.controllers && this.parent.data.boxes) {
        return this.parent.data.boxes.some((box) => this.dataUtil.isComet(box));
    }
    return false;
};

pure.web.jsp.DefaultShelf.prototype.isTachyon = function(){
    if (this.parent && this.parent.data && this.parent.data.controllers && this.parent.data.controllers.length) {
        if (this.dataUtil.isTachyon(this.parent.data.controllers[0])) {
            return true;
        }
    }

    // Pure1 builds the HW boxes differently than on array GUI. This is needed to work around that difference
    // longer term, we need to figure out why and how to account for these diffs in a more programmatic fashion
    // Tracked by: https://jira.purestorage.com/browse/CLOUD-37568
    if (this.parent && this.parent.data && !this.parent.data.controllers && this.parent.data.boxes) {
        return this.parent.data.boxes.some((box) => this.dataUtil.isTachyon(box));
    }
    return false;
};

pure.web.jsp.DefaultShelf.prototype.drawCacheDriveLayer = function (x, y, w, h) {
    var component = this.paper.rect(x, y, w, h, 0);

    component.attr('stroke', 'none');
    component.node.setAttribute('style', 'mask: url(#mask-stripe); fill: #A9A9A9');

    return component;
};

pure.web.jsp.DefaultShelf.prototype.drawDriveComponent = function (x, y, w, h, fillColor, title, arrayData, bay, arch) {
    var _this = this;
    var graph = this.graph;
    var barWidth = graph.barWidth;

    arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, '#000000');
    if (bay['pureDrive'] && bay['pureDrive']['type'] === 'cache') {
        arch.cacheDriveLayer = this.drawCacheDriveLayer(x + 1, y + 12, w - 2, h - 13);
    }
    var space = (w - (barWidth - 4)) / 2;
    arch.statusComponent = this.drawStatus(x + space, y + space, barWidth - 4, barWidth - 4, pure.theme.component[bay.overallStatus]);
    arch.identifyComponent = this.drawIdentify(x + barWidth / 2 - 3, y + h - barWidth / 2 - 3, Math.floor(barWidth / 2), Math.floor(barWidth / 2), bay.identifyEnabled === true);

    arch.layerComponent = this.drawLayer(x, y, w, h, title, function () {
        return arrayData.getDiskPopupDiv(_this.root, bay, null, null, _this.isHelium(), _this.isComet(), _this.isTachyon(), _this.isRC());
    }, arch.mainComponent, '#000000', '#ffffff', arch);
};

/**
 * This file contains drawing functions for the
 * front of the Platinum chassis, which is basically a
 * shelf.
 **/

pure.web.jsp.FA500Shelf = function (box, graph, parent, archive) {
    if (!box) { return; }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    // Even though it's a shelf, everything says "Chassis"
    this.root = (box.index != null) ? 'Chassis ' + box.index : 'Chassis';
};

// Inheriting from the base shelf class
pure.web.jsp.FA500Shelf.prototype = new pure.web.jsp.DefaultShelf();
pure.web.jsp.FA500Shelf.prototype.constructor = pure.web.jsp.FA500Shelf;

pure.web.jsp.FA500Shelf.prototype.draw = function (y) {
    this.drawFront(0.5, y);
};

pure.web.jsp.FA500Shelf.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight3U;
    var arrayData = this.parent.arrayData;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.identifyComponent || !arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#000000', '#ffffff');
        arch.identifyComponent = this.drawIdentify(x + boxWidth - (graph.statusWidth + 10.5), y + 15.5,
                graph.identityWidth, graph.identityWidth, box.identifyEnabled === true);
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070', arch);
    } else {
        this.updateIdentify(arch.identifyComponent, box.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070');
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.nvramBayList) {
        arch.nvramBayList = [];
    }

    var nvramXOffsets = [];
    var nvramBayList = box.nvramBayList;
    for (var i = 0; i < nvramBayList.length; i++) {
        if (!arch.nvramBayList[i]) {
            arch.nvramBayList.push({});
        }
        var space = (barWidth + barSpace) * (i + 1.5);
        var w = 5.5 * barWidth;
        var h = graph.cardWidth * 1.6 - 5;
        nvramXOffsets[i] = 4 * i * (barWidth + barSpace * 3) + space;
        this.drawNvramDrive(x + nvramXOffsets[i] + 5, y + 15, w, h, nvramBayList[i], arch.nvramBayList[i]);
    }

    if (!arch.driveBayList) {
        arch.driveBayList = this.initEmptyArch(box.driveBayList.length);
    }

    var bayList = box.driveBayList;
    for (var i = 0; i < bayList.length; i++) {
        var bay = bayList[i];

        var offset = nvramXOffsets[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
        if (i >= 16) {
            offset = nvramXOffsets[3] + (i % 4 + 4) * (barWidth + barSpace); // Last 8 are together in a block
        }

        this.drawDrive(x + offset + 5, y + boxHeight - (barHeight + 10), barWidth, barHeight, bay, arch.driveBayList[i]);
    }
};

pure.web.jsp.FA500Shelf.prototype.drawNvramDrive = function (x, y, w, h, bay, arch) {
    var _this = this;
    var graph = this.graph;
    var fillColor = '#707070';

    w = Math.ceil(w);
    h = Math.ceil(h);
    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'identifying') {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    var title = 'NVRAM Flash Module';
    title += (bay && bay.name) ? ' - ' + bay.name : '';

    var arrayData = this.parent.arrayData;
    if (!arch.mainComponent || !arch.statusComponent || !arch.identifyComponent) {
        arch.mainComponent = this.drawComponent(x, y, w, h, fillColor);
        arch.statusComponent = this.drawStatus(x + w - graph.statusWidth - 5, y + 5, graph.statusWidth, graph.statusWidth, pure.theme.component[bay.overallStatus]);
        arch.identifyComponent = this.drawIdentify(x + w - graph.barWidth / 2 - 4, y + h - graph.barWidth / 2 - 4, 6, 6, bay.identifyEnabled === true);

        arch.layerComponent = this.drawLayer(x, y, w, h, title, function () {
            return arrayData.getDiskPopupDiv(_this.root, bay);
        }, arch.mainComponent, '#000000', '#ffffff', arch);
    } else {
        this.updateComponent(arch.mainComponent, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
        this.updateIdentify(arch.identifyComponent, bay.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, w, h, title, function () {
            return arrayData.getDiskPopupDiv(_this.root, bay);
        }, arch.mainComponent, '#000000', '#ffffff');
    }
};

/**
 * This file contains drawing functions for PAWS Shelves for both Virtual and EBS Drives.
 */

pure.web.jsp.CBSShelf = function (box, graph, parent, archive) {
    if (!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = "Drives";
};

// Inheriting from the base component class
pure.web.jsp.CBSShelf.prototype = new pure.web.jsp.DefaultShelf();
pure.web.jsp.CBSShelf.prototype.constructor = pure.web.jsp.CBSShelf;

pure.web.jsp.CBSShelf.prototype.draw = function (y) {
    this.drawFront(1, y);
};

pure.web.jsp.CBSShelf.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var width = box.driveType === 'nvram' ? graph.cloudBoxWidth : graph.cloudInstanceWidth;
    var height = box.driveType === 'nvram' ? graph.cloudInstanceHeight * 0.5 : graph.cloudInstanceHeight;
    var cur_x = x;

    if (!arch.driveBayList) {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;
    for (var i = 0; i < bayList.length; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }
        var bay = bayList[i];
        if (box.driveType === 'nvram') {
            this.drawDrive(cur_x, y, width, height, bay, arch.driveBayList[i]);
            cur_x += graph.cloudBoxWidth + graph.cloudControllerSpace;
        } else {
            this.drawDrive(cur_x, y, width, height, bay, arch.driveBayList[i]);
            if ((i + 1) % 6 == 0) {
                cur_x += width + graph.cloudControllerSpace;
            } else {
                cur_x += width + graph.cloudInstanceSpace;
            }
        }
    }
};

pure.web.jsp.CBSShelf.prototype.drawDrive = function (x, y, w, h, bay, arch, onTop) {
    var _this = this;
    var fillColor = '#ffffff';

    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'evacuated'
        && bay.overallStatus !== 'evacuating' ) {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    var title = this.getDriveTitle(bay);

    var arrayData = this.parent.arrayData;
    if (!arch.mainComponent || !arch.statusComponent) {
        this.drawDriveComponent(x, y, w, h, fillColor, title, arrayData, bay, arch, onTop);
    } else {
        this.updateComponent(arch.mainComponent, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
        this.updateLayerMouseover(arch, x, y, w, h, title, function () {
            return arrayData.getPAWSDriveBayPopupDiv(_this.root, bay);
        }, arch.mainComponent, '#ffffff', '#707070');
    }
};

pure.web.jsp.CBSShelf.prototype.drawDriveComponent = function (x, y, w, h, fillColor, title, arrayData, bay, arch) {
    var _this = this;
    var graph = this.graph;
    var barWidth = graph.barWidth;

    arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, '#c5c5c5', 1.75, 6);
    if (this.box.driveType === "nvram") {
        this.drawName(x, y);
    }
    var space = barWidth / 4;
    var status_x = x;
    var status_y = y;
    if (this.box.driveType === "nvram") {
        status_x = x + w - 4*space;
        status_y = y + space;
    } else {
        status_x = x + (w - graph.statusBarWidth)/2;
        status_y = y + space;
    }
    arch.statusComponent = this.drawStatus(status_x, status_y, graph.statusBarWidth, graph.statusBarWidth,
        pure.theme.component[bay.overallStatus], 0);
    arch.layerComponent = this.drawLayer(x, y, w, h, title, function () {
        return arrayData.getPAWSDriveBayPopupDiv(_this.root, bay);
    }, arch.mainComponent, '#ffffff', '#707070', arch, 6);
};

pure.web.jsp.CBSShelf.prototype.getDriveTitle = function(bay) {
    var title = "";
    var dataUtil = this.parent.dataUtil;
    if (dataUtil.isPAZShelf(this.box)) {
        if (this.box.driveType === "nvram") {
            title = "NVRAM Flash Module";
        } else {
            title = "SSD Disk";
        }
    } else {
        title = "Virtual Disk";
    }

    title += (bay && bay.index || bay.index === 0) ? ' - ' + bay.index : '';
    return title.trim();
};


pure.web.jsp.CBSShelf.prototype.drawName = function(x, y) {
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var space = barWidth / 2;

    /**
     * Note that there are two changes here:
     *  1. reduced font size from 13 to 10 because our graphics are smaller
     *  2. Scale Raphael seems to double the y value of `text` (it puts the value in the y of the text, and the dy of the tspan inside)
     *  looks like this issue: https://github.com/DmitryBaranovskiy/raphael/issues/491
     */

    setTimeout(
        function () {
            this.paper
                .text(x + space * 4.5, y + space + 2, 'NVRAM')
                .attr({ fill: '#8d8d8d', 'font-size': 10, 'font-family': 'Arial, Helvetica, sans-serif' });
        }.bind(this),
    );
};

pure.web.jsp.CBSShelf.prototype.getBoxHeight = function (graph) {
    if (this.box.driveType === 'nvram') {
        return graph.cloudInstanceHeight * 0.5;
    } else {
        return graph.cloudInstanceHeight;
    }
};

/**
 * This file contains drawing functions for the
 * front of the Oxygen chassis, which is basically a
 * shelf.
 **/

pure.web.jsp.OxygenShelf = function (box, graph, parent, archive) {
    if (!box) { return; }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    // Even though it's a shelf, everything says "Chassis"
    this.root = (box.index != null) ? 'Chassis ' + box.index : 'Chassis';
};

// Inheriting from the base shelf class
pure.web.jsp.OxygenShelf.prototype = new pure.web.jsp.DefaultShelf();
pure.web.jsp.OxygenShelf.prototype.constructor = pure.web.jsp.OxygenShelf;

pure.web.jsp.OxygenShelf.prototype.draw = function (y) {
    this.drawFront(0.5, y);
};

pure.web.jsp.OxygenShelf.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight5U;
    var arrayData = this.parent.arrayData;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.identifyComponent || !arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#000000', '#ffffff');
        arch.identifyComponent = this.drawIdentify(x + boxWidth - (graph.statusWidth + 3.5), y + 5.5,
            graph.identityWidth, graph.identityWidth, box.identifyEnabled === true);
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070', arch);
    } else {
        this.updateIdentify(arch.identifyComponent, box.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070');
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.driveBayList) {
        arch.driveBayList = this.initEmptyArch(box.driveBayList.length);
    }

    var bayList = box.driveBayList;
    for (var i = 0; i < bayList.length; i++) {
        var bay = bayList[i];

        // Grouping drives 5 in a group, 20 in a line
        var location = (i % 20) + Math.floor((i % 20) / 5) * 1.5 + 1.5;
        var offset = location * (barWidth + barSpace);
        if (i === 20) {
            y += (barHeight + 10);
        }
        this.drawDrive(x + offset + 5, y + 15, barWidth, barHeight, bay, arch.driveBayList[i]);
    }
};

/**
 * This file contains drawing functions for the
 * NVMEf shelf.
 **/

pure.web.jsp.XenonShelf = function (box, graph, parent, archive) {
    this.dataUtil = new pure.web.jsp.array.DataUtil();
    if (!box) { return; }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index || box.index === 0) ? 'DirectFlash Shelf ' + box.index : 'DirectFlash Shelf';
    this.sc0 = new pure.web.jsp.XenonShelfController(box.controllerList[0], graph, parent,
        this.arch.back.sc0, this.root + ", Back", "SC0");
    this.sc1 = new pure.web.jsp.XenonShelfController(box.controllerList[1], graph, parent,
        this.arch.back.sc1, this.root + ", Back", "SC1");
};

// Inheriting from the base shelf class
pure.web.jsp.XenonShelf.prototype = new pure.web.jsp.DefaultShelf();
pure.web.jsp.XenonShelf.prototype.constructor = pure.web.jsp.XenonShelf;

pure.web.jsp.XenonShelf.prototype.getBoxHeight = function ( graph ) {
    return graph.boxHeight3U;
};

pure.web.jsp.XenonShelf.prototype.setPaper = function ( paper ) {
    this.paper = paper;
    this.sc0.setPaper(paper);
    this.sc1.setPaper(paper);
};

pure.web.jsp.XenonShelf.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight3U;
    var arrayData = this.parent.arrayData;
    var i;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.identifyComponent || !arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#000000', '#ffffff');
        arch.identifyComponent = this.drawIdentify(x + boxWidth - (graph.statusWidth + 10.5), y + 15.5,
            graph.identityWidth, graph.identityWidth, box.identifyEnabled === true);
        arch.layerComponent = this.drawLayer(x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070', arch);
    } else {
        this.updateIdentify(arch.identifyComponent, box.identifyEnabled === true);
        this.updateLayerMouseover(arch, x, y, boxWidth, boxHeight, this.root, function () {
            return arrayData.getBoxDiv(box);
        }, arch.mainComponent, '#000000', '#707070');
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    var offsets = [];
    for (i = 0; i < 4; i++) {
        var space = (barWidth + barSpace) * (i + 1.5);
        offsets[i] = 4 * i * (barWidth + barSpace * 3) + space;
    }

    if (!arch.driveBayList) {
        arch.driveBayList = this.initEmptyArch(box.driveBayList.length);
    }

    var bayList = box.driveBayList;
    var bay;
    for (i = 0; i < Math.min(box.driveBayList.length, 20); i++) {
        bay = bayList[i];

        var offset = offsets[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
        if (i >= 16) {
            offset = offsets[3] + (i % 4 + 4) * (barWidth + barSpace); // Last 8 are together in a block
        }

        this.drawDrive(x + offset + 5, y + boxHeight - (barHeight + 10), barWidth, barHeight, bay, arch.driveBayList[i]);
    }

    if (box.driveBayList.length > 20) {
        for (i = 0; i < bayList.length - 20; i++) {
            bay = bayList[i + 20];
            var offsetX = (barHeight + barSpace) * Math.floor(i / 2) + offsets[0];
            var offsetY = (barWidth + barSpace) * (i % 2);
            this.drawDrive(x + offsetX + 5, y + offsetY + 20, barHeight, barWidth, bay, arch.driveBayList[i + 20], true);
        }
    }
};

pure.web.jsp.XenonShelf.prototype.getDriveTitle = function(bay) {
    var title = 'DirectFlash Module' + (this.isHelium() ? ' (c)' : this.isComet() ? ' (e)' : '');
    title += (bay && bay.name) ? ' - ' + bay.name : '';
    return title.trim();
};

pure.web.jsp.XenonShelf.prototype.drawDriveComponent = function (x, y, w, h, fillColor, title, arrayData, bay, arch, onTop) {
    var _this = this;
    var graph = this.graph;
    var barHeight = graph.barHeight;
    var barWidth = graph.barWidth;

    arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, '#000000');
    if (bay['pureDrive'] && bay['pureDrive']['type'] === 'cache') {
        if (onTop) {
            arch.cacheDriveLayer = this.drawCacheDriveLayer(x + 1, y + 2, w - 15, h - 3);
        } else {
            arch.cacheDriveLayer = this.drawCacheDriveLayer(x + 1, y + 12, w - 2, h - 13);
        }
    }
    var space;
    if (onTop) {
        space = (w - (barHeight - 4)) / 2;
        var offset = barHeight - barWidth;
        arch.statusComponent = this.drawStatus(x + offset + space, y + space, barWidth - 4, barWidth - 4, pure.theme.component[bay.overallStatus]);
    } else {
        space = (w - (barWidth - 4)) / 2;
        arch.statusComponent = this.drawStatus(x + space, y + space, barWidth - 4, barWidth - 4, pure.theme.component[bay.overallStatus]);
    }
    arch.identifyComponent = this.drawIdentify(x + barWidth / 2 - 3, y + h - barWidth / 2 - 3, Math.floor(barWidth / 2), Math.floor(barWidth / 2), bay.identifyEnabled === true);

    arch.layerComponent = this.drawLayer(x, y, w, h, title, function () {
        return arrayData.getDiskPopupDiv(_this.root, bay, null, null, _this.isHelium(), _this.isComet(), _this.isTachyon(), _this.isRC());
    }, arch.mainComponent, '#000000', '#ffffff', arch);
};

pure.web.jsp.XenonShelf.prototype.drawBack = function (x, y) {
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var psuWidth = Math.ceil(graph.boxHeight3U / 2 - 26);
    var psuHeight = graph.boxHeight3U / 3;

    var elementPadding = 5;
    var controllerHeight = (graph.boxHeight3U - 3 * elementPadding) / 2;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.psuList) {
        var numComponents = { numPSU: 2 };
        arch.psuList = this.initEmptyArch(numComponents.numPSU);

        // Drawing chassis background
        this.drawRect(x, y, graph.boxWidth, graph.boxHeight3U, '#000000', '#000000');
    }

    this.drawPsu(x + 8, y + elementPadding + controllerHeight - psuHeight - 6, psuWidth, psuHeight, data['powerSupplyList'][0], arch.psuList[0], 'vertical');
    this.drawPsu(x + 8, y + 2 * controllerHeight + 2 * elementPadding - psuHeight - 6, psuWidth, psuHeight, data['powerSupplyList'][1], arch.psuList[1], 'vertical');

    var scXOffset = psuWidth + 2 * elementPadding + 8;
    this.sc0.draw(x + scXOffset, y + elementPadding, graph.boxWidth - (scXOffset + elementPadding + 2), controllerHeight, arch.sc0);
    this.sc1.draw(x + scXOffset, y + controllerHeight + 2 * elementPadding, graph.boxWidth - (scXOffset + elementPadding + 2), controllerHeight, arch.sc1);
};

/**
 * This file stores
 */

pure.web.jsp.SlotMap = function (options) {
    this.model = options.model || 'FA-400';
    this.boxWidth = options.boxWidth;
    this.boxHeight = options.boxHeight;
    this.barHeight = options.barHeight;
    this.cardWidth = options.cardWidth;
    this.cardHeight = options.cardHeight;
    this.cardSpacing = options.cardSpacing;
    this.statusWidth = options.statusWidth;
    this.psuHeight = options.psuHeight;
    this.psuWidth = options.psuWidth;
    this.x = options.x || 0;
    this.y = options.y || 0;

    var fa300slotMap = {
        0 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 1 + 1) - 5, y: this.y + 10 },
        1 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 2 + 1) - 5, y: this.y + 10 },
        2 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 3 + 1) - 5, y: this.y + 10 },
        3 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 4 + 1) - 5, y: this.y + 10 },
        4 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 5 + 1) - 5, y: this.y + 10 },
        5 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 6 + 1) - 5, y: this.y + 10 },
        6 : { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * (7 - 7 + 1) - 5, y: this.y + 10 },
        management: { x: this.x + this.boxWidth - (this.cardWidth + this.cardSpacing) * 7 - 5 - 10 - this.cardWidth * 2,
            y: this.y + this.boxHeight - 10 - this.cardWidth * 2 },
        psu0: {x: this.x + 10, y: this.y + this.boxHeight / 2 - this.boxHeight / 2 + 10 },
        psu1: {x: this.x + 10, y: this.y + this.boxHeight / 2 + 5 }
    };

    var fa405slotMap = {
        1: { x: this.x + 10, y: this.y + 5},
        2: { x: this.x + this.barHeight + 12, y: this.y + 5},
        3: { x: this.x + this.barHeight * 2 + 17, y: this.y + 5},
        management: { x: this.x + this.cardHeight * 2 - 25, y: this.y + this.boxHeight - this.cardWidth - 5},
        psu0: { x: this.x + this.boxWidth - (this.psuWidth + 10), y: this.y + this.boxHeight - (this.psuHeight + 10) },
        psu1: { x: this.x + this.boxWidth - (this.psuWidth + 10) * 2, y: this.y + this.boxHeight - (this.psuHeight + 10) }
    };

    var fa500slotMap = {
        mezz: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 3),
            width: this.cardWidth,
            height: this.cardHeight },
        management0: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) * 2 + 4,
            width: this.cardWidth / 1.7 - 2,
            height: this.cardHeight },
        management1: {
            x: this.x + this.cardWidth * 2 + (this.statusWidth * 2) + 20,
            y: this.y + (this.cardHeight + 5) * 2 + 5,
            width: this.cardWidth / 1.7 - 2,
            height: this.cardHeight },
        0: {
            x: this.x + this.cardWidth + (this.statusWidth * 2) + 12,
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        1: {
            x: this.x + this.cardWidth + (this.statusWidth * 2) + 12,
            y: this.y + (this.cardHeight + 5) + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        2: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        3: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + (this.cardHeight + 5) + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        psu0: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 - this.boxHeight / 2 + 10 },
        psu1: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 + 5 }
    };

    var faRC20SlotMap = {
        management0: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) * 2 + 4,
            width: this.cardWidth / 1.7 - 2,
            height: this.cardHeight },
        management1: {
            x: this.x + this.cardWidth * 2 + (this.statusWidth * 2) + 20,
            y: this.y + (this.cardHeight + 5) + 10,
            width: this.cardWidth / 1.7 - 2,
            height: this.cardHeight * 2 },
        0: {
            x: this.x + this.cardWidth + (this.statusWidth * 2) + 12,
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        1: {
            x: this.x + this.cardWidth + (this.statusWidth * 2) + 12,
            y: this.y + (this.cardHeight + 5) + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        2: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        3: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + (this.cardHeight + 5) + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        psu0: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 - this.boxHeight / 2 + 10 },
        psu1: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 + 5 }
    };

    var oxygenSlotMap = {
        management: {
            x: this.x + this.cardWidth + 12,
            y: this.y + (this.cardHeight + 5) * 2 + 5,
            width: this.cardWidth / 1.7 - 2,
            height: this.cardHeight },
        0: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        1: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        2: {
            x: this.x + this.cardWidth + (this.statusWidth * 3) + 10,
            y: this.y + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        3: {
            x: this.x + this.cardWidth + (this.statusWidth * 3) + 10,
            y: this.y + (this.cardHeight + 5) + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        4: {
            x: this.x + this.cardWidth * 2 + (this.statusWidth * 4) + 20,
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        5: {
            x: this.x + this.cardWidth * 2 + (this.statusWidth * 4) + 20,
            y: this.y + (this.cardHeight + 5) + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        6: {
            x: this.x + (this.cardWidth + this.statusWidth + 12) * 3 + 18,
            y: this.y + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        7: {
            x: this.x + (this.cardWidth + this.statusWidth + 12) * 3 + 18,
            y: this.y + (this.cardHeight + 5) + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        8: {
            x: this.x + (this.cardWidth + this.statusWidth + 12) * 3 + 18,
            y: this.y + (this.cardHeight + 5) * 2 + 5,
            width: this.cardWidth,
            height: this.cardHeight }
    };

    var xenonSlotMap = {
        ethernet: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) * 2 + 5,
            width: this.cardWidth,
            height: this.cardHeight }
    };

    var neonSlotMap = {
        ethernet: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) * 2 + 5,
            width: this.cardWidth / 2 + 5,
            height: this.cardHeight }
    };

    var mercurySlotMap = $.extend({}, fa500slotMap);
    mercurySlotMap['management1'] = {
        x: this.x + this.cardWidth * 2 + (this.statusWidth * 2) + 20,
        y: this.y + (this.cardHeight + 5) + 10,
        width: this.cardWidth / 1.7 - 2,
        height: this.cardHeight * 2
    };

    var cobaltSlotMap = {
        ethernet: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + (this.cardHeight + 5) * 2 + 4,
            width: this.cardWidth,
            height: this.cardHeight },
        management: {
            x: this.x + this.cardWidth * 2 + (this.statusWidth * 2) + 47,
            y: this.y + (this.cardHeight + 5) * 2 - 24,
            width: this.cardHeight,
            height: this.cardWidth / 1.7 - 2},
        0: {
            x: this.x + (this.statusWidth * 2),
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        1: {
            x: this.x + this.cardWidth + (this.statusWidth * 4) + 16,
            y: this.y + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        2: {
            x: this.x + this.cardWidth + (this.statusWidth * 4) + 16,
            y: this.y + (this.cardHeight + 5) + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        3: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + 5,
            width: this.cardWidth,
            height: this.cardHeight },
        4: {
            x: this.x + (this.cardWidth + 15) * 2 + this.cardWidth / 1.7 + 20,
            y: this.y + (this.cardHeight + 5) + 5 ,
            width: this.cardWidth,
            height: this.cardHeight },
        psu0: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 - this.boxHeight / 2 + 10 },
        psu1: {
            x: this.x + 10,
            y: this.y + this.boxHeight / 2 + 5 }
    };

    var model = this.model ? this.model.toLowerCase() : '';
    if (model === 'fa-405') {
        this.slotMap = fa405slotMap;
    } else if (model.indexOf('fa-m') === 0) {
        this.slotMap = fa500slotMap;
    } else if (model.indexOf('fa-xl') === 0 || model.indexOf('fa-st') === 0) {
        this.slotMap = oxygenSlotMap;
    } else if (model.startsWith('fa-xc') || model.endsWith('r4') ||
        model.startsWith('fa-e') || model.startsWith('fa-c20')) {
        this.slotMap = cobaltSlotMap;
    } else if (model.startsWith('fa-rc20')) {
        this.slotMap = faRC20SlotMap;
    } else if (model.indexOf('fa-x') === 0) { // for tungsten/mercury/quicksilver
        if (model.endsWith('r2') || model.endsWith('r3')) { // for mercury or quicksilver
            this.slotMap = mercurySlotMap;
        } else { // for tungsten
            this.slotMap = fa500slotMap;
        }
    } else if (model.indexOf('fa-c') === 0) { // for helium
        this.slotMap = mercurySlotMap;
    } else if (model === 'dfsc1') {
        this.slotMap = xenonSlotMap;
    } else if (model === 'dfsc2') {
        this.slotMap =  neonSlotMap;
    } else {
        this.slotMap = fa300slotMap;
    }
};

pure.web.jsp.SlotMap.prototype.setOffset = function (offset) {
    this.x = offset.x;
    this.y = offset.y;
};

pure.web.jsp.SlotMap.prototype.getSlotMap = function () {
    return this.slotMap;
};

pure.web.jsp.SlotMap.prototype.get = function (slot) {
    return this.slotMap[slot];
};

pure.web.jsp.SlotMap.prototype.getX = function (slot) {
    return this.slotMap[slot].x;
};

pure.web.jsp.SlotMap.prototype.getY = function (slot) {
    return this.slotMap[slot].y;
};


/**
 * This file contains drawing functions for the
 * Nickel chassis.
 **/

pure.web.jsp.NickelChassis = function (box, graph, parent, archive) {
    this.dataUtil = new pure.web.jsp.array.DataUtil();
    if (!box) {
        return;
    }
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = box.index || box.index === 0 ? 'Chassis ' + box.index : 'Chassis';
    let c0 = box.controllerList[0],
        c1 = box.controllerList[1];

    if (c0.model) {
        if (c0.model.endsWith('R4') || c0.model.startsWith('FA-E') || c0.model.startsWith('FA-C20')) {
            this.ct0 = new pure.web.jsp.CobaltController(
                c0,
                graph,
                parent,
                this.arch.back.ct0,
                this.root + ', Back',
                'CT0'
            );
        } else {
            this.ct0 = new pure.web.jsp.FA500Controller(
                c0,
                graph,
                parent,
                this.arch.back.ct0,
                this.root + ', Back',
                'CT0'
            );
        }
    }

    if (c1.model) {
        if (c1.model.endsWith('R4') || c1.model.startsWith('FA-E') || c1.model.startsWith('FA-C20')) {
            this.ct1 = new pure.web.jsp.CobaltController(
                c1,
                graph,
                parent,
                this.arch.back.ct1,
                this.root + ', Back',
                'CT1'
            );
        } else {
            this.ct1 = new pure.web.jsp.FA500Controller(
                c1,
                graph,
                parent,
                this.arch.back.ct1,
                this.root + ', Back',
                'CT1'
            );
        }
    }
};

// Inheriting from the base shelf class
pure.web.jsp.NickelChassis.prototype = new pure.web.jsp.DefaultShelf();
pure.web.jsp.NickelChassis.prototype.constructor = pure.web.jsp.NickelChassis;

pure.web.jsp.NickelChassis.prototype.getBoxHeight = function (graph) {
    return graph.boxHeight3U;
};

pure.web.jsp.NickelChassis.prototype.setPaper = function (paper) {
    this.paper = paper;
    if (this.ct0) {
        this.ct0.setPaper(paper);
    }
    if (this.ct1) {
        this.ct1.setPaper(paper);
    }
};

pure.web.jsp.NickelChassis.prototype.drawFront = function (x, y) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight3U;
    var arrayData = this.parent.arrayData;
    var i;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.identifyComponent || !arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, '#000000', '#ffffff');
        arch.identifyComponent = this.drawIdentify(
            x + boxWidth - (graph.statusWidth + 10.5),
            y + 15.5,
            graph.identityWidth,
            graph.identityWidth,
            box.identifyEnabled === true
        );
        arch.layerComponent = this.drawLayer(
            x,
            y,
            boxWidth,
            boxHeight,
            this.root,
            function () {
                return arrayData.getBoxDiv(box);
            },
            arch.mainComponent,
            '#000000',
            '#707070',
            arch
        );
    } else {
        this.updateIdentify(arch.identifyComponent, box.identifyEnabled === true);
        this.updateLayerMouseover(
            arch,
            x,
            y,
            boxWidth,
            boxHeight,
            this.root,
            function () {
                return arrayData.getBoxDiv(box);
            },
            arch.mainComponent,
            '#000000',
            '#707070'
        );
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    var offsets = [];
    for (i = 0; i < 4; i++) {
        var space = (barWidth + barSpace) * (i + 1.5);
        offsets[i] = 4 * i * (barWidth + barSpace * 3) + space;
    }

    if (!arch.driveBayList) {
        arch.driveBayList = this.initEmptyArch(box.driveBayList.length);
    }

    var bayList = box.driveBayList;
    var bay;
    for (i = 0; i < Math.min(box.driveBayList.length, 20); i++) {
        bay = bayList[i];

        var offset = offsets[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
        if (i >= 16) {
            offset = offsets[3] + ((i % 4) + 4) * (barWidth + barSpace); // Last 8 are together in a block
        }

        this.drawDrive(
            x + offset + 5,
            y + boxHeight - (barHeight + 10),
            barWidth,
            barHeight,
            bay,
            arch.driveBayList[i]
        );
    }

    if (box.driveBayList.length > 20) {
        for (i = 0; i < bayList.length - 20; i++) {
            bay = bayList[i + 20];
            var offsetX = (barHeight + barSpace) * Math.floor(i / 2) + offsets[0];
            var offsetY = (barWidth + barSpace) * (i % 2);
            this.drawDrive(
                x + offsetX + 5,
                y + offsetY + 20,
                barHeight,
                barWidth,
                bay,
                arch.driveBayList[i + 20],
                true
            );
        }
    }
};

pure.web.jsp.NickelChassis.prototype.getDriveTitle = function (bay) {
    var title = 'DirectFlash Module' + (this.isHelium() ? ' (c)' : this.isComet() ? ' (e)' : '');
    title += bay && bay.name ? ' - ' + bay.name : '';
    return title.trim();
};

pure.web.jsp.NickelChassis.prototype.drawDriveComponent = function (
    x,
    y,
    w,
    h,
    fillColor,
    title,
    arrayData,
    bay,
    arch,
    onTop
) {
    var _this = this;
    var graph = this.graph;
    var barHeight = graph.barHeight;
    var barWidth = graph.barWidth;

    arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, '#000000');
    if (bay['pureDrive'] && bay['pureDrive']['type'] === 'cache') {
        if (onTop) {
            arch.cacheDriveLayer = this.drawCacheDriveLayer(x + 1, y + 2, w - 15, h - 3);
        } else {
            arch.cacheDriveLayer = this.drawCacheDriveLayer(x + 1, y + 12, w - 2, h - 13);
        }
    }
    var space;
    if (onTop) {
        space = (w - (barHeight - 4)) / 2;
        var offset = barHeight - barWidth;
        arch.statusComponent = this.drawStatus(
            x + offset + space,
            y + space,
            barWidth - 4,
            barWidth - 4,
            pure.theme.component[bay.overallStatus]
        );
    } else {
        space = (w - (barWidth - 4)) / 2;
        arch.statusComponent = this.drawStatus(
            x + space,
            y + space,
            barWidth - 4,
            barWidth - 4,
            pure.theme.component[bay.overallStatus]
        );
    }
    arch.identifyComponent = this.drawIdentify(
        x + barWidth / 2 - 3,
        y + h - barWidth / 2 - 3,
        Math.floor(barWidth / 2),
        Math.floor(barWidth / 2),
        bay.identifyEnabled === true
    );

    arch.layerComponent = this.drawLayer(
        x,
        y,
        w,
        h,
        title,
        function () {
            return arrayData.getDiskPopupDiv(
                _this.root,
                bay,
                null,
                null,
                _this.isHelium(),
                _this.isComet(),
                _this.isTachyon(),
                _this.isRC()
            );
        },
        arch.mainComponent,
        '#000000',
        '#ffffff',
        arch
    );
};

pure.web.jsp.NickelChassis.prototype.drawBack = function (x, y) {
    var data = this.box;
    var arch = this.arch.back;
    var graph = this.graph;
    var psuWidth = Math.ceil(graph.boxHeight3U / 2 - 26);
    var psuHeight = graph.boxHeight3U / 3;

    var elementPadding = 5;
    var controllerHeight = (graph.boxHeight3U - 3 * elementPadding) / 2;

    x = Math.ceil(x) + 0.5;
    y = Math.ceil(y) + 0.5;

    if (!arch.psuList) {
        var numComponents = {
            numPSU: 2,
        };

        arch.psuList = this.initEmptyArch(numComponents.numPSU);

        // Drawing chassis background
        this.drawRect(x, y, graph.boxWidth, graph.boxHeight3U, '#000000', '#000000');
    }

    this.drawPsu(
        x + 8,
        y + elementPadding + controllerHeight - psuHeight - 6,
        psuWidth,
        psuHeight,
        data['powerSupplyList'][0],
        arch.psuList[0],
        'vertical'
    );
    this.drawPsu(
        x + 8,
        y + 2 * controllerHeight + 2 * elementPadding - psuHeight - 6,
        psuWidth,
        psuHeight,
        data['powerSupplyList'][1],
        arch.psuList[1],
        'vertical'
    );

    var ctXOffset = psuWidth + 2 * elementPadding + 8;
    if (this.ct0) {
        this.ct0.draw(
            x + ctXOffset,
            y + elementPadding,
            graph.boxWidth - (ctXOffset + elementPadding + 2),
            controllerHeight,
            arch.ct0
        );
    }
    if (this.ct1) {
        this.ct1.draw(
            x + ctXOffset,
            y + controllerHeight + 2 * elementPadding,
            graph.boxWidth - (ctXOffset + elementPadding + 2),
            controllerHeight,
            arch.ct1
        );
    }
};

/**
 * This file contains all the drawing functions for the
 * Nickel chassis.
 */

pure.web.jsp.DashboardNickelChassis = function (box, graph, parent, archive)
{
    this.box = box;
    this.arch = archive;
    this.graph = graph;
    this.parent = parent;
    this.root = (box.index != null) ? "Chassis " + box.index : "Chassis";
    this.type = box.type;
};

// Inheriting from the base component class
pure.web.jsp.DashboardNickelChassis.prototype = new pure.web.jsp.GraphComponent();
pure.web.jsp.DashboardNickelChassis.prototype.constructor = pure.web.jsp.DashboardNickelChassis;

pure.web.jsp.DashboardNickelChassis.prototype.draw = function ( x, y ) {
    var box = this.box;
    var arch = this.arch.front;
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var barSpace = graph.barSpace;
    var barHeight = graph.barHeight;
    var boxWidth = graph.boxWidth;
    var boxHeight = graph.boxHeight3U;
    var statusWidth = graph.statusWidth;
    var statusPadding = graph.statusPadding;
    var componentPadding = graph.componentPadding;
    var bezelHeight = graph.bezelHeight;
    var bezelWidth = graph.bezelWidth;

    if (!arch.mainComponent) {
        arch.mainComponent = this.drawComponent(x, y, boxWidth, boxHeight, "#000000", "#ffffff");
    }

    this.drawStatusBar(graph, x, y, boxHeight, box, arch);

    if (!arch.driveBayList) {
        arch.driveBayList = [];
    }

    var bayList = box.driveBayList;
    var drivesStartX = x + 2 * statusPadding + statusWidth + 1;
    var driveXOffset = [];

    for (var i = 0; i < 20; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        if (i % 4 === 0 && i < 16) {
            driveXOffset[i/4] = i * (barWidth * 2);
        }

        var offset = driveXOffset[Math.floor(i / 4)] + (i % 4) * (barWidth + barSpace);
        if (i >= 16) {
            offset = driveXOffset[3] + (i % 4 + 4) * (barWidth + barSpace); // Last 8 are together in a block
        }

        // Subtract 46 from Y values because we need XenonShelfHeight Y placement, but boxHeight for total chassis 3U size
        if (bayList[i]) {
            var bay = bayList[i];
            this.drawDrive(drivesStartX + offset, y + boxHeight - barHeight - statusPadding - 46, barWidth, barHeight, bay, arch.driveBayList[i]);
        }
        else {
            this.drawComponent(drivesStartX + offset, y + boxHeight - barHeight - statusPadding - 46, barWidth, barHeight, "#363636", "#363636");
        }
    }

    for (var i = 20; i < 28; i++) {
        if (!arch.driveBayList[i]) {
            arch.driveBayList.push({});
        }

        var offsetX = driveXOffset[Math.floor((i - 20)/2)];
        var offsetY = Math.floor(i%2) * (barWidth + barSpace);

        var drivesEndX = 0;
        if (bayList[i]) {
            var bay = bayList[i];
            this.drawDrive(drivesStartX + offsetX, y + statusPadding + offsetY, barWidth, barHeight, bay, arch.driveBayList[i], true);
            drivesEndX = drivesStartX + offset + 1 + barWidth;
        }
        else {
            this.drawComponent(drivesStartX + offsetX, y + statusPadding + offsetY, barHeight, barWidth, "#363636", "#363636");
        }
    }

    var chassisWidth = drivesEndX - drivesStartX;

    if (!arch.logo) {
        var bezelImg = pure.theme.images.bezel_nickel;
        var bezelOffset = (componentPadding - bezelHeight / 2 + 10);
        bezelWidth = chassisWidth + 20;
        bezelHeight = 55;

        arch.logo = this.paper.image(
            pure.Context.contentPath(bezelImg),
            drivesStartX + (chassisWidth - bezelWidth ) / 2,
            y + boxHeight - bezelHeight - bezelOffset,
            bezelWidth, bezelHeight);
    }
};

pure.web.jsp.DashboardNickelChassis.prototype.drawDrive = function (x, y, w, h, bay, arch, isHorizontal) {
    var graph = this.graph;
    var barWidth = graph.barWidth;
    var fillColor = '#707070';

    if (bay.overallStatus !== 'ok' && bay.overallStatus !== 'healthy' && bay.overallStatus !== 'failing'
        && bay.overallStatus !== 'unrecognized' && bay.overallStatus !== 'identifying' && bay.overallStatus !== 'evacuated'
        && bay.overallStatus !== 'evacuating') {
        fillColor = pure.theme.component[bay.overallStatus];
    }

    if (!arch.mainComponent || !arch.statusComponent) {
        if (isHorizontal) {
            arch.mainComponent = this.drawComponent(x, y, h, w, fillColor, fillColor);
            arch.statusComponent = this.drawStatus(x + h - barWidth, y, barWidth, barWidth, pure.theme.component[bay.overallStatus]);
        } else {
            arch.mainComponent = this.drawComponent(x, y, w, h, fillColor, fillColor);
            arch.statusComponent = this.drawStatus(x, y, barWidth, barWidth, pure.theme.component[bay.overallStatus]);
        }
    } else {
        this.updateComponent(arch.mainComponent, fillColor, fillColor);
        this.updateStatus(arch.statusComponent, pure.theme.component[bay.overallStatus]);
    }
};

/**
 * This file is responsible for parsing the backend array data and
 * calling the "draw" functions of each of the components.
 */

pure.web.jsp.ArrayGraphDashboard = function (ele, data) {
    this.arch = [];
    this.ele = ele;
    this.data = data;

    if (this.data) {
        this.graph = this.calcGraph();
        this.graph.boxes = this.createBoxesFromData();
        this.graph.totalHeight = this.calcTotalHeight(this.graph);
    }
    this.dataUtil = new pure.web.jsp.array.DataUtil();
};

// Inheriting from ArrayGraphCommon class
pure.web.jsp.ArrayGraphDashboard.prototype = new pure.web.jsp.ArrayGraphCommon();
pure.web.jsp.ArrayGraphDashboard.prototype.constructor = pure.web.jsp.ArrayGraphDashboard;

pure.web.jsp.ArrayGraphDashboard.prototype.createBoxesFromData = function () {
    /**
     * See wiki for changes that were made here.
     * https://wiki.purestorage.com/pages/viewpage.action?pageId=163166311
     */
    this.data.boxes = this.dataUtil.createPAWSBoxes(this.data.boxes);
    var arch = this.arch;
    var graph = this.graph;
    var boxes = [];

    for (var i = 0; i < this.data.boxes.length; i++) {
        if (!arch[i]) {
            arch[i] = { front: {}, back: {} };
        }

        var boxData = this.data.boxes[i];
        if (boxData.type === 'storage_shelf') {
            if (this.dataUtil.isCBSShelf(boxData)) {
                boxes[i] = new pure.web.jsp.DashboardCBSShelf(boxData, graph, this, arch[i]);
            } else if (this.dataUtil.isXenonShelf(boxData) || this.dataUtil.isNeonShelf(boxData)) {
                boxes[i] = new pure.web.jsp.DashboardXenonShelf(boxData, graph, this, arch[i]);
            } else if (this.dataUtil.isOxygenShelf(boxData) || this.dataUtil.isTachyonShelf(boxData)) {
                boxes[i] = new pure.web.jsp.DashboardChassis(boxData, graph, this, arch[i]);
            } else {
                boxes[i] = new pure.web.jsp.DashboardShelf(boxData, graph, this, arch[i]);
            }
        } else if (boxData.type === 'chassis') {
            if (this.dataUtil.isNickelChassis(boxData)) {
                boxes.push(new pure.web.jsp.DashboardNickelChassis(boxData, graph, this, arch[i]));
            } else {
                boxes.push(new pure.web.jsp.DashboardChassis(boxData, graph, this, arch[i]));
            }
        } else if (boxData.type === 'controller') {
            // FA-405 controller's height is 10 less than a regular controller.
            if (boxData.model === 'FA-405') {
                boxes.push(new pure.web.jsp.FA405DashboardController(boxData, graph, this, arch[i]));
            } else if (this.dataUtil.isCBS(boxData)) {
                boxes.push(new pure.web.jsp.DashboardControllerCBS(boxData, graph, this, arch[i]));
            } else {
                boxes.push(new pure.web.jsp.DashboardController(boxData, graph, this, arch[i]));
            }
        }
    }

    return boxes;
};

pure.web.jsp.ArrayGraphDashboard.prototype.calcGraph = function () {
    var graph = {};

    graph.barWidth = 5;
    graph.barSpace = 2;
    graph.barPadding = 6;
    graph.barHeight = 30;
    graph.boxWidth = 198;
    graph.boxHeight = 45;
    graph.boxHeight1U = 35;
    graph.boxHeight2U = 83;
    graph.boxHeight3U = 102;
    graph.boxHeight5U = 170;
    graph.bezelHeight = Math.floor(77/2);
    graph.bezelWidth = Math.floor(99/2);
    graph.componentPadding = 7;
    graph.controllerHeight = 14;
    graph.controllerSpace = 2;
    graph.numDrives = 24;
    graph.nvramTopPadding = 5;
    graph.nvramWidth = 36;
    graph.nvramHeight = 12;
    graph.statusWidth = 6;
    graph.statusBarWidth = 5;
    graph.statusPadding = 5;
    graph.xenonShelfHeight = 56;
    graph.cloudInstanceWidth = 14;
    graph.cloudInstanceHeight = 36;
    graph.cloudInstanceSpace = 2.5;

    graph.totalWidth = graph.boxWidth;

    return graph;
};

pure.web.jsp.ArrayGraphDashboard.prototype.calcTotalHeight = function(graph) {
    var totalHeight = 0;
    var cbsControllerCount = 0;
    for (var i = 0; i < this.data.boxes.length; i++) {
        if (this.data.boxes[i]) {
            if (this.dataUtil.isCBS(this.data.boxes[i])) { // 2 PAWS controller is in the same row.
                cbsControllerCount++;
            }

            if (cbsControllerCount !== 1) { // Don't increase the total height for the first controller
                totalHeight += this.getBoxDashboardHeight(this.data.boxes[i], graph) + graph.barSpace;
            }
        }
    }
    return totalHeight;
};

pure.web.jsp.ArrayGraphDashboard.prototype.checkGraph = function () {
    var graph = this.calcGraph();
    this.data.boxes = this.dataUtil.createPAWSBoxes(this.data.boxes);
    graph.totalHeight = this.calcTotalHeight(graph);

    var resizeHappened = false;
    if (!pure.defined(this.graph) || this.graph.barWidth !== graph.barWidth
        || this.data.boxes.length !== this.graph.boxes.length ) {
        resizeHappened = true;
        if (this.paper) {
            this.paper.clear();
            this.paper.setSize(graph.totalWidth, graph.totalHeight);
        }
        this.arch = [];
        this.graph = graph;
    }

    this.graph.boxes = this.createBoxesFromData();
    this.drawGraph('pureui-flasharray-container');
    return resizeHappened;
};

pure.web.jsp.ArrayGraphDashboard.prototype.drawGraph = function (domElt) {
    var graph = this.graph;
    var barSpace = graph.barSpace;

    if (!this.paper) {
        this.paper = Raphael(domElt, graph.totalWidth, graph.totalHeight);
    }

    var x = 0.5;
    var y = 0.5;
    var pawsControllerCount = 0;
    for (var i = 0; i < graph.boxes.length; i++) {
        var box = graph.boxes[i];
        if (box) {
            box.setPaper(this.paper);

            if (this.dataUtil.isCBS(box.box)) {
                if (pawsControllerCount === 1) {
                    box.draw(x + graph.boxWidth / 2, y);
                } else {
                    box.draw(x, y);
                }
                pawsControllerCount++;
                if (pawsControllerCount === 2) {
                    y += box.getBoxHeight(graph) + 3 * barSpace;
                }
            } else if (this.dataUtil.isCBSShelf(box.box)) {
                box.draw(x, y);
                y += box.getBoxHeight(graph) + 3 * barSpace;
            } else {
                box.draw(x, y);
                y += box.getBoxHeight(graph) + barSpace;
            }
        }
    }
};

/**
 * This file is responsible for parsing the backend array data and
 * calling the "draw" functions of each of the components.
 */

pure.web.jsp.ArrayGraph = function (ele, data, arrayData, halfWidth) {
    this.arch = [];
    this.ele = ele;
    this.data = data;
    this.arrayData = arrayData;
    this.halfWidth = halfWidth;
    this.graph = this.calcGraph();
    this.graph.boxes = this.createBoxesFromData();
    this.graph.totalHeight = this.calcTotalHeight();
    this.dataUtil = new pure.web.jsp.array.DataUtil();
};

// Inheriting from ArrayGraphCommon class
pure.web.jsp.ArrayGraph.prototype = new pure.web.jsp.ArrayGraphCommon();
pure.web.jsp.ArrayGraph.prototype.constructor = pure.web.jsp.ArrayGraph;

pure.web.jsp.ArrayGraph.prototype.createBoxesFromData = function () {
    this.data.boxes = this.dataUtil.createPAWSBoxes(this.data.boxes);
    this.data.boxes = this.dataUtil.updateNDUBoxes(this.data.boxes);
    var arch = this.arch;
    var graph = this.graph;
    var boxes = [];

    var foundControllerIndex = -1;
    for (var i = 0; i < this.data.boxes.length; i++) {
        if (!arch[i]) {
            arch[i] = { front: {}, back: {} };
        }

        var boxData = this.data.boxes[i];
        if (boxData.type === 'storage_shelf') {
            if (this.dataUtil.isCBSShelf(boxData)) {
                boxes[i] = new pure.web.jsp.CBSShelf(boxData, graph, this, arch[i]);
            } else if (this.dataUtil.isXenonShelf(boxData) || this.dataUtil.isNeonShelf(boxData)) {
                if (!arch[i].back.sc0 || !arch[i].back.sc1) {
                    arch[i].back = { sc0: {}, sc1: {} };
                }
                boxes[i] = new pure.web.jsp.XenonShelf(boxData, graph, this, arch[i]);
            } else if (
                this.dataUtil.isOxygenShelf(boxData) ||
                this.dataUtil.isTachyonShelf(boxData)
            ) {
                boxes[i] = new pure.web.jsp.OxygenChassis(boxData, graph, this, arch[i]);
            } else {
                boxes[i] = new pure.web.jsp.DefaultShelf(boxData, graph, this, arch[i]);
            }
        } else if (boxData.type === 'chassis') {
            if (!arch[i].back.ct0 || !arch[i].back.ct1) {
                arch[i].back = { ct0: {}, ct1: {} };
            }
            if (this.dataUtil.isOxygen(boxData) || this.dataUtil.isTachyon(boxData)) {
                boxes.push(new pure.web.jsp.OxygenChassis(boxData, graph, this, arch[i]));
            } else if (this.dataUtil.isNickelChassis(boxData)) {
                boxes.push(new pure.web.jsp.NickelChassis(boxData, graph, this, arch[i]));
            } else {
                boxes.push(new pure.web.jsp.FA500Chassis(boxData, graph, this, arch[i]));
            }
        } else if (boxData.type === 'controller') {
            // If no model, try to default to other controller's model
            if (boxData.model && boxData.model !== '') {
                foundControllerIndex = i;
            } else if (foundControllerIndex >= 0) {
                boxData.model = this.data.boxes[foundControllerIndex].model;
            }
            switch (boxData.model) {
                case 'FA-300':
                    boxes[i] = new pure.web.jsp.FA300Controller(boxData, graph, this, arch[i]);
                    break;
                case 'FA-405':
                    boxes[i] = new pure.web.jsp.FA405Controller(boxData, graph, this, arch[i]);
                    break;
                case 'FA-450':
                    boxes[i] = new pure.web.jsp.FA450Controller(boxData, graph, this, arch[i]);
                    break;
                default:
                    if (this.dataUtil.isCBS(boxData)) {
                        boxes[i] = new pure.web.jsp.CBSController(boxData, graph, this, arch[i]);
                    } else {
                        boxes[i] = new pure.web.jsp.DefaultController(
                            boxData,
                            graph,
                            this,
                            arch[i]
                        );
                    }

                    break;
            }
        } else if (!boxData.type && boxData.overallStatus === 'missing') {
            boxes[i] = new pure.web.jsp.DefaultController(boxData, graph, this, arch[i]);
        }
    }

    return boxes;
};

pure.web.jsp.ArrayGraph.prototype.calcGraph = function () {
    var w = $(document).width();
    var h = $(document).height();

    // Adjusting sizes to be something reasonable
    var barWidth = Math.floor((w - 324) / 60);
    var barWidth2 = Math.floor(h - 200) / 40;
    barWidth = barWidth2 < barWidth ? barWidth2 : barWidth;
    barWidth = barWidth < 13 ? 13 : barWidth;
    barWidth = barWidth > 15 ? 15 : barWidth;

    var graph = {
        barWidth: barWidth,
        barSpace: 1,
        diskCount: 24,
    };

    graph.identityWidth = 8;
    graph.barHeight = graph.barWidth * 6;
    graph.statusWidth = graph.barWidth - 4;
    graph.statusBarWidth = 9;
    graph.statusPadding = 6;
    graph.cardWidth = Math.floor(graph.barWidth * 1.8);
    graph.cardSpacing = Math.floor(graph.barWidth * 0.4);
    graph.boxWidth = Math.floor((graph.diskCount + 3) * (graph.barWidth + graph.barSpace));
    graph.boxHeight = graph.barHeight + 20;
    graph.boxHeight1U = graph.barHeight / 2 + 25;
    graph.boxHeight3U = graph.boxHeight * 1.5;
    graph.boxHeight5U = graph.boxHeight * 2; // Use '4U' for 5U for better visual result because of the drive dimension
    graph.totalWidth = this.halfWidth ? graph.boxWidth + 10 : graph.boxWidth * 2 + 10;
    graph.tooltipDelay = 200;
    // cloud
    graph.cloudInstanceWidth = graph.barWidth * 2;
    graph.cloudInstanceHeight = graph.cloudInstanceWidth * 3;
    graph.cloudInstanceSpace = graph.barSpace * 7.5;
    graph.cloudBoxWidth = graph.cloudInstanceWidth * 6 + graph.cloudInstanceSpace * 5;
    graph.cloudControllerSpace = graph.barSpace * 15;

    return graph;
};

pure.web.jsp.ArrayGraph.prototype.calcTotalHeight = function (graph) {
    var totalHeight = 0;
    var cbsControllerCount = 0;
    for (var i = 0; i < this.data.boxes.length; i++) {
        if (this.data.boxes[i]) {
            if (this.data.boxes[i].type === 'chassis') {
                totalHeight += 15;
            }
            if (this.dataUtil.isCBS(this.data.boxes[i])) {
                // 2 PAWS controller is in the same row.
                cbsControllerCount++;
            }

            if (cbsControllerCount !== 1) {
                // Don't increase the total height for the first controller
                totalHeight += this.getBoxHeight(this.data.boxes[i], graph) + 12;
            }
        }
    }

    return totalHeight;
};

pure.web.jsp.ArrayGraph.prototype.isHA = function () {
    var num_controllers = 0;
    for (var i = 0; i < this.data.boxes.length; i++) {
        var box = this.data.boxes[i];
        if (box && box.type === 'controller') {
            num_controllers += 1;
        }
    }
    return num_controllers > 1;
};

pure.web.jsp.ArrayGraph.prototype.checkGraph = function () {
    var graph = this.calcGraph();
    graph.totalHeight = this.calcTotalHeight(graph);

    var resizeHappened = false;
    var ele = this.ele;
    if (this.graph.barWidth !== graph.barWidth) {
        resizeHappened = true;
        if (this.paper) {
            this.paper.clear();
            this.paper.setSize(graph.totalWidth, graph.totalHeight);
        }

        this.arch = [];
        this.graph = graph;
        $('.qtip.ui-tooltip').qtip('hide');
    }
    this.graph.boxes = this.createBoxesFromData();
    $(ele).css('width', graph.totalWidth);
    this.drawGraph();
    return resizeHappened;
};

pure.web.jsp.ArrayGraph.prototype.drawGraph = function () {
    var graph = this.graph;

    if (!this.paper) {
        this.paper = Raphael('arrayGraph', graph.totalWidth, graph.totalHeight);
        $(
            '<div style="height: 0;"> \
            <svg style="height: ' +
                graph.totalHeight +
                'px; width: ' +
                graph.totalWidth +
                'px"> \
                <defs> \
                 <pattern id="pattern-stripe" width="8" height="8" patternunits="userSpaceOnUse" patterntransform="rotate(45)">\
                  <rect width="4" height="8" transform="translate(0,0)" fill="#505050"></rect>\
                </pattern><mask id="mask-stripe">\
                  <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)"></rect>\
                </mask> \
                </defs> \
            </svg> \
            </div>'
        ).prependTo('body');
    }

    var x = 1;
    var y = 1;
    var cbsControllerCount = 0;
    for (var i = 0; i < graph.boxes.length; i++) {
        var box = graph.boxes[i];
        if (box) {
            box.setPaper(this.paper);

            if (!this.dataUtil.isCBS(box.box)) {
                box.draw(y);
                y += box.getBoxHeight(graph) + 12;
            } else {
                box.draw(x, y);
                x += graph.cloudBoxWidth + graph.cloudControllerSpace;
                cbsControllerCount++;
                if (cbsControllerCount === 2) {
                    y += box.getBoxHeight(graph) + 12;
                }
            }
        }
    }
};

/**
 * Colors
 * @type {Object}
 */
pure.theme = {
    component:{
        "ok":"#8dc63f",
        "enabled":"#8dc63f",
        "healthy":"#8dc63f",
        "recovering":"#b9d2a9",
        "degraded":"#ecc337",
        "failing":"#ecc337",
        "identifying":"#ecc337",
        "unadmitted":"#ecc337",
        "unhealthy":"#ecc337",
        "unknown":"#ecc337",
        "unrecognized":"#ecc337",
        "critical":"#ed1c24",
        "disconnect_bad":"#ed1c24",
        "not_installed":"#ed1c24",
        "not_present":"#ed1c24",
        "missing":"#ed1c24",
        "offline":"#ed1c24",
        "disconnect":"#b5b5b5",
        "disabled":"#b5b5b5",
        "evacuating":"#b5b5b5",
        "updating":"#b5b5b5",
        "evacuated":"#363636",
        "unused":"#363636",
        "reminder":"#f87431"
    },
    paths:{
        "None":"#999999",
        "Redundant":"#8dc63f",
        "Redundant - Failover":"#ecc337",
        "Uneven":"#ecc337",
        "Unused Port":"#ecc337",
        "Single Controller":"#ed1c24",
        "Single Controller - Failover":"#ed1c24"
    },
    images: {
        "bezel": "/images/logo-bezel.png",
        "bezel_x_series": "/images/x_series_bezel.png",
        "bezel_helium": "/images/helium_bezel.png",
        "bezel_oxygen": "images/oxygen_bezel.svg",
        "array_controller": "/images/array-controller-logo.png",
        "gray_logo": "/images/logo-gray.png",
        "cbs_logo": "/images/cbs.svg",
        "bezel_nickel": "/images/nickel_bezel.svg"
    },
    app_name: 'Pure1 Manage' // DO NOT remove, otherwise tooltip position will be wrong
};

/**
 * Returns critical, urgent or info color
 * @param priority int 1 -> critical, 2 = urgent, 3 = low
 */
pure.theme.simplePriorityColor = function (priority)
{
    switch (priority)
    {
        case 1:
            return pure.theme.component.critical;
        case 2:
            return pure.theme.component.degraded;
        case 3:
            return pure.theme.component.ok;
    }
    assert(false, 'Invalid argument (' + priority + ') passed to pure.theme.simplePriorityColor');
};
