export enum PeakLicenseStatus {
    EXPIRED = 'Expired',
    EXPIRES_IN_30_DAYS = 'Expires in 30 days',
    EXPIRES_IN_120_DAYS = 'Expires in 120 days',
    UNDER_CONTRACT = 'Under Contract',
}

export class PeakStatusSummary {
    status: PeakLicenseStatus;
    count: number;

    constructor(json: any) {
        this.status = json.status;
        this.count = json.count;
    }
}

export class PeakSubscriptionTypeSummary {
    type: string;
    count: number;
    status: PeakStatusSummary[];

    constructor(json: any) {
        this.type = json.type;
        this.count = json.count;
        this.status = json.status.map(statusJson => new PeakStatusSummary(statusJson));
    }
}

export class PeakSummary {
    statusSummaries: PeakStatusSummary[];
    typeSummaries: PeakSubscriptionTypeSummary[];

    constructor(json: any) {
        this.statusSummaries = json.status_summaries.map(statusJson => new PeakStatusSummary(statusJson));
        this.typeSummaries = json.type_summaries.map(typeJson => new PeakSubscriptionTypeSummary(typeJson));
    }
}
