import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { smartTimer } from '@pstg/smart-timer';
import { AuthorizationService } from '@pure/authz-authorizer';
import {
    ArrayContractStatus,
    CachedCurrentUserService,
    CopilotOnboardingService,
    CurrentUser,
    FeatureFlagDxpService,
    IncidentService,
    SubscriptionType,
    UnifiedArray,
    UnifiedArrayService,
    AlertsCountService,
} from '@pure1/data';
import { flatMap, cloneDeep } from 'lodash';
import moment from 'moment';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ampli } from '../../ampli';
import { WINDOW } from '../../app/injection-tokens';
import { MENU } from '../../app/menu';
import { MpaRequestsBadgeService } from '../../messages/mpa-requests/services/mpa-requests-badge.service';
import { MENU_SEPARATOR_TITLE } from '../../services/tabs/tabs.model';
import { IMainTab, ITab, TabsService } from '../../services/tabs/tabs.service';
import { SupportCaseOrchestratorService } from '../../support/services/support-case-orchestrator.service';
import { TouService } from '../../tou/tou.service';
import { recursiveListItems } from '../../utils/collection';
import { FeatureNames } from '../../model/FeatureNames';
import {
    AppHeaderComponent,
    AvatarMenuComponent,
    BadgeEmphasis,
    MenuComponent,
    PageBaseLayoutComponent,
} from '@pure/hive';
import { NavMenuIdPipe } from '../pipes/nav-menu-id.pipe';
import { NotificationCenterCounterService } from '@pure/notification-center';

const FIRST_LOAD_DELAY = 100;
const PROACTIVE_RECOMMENDATIONS_REFRESH_INTERVAL_MS = moment.duration(300, 'seconds').asMilliseconds();

/** Make the org switcher element disabled (readonly) on certain pages. Also auto-collapse sidenav when going to these pages. */
const ORG_SWITCHER_READONLY_TAB_IDS = ['tab-reporting'];

interface IConnectedCount {
    connected: number;
    disconnected: number;
}

@Component({
    selector: 'page-wrapper',
    templateUrl: 'page-wrapper.component.html',
    host: {
        id: 'wrapper',
    },
    providers: [NavMenuIdPipe],
})
export class PageWrapperComponent implements OnInit, OnDestroy {
    @ViewChild(AvatarMenuComponent) avatarMenuComponent: AvatarMenuComponent;
    @ViewChild(AppHeaderComponent) appHeaderComponent: AppHeaderComponent;
    @ViewChild(PageBaseLayoutComponent) pageBaseLayoutComponent: PageBaseLayoutComponent;
    @ViewChild('prorecDummyMenu') prorecDummyMenuElem: MenuComponent;

    MENU_SEPARATOR_TITLE = MENU_SEPARATOR_TITLE;

    pageTitle = 'Pure1';
    stateName = '';
    pageHelpUrl = '';

    tabs: IMainTab[] = [];
    tabsToRender: IMainTab[] = [];
    expansion = [];
    arrayCounts: IConnectedCount;
    eolApplianceCount: number;
    showEolApplianceBadge = false;
    showEolOrEoscApplianceBadge = false;
    eolOrEoscApplianceCount: number;
    eolOrEoscApplianceTooltip: string;
    eoscApplianceCount: number;
    showArrayCountBadge = false;
    expiredSupportContractCount: number;
    mpaRequestPendingCount: number;
    openAlertsCount: number = 0;
    alertsEmphasisBadge: BadgeEmphasis;
    activeTabId: string;
    currentUser: CurrentUser = null;

    isNotificationPopupOpen = false;
    isNotificationPopupOpenTimeoutId: number;
    isFFCopilotEnabled$: Observable<boolean>;
    isVmAssessmentsBetaEnabled$: Observable<boolean>;
    isEOSCEnabled = false;

    proRecOpaCheck = false;
    hasProRecFeature = false;
    recommendationCount: number;
    isOrgSwitcherReadonly = false;
    activeNavigation: 'pure1' | 'draas' = 'pure1';
    navigationCollapsedByRoute = false;
    activeDraasClusterId: string | null = null;

    /** The "fullscreen" is defined on the router, and is used by the custom idp config validation (eg: https://localhost:8443/administration/customidp/testresults) */
    @HostBinding('class.full-screen') fullScreen = false;
    @HostBinding('class.export-overlay-enabled') hasActiveExports = false;

    private unsubscribeTransitionsOnSuccess: Function;

    private readonly destroy$ = new Subject<void>();

    protected readonly ORG_SWITCHING_FF = FeatureNames.ORG_SWITCHING;

    constructor(
        private touService: TouService,
        public supportCaseOrchestrator: SupportCaseOrchestratorService,
        private cachedCurrentUserService: CachedCurrentUserService,
        @Inject(WINDOW) private window: Window,
        private featureFlagDxpService: FeatureFlagDxpService,
        private router: Router,
        private route: ActivatedRoute,
        private tabsService: TabsService,
        private unifiedArrayService: UnifiedArrayService,
        private mpaRequestsBadgeService: MpaRequestsBadgeService,
        public notificationCenterCounterService: NotificationCenterCounterService,
        private navMenuIdPipe: NavMenuIdPipe,
        private authorizationService: AuthorizationService,
        private incidentService: IncidentService,
        private copilotOnboardingModalService: CopilotOnboardingService,
        private alertsCountService: AlertsCountService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.cachedCurrentUserService
            .get()
            .pipe(take(1))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
            });

        // Slightly delay a lot of init calls to give a chance to the main content to render before counts, reports, …
        this.window.setTimeout(() => {
            this.touService.run().subscribe();
        }, FIRST_LOAD_DELAY);

        this.isFFCopilotEnabled$ = this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.COPILOT)
            .pipe(map(policy => policy?.enabled === true));

        this.isVmAssessmentsBetaEnabled$ = this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.VM_ASSESSMENT_BETA_BANNER)
            .pipe(map(feature => feature?.enabled === true));

        this.featureFlagDxpService.getFeatureFlag(FeatureNames.EOC).subscribe(feature => {
            this.isEOSCEnabled = feature?.enabled;
        });

        const logCenterEnabled = await firstValueFrom(
            this.featureFlagDxpService.getFeatureFlag(FeatureNames.LOG_CENTER).pipe(
                take(1),
                map(x => x.enabled),
            ),
        );

        const menu = cloneDeep(MENU);

        if (logCenterEnabled) {
            const messages = menu.find(x => x.path === '/messages');
            if (messages) {
                const auditLog = messages.items.find(x => x.path === '/messages/auditlog');
                auditLog.path = { type: 'app', appId: 'log-center' };

                const sessionLog = messages.items.find(x => x.path === '/messages/sessions');
                sessionLog.path = { type: 'app', appId: 'log-center' };
            }
        }

        // Load the tabs
        const pipe = new NavMenuIdPipe();
        this.tabsService
            .build$(menu)
            .pipe(takeUntil(this.destroy$))
            .subscribe(tabs => {
                this.tabs = [...tabs];
                this.updateRenderedTabs();
                this.updateActiveTabs();
                this.expansion = tabs.map(tab => pipe.transform(tab));
            });

        // To support browser navigation and such, we don't rely on the sidenav's output event
        // to tell us what tab is active. Instead, hook into the router events to update the active tab id on route change.
        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.updateActiveTabs());

        // Regularly update connected arrays and expired support contract counts
        const requestFields: getArraysFields[] = [
            'appliance_id',
            'is_current',
            'last_updated',
            'contract_status',
            'evergreen_tier',
            'has_end_of_life_hardware',
        ]; // Only request the subset of fields that we need

        smartTimer(0, moment.duration(30, 'seconds').asMilliseconds())
            .pipe(
                exhaustMap(() =>
                    recursiveListItems(
                        this.unifiedArrayService,
                        {
                            fields: requestFields,
                            supportStatusFilterOption: 'include_arrays_in_courtesy_period',
                        },
                        'appliance_id',
                        array => array.appliance_id,
                    ).pipe(
                        catchError(err => {
                            console.warn('Sidenav: failed to update arrays for counts', err);
                            return of(null);
                        }),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(result => {
                this.updateSummaryBadges(result);
            });

        // Proactive recommendations
        combineLatest([
            this.featureFlagDxpService.getFeatureFlag(FeatureNames.PROACTIVE_RECOMMENDATIONS),
            this.authorizationService.isAllowed('PURE1:read:proactive_recommendations'),
        ])
            .pipe(
                tap(([_, isAllowed]) => {
                    this.proRecOpaCheck = isAllowed;
                }),
                map(([feature, _]) => feature),
                filter(feature => feature?.enabled === true),
                switchMap(() => {
                    this.hasProRecFeature = true;
                    // If user has access to ProRec, query IncidentService
                    if (!this.proRecOpaCheck) {
                        return of(void 0);
                    }
                    return this.getOpenIncidentCount();
                }),
                takeUntil(this.destroy$),
            )
            .subscribe(data => {
                if (data != null) {
                    this.recommendationCount = data;
                }
            });

        // alerts badge logic
        this.alertsCountService
            .getOpenAlerts()
            .pipe(takeUntil(this.destroy$))
            .subscribe(alerts => {
                alerts.response.forEach(item => {
                    this.openAlertsCount = this.openAlertsCount + item.count;
                    if (item.currentSeverity === 'critical') {
                        this.alertsEmphasisBadge = 'major';
                    } else if (item.currentSeverity === 'warning') {
                        if (this.alertsEmphasisBadge !== 'major') {
                            this.alertsEmphasisBadge = 'warning';
                        }
                    } else if (item.currentSeverity === 'info') {
                        if (this.alertsEmphasisBadge !== 'major' && this.alertsEmphasisBadge !== 'warning') {
                            this.alertsEmphasisBadge = 'info';
                        }
                    }
                });
            });

        // Update mpa count
        this.mpaRequestsBadgeService.loadFreshMpaRequests();
        this.mpaRequestsBadgeService.mpaPendingRequests$.pipe(takeUntil(this.destroy$)).subscribe(requests => {
            this.mpaRequestPendingCount = requests.length;
        });

        // The NavigationSkipped event is here due to CLOUD-124234. This may be a bug in the interaction with the Angular Router and UIRouter.
        // Having the NavigationSkipped here doesn't actually hurt anything since we always updated based on the current state, it just
        // means we update more than we might need to otherwise. It may no longer be needed once we fully remove UIRouter.
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(e => e instanceof NavigationEnd || e instanceof NavigationSkipped),
            )
            .subscribe((e: NavigationEnd | NavigationSkipped) => this.onRouteChangeEnd(e));

        // Kick off initial callback since router.events won't trigger on the first page load.
        // Without this, the page title / helpUrl won't get set correctly on page load.
        this.onRouteChangeEnd(null);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        if (this.unsubscribeTransitionsOnSuccess) {
            this.unsubscribeTransitionsOnSuccess();
        }
    }

    onActiveExportsChanged(active: boolean): void {
        this.hasActiveExports = active;
    }

    handleNotificationOpenChange(isOpen: boolean): void {
        // We want to keep the NotificationPopupComponent out of the DOM if its not visible
        // since that component also has internal timers that are pointless if the popup is not visible.
        if (isOpen) {
            this.isNotificationPopupOpen = true;
            // Ensure we don't have an old timeout still going
            this.window.clearTimeout(this.isNotificationPopupOpenTimeoutId);
        } else {
            // We need to remove the component on a timeout to ensure the animation has time to finish before removing from the DOM
            this.isNotificationPopupOpenTimeoutId = this.window.setTimeout(() => {
                this.isNotificationPopupOpen = false;
            }, 500);
        }
    }

    handleAIBotOpenChange(isOpen: boolean): void {
        // We treat the AI button as a link only. So when it is clicked, immediately hide
        // the popover panel, and redirect.
        if (isOpen) {
            this.window.setImmediate(() => {
                // A setImmediate() is needed to prevent a (seemingly harmless) warning: "NG0911: View has already been destroyed."
                this.appHeaderComponent.hideAiBotPopUp();
            });
        }

        // Don't do anything if we're already on the AI page
        if (this.router.url.split('?')[0]?.includes('/copilot')) {
            return;
        }

        const keywordSuggestion = this.copilotOnboardingModalService.getSuggestionKeywordFromUrl(
            this.router.url.split('?')[0],
        );

        // If user hasn't accessed AI through the modal dialog button yet, show the modal dialog first, so they access it that way
        if (this.copilotOnboardingModalService.shouldShowOnboardingModal()) {
            this.copilotOnboardingModalService.showOnboardingModal();
        } else {
            this.router.navigate(['/copilot'], { queryParams: { suggestion: keywordSuggestion } });
        }
    }

    openMenuLinkNewTab(url: string): void {
        this.window.open(url, '_blank');
    }

    closeNotificationPopup(): void {
        this.appHeaderComponent.hideNotificationsPopUp();
    }

    handleClickLogo(): void {
        this.router.navigate(['/']);
    }

    handleClickProRecIcon(): void {
        // We don't actually want the menu popover, we just have to have it for Hive. So hide it immediately.
        this.prorecDummyMenuElem.hideOverlay();

        this.router.navigate(['/services/servicecatalog/recommendationsview']);
    }

    /**
     * This determines which tabs will be rendered based on the current active navigation.
     */
    updateRenderedTabs(): void {
        // DRaaS cluster-specific routes contain the cluster ID in the URL.
        // We need to extract it in order to render the submenu tabs' paths correctly.
        if (this.router.url.startsWith('/draas/deployments/c')) {
            this.activeDraasClusterId = this.router.url.split('/')[3];
        } else {
            this.activeDraasClusterId = null;
        }

        switch (this.activeNavigation) {
            case 'draas':
                // DRaaS submenu paths need to be rendered dynamically based on the active cluster ID
                this.tabsToRender = this.tabs
                    .filter(tab => tab.submenu === 'draas')
                    .map(tab => ({
                        ...tab,
                        path: tab.path.replace(':clusterId', this.activeDraasClusterId),
                        subTabs: tab.subTabs.map(subTab => ({
                            ...subTab,
                            path: subTab.path.replace(':clusterId', this.activeDraasClusterId),
                        })),
                    }));
                break;

            case 'pure1':
            default:
                this.tabsToRender = this.tabs.filter(tab => tab.submenu === undefined);
                break;
        }
    }

    /**
     * This handles Angular router navigation
     */
    private onRouteChangeEnd(e: NavigationEnd | NavigationSkipped): void {
        let currentRoute = this.route.root;
        const { snapshot } = currentRoute;
        let data = snapshot.data;
        this.pageTitle = snapshot.title;
        while (currentRoute.children[0] != null) {
            currentRoute = currentRoute.children[0];
            if (currentRoute.snapshot) {
                if (currentRoute.snapshot.title) {
                    this.pageTitle = currentRoute.snapshot.title;
                }
                data = {
                    ...data,
                    ...currentRoute.snapshot.data,
                };
            }
        }

        if (data.pageHelpUrlHidingFeatureFlag) {
            this.featureFlagDxpService.getFeatureFlag(data.pageHelpUrlHidingFeatureFlag).subscribe(feature => {
                if (!feature || feature.enabled !== true) {
                    this.pageHelpUrl = data.pageHelpUrl;
                }
            });
        } else {
            this.pageHelpUrl = data.pageHelpUrl;
        }

        if (data.collapseNavigation === true) {
            this.navigationCollapsedByRoute = true;
            this.pageBaseLayoutComponent.navigationOpen = false;
        } else if (this.navigationCollapsedByRoute === true) {
            // If coming from a page where we auto-collapse the sidenav, then re-expand it. This will re-expand it
            // even if the collapse was due to some other reason, like shrinking the browser width, so we only want
            // to trigger this on pages where it is auto-collapsed.
            this.pageBaseLayoutComponent.navigationOpen = true;
            this.navigationCollapsedByRoute = false;
        }

        this.fullScreen = data.fullScreen === true;

        switch (data.navSubmenu) {
            case 'draas':
                this.activeNavigation = 'draas';
                break;
            default:
                this.activeNavigation = 'pure1';
        }

        this.updateRenderedTabs();
    }

    private updateSummaryBadges(arrays: UnifiedArray[]): void {
        if (arrays == null) {
            return; // If null due to error / invalid data, don't change anything
        }

        // Update counts
        if (!this.isEOSCEnabled) {
            this.expiredSupportContractCount = arrays.filter(
                array =>
                    (array.subscription_type === SubscriptionType.FOUNDATION ||
                        array.subscription_type === SubscriptionType.FOREVER) &&
                    array.contract_status === ArrayContractStatus.EXPIRED,
            ).length;
        }
        this.eolApplianceCount = arrays.filter(array => array.has_end_of_life_hardware).length;
        if (this.isEOSCEnabled) {
            this.eoscApplianceCount = arrays.filter(
                array => array.contract_status === ArrayContractStatus.EXPIRED,
            ).length;
            const eolOrEoscApplianceCount = arrays.filter(
                array => array.has_end_of_life_hardware || array.contract_status === ArrayContractStatus.EXPIRED,
            ).length;
            if (this.eolApplianceCount > 0 && this.eoscApplianceCount > 0) {
                this.eolOrEoscApplianceCount = eolOrEoscApplianceCount;
                this.eolOrEoscApplianceTooltip = `where the hardware is going End of Life or Evergreen Subscription Support Contract has expired.`;
            } else if (this.eoscApplianceCount > 0) {
                this.eolOrEoscApplianceCount = this.eoscApplianceCount;
                this.eolOrEoscApplianceTooltip = `where Evergreen Subscription Support Contract has expired.`;
            } else {
                this.eolOrEoscApplianceCount = this.eolApplianceCount;
                this.eolOrEoscApplianceTooltip = `where the hardware is going End of Life.`;
            }
        }

        const connectedArrays = arrays.filter(array => array.isCurrent);
        this.arrayCounts = {
            connected: connectedArrays.length,
            disconnected: arrays.length - connectedArrays.length,
        };

        // For Appliances, we have two badges, but only space to show one at a time.
        // If a user has both the EOL/EOSC(if EOSC feature flag enabled) and Array Count
        // badges needing to be shown, only show one of them, but cycle through which one gets shown, with the EOL/EOSC shown first.
        let newShowEolApplianceBadge = this.eolApplianceCount > 0;
        let newShowArrayCountBadge = this.arrayCounts.disconnected > 0;

        //If EOSC feature flag is enabled, show the EOL/EOSC badge based on whether the user has EOL or EOSC appliances.
        if (!this.isEOSCEnabled) {
            if (newShowEolApplianceBadge && newShowArrayCountBadge) {
                if (this.showEolApplianceBadge) {
                    newShowEolApplianceBadge = false;
                } else {
                    newShowArrayCountBadge = false;
                }
            }
        } else {
            let newshowEolOrEoscApplianceBadge = this.eolOrEoscApplianceCount > 0;
            if (newshowEolOrEoscApplianceBadge && newShowArrayCountBadge) {
                if (this.showEolOrEoscApplianceBadge) {
                    newshowEolOrEoscApplianceBadge = false;
                } else {
                    newShowArrayCountBadge = false;
                }
            }
            this.showEolOrEoscApplianceBadge = newshowEolOrEoscApplianceBadge;
        }
        this.showEolApplianceBadge = newShowEolApplianceBadge;
        this.showArrayCountBadge = newShowArrayCountBadge;
    }

    private updateActiveTabs(): void {
        const isActiveParams: IsActiveMatchOptions = {
            matrixParams: 'ignored',
            paths: 'subset',
            queryParams: 'ignored',
            fragment: 'ignored',
        };

        const activeTab = flatMap(this.tabs.map(tab => tab.subTabs))
            .concat(this.tabs)
            .filter(tab => tab?.path != null)
            .find(tab => {
                if (
                    tab.path === '/services/servicecatalog/main' &&
                    this.router.isActive('/services/servicecatalog/details', isActiveParams)
                ) {
                    // Highlight the main tab when on the details page
                    return true;
                } else if (this.router.url.startsWith('/draas/deployments/c')) {
                    // DRaaS pages that are cluster-specific contain the cluster ID in the URL.
                    // In order to highlight the correct tab, we need to replace the placeholder with the actual cluster ID when checking if the tab is active.
                    // DRaaS cluster ID always starts with 'c'.
                    return (
                        tab.path.startsWith('/draas/deployments/:clusterId') &&
                        this.router.isActive(tab.path.replace(':clusterId', this.activeDraasClusterId), isActiveParams)
                    );
                } else {
                    return this.router.isActive(tab.path, isActiveParams);
                }
            });

        this.activeTabId = this.navMenuIdPipe.transform(activeTab);

        this.isOrgSwitcherReadonly =
            ORG_SWITCHER_READONLY_TAB_IDS.includes(this.activeTabId) ||
            this.router.isActive('/notifications', isActiveParams);
    }

    private getOpenIncidentCount(): Observable<number> {
        return smartTimer(0, PROACTIVE_RECOMMENDATIONS_REFRESH_INTERVAL_MS).pipe(
            exhaustMap(() =>
                this.incidentService.list().pipe(
                    take(1),
                    catchError(err => {
                        console.error('Failed to update incident count', err);
                        return of(null);
                    }),
                ),
            ),
            map(data => data?.total),
        );
    }

    protected handleIconButtonClick(item: string): void {
        const events: Record<string, () => void> = {
            help: ampli.helpClicked,
            notifications: ampli.notificationsClicked,
            appSwitch: ampli.appSwitcherClicked,
        };

        if (events[item]) {
            events[item].call(ampli);
        }
    }

    protected handleNavigationOpenChange(opened: boolean): void {
        if (opened) {
            ampli.expandLeftSideNavigationClicked();
        } else {
            ampli.collapseLeftSideNavigationClicked();
        }
    }

    protected handleHelpItemClick(item: string): void {
        if (item === 'help with this page') {
            ampli.helpClicked({
                'help item': item,
                'help path': this.router.url,
            });
        } else {
            ampli.helpClicked({
                'help item': item,
            });
        }
    }
}
