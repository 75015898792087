import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { UniversalCreditsTransaction } from '../models/universal-credits-transaction';

const UNIVERSAL_CREDITS_TRANSACTION_ENDPOINT = '/rest/v1/universal-credits/transactions';

@Injectable({ providedIn: 'root' })
export class UniversalCreditsTransactionService extends GenericService<UniversalCreditsTransaction> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: UniversalCreditsTransaction,
            endpoint: UNIVERSAL_CREDITS_TRANSACTION_ENDPOINT,
        });
    }
}
