<div class="table-fixed-header-container" [ngClass]="{ 'is-loading': isLoading }">
    <table
        class="table table-bordered table-fixed-header table-hover"
        [ngClass]="{ 'not-first-col': multiSelect }"
        [pureshadowscroll]="{ scrollElem: 'tbody', shadowElem: 'thead' }"
    >
        <!-- Head -->
        <thead class="thead-default">
            <tr class="multiline">
                <!-- Select column -->
                <ng-container *ngIf="multiSelect">
                    <th class="checkbox-col">
                        <div class="column-group-heading">
                            <div class="column-group-title">&nbsp;</div>
                        </div>
                        <div class="column-headings">
                            <div class="column-heading" container="body">
                                <input
                                    type="checkbox"
                                    id="check_all"
                                    class="select-all-check"
                                    (click)="clickSelectAllCheckbox($event)"
                                    [hidden]="isReadonly"
                                    [disabled]="areAnyItemsDisabled()"
                                    #selectAllCheckbox
                                />
                            </div>
                        </div>
                    </th>
                </ng-container>

                <ng-container *ngFor="let columnOption of columnOptions">
                    <th
                        *ngIf="!columnOption.isHidden"
                        class="{{
                            getHeaderColumnWeight(columnOption) == null
                                ? ''
                                : 'weight-' + getHeaderColumnWeight(columnOption)
                        }}"
                        [attr.colspan]="getHeaderColumnColspan(columnOption)"
                        [ngClass]="{
                            'text-xs-center': hasSubColumns(columnOption) || columnOption.centerText,
                            'has-search': columnOption.hasSearch,
                        }"
                    >
                        <div class="column-group-heading" *ngIf="subColumnsAreExpanded(columnOption)">
                            <div
                                class="column-group-title"
                                [ngClass]="sortClass(columnOption)"
                                (click)="toggleSort(columnOption)"
                            >
                                <span
                                    *ngIf="!isTemplateRef(columnOption.title); else displayTemplate"
                                    [innerHTML]="columnOption.title"
                                ></span>
                                <ng-template #displayTemplate>
                                    <ng-container *ngTemplateOutlet="$any(columnOption.title)"></ng-container>
                                </ng-template>
                                <div
                                    class="table-subcolumns-expand-collapse-arrow"
                                    *ngIf="columnOption.subColumns && columnOption.collapsedWeight"
                                    (click)="toggleSubColumns(columnOption)"
                                    ><span class="fa fa-caret-left"></span
                                ></div>
                            </div>
                            <div class="column-subtitle" *ngIf="columnOption.subtitle">
                                <span
                                    *ngIf="!isTemplateRef(columnOption.subtitle); else displayTemplateSubTitle"
                                    [innerHTML]="columnOption.subtitle"
                                ></span>
                                <ng-template #displayTemplateSubTitle>
                                    <ng-container *ngTemplateOutlet="$any(columnOption.subtitle)"></ng-container>
                                </ng-template>
                            </div>
                        </div>
                        <div
                            class="column-group-heading"
                            *ngIf="!subColumnsAreExpanded(columnOption) && columnOption.collapsedColumnOption"
                        >
                            <div
                                class="column-group-title"
                                [ngClass]="sortClass(columnOption.collapsedColumnOption)"
                                (click)="toggleSort(columnOption.collapsedColumnOption)"
                            >
                                <span
                                    *ngIf="
                                        !isTemplateRef(columnOption.collapsedColumnOption.title);
                                        else displayTemplate
                                    "
                                    [innerHTML]="columnOption.collapsedColumnOption.title"
                                ></span>
                                <ng-template #displayTemplate>
                                    <ng-container
                                        *ngTemplateOutlet="$any(columnOption.collapsedColumnOption.title)"
                                    ></ng-container>
                                </ng-template>
                                <div
                                    class="table-subcolumns-expand-collapse-arrow"
                                    *ngIf="columnOption.subColumns && columnOption.collapsedWeight"
                                    (click)="toggleSubColumns(columnOption)"
                                    ><span class="fa fa-caret-right"></span
                                ></div>
                            </div>
                            <div class="column-subtitle" *ngIf="columnOption.collapsedColumnOption.subtitle">
                                <span
                                    *ngIf="
                                        !isTemplateRef(columnOption.collapsedColumnOption.subtitle);
                                        else displayTemplateSubTitle
                                    "
                                    [innerHTML]="columnOption.collapsedColumnOption.subtitle"
                                ></span>
                                <ng-template #displayTemplateSubTitle>
                                    <ng-container
                                        *ngTemplateOutlet="$any(columnOption.collapsedColumnOption.subtitle)"
                                    ></ng-container>
                                </ng-template>
                            </div>
                        </div>
                        <div
                            class="column-headings"
                            *ngIf="
                                (hasSubColumns(columnOption) && areSubColumnsExpanded(columnOption)) ||
                                columnOption.hasSearch
                            "
                        >
                            <!-- search box -->
                            <div class="column-heading" *ngIf="columnOption.hasSearch">
                                <input
                                    type="search"
                                    class="form-control"
                                    [ngModel]="getTableHeaderFilter(columnOption.searchItem, columnOption.key)"
                                    (debounceChange)="onSearchChange(columnOption.key, $event, columnOption.searchItem)"
                                />
                            </div>
                            <!-- sub columns -->
                            <div
                                class="column-heading text-xs-center"
                                *ngFor="let subColumnOption of columnOption.subColumns"
                            >
                                <div
                                    class="column-title"
                                    *ngIf="!subColumnOption.isHidden"
                                    [ngClass]="sortClass(subColumnOption)"
                                    (click)="clickSubColumnTitle($event, subColumnOption)"
                                >
                                    <span
                                        *ngIf="!isTemplateRef(subColumnOption.title); else displayTemplate"
                                        [innerHTML]="subColumnOption.title"
                                    ></span>
                                    <ng-template #displayTemplate>
                                        <ng-container *ngTemplateOutlet="$any(subColumnOption.title)"></ng-container>
                                    </ng-template>
                                </div>
                                <div class="column-subtitle" *ngIf="subColumnOption.subtitle">
                                    <span
                                        *ngIf="!isTemplateRef(subColumnOption.subtitle); else displayTemplateSubTitle"
                                        [innerHTML]="subColumnOption.subtitle"
                                    ></span>
                                    <ng-template #displayTemplateSubTitle>
                                        <ng-container *ngTemplateOutlet="$any(subColumnOption.subtitle)"></ng-container>
                                    </ng-template>
                                </div>
                                <!-- sort params dropdown -->
                                <ul
                                    class="list-group"
                                    *ngIf="
                                        showSortParamsDropdown && subColumnOption.key === sortParamsDropdownColumnKey
                                    "
                                    #sortParamsDropdown
                                >
                                    <li
                                        *ngFor="
                                            let sortParam of subColumnOption.getSortParams();
                                            trackBy: 'id' | trackByProperty
                                        "
                                        [ngClass]="{
                                            highlighted:
                                                sort.key &&
                                                sort.key === sortParam.key &&
                                                sort.order === sortParam.order,
                                        }"
                                        (click)="clickSortParam(sortParam)"
                                        class="list-group-item list-group-item-action"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="$any(subColumnOption.sortParamsMapping(sortParam))"
                                        ></ng-container>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </th>
                </ng-container>
            </tr>

            <!-- Select all filtered -->
            <tr *ngIf="showSelectAllFilteredMessage">
                <th class="select-all-filtered" [ngClass]="{ 'is-loading': getSelectAllFilteredItems$ != null }">
                    <span
                        class="select-all-filtered-message"
                        [ngClass]="{ 'exceeds-limit': exceedsSelectAllFilteredLimit() }"
                    >
                        <!-- Initial message / fetch request in progress -->
                        <ng-container *ngIf="!isSelectingAllFiltered; else selectingAllFilteredTemplate">
                            All
                            {{
                                tableOptions.selectAllFilteredOptions.getFilteredItemCountInCurrentPage?.() ||
                                    items.length
                            }}
                            {{ tableOptions.selectAllFilteredOptions.itemName }} on this page selected.
                            <a
                                #tooltip="ngbTooltip"
                                (click)="clickSelectAllFiltered($event, tooltip)"
                                ngbTooltip="We have reduced the max number of  {{
                                    tableOptions.selectAllFilteredOptions.itemName
                                }} you can simulate to {{
                                    tableOptions?.maxSelectionOption?.maximumSelection
                                }}. Please refine your filters."
                                [disableTooltip]="!exceedsSelectAllFilteredLimit()"
                                [openDelay]="0"
                                container="body"
                                angulartics2On="click"
                                angularticsAction="Performance Table - Select All Filtered - Click select all"
                                [angularticsLabel]="
                                    selectAllFilteredCount + ' ' + tableOptions.selectAllFilteredOptions.itemName
                                "
                            >
                                Select all {{ selectAllFilteredCount | number: '1.0' }} filtered
                                {{ tableOptions.selectAllFilteredOptions.itemName }}.
                                <i class="exceeds-limit-icon fa fa-warning text-warning"></i>
                            </a>
                            <pureui-spinner [pureuiShow]="getSelectAllFilteredItems$ != null"></pureui-spinner>
                        </ng-container>

                        <!-- All items selected (fetch request complete) -->
                        <ng-template #selectingAllFilteredTemplate>
                            All {{ selection.length | number: '1.0' }} filtered
                            {{ tableOptions.selectAllFilteredOptions.itemName }} selected.
                            <a
                                (click)="clickClearSelection($event)"
                                angulartics2On="click"
                                angularticsAction="Performance Table - Select All Filtered - Click clear selection"
                                [angularticsLabel]="
                                    selection.length + ' ' + tableOptions.selectAllFilteredOptions.itemName
                                "
                            >
                                Clear selection.
                            </a>
                        </ng-template>
                    </span>
                </th>
            </tr>
        </thead>

        <!-- Body -->
        <tbody>
            <ng-container *ngFor="let item of items; trackBy: 'id' | trackByProperty">
                <tr
                    (click)="selectResource($event, item)"
                    [ngClass]="getTableRowClassArray(item)"
                    [class.table-active]="isItemSelected(item)"
                >
                    <!-- Selected cell -->
                    <ng-container *ngIf="multiSelect">
                        <td class="checkbox-col" container="body">
                            <input
                                type="checkbox"
                                data-id="check_one"
                                class="cell-text"
                                [checked]="isItemSelected(item)"
                                [hidden]="isReadonly"
                                [disabled]="isItemDisabled(item)"
                            />
                        </td>
                    </ng-container>

                    <ng-container *ngFor="let tableColumn of columns; trackBy: 'key' | trackByProperty">
                        <!-- Value cell -->
                        <td
                            *ngIf="
                                !tableColumn.isHidden && (!isItemUnavailable(item) || tableColumn.showWhenUnavailable)
                            "
                            [ngClass]="getDataRowWeightClass(tableColumn)"
                            [class.text-xs-center]="tableColumn.centerText"
                            [class.table-center-vertical]="tableColumn.centerVertical"
                            (click)="customRowClick($event, item)"
                        >
                            <div *ngIf="!isItemDisabled(item)" class="value-cell-data">
                                <span
                                    class="cell-text"
                                    *ngIf="!isTemplateRef(tableColumn.getText(item)); else displayTemplate"
                                >
                                    {{ tableColumn.getText(item) }}
                                </span>
                                <ng-template #displayTemplate>
                                    <ng-container
                                        *ngTemplateOutlet="$any(tableColumn.getText(item)); context: { item: item }"
                                    ></ng-container>
                                </ng-template>
                            </div>

                            <div
                                *ngIf="isItemDisabled(item)"
                                class="value-cell-data"
                                [ngbTooltip]="getDisabledTooltipMessage()"
                                placement="top"
                                container="body"
                            >
                                <span
                                    class="cell-text"
                                    *ngIf="!isTemplateRef(tableColumn.getText(item)); else displayTemplate"
                                >
                                    {{ tableColumn.getText(item) }}
                                </span>
                                <ng-template #displayTemplate>
                                    <ng-container
                                        *ngTemplateOutlet="$any(tableColumn.getText(item)); context: { item: item }"
                                    ></ng-container>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <td
                        *ngIf="isItemUnavailable(item) && getColumnUnavailableSpan() !== 0"
                        [attr.colspan]="getColumnUnavailableSpan()"
                        class="outdated-message-wrapper {{ 'weight-' + getColumnUnavailableSpan() }}"
                    >
                        <div class="outdated-message">
                            <span
                                *ngIf="!isTemplateRef(getUnavailableTemplate(item)); else displayTemplate"
                                [innerHTML]="getUnavailableTemplate(item)"
                            ></span>
                            <ng-template #displayTemplate>
                                <ng-container
                                    *ngTemplateOutlet="$any(getUnavailableTemplate(item)); context: { item: item }"
                                ></ng-container>
                            </ng-template>
                        </div>
                    </td>
                </tr>

                <!--optional secondary row to render anything below a given row (id)-->
                <tr *ngIf="visibleSecondaryRowIds?.has(item.id)" class="secondary-row">
                    <td>
                        <ng-container *ngTemplateOutlet="secondaryRowTemplate; context: { item: item }"></ng-container>
                    </td>
                </tr>
            </ng-container>

            <tr class="no-results-row" *ngIf="!items || items.length === 0">
                <td>
                    <span *ngIf="items != null">
                        <span *ngIf="!isTemplateRef(tableOptions?.noResultsMessage); else noResultsTemplate">{{
                            tableOptions?.noResultsMessage || 'No results found. Please adjust your filters.'
                        }}</span>
                        <ng-template #noResultsTemplate>
                            <ng-container *ngTemplateOutlet="$any(tableOptions?.noResultsMessage)"></ng-container>
                        </ng-template>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <pureui-spinner class="performance-table-spinner" [pureuiShow]="isLoading"></pureui-spinner>
</div>
