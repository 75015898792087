import { combineReducers } from 'redux';
import {
    ADD_FILTER,
    REMOVE_FILTERS,
    REMOVE_ALL_FILTERS,
    SWAP_ALL_FILTERS,
    COPY_ALL_FILTERS,
    ADD_SORT,
    REMOVE_SORTS,
    REMOVE_ALL_SORTS,
    ADD_PAGINATION,
    REMOVE_PAGINATION,
    ADD_TIME,
    REMOVE_TIMES,
    REMOVE_ALL_TIMES,
    ADD_AGGREGATION_METHOD,
    REMOVE_AGGREGATION_METHOD,
    ADD_METRIC_SERIES,
    REMOVE_METRIC_SERIES,
    REMOVE_ALL_METRIC_SERIES,
    ADD_SELECTION,
    REMOVE_SELECTION,
    REMOVE_ALL_SELECTION,
    SWAP_ALL_SELECTION,
    ADD_CLUSTER,
    REMOVE_CLUSTER,
    ADD_METRIC_TYPE,
    REMOVE_METRIC_TYPE,
    SET_RESIZER_SIZE,
    ADD_CAPACITY_FULL_METHOD,
    REMOVE_CAPACITY_FULL_METHOD,
    ADD_FOCUS,
    REMOVE_FOCUS,
    ADD_GROUP_SELECTION,
    REMOVE_GROUP_SELECTION,
    ADD_TAB,
    REMOVE_TAB,
    IActionFilter,
    IActionSort,
    IActionPagination,
    IActionMetricSeries,
    IActionCluster,
    IActionMetricType,
    IActionAggregationMethod,
    IActionTime,
    IActionSelection,
    IActionResizer,
    IActionCapacityFullMethod,
    IActionGroupSelection,
    IActionEntityFocus,
    IActionFleetType,
    ADD_FLEET_TYPE,
    REMOVE_FLEET_TYPE,
    IActionTab,
    IActionEdgeServiceAgentCardsCollapseState,
    ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE,
    REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE,
    IActionPanelTab,
    ADD_PANEL_SELECTION,
    REMOVE_PANEL_SELECTION,
    REMOVE_FILTERS_BY_KEY,
    ADD_STEPUP_TOKEN,
    REMOVE_EXPIRED_STEPUP_TOKENS,
    REMOVE_ALL_STEPUP_TOKENS,
    IActionStepUpToken,
    IActionItemType,
    ADD_ITEM_TYPE,
    REMOVE_ITEM_TYPE,
    SWAP_ITEM_TYPE,
    DRAAS_SELECT_POLICY,
    DRAAS_DESELECT_POLICY,
    DRAAS_SELECT_PLAN,
    DRAAS_DESELECT_PLAN,
    IActionDisasterRecoverySelectPolicy,
    IActionDisasterRecoverySelectPlan,
    IActionDisasterRecoverySelectGroupsView,
    DRAAS_SELECT_GROUPS_VIEW,
    DRAAS_SELECT_CLUSTER,
    DRAAS_TOGGLE_ITEM,
    IActionDisasterRecoveryToggleItem,
    IActionDisasterRecoveryFilter,
    DRAAS_FILTER_VMS,
    DRAAS_FILTER_VM_JOB_EXECUTIONS,
    IActionToggleDoNotShowModal,
    TOGGLE_DO_NOT_SHOW_MODAL,
    IActionPerformanceAlert,
    IActionDisasterRecoveryCluster,
    DRAAS_DESELECT_CLUSTER,
    IActionDisasterRecoverySelectReportType,
    DRAAS_SELECT_REPORT_TYPE,
    IActionDisasterRecoverySelectReport,
    DRAAS_SELECT_REPORT,
    IActionDisasterRecoverySelectInventoryView,
    DRAAS_SELECT_INVENTORY_VIEW,
    IActionSetPureProtectFileId,
    SET_PURE_PROTECT_FILE_ID,
    IActionSetPureProtectSiteId,
    SET_PURE_PROTECT_SITE_ID,
    IActionSetPureProtectOpportunityId,
    SET_PURE_PROTECT_OPPORTUNITY_ID,
    IActionSetPureProtectAdminView,
    SET_PURE_PROTECT_ADMIN_VIEW,
} from './actions';
import {
    IStateFilter,
    IStateTime,
    IStateFiltersSlice,
    IStateSortsSlice,
    IStatePaginationSlice,
    IStateMetricSeriesSlice,
    IStateClusterSlice,
    IStateMetricTypeSlice,
    IStateAggregationMethodSlice,
    IStateTimeSlice,
    IStateSelectionSlice,
    IStateResizerSlice,
    IStateCapacityFullMethodSlice,
    IStateEntityFocusSlice,
    IStateGroupSelectionSlice,
    IStateFleetType,
    IStateTabSlice,
    IStateEdgeServiceAgentCardsCollapseState,
    IStateRegionSlice,
    IStatePanelTabSlice,
    IStateStepUpTokenSlice,
    IStateItemType,
    IStateDisasterRecoverySelectedPolicySlice,
    IStateDisasterRecoverySelectedPlanSlice,
    IStateDisasterRecoverySelectedGroupsViewSlice,
    IStateDisasterRecoveryClusterSlice,
    IStateDisasterRecoveryToggledItemsSlice,
    IStateDisasterRecoveryFiltersSlice,
    IStatePerformanceAlertId,
    IStateDisasterRecoverySelectedReportTypeSlice,
    IStateDisasterRecoverySelectedReportSlice,
    IStateDisasterRecoverySelectedInventoryViewSlice,
    IStatePureProtectFileId,
    IStatePureProtectOpportunityId,
    IStatePureProtectSiteId,
    IStatePureProtectAdminView,
} from './pure-redux.service';
import moment from 'moment';

const filterEquals = (filter1: IStateFilter, filter2: IStateFilter): boolean =>
    filter1.namespace === filter2.namespace &&
    filter1.key.toLocaleLowerCase() === filter2.key.toLocaleLowerCase() &&
    filter1.value.toLocaleLowerCase() === filter2.value.toLocaleLowerCase() &&
    filter1.entity === filter2.entity;

const timeEquals = (time1: IStateTime, time2: IStateTime): boolean =>
    time1.key.toLocaleLowerCase() === time2.key.toLocaleLowerCase() &&
    time1.value.toLocaleLowerCase() === time2.value.toLocaleLowerCase();

export function filterReducer(state: IStateFiltersSlice = {}, action: IActionFilter): IStateFiltersSlice {
    switch (action.type) {
        case ADD_FILTER: {
            const barFilters = state[action.barId];
            if (barFilters && barFilters.find(filter => filterEquals(filter, action.filter))) {
                return state;
            }
            const newState = Object.assign({}, state);
            const addedFilter = { ...action.filter };
            newState[action.barId] = (barFilters || []).concat([addedFilter]);

            return newState;
        }
        case REMOVE_FILTERS: {
            const barFilters = state[action.barId];
            if (!barFilters) {
                return state;
            }

            // Try to remove
            const newBarFilters = barFilters.filter(filter => !action.filterIds.includes(filter.id));

            // Nothing got removed
            if (newBarFilters.length === barFilters.length) {
                return state;
            }
            const newState = { ...state };

            if (newBarFilters.length === 0) {
                delete newState[action.barId];
            } else {
                newState[action.barId] = newBarFilters;
            }

            return newState;
        }
        case REMOVE_FILTERS_BY_KEY: {
            const barFilters = state[action.barId];
            if (!barFilters) {
                return state;
            } else {
                return {
                    ...state,
                    [action.barId]: barFilters.filter(f => !(f.namespace === action.namespace && f.key === action.key)),
                };
            }
        }
        case REMOVE_ALL_FILTERS: {
            const newState = { ...state };
            const barFilters = state[action.barId];
            if (!barFilters) {
                return state;
            }
            delete newState[action.barId];
            return newState;
        }
        case SWAP_ALL_FILTERS: {
            const newState = { ...state };
            const bar1Filters = state[action.barId1];
            newState[action.barId1] = newState[action.barId2];
            newState[action.barId2] = bar1Filters;

            return newState;
        }
        case COPY_ALL_FILTERS: {
            // this is a shallow copy and newState[action.barId2] and state[action.barId1] will point to the same object in memory.
            // however, redux stores/reads the states to/from localstorage of browser. Localstorage uses toString and json parser to save/read data, which makes the shallow copy to a deep copy.
            const newState = { ...state };
            newState[action.barId2] = state[action.barId1];

            return newState;
        }

        default:
            return state;
    }
}

export function sortReducer(state: IStateSortsSlice = {}, action: IActionSort): IStateSortsSlice {
    switch (action.type) {
        case ADD_SORT: {
            const barSorts = state[action.barId];
            if (barSorts && barSorts.find(sort => sort === action.sort)) {
                return state;
            }
            const newState = Object.assign({}, state);
            const addedSort = action.sort;
            newState[action.barId] = (barSorts || []).concat([addedSort]);
            return newState;
        }
        case REMOVE_SORTS: {
            const barSorts = state[action.barId];
            if (!barSorts) {
                return state;
            }
            // Try to remove
            const newBarSorts = barSorts.filter(sort => !action.names.includes(sort));

            // Nothing got removed
            if (newBarSorts.length === barSorts.length) {
                return state;
            }
            const newState = { ...state };

            if (newBarSorts.length === 0) {
                delete newState[action.barId];
            } else {
                newState[action.barId] = newBarSorts;
            }

            return newState;
        }
        case REMOVE_ALL_SORTS: {
            const newState = { ...state };
            const barSorts = state[action.barId];
            if (!barSorts) {
                return state;
            }
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function paginationReducer(state: IStatePaginationSlice = {}, action: IActionPagination): IStatePaginationSlice {
    switch (action.type) {
        case ADD_PAGINATION: {
            const barPaginations = state[action.barId];
            if (barPaginations && barPaginations === action.page) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.page;
            return newState;
        }
        case REMOVE_PAGINATION: {
            const barPaginations = state[action.barId];
            if (!barPaginations) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function metricSeriesReducer(
    state: IStateMetricSeriesSlice = {},
    action: IActionMetricSeries,
): IStateMetricSeriesSlice {
    switch (action.type) {
        case ADD_METRIC_SERIES: {
            let metricSeriesToAdd = [];
            const barMetricSeries = state[action.barId];
            if (barMetricSeries) {
                // remove existing metris from the adding list
                metricSeriesToAdd = action.metricSeries.filter(
                    metricsSeries => !barMetricSeries.includes(metricsSeries),
                );
                if (metricSeriesToAdd.length === 0) {
                    return state;
                }
            } else {
                metricSeriesToAdd = action.metricSeries;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = (barMetricSeries || []).concat(metricSeriesToAdd);
            return newState;
        }
        case REMOVE_METRIC_SERIES: {
            const barMetricSeries = state[action.barId];
            if (!barMetricSeries) {
                return state;
            }
            // Try to remove
            const newBarMetricSeries = barMetricSeries.filter(
                metricsSeries => !action.metricSeries.includes(metricsSeries),
            );

            // Nothing got removed
            if (newBarMetricSeries.length === barMetricSeries.length) {
                return state;
            }
            const newState = { ...state };

            if (newBarMetricSeries.length === 0) {
                delete newState[action.barId];
            } else {
                newState[action.barId] = newBarMetricSeries;
            }
            return newState;
        }
        case REMOVE_ALL_METRIC_SERIES: {
            const barMetricSeries = state[action.barId];
            if (!barMetricSeries) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function clusterReducer(state: IStateClusterSlice = {}, action: IActionCluster): IStateClusterSlice {
    switch (action.type) {
        case ADD_CLUSTER: {
            const barCluster = state[action.barId];
            if (barCluster && barCluster === action.cluster) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.cluster;
            return newState;
        }
        case REMOVE_CLUSTER: {
            const barCluster = state[action.barId];
            if (!barCluster) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function metricTypeReducer(state: IStateMetricTypeSlice = {}, action: IActionMetricType): IStateMetricTypeSlice {
    switch (action.type) {
        case ADD_METRIC_TYPE: {
            const barMetricType = state[action.barId];
            if (barMetricType && barMetricType === action.metricType) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.metricType;
            return newState;
        }
        case REMOVE_METRIC_TYPE: {
            const barMetricType = state[action.barId];
            if (!barMetricType) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function fleetTypeReducer(state: IStateFleetType = {}, action: IActionFleetType): IStateFleetType {
    switch (action.type) {
        case ADD_FLEET_TYPE: {
            const barFleetType = state[action.barId];
            if (barFleetType && barFleetType === action.fleetType) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.fleetType;
            return newState;
        }
        case REMOVE_FLEET_TYPE: {
            const barFleetType = state[action.barId];
            if (!barFleetType) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function itemTypeReducer(state: IStateItemType = {}, action: IActionItemType): IStateItemType {
    switch (action.type) {
        case ADD_ITEM_TYPE: {
            const barItemType = state[action.barId];
            if (barItemType && barItemType === action.itemType) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.itemType;
            return newState;
        }
        case REMOVE_ITEM_TYPE: {
            const barItemType = state[action.barId];
            if (!barItemType) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        case SWAP_ITEM_TYPE: {
            const newState = Object.assign({}, state);
            [newState[action.barId1], newState[action.barId2]] = [newState[action.barId2], newState[action.barId1]]; // just swapping
            return newState;
        }
        default:
            return state;
    }
}

export function edgeServiceAgentCardsCollapseStateReducer(
    state: IStateEdgeServiceAgentCardsCollapseState = {},
    action: IActionEdgeServiceAgentCardsCollapseState,
): IStateEdgeServiceAgentCardsCollapseState {
    switch (action.type) {
        case ADD_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE: {
            const barEdgeServiceAgentCardsCollapseState = state[action.barId];

            if (barEdgeServiceAgentCardsCollapseState && barEdgeServiceAgentCardsCollapseState === action.status) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.status;
            return newState;
        }
        case REMOVE_EDGE_SERVICE_AGENT_CARDS_COLLAPSE_STATE: {
            const barEdgeServiceAgentCardsCollapseState = state[action.barId];
            if (!barEdgeServiceAgentCardsCollapseState) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function aggregationMethodReducer(
    state: IStateAggregationMethodSlice = {},
    action: IActionAggregationMethod,
): IStateAggregationMethodSlice {
    switch (action.type) {
        case ADD_AGGREGATION_METHOD: {
            const barAggregationMethod = state[action.barId];
            if (barAggregationMethod && barAggregationMethod === action.aggregationMethod) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.aggregationMethod;
            return newState;
        }
        case REMOVE_AGGREGATION_METHOD: {
            const barAggregationMethod = state[action.barId];
            if (!barAggregationMethod) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function capacityFullMethodReducer(
    state: IStateCapacityFullMethodSlice = {},
    action: IActionCapacityFullMethod,
): IStateCapacityFullMethodSlice {
    switch (action.type) {
        case ADD_CAPACITY_FULL_METHOD: {
            const barCapacityFullMethod = state[action.barId];
            if (barCapacityFullMethod && barCapacityFullMethod === action.capacityFullMethod) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.capacityFullMethod;
            return newState;
        }
        case REMOVE_CAPACITY_FULL_METHOD: {
            const barCapacityFullMethod = state[action.barId];
            if (!barCapacityFullMethod) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function timeReducer(state: IStateTimeSlice = {}, action: IActionTime): IStateTimeSlice {
    switch (action.type) {
        case ADD_TIME: {
            const barTimes = state[action.barId];
            if (barTimes && barTimes.find(time => timeEquals(time, action.time))) {
                return state;
            }
            const newState = Object.assign({}, state);
            const addedTime = { ...action.time };
            newState[action.barId] = (barTimes || []).concat([addedTime]);

            return newState;
        }
        case REMOVE_TIMES: {
            const barTimes = state[action.barId];
            if (!barTimes) {
                return state;
            }

            // Try to remove
            const newBarTimes = barTimes.filter(time => !action.keys.includes(time.key));

            // Nothing got removed
            if (newBarTimes.length === barTimes.length) {
                return state;
            }
            const newState = { ...state };

            if (newBarTimes.length === 0) {
                delete newState[action.barId];
            } else {
                newState[action.barId] = newBarTimes;
            }

            return newState;
        }
        case REMOVE_ALL_TIMES: {
            const newState = { ...state };
            const barTimes = state[action.barId];
            if (!barTimes) {
                return state;
            }
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export function selectionReducer(state: IStateSelectionSlice = {}, action: IActionSelection): IStateSelectionSlice {
    switch (action.type) {
        case ADD_SELECTION: {
            let selectionToAdd = [];
            const barSelection = state[action.barId];
            if (barSelection) {
                // remove existing selection from the adding list
                selectionToAdd = action.selection.filter(selection => !barSelection.includes(selection));
                if (selectionToAdd.length === 0) {
                    return state;
                }
            } else {
                selectionToAdd = action.selection;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = (barSelection || []).concat(selectionToAdd);
            return newState;
        }
        case REMOVE_SELECTION: {
            const barSelection = state[action.barId];
            if (!barSelection) {
                return state;
            }
            // Try to remove
            const newBarSelection = barSelection.filter(selection => !action.selection.includes(selection));

            // Nothing got removed
            if (newBarSelection.length === barSelection.length) {
                return state;
            }
            const newState = { ...state };

            if (newBarSelection.length === 0) {
                delete newState[action.barId];
            } else {
                newState[action.barId] = newBarSelection;
            }
            return newState;
        }
        case REMOVE_ALL_SELECTION: {
            const newState = { ...state };
            const barSelection = state[action.barId];
            if (!barSelection) {
                return state;
            }
            delete newState[action.barId];
            return newState;
        }
        case SWAP_ALL_SELECTION: {
            const newState = { ...state };
            const bar1Selection = state[action.barId1];
            newState[action.barId1] = newState[action.barId2];
            newState[action.barId2] = bar1Selection;

            return newState;
        }
        default:
            return state;
    }
}

export function groupSelectionReducer(
    state: IStateGroupSelectionSlice = {},
    action: IActionGroupSelection,
): IStateGroupSelectionSlice {
    switch (action.type) {
        case ADD_GROUP_SELECTION: {
            const barGroupSelection = state[action.barId];
            if (barGroupSelection && barGroupSelection === action.groupSelection) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.groupSelection;
            return newState;
        }
        case REMOVE_GROUP_SELECTION: {
            const barGroupSelection = state[action.barId];
            if (!barGroupSelection) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

const resizerSizeReducer = (state: IStateResizerSlice = {}, action: IActionResizer): IStateResizerSlice => {
    const newState = { ...state };
    switch (action.type) {
        case SET_RESIZER_SIZE: {
            if (state[action.resizerId] === action.size) {
                return state;
            }
            newState[action.resizerId] = action.size;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoveryToggledItemsReducer = (
    state: IStateDisasterRecoveryToggledItemsSlice = {},
    action: IActionDisasterRecoveryToggleItem,
): IStateDisasterRecoveryToggledItemsSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_TOGGLE_ITEM: {
            if (newState[action.groupId]?.includes(action.itemId)) {
                newState[action.groupId] = newState[action.groupId].filter(itemId => itemId !== action.itemId);
            } else {
                newState[action.groupId] = newState[action.groupId]
                    ? [...newState[action.groupId], action.itemId]
                    : [action.itemId];
            }
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedGroupsViewReducer = (
    state: IStateDisasterRecoverySelectedGroupsViewSlice = {},
    action: IActionDisasterRecoverySelectGroupsView,
): IStateDisasterRecoverySelectedGroupsViewSlice => {
    switch (action.type) {
        case DRAAS_SELECT_GROUPS_VIEW: {
            const newState = { ...state };
            newState[action.barId] = action.viewId;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedInventoryViewReducer = (
    state: IStateDisasterRecoverySelectedInventoryViewSlice = {},
    action: IActionDisasterRecoverySelectInventoryView,
): IStateDisasterRecoverySelectedInventoryViewSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_INVENTORY_VIEW: {
            newState[action.barId] = action.viewId;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedPolicyReducer = (
    state: IStateDisasterRecoverySelectedPolicySlice = {},
    action: IActionDisasterRecoverySelectPolicy,
): IStateDisasterRecoverySelectedPolicySlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_POLICY: {
            newState[action.barId] = action.policyId;
            return newState;
        }
        case DRAAS_DESELECT_POLICY: {
            newState[action.barId] = undefined;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedPlanReducer = (
    state: IStateDisasterRecoverySelectedPlanSlice = {},
    action: IActionDisasterRecoverySelectPlan,
): IStateDisasterRecoverySelectedPlanSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_PLAN: {
            newState[action.barId] = action.plan;
            return newState;
        }
        case DRAAS_DESELECT_PLAN: {
            newState[action.barId] = undefined;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedReportTypeReducer = (
    state: IStateDisasterRecoverySelectedReportTypeSlice = {},
    action: IActionDisasterRecoverySelectReportType,
): IStateDisasterRecoverySelectedReportTypeSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_REPORT_TYPE: {
            newState.reportType = action.reportType;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoverySelectedReportReducer = (
    state: IStateDisasterRecoverySelectedReportSlice = {},
    action: IActionDisasterRecoverySelectReport,
): IStateDisasterRecoverySelectedReportSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_REPORT: {
            newState.reportId = action.reportId;
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoveryFiltersReducer = (
    state: IStateDisasterRecoveryFiltersSlice = {},
    action: IActionDisasterRecoveryFilter,
): IStateDisasterRecoveryFiltersSlice => {
    const newState: IStateDisasterRecoveryFiltersSlice = { ...state };
    if (!newState[action.barId]) {
        newState[action.barId] = {};
    }
    const barFilters = newState[action.barId];
    switch (action.type) {
        case DRAAS_FILTER_VMS: {
            barFilters.vm = { ...barFilters.vm, [action.key]: action.value };
            return newState;
        }
        case DRAAS_FILTER_VM_JOB_EXECUTIONS: {
            barFilters.vmJobExecution = { ...barFilters.vmJobExecution, [action.key]: action.value };
            return newState;
        }
        default:
            return state;
    }
};

export const disasterRecoveryClusterReducer = (
    state: IStateDisasterRecoveryClusterSlice = {},
    action: IActionDisasterRecoveryCluster,
): IStateDisasterRecoveryClusterSlice => {
    const newState = { ...state };
    switch (action.type) {
        case DRAAS_SELECT_CLUSTER:
            newState.clusterId = action.clusterId;
            return newState;
        case DRAAS_DESELECT_CLUSTER:
            newState.clusterId = undefined;
            return newState;
        default:
            return state;
    }
};

export function tabReducer(state: IStateTabSlice = {}, action: IActionTab): IStateTabSlice {
    switch (action.type) {
        case ADD_TAB: {
            const barTab = state[action.barId];
            if (barTab && barTab === action.tab) {
                return state;
            }
            const newState = Object.assign({}, state);
            newState[action.barId] = action.tab;
            return newState;
        }
        case REMOVE_TAB: {
            const barTab = state[action.barId];
            if (!barTab) {
                return state;
            }
            const newState = Object.assign({}, state);
            delete newState[action.barId];
            return newState;
        }
        default:
            return state;
    }
}

export const entityFocusReducer = (
    state: IStateEntityFocusSlice = {},
    action: IActionEntityFocus,
): IStateEntityFocusSlice => {
    const newState = { ...state };
    switch (action.type) {
        case ADD_FOCUS: {
            const { entityId, barId } = action;
            newState[barId] = [{ key: 'entityId', value: entityId }];
            return newState;
        }
        case REMOVE_FOCUS: {
            const { barId } = action;
            delete newState[barId];
            return newState;
        }
        default: {
            return newState;
        }
    }
};

export const panelTabReducer = (
    state: IStatePanelTabSlice = { selection: undefined },
    action: IActionPanelTab,
): IStatePanelTabSlice => {
    const newState = { ...state };
    switch (action.type) {
        case ADD_PANEL_SELECTION:
            newState.selection = action.selection;
            break;
        case REMOVE_PANEL_SELECTION:
            delete newState.selection;
            break;
        default:
        // Fall through and just return newState
    }
    return newState;
};

export function stepUpReducer(state: IStateStepUpTokenSlice = {}, action: IActionStepUpToken): IStateStepUpTokenSlice {
    switch (action.type) {
        case ADD_STEPUP_TOKEN: {
            // don't change state if token is already stored
            if (state[action.arrayId]?.accessToken === action.accessToken) {
                return state;
            }

            return {
                ...state,
                [action.arrayId]: {
                    accessToken: action.accessToken,
                    expiresAt: action.expiresAt.toISOString(),
                },
            };
        }

        case REMOVE_EXPIRED_STEPUP_TOKENS:
            return Object.keys(state).reduce((acc, arrayId) => {
                if (moment(state[arrayId].expiresAt) < action.currentTime) {
                    const { [arrayId]: removedArray, ...newAcc } = acc;
                    return newAcc;
                }

                return acc;
            }, state);

        case REMOVE_ALL_STEPUP_TOKENS:
            return {};

        default:
            return state;
    }
}

export function performanceAlertIdReducer(
    state: IStatePerformanceAlertId = {},
    action: IActionPerformanceAlert,
): IStatePerformanceAlertId {
    const newState = { ...state };
    const barId = action.barId;
    switch (action.type) {
        case 'SET_PERFORMANCE_ALERT':
            newState[barId] = action.alertId;
            return newState;
        case 'REMOVE_PERFORMANCE_ALERT':
            delete newState[barId];
            return newState;
        default:
            return state;
    }
}

export function pureProtectFileIdReducer(
    state: IStatePureProtectFileId = { fileId: undefined },
    action: IActionSetPureProtectFileId,
): IStatePureProtectFileId {
    const newState = { ...state };
    switch (action.type) {
        case SET_PURE_PROTECT_FILE_ID:
            newState.fileId = action.fileId;
            return newState;
        default:
            return state;
    }
}

export function pureProtectSiteIdReducer(
    state: IStatePureProtectSiteId = { siteId: undefined },
    action: IActionSetPureProtectSiteId,
): IStatePureProtectSiteId {
    const newState = { ...state };
    switch (action.type) {
        case SET_PURE_PROTECT_SITE_ID:
            newState.siteId = action.siteId;
            return newState;
        default:
            return state;
    }
}

export function pureProtectOpportunityIdReducer(
    state: IStatePureProtectOpportunityId = { opportunityId: undefined },
    action: IActionSetPureProtectOpportunityId,
): IStatePureProtectOpportunityId {
    const newState = { ...state };
    switch (action.type) {
        case SET_PURE_PROTECT_OPPORTUNITY_ID:
            newState.opportunityId = action.opportunityId;
            return newState;
        default:
            return state;
    }
}

export function pureProtectAdminViewReducer(
    state: IStatePureProtectAdminView = { adminView: undefined },
    action: IActionSetPureProtectAdminView,
): IStatePureProtectAdminView {
    const newState = { ...state };
    switch (action.type) {
        case SET_PURE_PROTECT_ADMIN_VIEW:
            newState.adminView = action.adminView;
            return newState;
        default:
            return state;
    }
}

export const appReducer = combineReducers({
    filters: filterReducer,
    sorts: sortReducer,
    paginations: paginationReducer,
    times: timeReducer,
    metricSeries: metricSeriesReducer,
    aggregationMethod: aggregationMethodReducer,
    capacityFullMethod: capacityFullMethodReducer,
    selection: selectionReducer,
    groupSelection: groupSelectionReducer,
    cluster: clusterReducer,
    metricType: metricTypeReducer,
    fleetType: fleetTypeReducer,
    itemType: itemTypeReducer,
    resizerSize: resizerSizeReducer,
    tab: tabReducer,
    entityFocus: entityFocusReducer,
    edgeServiceAgentCardsCollapseState: edgeServiceAgentCardsCollapseStateReducer,
    panel: panelTabReducer,
    stepUpTokens: stepUpReducer,
    disasterRecoveryToggledItems: disasterRecoveryToggledItemsReducer,
    disasterRecoverySelectedGroupsView: disasterRecoverySelectedGroupsViewReducer,
    disasterRecoverySelectedInventoryView: disasterRecoverySelectedInventoryViewReducer,
    disasterRecoverySelectedPolicy: disasterRecoverySelectedPolicyReducer,
    disasterRecoverySelectedPlan: disasterRecoverySelectedPlanReducer,
    disasterRecoveryFilters: disasterRecoveryFiltersReducer,
    disasterRecoveryCluster: disasterRecoveryClusterReducer,
    disasterRecoverySelectedReportType: disasterRecoverySelectedReportTypeReducer,
    disasterRecoverySelectedReport: disasterRecoverySelectedReportReducer,
    performanceAlertId: performanceAlertIdReducer,
    pureProtectFileId: pureProtectFileIdReducer,
    pureProtectSiteId: pureProtectSiteIdReducer,
    pureProtectOpportunityId: pureProtectOpportunityIdReducer,
    pureProtectAdminView: pureProtectAdminViewReducer,
});
