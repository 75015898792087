import { Options } from '@angular-slider/ngx-slider';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgbDateStruct, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
    LicenseUpdate,
    ServiceCatalogOffering,
    ServiceCatalogQuotingOptions,
    ServiceCatalogStructuredPricing,
    WorkloadStats,
} from '@pure1/data';
import { Angulartics2 } from 'angulartics2';
import moment from 'moment';
import { WINDOW } from '../../../../app/injection-tokens';

import { convertToDate, disableWeekends } from '../../../../subscription/subscription-utils/subscription-utils';
import { LicenseType } from '../../../../data/models/license';

const SATURDAY = 6;
const SUNDAY = 7;

@Component({
    selector: 'new-site-entry',
    templateUrl: './new-site-entry.component.html',
})
export class NewSiteEntryComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() readonly serviceCatalog: ServiceCatalogOffering;
    @Input() readonly analyticsLabel: string;
    @Input() readonly updatedLicense: LicenseUpdate;
    @Input() readonly workloadStats: WorkloadStats[];
    @Output() readonly siteInfoChanged = new EventEmitter<LicenseUpdate>();
    @Output() readonly deleteTriggered = new EventEmitter<void>();

    @ViewChild('tooltip') readonly tooltip: NgbTooltip;

    isMaxAmount: boolean;
    increaseAmount: number;
    increaseAmountInSlider: number; // Separate slider amount makes it so the slider doesn't jump when entering numbers
    increasePerformance: number;
    increasePerformanceInSlider: number;
    maxAmount: string;
    selectedOption: ServiceCatalogQuotingOptions;
    reserveStructuredPricing: ServiceCatalogStructuredPricing;
    siteName: string;
    siteStartDate: NgbDateStruct;
    reserveSliderOptions: Options;
    performanceSliderOptions: Options;
    selectedWorkload: WorkloadStats;
    minDate: NgbDateStruct;
    selectedPre: boolean;

    readonly CAPACITY_MAX_LENGTH = 5;
    readonly NAME_MAX_LENGTH = 80;
    readonly SUNDAY = SUNDAY;
    readonly LicenseType = LicenseType;

    private minReserve: number;
    private maxReserve: number;
    private increment: number;
    private needToOffsetInput = true;
    private hasCapacityChanged = false; // For analytics
    private minPerformance: number;
    private maxPerformance: number;
    private performanceIncrement: number;
    private needToOffsetPerformanceInput = true;

    constructor(
        @Inject(WINDOW) private window: Window,
        private angulartics2: Angulartics2,
    ) {}

    // Used only for NgbDatePicker. NgbDatePicker prefers static binding.
    readonly disableWeekends = disableWeekends;

    ngOnInit(): void {
        if (
            this.serviceCatalog?.quotingOptions[0]?.terms?.length > 0 &&
            this.serviceCatalog?.quotingOptions[0]?.requestAmounts
        ) {
            const capacityOptions = this.serviceCatalog.quotingOptions[0].requestAmounts;

            this.minReserve = capacityOptions[0];
            this.maxReserve = capacityOptions[capacityOptions.length - 1];
            this.increaseAmount = this.minReserve;
            this.increaseAmountInSlider = this.increaseAmount;
            this.increment = capacityOptions[1] - capacityOptions[0];
        }
        this.reserveSliderOptions = {
            floor: this.minReserve,
            ceil: this.maxReserve,
            showSelectionBar: true,
            hidePointerLabels: true,
            hideLimitLabels: true,
            step: this.increment,
        };

        if (
            this.serviceCatalog?.quotingOptions[0]?.terms?.length > 0 &&
            this.serviceCatalog?.quotingOptions[0]?.requestPerformanceAmounts
        ) {
            const performanceOptions = this.serviceCatalog.quotingOptions[0].requestPerformanceAmounts;
            this.minPerformance = performanceOptions[0];
            this.maxPerformance = performanceOptions[performanceOptions.length - 1];
            this.increasePerformance = this.minPerformance;
            this.increasePerformanceInSlider = this.increasePerformance;
            this.performanceIncrement = performanceOptions[1] - performanceOptions[0];
        }
        this.performanceSliderOptions = {
            floor: this.minPerformance,
            ceil: this.maxPerformance,
            disabled: this.minPerformance >= this.maxPerformance,
            showSelectionBar: true,
            hidePointerLabels: true,
            hideLimitLabels: true,
            step: this.performanceIncrement,
        };

        // Get earliest possible start time
        const now = moment().utc().startOf('day').add(1, 'week');
        this.minDate = {
            day: now.date(), // Don't ask me why it's date() and not day()
            month: now.month() + 1, // 0 offset for months... but date() is 1 offset
            year: now.year(), // Works as expected.
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.updatedLicense) {
            this.updateExistingQuote();
        }
        if (changes.updatedLicense || changes.workloadStats) {
            this.updateWorkloadStatus();
        }
        if (changes.serviceCatalog) {
            if (this.serviceCatalog?.quotingOptions?.length > 0) {
                this.selectedOption = this.serviceCatalog.quotingOptions[0];
            }
        }
    }

    ngAfterViewInit(): void {
        // After we're done initializing, emit so the parent component picks up our initialized values
        this.emitUpdateRequest();
    }

    updateExistingQuote(): void {
        if (this.updatedLicense) {
            this.siteName = this.updatedLicense.siteName;
            this.increaseAmount = this.updatedLicense.additionalAmount;
            this.increaseAmountInSlider = this.updatedLicense.additionalAmount;
            this.increasePerformance = this.updatedLicense.performance;
            this.increasePerformanceInSlider = this.updatedLicense.performance;
            const updatedTime = this.updatedLicense.startDate?.utc();
            this.siteStartDate = updatedTime
                ? {
                      day: updatedTime.date(),
                      month: updatedTime.month() + 1,
                      year: updatedTime.year(),
                  }
                : null;
            this.selectedPre = this.updatedLicense.isPre;
        }
    }

    updateWorkloadStatus(): void {
        if (this.updatedLicense) {
            this.selectedWorkload = this.workloadStats?.find(workload => {
                return this.updatedLicense.workload?.name === workload.name;
            });
            this.emitUpdateRequest();
        }
    }

    // Flow is user types input -> triggers onInputAmountChange ->
    // user clicks out of box   -> final input gets checked and used
    onInputAmountChange(amountInUnitText: number): void {
        if (!this.hasCapacityChanged) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Reserved Capacity Input changed' },
            });

            this.hasCapacityChanged = true;
        }

        this.increaseAmount = amountInUnitText;
        // When the user changes the input using the box, we don't want to offset it
        // The reason for this one-time variable is that (valueChanges) of ngx-slider will trigger
        // even if [increaseAmountInSlider] is changed programmatically
        this.needToOffsetInput = false;
    }

    onAmountChange(increaseAmountInUnit: number): void {
        // Check to see if this has ever been changed
        if (!this.hasCapacityChanged) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Reserved Capacity Slider changed' },
            });

            this.hasCapacityChanged = true;
        }

        if (this.needToOffsetInput) {
            this.increaseAmount = increaseAmountInUnit;
        }
        this.increaseAmountInSlider = increaseAmountInUnit;
        this.needToOffsetInput = true;
        this.emitUpdateRequest();
    }

    onInputPerformanceChange(increasePerformanceInUnit: number): void {
        this.increasePerformance = increasePerformanceInUnit;
        this.needToOffsetPerformanceInput = false;
    }

    onPerformanceChange(increasePerformanceInUnit: number): void {
        this.increasePerformance = increasePerformanceInUnit;
        this.increasePerformanceInSlider = increasePerformanceInUnit;
        this.needToOffsetPerformanceInput = true;
        this.emitUpdateRequest();
    }

    onStartDateChange(newStartDate: NgbDateStruct): void {
        // Check to see if this has ever been changed
        if (this.siteStartDate === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Start Date added' },
            });
        }

        this.siteStartDate = newStartDate;

        this.emitUpdateRequest();
    }

    onNameChange(newName: string): void {
        // Check to see if this has ever been changed
        if (this.siteName === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'License Details added' },
            });
        }

        this.siteName = newName;

        this.emitUpdateRequest();
    }

    onWorkloadChange(newWorkload: WorkloadStats): void {
        // Check to see if this has ever been changed
        if (this.selectedWorkload === undefined) {
            this.angulartics2.eventTrack.next({
                action: this.analyticsLabel,
                properties: { category: 'Action', label: 'Workload added' },
            });
        }

        this.selectedWorkload = newWorkload;

        if (this.selectedWorkload?.name === 'Custom') {
            // Open it and have it close after 5 seconds. Since our closeDelay is 5 seconds, we just open and close it
            // Store tooltip locally just in case it gets replaced before closing
            const tooltip = this.tooltip;
            tooltip.open();
            this.window.setTimeout(() => {
                tooltip.close();
            }, moment.duration(5, 'seconds').asMilliseconds());
        }

        this.emitUpdateRequest();
    }

    onInputAmountBlur(reserveAmountInputElement: HTMLInputElement): void {
        if (reserveAmountInputElement.value === '') {
            this.increaseAmount = this.minReserve;
            this.needToOffsetInput = true;
        } else {
            if (this.increaseAmount < this.minReserve) {
                this.increaseAmount = this.minReserve;
                this.needToOffsetInput = true;
            } else if (this.increaseAmount > this.maxReserve) {
                this.increaseAmount = this.maxReserve;
                this.needToOffsetInput = true;
            }
        }
        this.increaseAmountInSlider = this.increaseAmount;

        this.emitUpdateRequest();
    }

    onInputPerformanceBlur(reservePerformanceInputElement: HTMLInputElement): void {
        if (reservePerformanceInputElement.value === '') {
            this.increasePerformance = 0;
        } else {
            if (this.increasePerformance < this.minPerformance) {
                this.increasePerformance = this.minPerformance;
            } else if (this.increasePerformance > this.maxPerformance) {
                this.increasePerformance = this.maxPerformance;
            }
        }
        this.increasePerformanceInSlider = this.increasePerformance;

        this.emitUpdateRequest();
    }

    onPreChange(newlySelectedPre: boolean): void {
        this.selectedPre = newlySelectedPre;
        this.emitUpdateRequest();
    }

    deleteEntry(): void {
        this.deleteTriggered.emit();
    }

    private emitUpdateRequest(): void {
        const newSiteInfo: LicenseUpdate = new LicenseUpdate({
            license_id: null,
            site_name: this.siteName,
            license_type: this.serviceCatalog.licenseType,
            product_sku: this.selectedOption?.productSku,
            additional_amount: this.increaseAmount,
            performance: this.increasePerformance,
            new_site: true,
            start_date: convertToDate(this.siteStartDate),
            workload: {
                name: this.selectedWorkload?.name,
            },
            is_pre: this.selectedPre,
        });

        this.siteInfoChanged.emit(newSiteInfo);
    }
}
