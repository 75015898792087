import {
    DraasApiSiteExtended,
    DraasApiAwsSite,
    DraasApiVSphereSite,
    DraasApiSiteRecoverySummary,
    DraasApiSiteProtectionSummary,
    DraasApiRecoveryJobExecutionsSummary,
} from '@pure/paas-api-gateway-client-ts';
import moment from 'moment';

export class DisasterRecoverySiteExtended {
    site: DisasterRecoveryAwsSite | DisasterRecoveryVSphereSite;
    recoverySummary: DisasterRecoverySiteRecoverySummary;
    protectionSummary: DisasterRecoverySiteProtectionSummary;

    constructor(json: DraasApiSiteExtended) {
        this.site =
            json.site.type === 'AWS'
                ? new DisasterRecoveryAwsSite(json.site)
                : new DisasterRecoveryVSphereSite(json.site);
        this.recoverySummary = new DisasterRecoverySiteRecoverySummary(json.recovery_summary);
        this.protectionSummary = new DisasterRecoverySiteProtectionSummary(json.protection_summary);
    }
}

export class DisasterRecoverySite {
    status: DisasterRecoverySiteStatus;
    siteType: DisasterRecoverySiteType;
    name: string;
    id: string;
    version: number;
    clusterId: string;
    lastUpdatedAtUtc?: string;

    constructor(json: DraasApiAwsSite | DraasApiVSphereSite) {
        this.status = json.status;
        this.siteType = json.site_type;
        this.name = json.name;
        this.id = json.id;
        this.version = json.version;
        this.clusterId = json.cluster_id;
        this.lastUpdatedAtUtc = json.last_updated_at_utc;
    }
}

export type DisasterRecoverySiteType = 'VSPHERE' | 'AWS';

export type DisasterRecoverySiteStatus =
    | 'HEALTHY'
    | 'UNHEALTHY'
    | 'PENDING_DEPLOYMENT'
    | 'NOT_STARTED'
    | 'CREDENTIALS_INVALID'
    | 'DELETION_IN_PROGRESS'
    | 'UNKNOWN';

export class DisasterRecoveryAwsSite extends DisasterRecoverySite {
    instanceType: string;
    region: string;

    constructor(json: DraasApiAwsSite) {
        super(json);
        this.instanceType = json.instance_type;
        this.region = json.region;
    }
}

export class DisasterRecoveryVSphereSite extends DisasterRecoverySite {
    storageQuotaInBytes?: number;

    constructor(json: DraasApiVSphereSite) {
        super(json);
        this.storageQuotaInBytes = json.storage_quota_in_bytes;
    }
}

export class DisasterRecoverySiteRecoverySummary {
    recoveryJobExecutionSummary: DisasterRecoveryRecoveryJobExecutionsSummary;
    lastRto: moment.Duration;

    constructor(json: DraasApiSiteRecoverySummary) {
        this.recoveryJobExecutionSummary = json.recovery_job_execution_summary
            ? new DisasterRecoveryRecoveryJobExecutionsSummary(json.recovery_job_execution_summary)
            : null;
        this.lastRto = json.last_rto_duration ? moment.duration(json.last_rto_duration) : null;
    }
}

export class DisasterRecoveryRecoveryJobExecutionsSummary {
    recovered: number;
    failed: number;
    notTested: number;
    total: number;

    constructor(json: DraasApiRecoveryJobExecutionsSummary) {
        this.recovered = json.recovered;
        this.failed = json.failed;
        this.notTested = json.not_tested;
        this.total = json.total;
    }
}

export class DisasterRecoverySiteProtectionSummary {
    protectedVms: number;
    discoveredVms: number;
    protectedBytes: number;

    constructor(json: DraasApiSiteProtectionSummary) {
        this.protectedVms = json.protected_vms;
        this.discoveredVms = json.discovered_vms;
        this.protectedBytes = json.protected_bytes;
    }
}
