import moment from 'moment';
import { License, LicenseAsset, LicenseSubscriptionResource, LicenseType, LicenseUsage } from '@pure1/data';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { auditTime, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { smartTimer } from '@pstg/smart-timer';
import { SimulationsService } from '../simulation-summary/simulations.service';
import { CapacityFull, CapacityProjectionService } from '../training-windows/capacity-projection.service';

export interface ForecastedLicense {
    id: string;
    name: string;
    licenseType: LicenseType;
    reservedAmount: number;
    fullInDays: number | string;
    licenseAssets: LicenseAsset[];
    licenseUsage: LicenseUsage;
    subscription: LicenseSubscriptionResource;
    license: License;
    simulatedLicense: ForecastedLicense;
}

// current Kong rate limit for SPOG is 100 req/sec/IP
const LICENSE_LIMIT_PER_SECOND = 50;

@Injectable() // Not provided in root: only gets used as sandboxed instance
export class ForecastedLicensesManager implements OnDestroy {
    private readonly _forecastedLicenses$ = new BehaviorSubject<ForecastedLicense[]>(null);

    private readonly _debouncedForecastedLicenses$ = this._forecastedLicenses$.pipe(auditTime(100), shareReplay(1));

    /**
     * Emits the new values whenever the ForecastedLicenses updates, and when first subscribed to.
     */
    get forecastedLicenses$(): Observable<ForecastedLicense[]> {
        return this._debouncedForecastedLicenses$;
    }

    private timeRange: moment.Duration;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly simulationsService: SimulationsService,
        private readonly capacityProjectionService: CapacityProjectionService,
    ) {
        this.simulationsService.licenseSimulations$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
            this._forecastedLicenses$.value?.forEach(forecastLicense => {
                this.getDaysToOnDemand(forecastLicense.simulatedLicense, true);
            });
            this.sliceForecastedLicenses();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    update(licenses: License[], timeRange: moment.Duration): void {
        this.timeRange = timeRange;

        const licensesToUpdate = licenses || [];
        // Update forecasted
        const newForecastedLicenses = licensesToUpdate.map(a => this.getForecastedLicense(a, licensesToUpdate.length));

        // Update simulated
        newForecastedLicenses.forEach(license => {
            this.updateSimulatedLicense(license);
        });
        this._forecastedLicenses$.next(newForecastedLicenses);
    }

    updateSimulatedLicense(license: ForecastedLicense): void {
        const simulatedReservedAmount = this.simulationsService.getExpandLicenseReserveLevel(
            license.id,
        )?.license_reserve_level;
        if (simulatedReservedAmount) {
            const simulatedLicense: ForecastedLicense = {
                id: license.id,
                name: license.name,
                licenseType: license.licenseType,
                reservedAmount: simulatedReservedAmount,
                fullInDays: null,
                licenseAssets: license.licenseAssets,
                licenseUsage: license.licenseUsage,
                subscription: license.subscription,
                license: license.license,
                simulatedLicense: license,
            };
            this.getDaysToOnDemand(simulatedLicense, true);
            license.simulatedLicense = simulatedLicense;
        } else {
            license.simulatedLicense = null;
        }
        this.sliceForecastedLicenses();
    }

    getOnDemandWithSimulationObservable$(
        license: ForecastedLicense,
        withSimulation: boolean,
    ): Observable<CapacityFull> {
        const middle = moment.utc().startOf('day').valueOf();
        const timePeriod = this.timeRange.asMilliseconds();
        const startTime = middle - timePeriod;
        const endTime = middle;
        return this.simulationsService.simulation$.pipe(
            take(1),
            switchMap(simulation =>
                this.capacityProjectionService.getSimulatedCapacityFull(
                    null,
                    simulation.id,
                    withSimulation,
                    startTime,
                    endTime,
                    this.timeRange.asDays(),
                    license.id,
                ),
            ),
            takeUntil(this.destroy$),
        );
    }

    private getForecastedLicense(license: License, licenseCount: number): ForecastedLicense {
        const forecastLicense: ForecastedLicense = {
            id: license.id,
            name: license.name,
            licenseType: license.licenseType,
            reservedAmount: license.reservedAmount,
            fullInDays: null,
            licenseAssets: license.licenseAssets,
            licenseUsage: license.licenseUsage,
            subscription: license.subscription,
            license: license,
            simulatedLicense: null,
        };
        this.getDaysToOnDemand(forecastLicense, false, licenseCount);
        return forecastLicense;
    }

    private getDaysToOnDemand(license: ForecastedLicense, withSimulation: boolean, licenseCount: number = 0): void {
        if (license?.licenseAssets?.length > 0) {
            let delayMs = 0;
            if (licenseCount > LICENSE_LIMIT_PER_SECOND) {
                // compute random initial delay to spread out fetching licenses and prevent rate limiting
                delayMs = Math.floor(((Math.random() * licenseCount) / LICENSE_LIMIT_PER_SECOND) * 1000);
            }
            smartTimer(delayMs)
                .pipe(
                    switchMap(() => this.getOnDemandWithSimulationObservable$(license, withSimulation)),
                    takeUntil(this.destroy$),
                )
                .subscribe({
                    next: capacityFull => {
                        if (license.reservedAmount !== 0 && capacityFull?.fullInDays != null) {
                            license.fullInDays = capacityFull?.fullInDays;
                        } else {
                            license.fullInDays = '';
                        }
                        this.sliceForecastedLicenses();
                    },
                    error: err => {
                        if (
                            !err?.error?.message ||
                            (err.error.message !== 'NO_TRAINING_DATA' &&
                                err.error.message !== 'INSUFFICIENT_TRAINING_DATA' &&
                                err.error.message !== 'TOO_MANY_MISSING_VALUES')
                        ) {
                            console.error('Get Days to On-demand value failed', err);
                        }
                        license.fullInDays = '';
                        this.sliceForecastedLicenses();
                    },
                });
        }
    }

    private sliceForecastedLicenses(): void {
        // We call this on each stream we subscribe to, and on each asynchronous operation that modifies a license
        if (this._forecastedLicenses$.value) {
            this._forecastedLicenses$.next(this._forecastedLicenses$.value.slice());
        }
    }
}
