import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    License,
    Partner,
    Subscription,
    QuoteType,
    ServiceCatalogGroup,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV3,
    DisplayedPartner,
    convertToAmpliQuoteRequestConfig,
} from '@pure1/data';
import { take } from 'rxjs/operators';
import { ServiceCatalogOfferingsService } from '../../../service-catalog/services/service-catalog-offerings.service';
import { ToastType, ToastService } from '../../../services/toast.service';
import { Angulartics2 } from 'angulartics2';
import { PartnerInfoService } from '../../../ui/components/partner-info/partner-info.service';
import { ampli } from '../../../ampli';

const TIB_IN_BYTES = 1024 * 1024 * 1024 * 1024;

@Component({
    selector: 'renew-summary-modal',
    templateUrl: './renew-summary-modal.component.html',
})
export class RenewSummaryModalComponent implements OnInit {
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly subscription: Subscription;
    @Input() readonly licenses: License[];
    @Input() readonly pendingQuote: ServiceCatalogQuote;
    @ViewChild('manageSubscriptionModal') readonly manageSubscriptionModal: TemplateRef<any>;

    loading = false;
    renewalAcceptedRequestSucceeded = false;
    renewalEndDate: string;
    newLicenses: License[] = [];
    existingLicenses: License[] = [];
    increaseReserved: number[] = [];
    increaseTermInMonths: number = null;
    displayedPartner: DisplayedPartner;
    partnersInfos: Partner[];
    private offeringsInGroups: ServiceCatalogGroup[];
    private readonly analyticsPrefix = 'Renewal Summary Modal - ';

    constructor(
        private serviceCatalogOfferingsService: ServiceCatalogOfferingsService,
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV3,
        private toast: ToastService,
        private angulartics2: Angulartics2,
        private partnerInfoService: PartnerInfoService,
        public modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.existingLicenses = this.licenses.map(license => Object.assign({}, license));
        if (this.pendingQuote) {
            this.increaseTermInMonths = this.pendingQuote.termInMonth;

            if (this.pendingQuote.pure1Acknowledged) {
                this.pendingQuote.licenseUpdates?.forEach(licenseUpdate => {
                    this.increaseReserved.push(licenseUpdate.additionalAmount);
                    this.existingLicenses.forEach(existingLicense => (existingLicense.startDate = null));
                });
            } else {
                this.existingLicenses.forEach(existingLicense => {
                    this.increaseReserved.push(existingLicense.reservedAmount);
                    this.existingLicenses.forEach(existingLicense => (existingLicense.startDate = null));
                });
                this.increaseTermInMonths = 12;
            }
        }

        // prefetch offerings info for new products
        this.serviceCatalogOfferingsService.offeringsInGroups$.pipe(take(1)).subscribe(oInGroups => {
            this.offeringsInGroups = oInGroups;
        });

        //Get renewal End Date
        if (this.subscription.endDate !== null && this.subscription.endDate !== undefined) {
            const endDate = this.subscription.endDate.clone();
            this.renewalEndDate = endDate.add(this.increaseTermInMonths, 'month').utc().format('MMMM DD YYYY');
        } else {
            this.renewalEndDate = null;
        }

        //Generate the partner info
        this.partnerInfoService.partnersInfos$.pipe(take(1)).subscribe(data => {
            this.partnersInfos = data;
            this.generatePartnerInfo();
        });
    }

    modifyRenewal(): void {
        const modalRef = this.modalService.open(this.manageSubscriptionModal, {
            backdrop: 'static',
            windowClass: 'manage-subscription-modal',
        });
        this.activeModal.dismiss();
    }

    generatePartnerInfo(): void {
        this.displayedPartner = this.partnerInfoService.generateDisplayedPartnerInfo(
            this.pendingQuote,
            this.subscription,
        );
        this.loading = false;
    }

    acceptRenewal(): void {
        if (this.pendingQuote?.pure1Acknowledged) {
            return;
        }

        this.loading = true;

        // We don't set license_updates or products since nothing can be changed
        const params = ServiceCatalogQuote.fromRenewalRequestJson({
            id: this.pendingQuote?.id,
            quote_type: QuoteType.RENEWAL,
            term_in_month: this.pendingQuote?.termInMonth ? this.pendingQuote.termInMonth : 12, //default is 12 months
            reserved_unit: 'TiB', //Reserved unit for renewal quotes is always TiB
            on_demand: null, //Null for now since we might support on-demand in the future
            request_type: 'Quote',
            order_category: this.subscription.isPoc ? 'New Subscription' : 'Subscription Renewal',
            subscription_id: this.subscription.id,
            pure1_acknowledged: true,
        });
        //triggering Amplitude events to track quote requested actions
        ampli.quoteRequested({
            'quote requested from': 'subscription page',
            'requested configuration': convertToAmpliQuoteRequestConfig(
                this.subscription.programType,
                QuoteType.RENEWAL,
            ),
        });

        if (this.subscription != null) {
            this.serviceCatalogQuoteService
                .update(params)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        // this.activeModal.close();
                        this.loading = false;
                        this.renewalAcceptedRequestSucceeded = true;
                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + `Accept Renewal Offer Success`,
                            properties: {
                                category: 'Action',
                                label: `accept ${this.subscription.programType} renewal for ${this.subscription.name}`,
                            },
                        });
                    },
                    error: error => {
                        this.loading = false;
                        console.error('error submitting subscription renewal', error);
                        this.toast.add(ToastType.error, 'Error submitting subscription renewal');
                        this.angulartics2.eventTrack.next({
                            action: this.analyticsPrefix + `Error Accepting Renewal Offer`,
                            properties: {
                                category: 'Action',
                                label: `error for accepting ${this.subscription.programType} renewal for ${this.subscription.name}`,
                            },
                        });
                    },
                });
        }
    }
}
