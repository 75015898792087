import { escape, escapeRegExp } from 'lodash';
import { Component, Input, EventEmitter, Output, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NamedValue } from './autocomplete.directive';

export enum MoveSelectionDirection {
    Up = -1,
    Down = 1,
}
const NO_SELECTION = -1;

@Component({
    selector: 'pure-autocomplete-menu',
    templateUrl: 'autocomplete-menu.html',
})
export class AutocompleteMenuComponent<T> {
    readonly MAX_ITEMS = 800;

    _namedValues: NamedValue<T>[] = [];
    _match = '';
    _matchRegex: RegExp;
    _selectedValues: NamedValue<T>[] = []; // want to preserve order

    // Since the inputs are not typically passed by template bindings, angular does not detect
    // the changes, so we have to do it manually.
    @Input()
    set namedValues(nv: NamedValue<T>[]) {
        const clean =
            this._namedValues.length === nv.length &&
            nv.every((namedValue, index) => {
                return (
                    namedValue.name === this._namedValues[index].name &&
                    namedValue.value === this._namedValues[index].value
                );
            });
        // only update named values and detect changes when named values have changed
        if (!clean) {
            this._namedValues = nv;
            this.markCategoryBoundaries(nv);
            this.updateSelectedIndex();
            this.changeDetector.detectChanges();
        }
    }
    get namedValues(): NamedValue<T>[] {
        return this._namedValues;
    }

    @Input()
    set match(m: string) {
        if (this._match !== m) {
            // only update match and detect changes whenever the new match does not equal the old match
            this._match = m;
            this._matchRegex = new RegExp('(' + escapeRegExp(escape(m)) + ')', 'gi');
            this.updateSelectedIndex();
            this.changeDetector.detectChanges();
        }
    }
    get match(): string {
        return this._match;
    }

    @Input()
    set selectedValues(sv: NamedValue<T>[]) {
        this._selectedValues = sv;
        this.changeDetector.detectChanges();
    }
    get selectedValues(): NamedValue<T>[] {
        return this._selectedValues;
    }

    @Output() valueselect = new EventEmitter<NamedValue<T>[]>();

    get matchRegex(): RegExp {
        return this._matchRegex;
    }

    multiSelect = false; // this should not change, so we don't need to worry about detecting changes
    categoryFirsts: Set<number> = new Set();
    selectedIndex = NO_SELECTION;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private el: ElementRef,
    ) {}

    /**
     * default comparison of NamedValues will just be strict equality on the name (chould be overridden in options?)
     * returns true if names are the same, false if names are different
     */
    compareNamedValues: (a: NamedValue<T>, b: NamedValue<T>) => boolean = (a: NamedValue<T>, b: NamedValue<T>) =>
        a.name === b.name;

    trackByNamedValue(index: number, item: NamedValue<T>): string {
        return item.name + '||' + item.value;
    }

    // this depends on both this.match and this.namedValues, so whenever either changes we must update the selectedIndex
    updateSelectedIndex(): void {
        this.selectedIndex = this.selectByPrefix();
    }

    moveSelection(direction: MoveSelectionDirection): void {
        const total = this.namedValues.length;
        if (total > 0) {
            if (this.selectedIndex !== NO_SELECTION) {
                this.selectedIndex = (this.selectedIndex + direction + total) % total;
            } else {
                this.selectedIndex = 0;
            }
            this.scroll();
            this.changeDetector.detectChanges();
        }
    }

    select(nondestructive = false): boolean {
        // this selects the current NamedValue<T> at the highlighted index
        // or returns false if no index is highlighted
        if (this.selectedIndex !== NO_SELECTION) {
            const namedValue = this.namedValues[this.selectedIndex];
            if (this.multiSelect) {
                this.toggle(namedValue, nondestructive);
            } else {
                this.valueselect.emit([namedValue]);
            }
            return true;
        } else {
            return false;
        }
    }

    onClick(namedValue: NamedValue<T>, $event: MouseEvent): void {
        $event.preventDefault(); // if we click on the label, we don't want two click events (one for the span and one for the input)
        $event.stopImmediatePropagation();
        if (this.multiSelect) {
            this.toggle(namedValue);
        } else {
            this.valueselect.emit([namedValue]);
        }
    }

    toggle(namedValue: NamedValue<T>, nondestructive = false): void {
        if (this._selectedValues.some(nv => this.compareNamedValues(nv, namedValue))) {
            if (!nondestructive) {
                // this code executes, unless we have a nondestructive toggle (i.e. only can select, not deselect)
                this._selectedValues.splice(
                    this._selectedValues.findIndex(nv => this.compareNamedValues(nv, namedValue)),
                    1,
                );
            }
        } else {
            this._selectedValues.push(namedValue);
        }
        this.valueselect.emit(this._selectedValues);
    }

    isSelected(namedValue: NamedValue<T>): boolean {
        return this._selectedValues.some(nv => this.compareNamedValues(nv, namedValue));
    }

    private selectByPrefix(): number {
        let prefixIndex = NO_SELECTION;
        if (this.match) {
            const matchLc = this.match.toLowerCase();
            for (let i = 0; i < this.namedValues.length; i++) {
                if (this.namedValues[i].name.toLowerCase().startsWith(matchLc)) {
                    if (prefixIndex !== NO_SELECTION) {
                        // multiple matches
                        return NO_SELECTION;
                    } else {
                        prefixIndex = i;
                    }
                }
            }
            // Only one match, select this one
            if (prefixIndex !== NO_SELECTION) {
                return prefixIndex;
            }
        }
        return NO_SELECTION;
    }

    private scroll(): void {
        const toShow = this.el.nativeElement.querySelector(
            `.autocomplete-suggestion:nth-child(${this.selectedIndex + 1})`,
        );
        const autocompleteMenuWrapper = this.el.nativeElement.querySelector('.autocomplete-menu');
        if (toShow) {
            const menuTop = autocompleteMenuWrapper.scrollTop;
            const menuBottom = menuTop + autocompleteMenuWrapper.clientHeight;
            const top = toShow.offsetTop;
            const bottom = top + toShow.clientHeight;
            let scrollTopFinal = menuTop;

            if (top < menuTop) {
                scrollTopFinal = top;
            } else if (bottom > menuBottom) {
                scrollTopFinal = menuTop + bottom - menuBottom;
            }
            autocompleteMenuWrapper.scrollTop = scrollTopFinal;
        }
    }

    private markCategoryBoundaries(namedValues: NamedValue<T>[]): void {
        // We assume that the NamedValue array is already sorted by category name in the appropriate order, and that
        // within each category, the NamedValues are sorted correctly.
        // In a single pass, this method saves the index of a NamedValue if the category name is
        // different from the category name of the NamedValue that preceeds it.
        this.categoryFirsts.clear();
        namedValues.reduce(
            (previousValue: NamedValue<T>, currentValue: NamedValue<T>, currentIndex: number) => {
                if (currentValue.category) {
                    // categories are not required, so won't save the index if category is missing
                    if (
                        (previousValue.category && previousValue.category.displayName) !==
                        currentValue.category.displayName
                    ) {
                        this.categoryFirsts.add(currentIndex);
                    }
                }
                return currentValue;
            },
            { name: undefined, value: undefined, category: undefined },
        ); // we use a dummy named value as an initializer
    }
}
