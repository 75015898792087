import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { PeakLicense } from '../models/peak-license';
import { SortParams } from '@pure1/data';
import { map, Observable } from 'rxjs';

const PEAK_LICENSE_ENDPOINT = '/rest/v1/peak-subscriptions/licenses';

@Injectable({
    providedIn: 'root',
})
export class PeakLicenseService extends GenericService<PeakLicense> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: PeakLicense,
            endpoint: PEAK_LICENSE_ENDPOINT,
            defaultParams: {
                pageStart: 0,
                pageSize: 25,
                sort: <SortParams>{
                    key: 'name',
                    order: 'asc',
                },
            },
        });
    }

    requestRenewal(license: PeakLicense): Observable<Object> {
        const renewalRequest = {
            id: license.id,
            renewal_requested: true,
        };

        return this.http.patch(PEAK_LICENSE_ENDPOINT, renewalRequest).pipe(map(() => void 0));
    }
}
