import { HttpResponse } from '@angular/common/http';

import { bannerStatus } from '../models/banner';
import { SortParams } from '../interfaces/list-params';
import { IRestResponse } from '../interfaces/collection';

const ARRAY_VOLUME_SEPARATOR = '|';

export const FILTER_ARRAY_INCLUDE_PAAS_FIELD = 'is_paas_array';

/**
 * Helper to create the "sort" query parameter for a ListParams. Does not include the 'sort=' prefix.
 * Throws error if no sort parameters are specified.
 * @param secondarySort If specified, then always sort by this as well to ensure a deterministic sort order.
 */
export function createSortQueryString(sort: SortParams | SortParams[], secondarySort?: SortParams): string {
    if (!sort) {
        throw new Error(`sort parameter is required`);
    }

    const sortArray: SortParams[] = Array.isArray(sort) ? sort.slice() : [sort];
    if (secondarySort && !sortArray.some(s => s.key === secondarySort.key)) {
        sortArray.push(secondarySort);
    }

    return sortArray
        .map(s => {
            let result: any = s; // in case 's' is already a string, we set it here so it is returned by default
            if (s.key) {
                result = s.key + (s.order === 'desc' ? '-' : '');
            }
            return result;
        })
        .join(',');
}

/**
 * Transforms the content for a HTTP post into the format for form-urlencoded content.
 * This is useful when you want to move a lengthy url parameter into the body without having to change the backend
 * to accept a JSON object (like we normally post) since Java's HttpServletRequest.getParameter() function
 * works on both url params and posted values in a form-urlencoded request.
 * @param data The data to be included in the body. Assumes a flat object with string values.
 */
export function formUrlEncodedRequestTransformer(data: { [key: string]: string }): string {
    return Object.keys(data)
        .sort((a, b) => a.localeCompare(b)) // Sort to keep the order deterministic, even though it should be irrelevant
        .filter(key => data[key] !== null && data[key] !== undefined)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}

/**
 * Helper to transform legacy REST endpoint response to the current format, which expects an array of 'items' and a
 * 'total_item_count' in the response.data object. Note that 'total_item_count' may not necessarily be equal to items.length
 * @param response: the raw response from the legacy REST endpoint, 'any' type as the response can be in any format
 * @param {string} endpoint: the legacy endpoint, it should match the endpoint in serviceParams if using generic.service
 * @returns an object which has an 'items' and a 'total_item_count' properties.
 */
export function customMappingForLegacyResponses(response: HttpResponse<any>, endpoint: string): IRestResponse<any> {
    switch (endpoint) {
        case '/rest/v3/arrays':
            return {
                items: (response.body || []).map(array => {
                    array.name = array.hostname; // the response from GET /rest/v3/arrays does not have 'name', only 'hostname'
                    return array;
                }),
                total_item_count: Number(response.headers.get('X-TOTAL-ITEM-COUNT')) || response.body?.length || 0,
            };

        case '/rest/v3/volumes':
        case '/rest/v3/volumes/capacity-metrics':
            return {
                items: (response.body || []).map(volObj => {
                    volObj.id = volObj.array_id + ARRAY_VOLUME_SEPARATOR + volObj.core_id; // We need to combine array_id and core_id to form unique 'id'
                    return volObj;
                }),
                total_item_count: Number(response.headers.get('X-TOTAL-ITEM-COUNT')) || response.body?.length || 0,
            };

        case '/rest/v1/banner': {
            const statusValue = (status: bannerStatus): number => {
                if (status === 'info') {
                    return 0;
                } else if (status === 'warning') {
                    return 1;
                } else if (status === 'critical') {
                    return 2;
                } else {
                    throw new Error('banner status not recognized');
                }
            };
            const result: IRestResponse<any> = {
                items: response.body,
                total_item_count: response.body.length,
            };

            // always put the critical messages on the top, then warning, then info
            result.items.sort((b1, b2) => statusValue(b2.status) - statusValue(b1.status));
            return result;
        }

        case '/rest/v1/views': {
            const result: IRestResponse<any> = {
                items: response.body.items,
                total_item_count: response.body.pagination_info.total_item_count,
            };
            return result;
        }

        case '/rest/v1/user':
            return {
                items: [response.body],
                total_item_count: 1,
            };

        case '/rest/v1/security-patch-fb-2025-a':
            return {
                items: response.body,
                total_item_count: response.body.length,
            };

        default:
            return response?.body;
    }
}

/*
 * quote and escape single quote and backslash for any value
 */
export function quoteAndEscape(param: string): string {
    return `'${param.replace(/\\/g, '\\\\').replace(/\'/g, "\\'")}'`;
}

// The following can be cleaned up when Array_Manager is moved to data layer
/**
 * (Copied from pure_utils)
 * Options for PureUtils.buildUrlFilterString()
 */
interface IBuildUrlFilterStringOptions {
    /**
     * The name of the parameters to wrap in wildcards. Example:
     *      Input: A=[1,2]
     *      Default: A=1 and A=2
     *      Wrap wildcard: A=*1* and A=*2*
     */
    paramsWrapWildcard?: string[];

    paramsTags?: string[];
}

/**
 * (Copied from pure_utils)
 * Builds the string for the "filter" part of a url request for the Pure1 api
 */
export function buildUrlFilterStringForV3Endpoint(filterObj: Object, options: IBuildUrlFilterStringOptions): string {
    if (!filterObj) {
        return null;
    }

    options = options || {};

    // All the parts of the filter query. We will join these together in the end.
    const queryParts: string[] = [];
    const wildCards: { string?: (string | number)[] } = {};
    // TODO: investigate why we are received already well formatted queries: CLOUD-34979
    if (typeof filterObj !== 'object') {
        if (typeof filterObj === 'string') {
            queryParts.push(filterObj);
        }
        // add other types handling here if needed

        return queryParts.join(' and ');
    } else {
        Object.keys(filterObj)
            .filter(key => key !== 'product') // Special-case the handling of product
            .forEach(key => {
                const origValue = filterObj[key];
                // Skip empty values (we still want to keep some falsey values like "0" and "false")
                if (
                    origValue === undefined ||
                    origValue === null ||
                    origValue === '' ||
                    (Array.isArray(origValue) && origValue.length <= 0)
                ) {
                    return;
                }

                // Force the value into an array to keep things simple
                let values: (string | number)[] = Array.isArray(origValue) ? origValue : [origValue];
                // Special-case the handling of is_paas_array. Array with 1 value === 'empty' should be treated as an empty array
                if (key === FILTER_ARRAY_INCLUDE_PAAS_FIELD && values.length === 1 && values[0] === 'empty') {
                    values = [];
                }

                if (options.paramsWrapWildcard && options.paramsWrapWildcard.includes(key)) {
                    const strValues = values.map(value => (value ? quoteAndEscape(value.toString()) : ''));
                    // for our wildcard matches, for now simply collect all of the ones with the same key together
                    // they will be consolidated into a single query parameter like:
                    // contains(array_name, ['name1', 'name2'])
                    // but the user can type them in separately
                    if (wildCards[key]) {
                        wildCards[key].push(...strValues);
                    } else {
                        wildCards[key] = strValues;
                    }
                } else if (options.paramsTags && options.paramsTags.includes(key)) {
                    // origValue is a Map<string, string[]>, since it is a tags field
                    origValue.forEach((tagValues: string[], tagKey: string) => {
                        const tagValueStr = tagValues.map(tagValue => quoteAndEscape(tagValue)).join(',');
                        queryParts.push(`tags(${quoteAndEscape(tagKey)},(${tagValueStr}))`);
                    });
                } else {
                    const strValues = values.map(value => (value ? quoteAndEscape(value.toString()) : ''));
                    // Example: { model: ['fa','ba','ca'] } => 'model=('fa','ba','ca')
                    queryParts.push(`${key}=(${strValues})`);
                }
            });
    }
    const wildKeys = Object.keys(wildCards);
    wildKeys.forEach(wildKey => {
        const queryString = `contains(${wildKey},(${wildCards[wildKey].join(',')}))`;
        queryParts.push(queryString);
    });

    return queryParts.join(' and ');
}
