@let capacityLabel = isFeatureEnabled ? 'Usable' : 'EUC';
<div class="license-desc-row">
    <div class="license-desc-form">
        <label class="license-desc-label" for="license-desc-input">
            License Description
            <pureui-svg
                svg="info-circle.svg"
                height="13"
                ngbTooltip="The name of the site/location where you want this service deployed."
                placement="top"
                container="body"
            >
            </pureui-svg>
        </label>
        <input
            id="license-desc-input"
            class="form-control"
            [maxlength]="NAME_MAX_LENGTH"
            [ngModel]="siteName"
            (ngModelChange)="onNameChange($event)"
        />
    </div>
    <div class="delete-license-button-container">
        <button type="button" class="close modal-x-button pull-right" (click)="deleteEntry()">
            <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" height="24"></pureui-svg>
        </button>
    </div>
</div>

<div class="reserved-capacity-row">
    <div class="license-type-container">
        <label for="license-type"> License Type</label>
        <select
            id="license-type"
            class="custom-select"
            required
            [ngModel]="selectedModel"
            (ngModelChange)="onModelChange($event)"
        >
            <option [ngValue]="undefined" hidden>--Select--</option>
            <option *ngFor="let model of flexHardwareModelNames" [ngValue]="model">
                {{ model }}
            </option>
        </select>
    </div>

    <div class="capacity-container">
        <label class="capacity-label">
            Reserved ({{ capacityLabel }})
            <div class="capacity-label-info-container">
                <pureui-svg
                    svg="info-circle.svg"
                    height="13"
                    ngbTooltip="Use the slider bar (or enter the value in the field) to select the required reserved
                        capacity for the license. The min and max reserve capacity limits for the license vary with
                        the number and capacity of arrays selected for that license."
                    placement="top"
                    container="body"
                >
                </pureui-svg>
            </div>
        </label>
        <div class="reserve-amount">
            <div class="pure1-common-slider">
                <ngx-slider
                    [value]="increaseAmountInSlider"
                    (valueChange)="onCapacityAmountChange($event)"
                    [options]="sliderOptions"
                ></ngx-slider>
            </div>
            <input
                #amount
                type="text"
                [maxlength]="CAPACITY_MAX_LENGTH"
                class="form-control"
                (blur)="onCapacityInputBlur(amount)"
                [ngModel]="increaseAmount"
                (ngModelChange)="onCapacityInputAmountChange($event)"
            />
            <span class="reserve-unit"> TiB </span>
        </div>
    </div>
</div>

<div class="workload-params-row">
    @if (!isFeatureEnabled) {
        <div class="pre-compressed-container">
            <label for="pre-type">Pre Compressed Data (optional)</label>
            <select id="pre-type" [ngModel]="selectedPre" (ngModelChange)="onPreChange($event)" class="custom-select">
                <option [ngValue]="undefined" hidden>--Select--</option>
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
            </select>
        </div>
    }
    <div class="under-capacity-container">
        <div class="workload-container">
            <label for="workload-type">Workload (optional)</label>
            <select
                id="workload-type"
                [ngModel]="selectedWorkload"
                (ngModelChange)="onWorkloadChange($event)"
                class="custom-select"
                required
                ngbTooltip="For custom workloads, please use the description box below to add details such as type of workload & performance characteristics."
                container="body"
                triggers="manual"
                #tooltip="ngbTooltip"
            >
                <option [ngValue]="undefined" hidden>--Select--</option>
                <option *ngFor="let opt of workloadStats; trackBy: 'name' | trackByProperty" [ngValue]="opt">
                    {{ opt.name }}
                </option>
            </select>
        </div>

        <div class="site-datepicker-container">
            <label class="site-datepicker-label">
                Requested Activation Date (optional)
                <pureui-svg
                    svg="info-circle.svg"
                    height="13"
                    ngbTooltip="Select the date you would like the capacity available for consumption."
                    placement="top"
                    container="body"
                >
                </pureui-svg>
            </label>
            <input
                class="form-control"
                placeholder="Choose a date"
                [ngModel]="siteStartDate"
                (ngModelChange)="onStartDateChange($event)"
                ngbDatepicker
                [minDate]="minDate"
                [firstDayOfWeek]="SUNDAY"
                readonly
                container="body"
                [markDisabled]="disablePastAndWeekends"
                #siteDatePicker="ngbDatepicker"
                (click)="siteDatePicker.toggle()"
            />
        </div>
    </div>
</div>

<div class="license-arrays-container" [ngClass]="{ 'license-arrays-loading': capacitiesLoading }">
    <pureui-spinner class="p-loading" [pureuiShow]="capacitiesLoading"></pureui-spinner>
    <div class="license-array-title">
        <span>Required Capacity</span>
        @let tooltipText =
            'Values represent the available capacity for the selected datapack configuration. Use the Reserved (' +
            capacityLabel +
            ') slider to adjust the required Reserved ' +
            capacityLabel +
            ' value for the license.';
        @if (isFeatureEnabled) {
            <pureui-svg svg="info-circle.svg" height="13" [ngbTooltip]="tooltipText" placement="top" container="body">
            </pureui-svg>
        } @else {
            <a
                href="https://support.purestorage.com/Pure1/Pure1_Manage/006_Analytics/Effective_Used_Capacity_Quick_Reference_Guide"
                target="_blank"
                [ngbTooltip]="tooltipText"
                placement="top"
                container="body"
            >
                How it works
            </a>
        }
    </div>
    <ng-container *ngFor="let licenseArray of licenseArrayCapacities; index as i; trackBy: trackByIndex">
        <div class="license-array-row">
            <div class="license-array-hardware-container">
                <select
                    id="license-array-hardware-config"
                    class="custom-select"
                    [ngModel]="licenseArray"
                    (ngModelChange)="onFlexHardwareCapacityChange($event, i)"
                >
                    <option
                        *ngFor="let hardwareConfigOption of flexHardwareModelsMap.get(selectedModel)?.capacity"
                        [ngValue]="hardwareConfigOption"
                    >
                        Raw: {{ hardwareConfigOption.rawTB | number: '1.0-1' }} TB, Usable:
                        {{ hardwareConfigOption.usableTiB | number: '1.0-1' }} TiB{{
                            isFeatureEnabled
                                ? ''
                                : ', Max EUC: ' + (hardwareConfigOption.maximumEUCTiB | number: '1.0-1') + ' TiB'
                        }}
                    </option>
                </select>
                <div class="delete-array-button-container">
                    <button
                        type="button"
                        class="close modal-x-button pull-right"
                        (click)="deleteLicenseArray(i)"
                        [ngClass]="{ disabled: licenseArrayCapacities?.length <= 1 }"
                    >
                        <pureui-svg class="pstg-action-icon" svg="trash_erase.svg" height="20"></pureui-svg>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <div
        class="add-license-array"
        (click)="licenseArrayCapacities?.length < 5 && selectedModel != null && addLicenseArray()"
        [ngClass]="{ disabled: licenseArrayCapacities?.length >= 5 || selectedModel == null }"
    >
        <span class="add-sign">+</span>
        <label class="add-sign-label">Add Array</label>
    </div>
</div>
