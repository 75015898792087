import {
    DraasApiAwsWorkerInfo,
    DraasApiNode,
    DraasApiVSphereWorkerInfo,
    DraasApiWorkerDisk,
} from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryNode {
    id: string;
    clusterId: string;
    status: 'WAITING_REGISTRATION' | 'PENDING' | 'HEALTHY' | 'UNHEALTHY' | 'READY';
    currentReleaseVersion?: string;
    workerInfo?: DisasterRecoveryVSphereWorkerInfo | DisasterRecoveryAwsWorkerInfo;

    constructor(json: DraasApiNode) {
        this.id = json.id.node_id;
        this.clusterId = json.id.cluster_id;
        this.status = json.state.status;
        this.currentReleaseVersion = json.current_release_version;
        this.workerInfo = json.worker_info
            ? json.worker_info.type === 'VSphereWorkerInfo'
                ? new DisasterRecoveryVSphereWorkerInfo(json.worker_info)
                : new DisasterRecoveryAwsWorkerInfo(json.worker_info as DraasApiAwsWorkerInfo)
            : undefined;
    }
}

export class DisasterRecoveryVSphereWorkerInfo {
    memoryInMiB: number;
    vcpu: number;
    disks: DisasterRecoveryWorkerDisk[];
    type: 'VSphereWorkerInfo';

    constructor(json: DraasApiVSphereWorkerInfo) {
        this.memoryInMiB = json.memory_in_mib;
        this.vcpu = json.vcpu;
        this.disks = json.disks?.map(disk => new DisasterRecoveryWorkerDisk(disk));
        this.type = 'VSphereWorkerInfo';
    }
}

export class DisasterRecoveryAwsWorkerInfo {
    memoryInMiB: number;
    vcpu: number;
    disks: DisasterRecoveryWorkerDisk[];
    type: 'AwsWorkerInfo';
    instanceType: string;
    region: string;

    constructor(json: DraasApiAwsWorkerInfo) {
        this.memoryInMiB = json.memory_in_mib;
        this.vcpu = json.vcpu;
        this.disks = json.disks?.map(disk => new DisasterRecoveryWorkerDisk(disk));
        this.type = 'AwsWorkerInfo';
        this.instanceType = json.instance_type;
        this.region = json.region;
    }
}

export class DisasterRecoveryWorkerDisk {
    sizeInBytes: number;

    constructor(json: DraasApiWorkerDisk) {
        this.sizeInBytes = json.size_in_bytes;
    }
}
