import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { UnifiedArray } from '../models/unified-array';
import { SortParams } from '../interfaces/list-params';

const unifiedArrayPerformanceFields: getArraysFields[] = [
    'aggregation',
    'appliance_id',
    'array_id',
    'busymeter_aggregation',
    'hostname',
    'os',
    'version',
    'install_address',
    'install_address_requested',
    'org_id',
    'model',
    'product',
    'fqdn',
    'contract_expiration_date',
    'contract_start_date',
    'contract_status',
    'active_status',
];
const unifiedArrayPerformanceParams = {
    pageStart: 0,
    pageSize: 3000,
    sort: <SortParams>{
        key: 'hostname',
        order: 'asc',
    },
    fields: unifiedArrayPerformanceFields,
};

@Injectable({ providedIn: 'root' })
export class UnifiedArrayPerformanceService extends GenericService<UnifiedArray> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: UnifiedArray,
            defaultParams: unifiedArrayPerformanceParams,
            endpoint: '/rest/v3/arrays',
            update: true,
        });
    }
}
