import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PureTeam } from '../utils/teamOwnership';
import { hasPermissionGuard } from '../app/guards/permission-guard';
import { NotificationCenterPageComponent } from './notification-center-page/notification-center-page.component';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { NotificationListComponent, RequestTabComponent } from '@pure/notification-center';

const routes: Routes = [
    {
        path: '',
        component: NotificationCenterPageComponent,
        canActivate: [hasPermissionGuard('PURE1:read:alert')], // TODO: [CLOUD-126709] Tritium to verify correct permission
        data: {
            ownedBy: PureTeam.TRITIUM,
        },
        title: '',
        children: [
            {
                path: 'preview',
                component: NotificationListComponent,
            },
            {
                path: 'requests',
                component: RequestTabComponent,
            },
            {
                path: '',
                redirectTo: 'preview',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'preferences',
        component: NotificationPreferencesComponent,
        canActivate: [hasPermissionGuard('PURE1:read:alert')], // TODO: [CLOUD-126709] Tritium to verify correct permission
        data: {
            ownedBy: PureTeam.TRITIUM,
        },
        title: '',
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'preview',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NotificationCenterRoutingModule {}
