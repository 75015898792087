import moment from 'moment';

export enum OrderScheduleStatus {
    SCHEDULE_STATUS_UNSPECIFIED = 'SCHEDULE_STATUS_UNSPECIFIED',
    SCHEDULE_DISABLED = 'SCHEDULE_DISABLED',
    SCHEDULE_PENDING = 'SCHEDULE_PENDING',
    SCHEDULE_IN_PROGRESS = 'SCHEDULE_IN_PROGRESS',
    SCHEDULE_CONFIRMED = 'SCHEDULE_CONFIRMED',
    INSTALLATION_COMPLETED = 'INSTALLATION_COMPLETED',
    CANCEL_REQUESTED = 'CANCEL_REQUESTED',
    CANCELLED = 'CANCELLED',
}

// Actions available for the USER to take on the order,
// i.e., actions available in Pure1, not on the PER side
export enum OrderUserPendingActions {
    NO_AVAILABLE_ACTION = 'NO_AVAILABLE_ACTION',
    SCHEDULE_INSTALLATION = 'SCHEDULE_INSTALLATION',
    MODIFY_INSTALLATION = 'MODIFY_INSTALLATION', // can be location or availability - maybe those need to be two different available actions? TBD
    CANCEL_INSTALLATION = 'CANCEL_INSTALLATION',
}

export enum OrderInstallationType {
    INSTALL_TYPE_UNSPECIFIED = 'INSTALL_TYPE_UNSPECIFIED',
    NEW = 'NEW',
    UPGRADE = 'UPGRADE',
}

export enum OrderScheduleAddressChangeStatus {
    ADDRESS_CHANGE_STATUS_UNSPECIFIED = 'ADDRESS_CHANGE_STATUS_UNSPECIFIED',
    CONFIRMED = 'CONFIRMED',
    IN_PROGRESS = 'IN_PROGRESS',
    DECLINED = 'DECLINED',
}

export class OrderInstallationCoordinator {
    name: string;
    email: string;
    phone: string;

    constructor(json: any) {
        this.name = json.name;
        this.email = json.email;
        this.phone = json.phone;
    }
}

export class OrderScheduleArrayProduct {
    salesforceSku: string;
    quantity: number;

    constructor(json: any) {
        this.salesforceSku = json.salesforce_sku;
        this.quantity = json.quantity;
    }
}

export class OrderScheduleArray {
    name: string;
    products: OrderScheduleArrayProduct[];

    constructor(json: any) {
        this.name = json.name;
        this.products = json.products ? json.products.map(product => new OrderScheduleArrayProduct(product)) : [];
    }
}

export class OrderScheduleArrayModel {
    model: string;
    quantity: number;
    arrays: OrderScheduleArray[];

    constructor(json: any) {
        this.model = json.model;
        this.quantity = json.quantity;
        this.arrays = json.arrays ? json.arrays.map(array => new OrderScheduleArray(array)) : [];
    }
}

export class OrderScheduleAddress {
    regionCode: string;
    postalCode: string;
    administrativeArea: string;
    locality: string;
    addressLines: string[];

    constructor(json: any) {
        this.regionCode = json.region_code;
        this.postalCode = json.postal_code;
        this.administrativeArea = json.administrative_area;
        this.locality = json.locality;
        this.addressLines = json.address_lines;
    }
}

export class OrderScheduleSlaViolations {
    requestedMinimumDays: number;
    requestedMaximumDays: number;
    earliestScheduleStartTime: moment.Moment;
    latestCancellationTime: moment.Moment;
    cancellationsRemaining: number;

    constructor(json: any) {
        this.requestedMinimumDays = json.requested_minimum_days;
        this.requestedMaximumDays = json.requested_maximum_days;
        this.earliestScheduleStartTime = json.earliest_schedule_start_timestamp
            ? moment(json.earliest_schedule_start_timestamp)
            : null;
        this.latestCancellationTime = json.latest_cancellation_timestamp
            ? moment(json.latest_cancellation_timestamp)
            : null;
        this.cancellationsRemaining = json.cancellation_remaining_count;
    }
}

export class OrderScheduleVisit {
    visitTime: moment.Moment;
    timeZone: string;
    arrayModels: OrderScheduleArrayModel[];

    constructor(json: any) {
        this.visitTime = moment(json.visit_timestamp);
        this.timeZone = json.time_zone;
        this.arrayModels = json.array_models
            ? json.array_models.map(arrayModel => new OrderScheduleArrayModel(arrayModel))
            : [];
    }
}

export class OrderInstallationRequestedTimeRangeDate {
    day: number;
    month: number;
    year: number;

    constructor(json: any) {
        this.day = json.day;
        this.month = json.month;
        this.year = json.year;
    }
}

export class OrderInstallationRequestedTimeRangeTime {
    dayOfWeek: string;
    startTime: string;
    endTime: string;

    constructor(json: any) {
        this.dayOfWeek = json.day_of_week;
        this.startTime = json.start_time;
        this.endTime = json.end_time;
    }
}

export class OrderInstallationRequestedTimeRange {
    timeZone: string;
    startDate: OrderInstallationRequestedTimeRangeDate;
    endDate: OrderInstallationRequestedTimeRangeDate;
    timeRanges: OrderInstallationRequestedTimeRangeTime[];

    constructor(json: any) {
        this.timeZone = json.time_zone;
        this.startDate = json.start_date ? new OrderInstallationRequestedTimeRangeDate(json.start_date) : null;
        this.endDate = json.end_date ? new OrderInstallationRequestedTimeRangeDate(json.end_date) : null;
        this.timeRanges = json.time_ranges
            ? json.time_ranges.map(timeRange => new OrderInstallationRequestedTimeRangeTime(timeRange))
            : [];
    }
}

export class InstallationSchedule {
    orderNumber: string;
    referenceId: string;

    address: OrderScheduleAddress;
    addressChangeStatus: OrderScheduleAddressChangeStatus;
    arrayModels: OrderScheduleArrayModel[];
    installType: OrderInstallationType;
    requestedTimeRange: OrderInstallationRequestedTimeRange;
    slaViolations: OrderScheduleSlaViolations;
    status: OrderScheduleStatus;
    statusChangeMessage: string;
    statusChangeTimestamp: moment.Moment;
    updateTime: moment.Moment;
    visits: OrderScheduleVisit[];

    constructor(json: any) {
        this.referenceId = json.opportunity_id;
        this.orderNumber = json.order_number;

        this.address = json.address ? new OrderScheduleAddress(json.address) : null;
        this.addressChangeStatus = json.address_change_status;
        this.arrayModels = json.array_models
            ? json.array_models.map(arrayModel => new OrderScheduleArrayModel(arrayModel))
            : [];
        this.installType = json.install_type;
        this.requestedTimeRange = json.requested_time_range
            ? new OrderInstallationRequestedTimeRange(json.requested_time_range)
            : null;
        this.slaViolations = json.sla_violations ? new OrderScheduleSlaViolations(json.sla_violations) : null;
        this.status = json.status;
        this.statusChangeMessage = json.status_change_message;
        this.statusChangeTimestamp = json.status_change_timestamp ? moment(json.status_change_timestamp) : null;
        this.updateTime = json.update_timestamp ? moment(json.update_timestamp) : null;
        this.visits = json.visits ? json.visits.map(visit => new OrderScheduleVisit(visit)) : [];
    }
}

export class OrderInstallationSchedule {
    orderNumber: string;
    address: OrderScheduleAddress;
    addressChangeStatus: OrderScheduleAddressChangeStatus;
    status: OrderScheduleStatus;
    installationSchedules: InstallationSchedule[];

    constructor(json: any) {
        this.orderNumber = json.order_number;
        this.address = json.address ? new OrderScheduleAddress(json.address) : null;
        this.addressChangeStatus = json.address_change_status;
        this.status = json.status;
        this.installationSchedules = json.installation_schedules
            ? json.installation_schedules.map(schedule => new InstallationSchedule(schedule))
            : [];
    }
}

export class OrderInstallation {
    referenceId: string;

    coordinator: OrderInstallationCoordinator;
    installationSchedules: OrderInstallationSchedule[];
    status: string;

    constructor(json: any) {
        this.referenceId = json.opportunity_id;

        this.coordinator = json.coordinator ? new OrderInstallationCoordinator(json.coordinator) : null;
        this.installationSchedules = json.installation_schedules_by_order
            ? json.installation_schedules_by_order.map(schedule => new OrderInstallationSchedule(schedule))
            : [];
        this.status = json.status;
    }
}
