import { DraasApiConfig } from './disaster-recovery-constants';

export type GFBEntity =
    | 'alerts'
    | 'arrays'
    | 'assessment'
    | 'asset subscription'
    | 'asset license'
    | 'asset appliance'
    | 'assignments'
    | 'audits'
    | 'buckets'
    | 'darksite'
    | 'directories'
    | 'draasVms'
    | 'edge service'
    | 'file systems'
    | 'file system replica links'
    | 'file system snapshots'
    | 'invoice'
    | 'object replica links'
    | 'opportunity quotes'
    | 'orders'
    | 'pods'
    | 'pod replica links'
    | 'portworx clusters'
    | 'portworx nodes'
    | 'portworx volumes'
    | 'portworx pools'
    | 'protection groups'
    | 'safeMode'
    | 'sessions'
    | 'snapshot volumes'
    | 'subscriptions'
    | 'subscription license'
    | 'sustainability'
    | 'licenses'
    | 'volumes'
    | 'volume snapshots'
    | 'security assessment'
    | 'directory snapshots'
    | 'pure skill subscription';

export const EntityEndpointMap: { [index in GFBEntity]?: string } = {
    alerts: '/rest/v4/alerts',
    arrays: '/rest/v3/arrays',
    assessment: '/rest/v1/dpa',
    'asset subscription': '/rest/v1/asset-management/subscription',
    'asset license': '/rest/v1/asset-management/license',
    'asset appliance': '/rest/v1/asset-management/appliance',
    assignments: '/rest/v1/assignments',
    audits: '/rest/v3/audits',
    buckets: '/rest/v3/buckets',
    darksite: '/rest/v1/darksite',
    directories: '/rest/v3/directories',
    'directory snapshots': '/rest/v4/directory-snapshots',
    draasVms: `${DraasApiConfig.getUrlPrefix()}/api/2.0/vms`,
    'edge service': '/rest/v1/ems/arrays',
    'file systems': '/rest/v4/file-systems',
    'file system replica links': '/rest/v3/file-system-replica-links',
    'file system snapshots': '/rest/v4/file-system-snapshots',
    invoice: '/rest/v1/partner/invoice',
    'object replica links': '/rest/v3/bucket-replica-links',
    'pure skill subscription': '/rest/v1/peak-subscriptions',
    pods: '/rest/v3/pods',
    'pod replica links': '/rest/v3/pod-replica-links',
    'portworx clusters': '/rest/v1/portworx/clusters',
    'portworx pools': '/rest/v1/portworx/pools',
    'portworx nodes': '/rest/v1/portworx/nodes',
    'portworx volumes': '/rest/v1/portworx/volumes',
    'protection groups': '/rest/v3/protection-groups',
    'opportunity quotes': '/rest/v1/quote-management',
    orders: '/rest/v3/sales-flows',
    safeMode: '/rest/v1/dpa/safe-mode', // capitalize M so it becomes 'SafeMode'
    sessions: '/rest/v3/sessions',
    'snapshot volumes': '/rest/v4/volumes',
    subscriptions: 'rest/v4/subscriptions',
    'subscription license': '/rest/v3/arrays/paas',
    licenses: 'rest/v3/subscription-licenses',
    sustainability: '/rest/v1/sustainability',
    'security assessment': '/rest/v1/security-assessment',
    volumes: '/rest/v3/volumes',
    'volume snapshots': '/rest/v4/volume-snapshots',
};

// use this if you need the Category that shows up in the GFB to be different from the entity
export const EntityDisplayNameMap: { [index in GFBEntity]?: string } = {
    'portworx clusters': 'clusters',
    'portworx pools': 'pools',
    'portworx nodes': 'nodes',
    'portworx volumes': 'volumes',
    'snapshot volumes': 'volumes',
    'asset subscription': 'subscriptions',
    'asset license': 'licenses',
    'asset appliance': 'assets',
};
