/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull spog-web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 79
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/purestorage/Pure1/implementation/spog-web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'e2fbbd672bacbea000b7def8c07b8e78',
  development: '5b5e84740689e7d20ebaa2fd921974f9'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '79',
    branch: 'main',
    source: 'spog-web',
    versionId: '1a52634e-100a-4ffb-9009-60ad9b694b2d'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * impersonating user's username/email - anpandey@purestorage.com
   */
  "impersonating user"?: string;
  /**
   * yes/true if the user is impersonating
   */
  "is impersonating user"?: boolean;
  /**
   * the logged in pure1 org for the user
   */
  "pure1 org"?: string;
  /**
   * pure1 org id
   */
  "pure1 org id"?: string;
  /**
   * user's role in the pure1 org they logged into
   */
  "pure1 user role"?: string;
  /**
   * time spent in minutes & seconds / session
   */
  "session duration"?: string;
  /**
   * Pure1 user's domain - example, @purestorage.com
   */
  "user domain"?: string;
  /**
   * logged in user email address
   */
  "user email"?: string;
  /**
   * geo/region from where the user is accessing the application
   */
  "user location"?: string;
}

export interface AdvancedOptionsClickedProperties {
  /**
   * the modal or page where advanced options button was clicked
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | prorec modal view, service catalog fa modal, service catalog hardware upgrade modal |
   */
  "advanced options from": "prorec modal view" | "service catalog fa modal" | "service catalog hardware upgrade modal";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
}

export interface AppSwitcherClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  appId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  appName?: string;
}

export interface ApplyPolicyBasedSafemodeSettingsErrorProperties {
  label: string;
}

export interface AssetManagementTaggingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "tagging behavior"?: string;
}

export interface BannerOpportunitiesProperties {
  "action details"?: string;
  "banner name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Successful Request, Failed Request, Clicked Custom Link, Clicked Read More, Dismiss Banner |
   */
  "banner opportunity action":
    | "Successful Request"
    | "Failed Request"
    | "Clicked Custom Link"
    | "Clicked Read More"
    | "Dismiss Banner";
}

export interface BottomTabsSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Evergreen//Flex, Evergreen//Forever, Evergreen//Foundation, Evergreen//One, Pure Protect |
   */
  "subscription program type":
    | "Evergreen//Flex"
    | "Evergreen//Forever"
    | "Evergreen//Foundation"
    | "Evergreen//One"
    | "Pure Protect";
}

export interface CatalogUniversalCreditQuoteRequestingProperties {
  quoteType: string;
}

export interface ChangeNotificationPreferenceProperties {
  /**
   * notification channel
   */
  channel?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  eventType?: string;
  topic: string;
}

export interface CopilotChatSuggestionClickedProperties {
  question: string;
}

export interface CopilotChatSuggestionGroupBubbleClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | suggested, performance, compliance, sustainability, security, invoices, release-advisor |
   */
  groupName:
    | "suggested"
    | "performance"
    | "compliance"
    | "sustainability"
    | "security"
    | "invoices"
    | "release-advisor";
}

export interface CopilotMessageFeedbackClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | thumbs up, thumbs down |
   */
  messageFeedback: "thumbs up" | "thumbs down";
}

export interface CopilotOnboardingModalButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | try now, maybe later |
   */
  onboardingButtonClicked: "try now" | "maybe later";
}

export interface DataProtectionV2GfbKeyboardPillDeleteProperties {
  category?: any;
  label?: any;
}

export interface DataProtectionV2ApplianceDetailClickToActionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Upgrade Purity, Simulate Capacity, Update SafeMode, Update Eradication Delay, Review Local Snapshots, View DRR drop |
   */
  action:
    | "Upgrade Purity"
    | "Simulate Capacity"
    | "Update SafeMode"
    | "Update Eradication Delay"
    | "Review Local Snapshots"
    | "View DRR drop";
}

export interface DataProtectionV2DrrDialogFeedbackButtonClickProperties {
  isUseful: boolean;
}

export interface DataProtectionV2DrrDialogFeedbackSentProperties {
  /**
   * Is DRR dialog helpful
   */
  feedback: boolean;
}

export interface DataProtectionV2DrrDialogSortActionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  field?: string;
}

export interface DataProtectionV2DrrDialogVolumeClickProperties {
  /**
   * Is item selected
   */
  selected: boolean;
}

export interface DataProtectionV2EntityDetailDialogFilterActionProperties {
  /**
   * Filter used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Local Snapshots, Replicated Snapshots, Entity Name |
   */
  filter: "Local Snapshots" | "Replicated Snapshots" | "Entity Name";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | MEETS_GUIDANCE, REVIEW_REQUIRED, Any |
   */
  ruleLevel?: "MEETS_GUIDANCE" | "REVIEW_REQUIRED" | "Any";
}

export interface DataProtectionV2EntityDetailDialogHoverActionProperties {
  /**
   * Component hover action is triggered on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | How we assess |
   */
  hoverOn: "How we assess";
}

export interface DataProtectionV2EntityDetailDialogSortActionProperties {
  /**
   * Column name used for Sort Action
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Appliance, Assessment, Model, Version, SafeMode Status, SafeMode Recoverability, Eradication Delay, Local Snapshots, Replicated Snapshots, Entity Name |
   */
  field:
    | "Appliance"
    | "Assessment"
    | "Model"
    | "Version"
    | "SafeMode Status"
    | "SafeMode Recoverability"
    | "Eradication Delay"
    | "Local Snapshots"
    | "Replicated Snapshots"
    | "Entity Name";
}

export interface DataProtectionV2ExclusionRulesDialogChangeDialogStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | REVIEW STEP, RULES STEP |
   */
  "dialog step": "REVIEW STEP" | "RULES STEP";
}

export interface DataProtectionV2ExclusionRulesDialogChangeTablePageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NEWLY_EXCLUDED, REASSESSED, TOTAL_EXCLUDED |
   */
  table: "NEWLY_EXCLUDED" | "REASSESSED" | "TOTAL_EXCLUDED";
}

export interface DataProtectionV2ExclusionRulesDialogChangeTableViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NEWLY_EXCLUDED, REASSESSED, TOTAL_EXCLUDED |
   */
  table: "NEWLY_EXCLUDED" | "REASSESSED" | "TOTAL_EXCLUDED";
}

export interface DataProtectionV2ExclusionRulesDialogFilterTableProperties {
  columnId: string;
  "filter value"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NEWLY_EXCLUDED, REASSESSED, TOTAL_EXCLUDED |
   */
  table: "NEWLY_EXCLUDED" | "REASSESSED" | "TOTAL_EXCLUDED";
}

export interface DataProtectionV2ExclusionRulesDialogOpenTheDialogProperties {
  /**
   * source place where the exclusions dialog is opened from
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | MAIN MENU, HONEYCOMB LINK, HOW WE ASSESS TOOLTIP, HOW WE CALCULATE TOOLTIP, ASSESSMENT ENTITY DETAIL DIALOG |
   */
  "source element":
    | "MAIN MENU"
    | "HONEYCOMB LINK"
    | "HOW WE ASSESS TOOLTIP"
    | "HOW WE CALCULATE TOOLTIP"
    | "ASSESSMENT ENTITY DETAIL DIALOG";
}

export interface DataProtectionV2ExclusionRulesDialogSortTableProperties {
  columnId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NEWLY_EXCLUDED, REASSESSED, TOTAL_EXCLUDED |
   */
  table: "NEWLY_EXCLUDED" | "REASSESSED" | "TOTAL_EXCLUDED";
}

export interface DataProtectionV2HoneycombChartApplianceClickProperties {
  drr?: boolean;
  /**
   * Is item selected
   */
  selected: boolean;
}

export interface DataProtectionV2HoneycombChartHoverActionProperties {
  /**
   * Component hover action is triggered on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | How we assess |
   */
  hoverOn: "How we assess";
}

export interface DataProtectionV2InsightsGoToApplianceDetailProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP";
}

export interface DataProtectionV2InsightsHoverOverSectionProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface DataProtectionV2InsightsSidebarClosedProperties {
  stepBack?: boolean;
}

export interface DataProtectionV2InsightsSidebarOpenedProperties {
  /**
   * True if sidebar with insights is opened by going back from appliance detail
   */
  stepBack: boolean;
}

export interface DataProtectionV2InsightsToggleCollapseProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface DataProtectionV2LocalSnapshotsOpenEntityDetailDialogProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | REVIEW_REQUIRED, MEETS_GUIDANCE |
   */
  ruleLevel: "REVIEW_REQUIRED" | "MEETS_GUIDANCE";
}

export interface DataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialogProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | REVIEW_REQUIRED, MEETS_GUIDANCE |
   */
  ruleLevel: "REVIEW_REQUIRED" | "MEETS_GUIDANCE";
}

export interface DataProtectionV2ResiliencyScorePanelHoverActionProperties {
  /**
   * Component hover action is triggered on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | How we assess, How we calculate, Feature icon |
   */
  hoverOn: "How we assess" | "How we calculate" | "Feature icon";
}

export interface DataProtectionV2TableViewApplianceClickProperties {
  /**
   * Is appliance selected
   */
  selected: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewCapacityViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewColumnSelectionProperties {
  columnId: string;
  isSelected: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewEntityCountViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewHoverOverTableProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewOpenEntityDetailDialogProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewSortActionProperties {
  key: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | asc, desc |
   */
  order: "asc" | "desc";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DataProtectionV2TableViewSwitchTableViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Assessment, Resiliency |
   */
  tableView: "Assessment" | "Resiliency";
}

export interface DisplayOptionsApplyProperties {
  /**
   * Applied for appliance genealogy event types
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | hardware upgrade, software upgrade, upcoming renewal, all eol, all dispatch event, ever modern upgrade |
   */
  "genealogy event types"?:
    | "hardware upgrade"
    | "software upgrade"
    | "upcoming renewal"
    | "all eol"
    | "all dispatch event"
    | "ever modern upgrade";
}

export interface DocumentationLinkClickedProperties {
  /**
   * Url path name (eg "/fleet/appliances/arrays"). Does not include query params.
   */
  "page pathname": string;
}

export interface EmsValidatorAcknowledgeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "org id": number;
  step: string;
}

export interface EmsValidatorCancelProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "org id": number;
  screen: string;
}

export interface EmsValidatorConfirmProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "org id": number;
}

export interface EmsValidatorStartProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "org id": number;
}

export interface ExportApplianceGenealogyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all, selected |
   */
  "genealogy export category": "all" | "selected";
}

export interface ExportingOnAssetTabProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Asset Management, Appliance Genealogy, Subscription Genealogy, Quote Management, Invoice Management |
   */
  "assets tab page":
    | "Asset Management"
    | "Appliance Genealogy"
    | "Subscription Genealogy"
    | "Quote Management"
    | "Invoice Management";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all, filtered |
   */
  "export category": "all" | "filtered";
}

export interface GfbTagUsageProperties {
  /**
   * List of array property keys (not to be confused with cloud tags)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "array properties": string[];
  /**
   * List of cloud tag keys (not to be confused with array properties)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "cloud tags": string[];
  /**
   * If this event was emitted as the result of a page/component being initialized, and not the user actually interacting with it.
   *
   * Usually emitting events automatically as part of page/component initialization is a bad idea, but sometimes it is what you need to do, so here we are.
   */
  "is initialization event": boolean;
  /**
   * For the "most values key", how many values there are
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "most values count"?: number;
  /**
   * Which filter key has the most values
   */
  "most values key"?: string;
  /**
   * Url path name (eg "/fleet/appliances/arrays"). Does not include query params.
   */
  "page pathname": string;
  /**
   * Total count of filter keys (cloud tags + appliance properties)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "total key count": number;
}

export interface HelpClickedProperties {
  "help item"?: string;
  "help path"?: string;
}

export interface InvoiceManagementDisputeInvoiceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | success, failed |
   */
  "submit status": "success" | "failed";
}

export interface ModalChartShownProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
}

export interface NeedsAttentionDropdownSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Evergreen//Flex, Evergreen//Forever, Evergreen//Foundation, Evergreen//One, Pure Protect |
   */
  "subscription program type":
    | "Evergreen//Flex"
    | "Evergreen//Forever"
    | "Evergreen//Foundation"
    | "Evergreen//One"
    | "Pure Protect";
}

export interface OpenPreferencesOfNotificationCenterProperties {
  "opened from popup": boolean;
}

export interface OrdersPageOrderRequestedTimeSelectionProperties {
  requestedAt: string;
}

export interface OrdersPageOrderStatusSelectionProperties {
  /**
   * whether the orders are open or closed orders
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | open, closed, all |
   */
  orderStatus: "open" | "closed" | "all";
}

export interface OrdersPageOrderTypeSelectionProperties {
  /**
   * whether the orders come from the pure1 (self-service) or SFDC (assisted)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | self-service, assisted |
   */
  orderType: "self-service" | "assisted";
}

export interface OrganisationSwitcherExpandedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalOrgsCount: number;
}

export interface OrganisationToSwitchSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentOrgId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  targetOrgId: number;
}

export interface PerformanceTableFilterChangeProperties {
  "filter entity"?: string;
  "filter key": string;
  "filter value"?: string;
  /**
   * Url path name (eg "/fleet/appliances/arrays"). Does not include query params.
   */
  "page pathname": string;
}

export interface ProrecCancelledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
}

export interface ProrecCapacityCardClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
}

export interface ProrecCombinedCardClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
}

export interface ProrecControllerCardClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
}

export interface ProrecDismissIconClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | card view, prorec modal view |
   */
  "dismissal source": "card view" | "prorec modal view";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
}

export interface ProrecDismissedProperties {
  /**
   * pass the value user clicked or commented as the reason to discuss the recommendation
   */
  "reason dismissed": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | generator, systems engineer |
   */
  "recommendation creator": "generator" | "systems engineer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
}

export interface ProrecSettingsModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | service catalog page, recommendations page, email notifications, prorec dismiss modal |
   */
  "prorec settings modal opened from":
    | "service catalog page"
    | "recommendations page"
    | "email notifications"
    | "prorec dismiss modal";
}

export interface ProrecSettingsModifiedProperties {
  "capacity threshold days changed": string;
  "capacity threshold percent changed": string;
  "notification frequency changed": string;
  "subscription capacity threshold days changed": string;
  "subscription capacity threshold percent changed": string;
}

export interface QuoteRequestedProperties {
  /**
   * Page/Modal where the quote request initiated
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | proactive recommendation modal, planning page modal, catalog detail page, subscription page, se recommendation modal |
   */
  "quote requested from":
    | "proactive recommendation modal"
    | "planning page modal"
    | "catalog detail page"
    | "subscription page"
    | "se recommendation modal";
  /**
   * the type of request - fa, fb, e//one, e//flex & expansion, renewals, net new
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capex capacity expansion, capex controller upgrades, capex new, capex controller and capacity upgrade, capex m2x upgrade, capex 6g eol upgrade, capex lean trade up, evergreen//one new, evergreen//one expansion, evergreen//one renewal, evergreen//forever&foundation renewal, evergreen//flex new, evergreen//flex expansion, evergreen//flex renewal, pure protect new, pure protect expansion, pure protect renewal, unknown, capex eol 1.1 upgrade, capex eol 2.0 upgrade, capex eol c60r1 upgrade |
   */
  "requested configuration":
    | "capex capacity expansion"
    | "capex controller upgrades"
    | "capex new"
    | "capex controller and capacity upgrade"
    | "capex m2x upgrade"
    | "capex 6g eol upgrade"
    | "capex lean trade up"
    | "evergreen//one new"
    | "evergreen//one expansion"
    | "evergreen//one renewal"
    | "evergreen//forever&foundation renewal"
    | "evergreen//flex new"
    | "evergreen//flex expansion"
    | "evergreen//flex renewal"
    | "pure protect new"
    | "pure protect expansion"
    | "pure protect renewal"
    | "unknown"
    | "capex eol 1.1 upgrade"
    | "capex eol 2.0 upgrade"
    | "capex eol c60r1 upgrade";
}

export interface RedirectToAlertsPageFromNotificationsPopupProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  eventType?: string;
}

export interface SeRecCreateModalCancelledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "target organization id": number;
}

export interface SeRecCreateModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "target organization id": number;
}

export interface SeRecCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | capacity upgrade, controller upgrade, combined upgrade, m2x upgrade, 6g eol upgrade, lean sas to nvme upgrade, evergreen one reserve commit expansion, eol 1.1 upgrade, eol 2.0 upgrade, eol c60r1 upgrade |
   */
  "recommendation type":
    | "capacity upgrade"
    | "controller upgrade"
    | "combined upgrade"
    | "m2x upgrade"
    | "6g eol upgrade"
    | "lean sas to nvme upgrade"
    | "evergreen one reserve commit expansion"
    | "eol 1.1 upgrade"
    | "eol 2.0 upgrade"
    | "eol c60r1 upgrade";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "target organization id": number;
}

export interface SecurityAssessmentApplianceUrlClickProperties {
  /**
   * source place of redirect to appliances page in Security Assessment
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SECURITY_TABLE, SECURITY_ANOMALIES_DIALOG, SECURITY_HISTORICAL_ANOMALIES_DIALOG, SECURITY_HISTORICAL_ALERTS_DIALOG, SECURITY_HISTORICAL_AUDIT_DIALOG, SECURITY_HISTORICAL_REMOTE_SESSIONS_DIALOG |
   */
  sourcePlace:
    | "SECURITY_TABLE"
    | "SECURITY_ANOMALIES_DIALOG"
    | "SECURITY_HISTORICAL_ANOMALIES_DIALOG"
    | "SECURITY_HISTORICAL_ALERTS_DIALOG"
    | "SECURITY_HISTORICAL_AUDIT_DIALOG"
    | "SECURITY_HISTORICAL_REMOTE_SESSIONS_DIALOG";
}

export interface SecurityAssessmentCurrentAnomaliesDialogAnomalyTagSelectProperties {
  /**
   * Current anomaly tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DRR, WRITE_LATENCY, READ_LATENCY, OBJECT_COUNT_VOLUMES, OBJECT_COUNT_SNAPSHOTS |
   */
  tab: "DRR" | "WRITE_LATENCY" | "READ_LATENCY" | "OBJECT_COUNT_VOLUMES" | "OBJECT_COUNT_SNAPSHOTS";
}

export interface SecurityAssessmentCurrentAnomaliesDialogApplianceSelectProperties {
  /**
   * Current anomaly tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DRR, WRITE_LATENCY, READ_LATENCY, OBJECT_COUNT_VOLUMES, OBJECT_COUNT_SNAPSHOTS, UNUSUAL_ACTIVITIES |
   */
  tab:
    | "DRR"
    | "WRITE_LATENCY"
    | "READ_LATENCY"
    | "OBJECT_COUNT_VOLUMES"
    | "OBJECT_COUNT_SNAPSHOTS"
    | "UNUSUAL_ACTIVITIES";
}

export interface SecurityAssessmentCurrentAnomaliesDialogTabSelectProperties {
  /**
   * Current anomaly tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DRR, WRITE_LATENCY, READ_LATENCY, OBJECT_COUNT_VOLUMES, OBJECT_COUNT_SNAPSHOTS, UNUSUAL_ACTIVITIES |
   */
  tab:
    | "DRR"
    | "WRITE_LATENCY"
    | "READ_LATENCY"
    | "OBJECT_COUNT_VOLUMES"
    | "OBJECT_COUNT_SNAPSHOTS"
    | "UNUSUAL_ACTIVITIES";
}

export interface SecurityAssessmentCurrentAnomaliesDialogVolumeSelectProperties {
  /**
   * Is item selected
   */
  selected: boolean;
  /**
   * Current anomaly tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DRR, WRITE_LATENCY, READ_LATENCY, OBJECT_COUNT_VOLUMES, OBJECT_COUNT_SNAPSHOTS |
   */
  tab: "DRR" | "WRITE_LATENCY" | "READ_LATENCY" | "OBJECT_COUNT_VOLUMES" | "OBJECT_COUNT_SNAPSHOTS";
}

export interface SecurityAssessmentCurrentAnomaliesDialogVolumesTableSortProperties {
  key: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | asc, desc |
   */
  order: "asc" | "desc";
  /**
   * Current anomaly tab
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DRR, WRITE_LATENCY, READ_LATENCY, OBJECT_COUNT_VOLUMES, OBJECT_COUNT_SNAPSHOTS |
   */
  tab: "DRR" | "WRITE_LATENCY" | "READ_LATENCY" | "OBJECT_COUNT_VOLUMES" | "OBJECT_COUNT_SNAPSHOTS";
}

export interface SecurityAssessmentFleetHoneycombClickProperties {
  /**
   * security assessment metric
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATA_ENCRYPTION_DISABLED, PURITY_END_OF_LIFE_VERSION_IN_USE, PURITY_OPTIMIZATIONS_PENDING, REMOTE_ACCESS_CONTROL_ENABLED, NTP_SERVER_ENABLED_AND_RUNNING, RAPID_DATA_LOCKING_ENABLED, PUBLIC_OR_OPEN_BUCKET_ACCESS, DEFAULT_PASSWORD_IN_USE, LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS, CURRENT_CRITICAL_ALERTS, DATA_ENCRYPTION_ENABLED, DATA_PROTECTION_FEATURES, CURRENT_ANOMALIES, NOT_PHONING_HOME, REMOTE_ASSIST_SESSIONS, ADMIN_OPERATIONS, CRITICAL_ALERTS, ANOMALIES, OTHER_COMMON_VULNERABILITIES_EXPOSURES, CRITICAL_COMMON_VULNERABILITIES_EXPOSURES |
   */
  metric:
    | "DATA_ENCRYPTION_DISABLED"
    | "PURITY_END_OF_LIFE_VERSION_IN_USE"
    | "PURITY_OPTIMIZATIONS_PENDING"
    | "REMOTE_ACCESS_CONTROL_ENABLED"
    | "NTP_SERVER_ENABLED_AND_RUNNING"
    | "RAPID_DATA_LOCKING_ENABLED"
    | "PUBLIC_OR_OPEN_BUCKET_ACCESS"
    | "DEFAULT_PASSWORD_IN_USE"
    | "LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS"
    | "CURRENT_CRITICAL_ALERTS"
    | "DATA_ENCRYPTION_ENABLED"
    | "DATA_PROTECTION_FEATURES"
    | "CURRENT_ANOMALIES"
    | "NOT_PHONING_HOME"
    | "REMOTE_ASSIST_SESSIONS"
    | "ADMIN_OPERATIONS"
    | "CRITICAL_ALERTS"
    | "ANOMALIES"
    | "OTHER_COMMON_VULNERABILITIES_EXPOSURES"
    | "CRITICAL_COMMON_VULNERABILITIES_EXPOSURES";
  /**
   * Is item selected
   */
  selected: boolean;
}

export interface SecurityAssessmentFleetMetricClickProperties {
  /**
   * security assessment metric
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATA_ENCRYPTION_DISABLED, PURITY_END_OF_LIFE_VERSION_IN_USE, PURITY_OPTIMIZATIONS_PENDING, REMOTE_ACCESS_CONTROL_ENABLED, NTP_SERVER_ENABLED_AND_RUNNING, RAPID_DATA_LOCKING_ENABLED, PUBLIC_OR_OPEN_BUCKET_ACCESS, DEFAULT_PASSWORD_IN_USE, LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS, CURRENT_CRITICAL_ALERTS, DATA_ENCRYPTION_ENABLED, DATA_PROTECTION_FEATURES, CURRENT_ANOMALIES, NOT_PHONING_HOME, REMOTE_ASSIST_SESSIONS, ADMIN_OPERATIONS, CRITICAL_ALERTS, ANOMALIES, OTHER_COMMON_VULNERABILITIES_EXPOSURES, CRITICAL_COMMON_VULNERABILITIES_EXPOSURES |
   */
  metric:
    | "DATA_ENCRYPTION_DISABLED"
    | "PURITY_END_OF_LIFE_VERSION_IN_USE"
    | "PURITY_OPTIMIZATIONS_PENDING"
    | "REMOTE_ACCESS_CONTROL_ENABLED"
    | "NTP_SERVER_ENABLED_AND_RUNNING"
    | "RAPID_DATA_LOCKING_ENABLED"
    | "PUBLIC_OR_OPEN_BUCKET_ACCESS"
    | "DEFAULT_PASSWORD_IN_USE"
    | "LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS"
    | "CURRENT_CRITICAL_ALERTS"
    | "DATA_ENCRYPTION_ENABLED"
    | "DATA_PROTECTION_FEATURES"
    | "CURRENT_ANOMALIES"
    | "NOT_PHONING_HOME"
    | "REMOTE_ASSIST_SESSIONS"
    | "ADMIN_OPERATIONS"
    | "CRITICAL_ALERTS"
    | "ANOMALIES"
    | "OTHER_COMMON_VULNERABILITIES_EXPOSURES"
    | "CRITICAL_COMMON_VULNERABILITIES_EXPOSURES";
}

export interface SecurityAssessmentFleetTooltipHoverProperties {
  complianceTooltip: boolean;
  /**
   * security assessment metric
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATA_ENCRYPTION_DISABLED, PURITY_END_OF_LIFE_VERSION_IN_USE, PURITY_OPTIMIZATIONS_PENDING, REMOTE_ACCESS_CONTROL_ENABLED, NTP_SERVER_ENABLED_AND_RUNNING, RAPID_DATA_LOCKING_ENABLED, PUBLIC_OR_OPEN_BUCKET_ACCESS, DEFAULT_PASSWORD_IN_USE, LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS, CURRENT_CRITICAL_ALERTS, DATA_ENCRYPTION_ENABLED, DATA_PROTECTION_FEATURES, CURRENT_ANOMALIES, NOT_PHONING_HOME, REMOTE_ASSIST_SESSIONS, ADMIN_OPERATIONS, CRITICAL_ALERTS, ANOMALIES, OTHER_COMMON_VULNERABILITIES_EXPOSURES, CRITICAL_COMMON_VULNERABILITIES_EXPOSURES |
   */
  metric:
    | "DATA_ENCRYPTION_DISABLED"
    | "PURITY_END_OF_LIFE_VERSION_IN_USE"
    | "PURITY_OPTIMIZATIONS_PENDING"
    | "REMOTE_ACCESS_CONTROL_ENABLED"
    | "NTP_SERVER_ENABLED_AND_RUNNING"
    | "RAPID_DATA_LOCKING_ENABLED"
    | "PUBLIC_OR_OPEN_BUCKET_ACCESS"
    | "DEFAULT_PASSWORD_IN_USE"
    | "LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS"
    | "CURRENT_CRITICAL_ALERTS"
    | "DATA_ENCRYPTION_ENABLED"
    | "DATA_PROTECTION_FEATURES"
    | "CURRENT_ANOMALIES"
    | "NOT_PHONING_HOME"
    | "REMOTE_ASSIST_SESSIONS"
    | "ADMIN_OPERATIONS"
    | "CRITICAL_ALERTS"
    | "ANOMALIES"
    | "OTHER_COMMON_VULNERABILITIES_EXPOSURES"
    | "CRITICAL_COMMON_VULNERABILITIES_EXPOSURES";
}

export interface SecurityAssessmentFleetStatisticsHoverActionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | HOW_WE_CALCULATE, TOTAL_SCORE, COMPLIANCE, OPERATIONAL, TOTAL_COUNT, NOT_PHONING_HOME, EXCLUDED |
   */
  tooltip:
    | "HOW_WE_CALCULATE"
    | "TOTAL_SCORE"
    | "COMPLIANCE"
    | "OPERATIONAL"
    | "TOTAL_COUNT"
    | "NOT_PHONING_HOME"
    | "EXCLUDED";
}

export interface SecurityAssessmentHistoricalDataDialogApplianceSelectProperties {
  /**
   * security assessment metric
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DATA_ENCRYPTION_DISABLED, PURITY_END_OF_LIFE_VERSION_IN_USE, PURITY_OPTIMIZATIONS_PENDING, REMOTE_ACCESS_CONTROL_ENABLED, NTP_SERVER_ENABLED_AND_RUNNING, RAPID_DATA_LOCKING_ENABLED, PUBLIC_OR_OPEN_BUCKET_ACCESS, DEFAULT_PASSWORD_IN_USE, LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS, CURRENT_CRITICAL_ALERTS, DATA_ENCRYPTION_ENABLED, DATA_PROTECTION_FEATURES, CURRENT_ANOMALIES, NOT_PHONING_HOME, REMOTE_ASSIST_SESSIONS, ADMIN_OPERATIONS, CRITICAL_ALERTS, ANOMALIES, OTHER_COMMON_VULNERABILITIES_EXPOSURES, CRITICAL_COMMON_VULNERABILITIES_EXPOSURES |
   */
  metric:
    | "DATA_ENCRYPTION_DISABLED"
    | "PURITY_END_OF_LIFE_VERSION_IN_USE"
    | "PURITY_OPTIMIZATIONS_PENDING"
    | "REMOTE_ACCESS_CONTROL_ENABLED"
    | "NTP_SERVER_ENABLED_AND_RUNNING"
    | "RAPID_DATA_LOCKING_ENABLED"
    | "PUBLIC_OR_OPEN_BUCKET_ACCESS"
    | "DEFAULT_PASSWORD_IN_USE"
    | "LONG_TIME_LIVING_REMOTE_ASSIST_SESSIONS"
    | "CURRENT_CRITICAL_ALERTS"
    | "DATA_ENCRYPTION_ENABLED"
    | "DATA_PROTECTION_FEATURES"
    | "CURRENT_ANOMALIES"
    | "NOT_PHONING_HOME"
    | "REMOTE_ASSIST_SESSIONS"
    | "ADMIN_OPERATIONS"
    | "CRITICAL_ALERTS"
    | "ANOMALIES"
    | "OTHER_COMMON_VULNERABILITIES_EXPOSURES"
    | "CRITICAL_COMMON_VULNERABILITIES_EXPOSURES";
}

export interface SecurityAssessmentHistoricalDataDialogRedirectToDetailsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | audit, alerts |
   */
  redirect: "audit" | "alerts";
}

export interface SecurityAssessmentHoneycombChartApplianceClickProperties {
  drr?: boolean;
  /**
   * Is item selected
   */
  selected: boolean;
}

export interface SecurityAssessmentHoneycombChartHoverActionProperties {
  /**
   * Component hover action is triggered on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | How we assess |
   */
  hoverOn: "How we assess";
}

export interface SecurityAssessmentHoneycombChartLegendClickProperties {
  assessmentLevel: string;
}

export interface SecurityAssessmentInsightsClickOnActionProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface SecurityAssessmentInsightsGoToApplianceDetailProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface SecurityAssessmentInsightsHoverOverSectionProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface SecurityAssessmentInsightsSidebarClosedProperties {
  stepBack?: boolean;
}

export interface SecurityAssessmentInsightsSidebarOpenedProperties {
  /**
   * True if sidebar with insights is opened by going back from appliance detail
   */
  stepBack: boolean;
}

export interface SecurityAssessmentInsightsToggleCollapseProperties {
  /**
   * Insight type used
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE, INCREASE_CAPACITY_FOR_ARRAY_WIDE, TURN_ON_ARRAY_WIDE, UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER, INCREASE_ERADICATION_TIMER, UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS, UPDATE_POLICY_FOR_REPLICATION, UPGRADE_PURITY_VERSION_FOR_REPLICATION, INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE, SWITCH_P_GROUP_TO_ARRAY_WIDE, TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK, TURN_ON_P_GROUP, INCREASE_CAPACITY_FOR_P_GROUP, DRR_DROP, PUBLIC_ACCESS_RISK, REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS, REMOTE_ASSIST_LONGER_THAN_ONE_DAY, END_OF_LIFE_RISK, DEFAULT_PASSWORD_RISK, REVIEW_DATA_PROTECTION, CURRENT_DRR_ANOMALIES_DETECTED, CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED, CURRENT_LATENCY_READ_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED, CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED, REMOTE_ACCESS_CONTROL_NOT_ENABLED, NTP_SERVER_NOT_ENABLED, PURITY_OPTIMIZATIONS_AVAILABLE, APPLIANCE_NOT_PHONING_HOME, CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED, SOFTWARE_DATA_ENCRYPTION_DISABLED, RAPID_DATA_LOCKING_NOT_ENABLED, CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED, CURRENT_MASS_DELETION_ANOMALIES_DETECTED, CRITICAL_CVE_DETECTED, OTHER_CVE_DETECTED |
   */
  insight:
    | "UPGRADE_PURITY_VERSION_AND_INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ARRAY_WIDE"
    | "INCREASE_CAPACITY_FOR_ARRAY_WIDE"
    | "TURN_ON_ARRAY_WIDE"
    | "UPGRADE_PURITY_VERSION_FOR_ERADICATION_TIMER"
    | "INCREASE_ERADICATION_TIMER"
    | "UPDATE_POLICY_FOR_LOCAL_SNAPSHOTS"
    | "UPDATE_POLICY_FOR_REPLICATION"
    | "UPGRADE_PURITY_VERSION_FOR_REPLICATION"
    | "INCREASE_CAPACITY_FOR_SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "SWITCH_P_GROUP_TO_ARRAY_WIDE"
    | "TURN_ON_P_GROUP_SNAPSHOT_RETENTION_LOCK"
    | "TURN_ON_P_GROUP"
    | "INCREASE_CAPACITY_FOR_P_GROUP"
    | "DRR_DROP"
    | "PUBLIC_ACCESS_RISK"
    | "REMOTE_ASSIST_LONGER_THAN_EIGHT_HOURS"
    | "REMOTE_ASSIST_LONGER_THAN_ONE_DAY"
    | "END_OF_LIFE_RISK"
    | "DEFAULT_PASSWORD_RISK"
    | "REVIEW_DATA_PROTECTION"
    | "CURRENT_DRR_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_WRITE_ANOMALIES_DETECTED"
    | "CURRENT_LATENCY_READ_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_VOLUME_ANOMALIES_DETECTED"
    | "CURRENT_OBJECT_COUNT_SNAPSHOTS_ANOMALIES_DETECTED"
    | "REMOTE_ACCESS_CONTROL_NOT_ENABLED"
    | "NTP_SERVER_NOT_ENABLED"
    | "PURITY_OPTIMIZATIONS_AVAILABLE"
    | "APPLIANCE_NOT_PHONING_HOME"
    | "CURRENTLY_OPEN_CRITICAL_ALERTS_DETECTED"
    | "SOFTWARE_DATA_ENCRYPTION_DISABLED"
    | "RAPID_DATA_LOCKING_NOT_ENABLED"
    | "CURRENT_ADMIN_ACTIVITY_ANOMALIES_DETECTED"
    | "CURRENT_MASS_DELETION_ANOMALIES_DETECTED"
    | "CRITICAL_CVE_DETECTED"
    | "OTHER_CVE_DETECTED";
}

export interface SecurityAssessmentTableViewChangeColumnSelectionProperties {
  key: string;
  /**
   * Is item selected
   */
  selected: boolean;
}

export interface SecurityAssessmentTableViewFilterActionProperties {
  key: string;
}

export interface SecurityAssessmentTableViewSortActionProperties {
  key: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | asc, desc |
   */
  order: "asc" | "desc";
}

export interface ServiceCatalogClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | left nav bar, prorec icon, email notifications |
   */
  "catalog navigation source": "left nav bar" | "prorec icon" | "email notifications";
}

export interface SortApplianceGenealogyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Chronologically, Alphabetically |
   */
  "Appliance Genealogy Sorting Category": "Chronologically" | "Alphabetically";
}

export interface SubscriptionGenealogyEventsDisplayOptionsAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | planned ramp, update request, renewal, expiration, upcoming renewal |
   */
  "subscription genealogy event types":
    | "planned ramp"
    | "update request"
    | "renewal"
    | "expiration"
    | "upcoming renewal";
}

export interface SubscriptionGenealogyExportingAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | all, selected |
   */
  "genealogy export category": "all" | "selected";
}

export interface SubscriptionGenealogySortingAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Chronologically, Alphabetically |
   */
  "Subscription Genealogy Sorting Category": "Chronologically" | "Alphabetically";
}

export interface SubscriptionGenealogySubscriptionNamesSelectionAppliedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | current name, initial name |
   */
  "subscription name": "current name" | "initial name";
}

export interface SupportCaseModalCategorySelectedProperties {
  label: string;
}

export interface SupportCaseModalSeveritySelectedProperties {
  label: string;
}

export interface SupportCaseSelectedProperties {
  caseId: string;
}

export interface SupportFilterChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface SupportTabChangedProperties {
  label: string;
}

export interface SustainabilityViewSelectionMapViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  eventType?: string;
}

export interface SustainabilityViewSelectionTableViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  eventType?: string;
}

export interface UmBatchEditRoleSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
}

export interface UmBatchEditSelectionChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
  /**
   * Number of selected items
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  itemsSelected: number;
}

export interface UmBatchEditViewSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
}

export interface UnsubscribeNotificationFromEmailProperties {
  topic: string;
}

export interface UsageMetricsTypeSwitchedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | License Usage Summary, Logical Managed Capacity License, License Usage Details, Logical Managed Capacity DRaaS Cluster, Appliance Usage Summary, Cluster Usage Summary, Unknown |
   */
  "subscription usage metrics types":
    | "License Usage Summary"
    | "Logical Managed Capacity License"
    | "License Usage Details"
    | "Logical Managed Capacity DRaaS Cluster"
    | "Appliance Usage Summary"
    | "Cluster Usage Summary"
    | "Unknown";
}

export interface VmAssessmentsClickedInvestigateInTopologyProperties {
  entityId: string;
}

export interface VmAssessmentsSelectedClusterProperties {
  clusterId: string;
}

export interface VmAssessmentsSelectedTableItemProperties {
  id: string;
  type: string;
}

export interface VmAssessmentsSelectedVcenterProperties {
  vcenterId: string;
}

export interface VmAssessmentsSplashCloseProperties {
  doNotShowAgain: boolean;
}

export interface VmAssessmentsSwitchedTableViewProperties {
  viewName: string;
}

export interface VmTopologyBannerLinkClickedProperties {
  label: string;
}

export interface VmTopologyChangedAggregationProperties {
  label: string;
}

export interface VmTopologyChangedOverlayMetricProperties {
  label: string;
}

export interface VmTopologyChangedTimeRangeProperties {
  label: string;
}

export interface VmTopologyColumnCollapsedProperties {
  label: string;
}

export interface VmTopologyColumnExpandedProperties {
  label: string;
}

export interface VmTopologyColumnSortProperties {
  label: string;
}

export interface VmTopologyDownloadCsvProperties {
  aggregation: string;
  clusterId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  endTime: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  startTime: number;
  vcenterId: string;
}

export interface VmTopologyEntityBadgeClickedProperties {
  "entity type": string;
  /**
   * This property captures the field that the user was trying to interact with
   */
  field: string;
  "field value": string;
}

export interface VmTopologyGlobalSearchClearProperties {
  label: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface VmTopologyGlobalSearchSearchingProperties {
  label: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface VmTopologyGlobalSearchSelectionProperties {
  label: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface VmTopologyLinkClickedProperties {
  label: string;
}

export interface VmTopologyNodeCheckedProperties {
  label: string;
}

export interface VmTopologyNodeDeselectedProperties {
  label: string;
}

export interface VmTopologyNodeSelectedProperties {
  label: string;
}

export interface VmTopologySearchProperties {
  label: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AdvancedOptionsClicked implements BaseEvent {
  event_type = 'advanced options clicked';

  constructor(
    public event_properties: AdvancedOptionsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AiCopilotClicked implements BaseEvent {
  event_type = 'ai copilot clicked';
}

export class AppSwitcherClicked implements BaseEvent {
  event_type = 'app switcher clicked';

  constructor(
    public event_properties?: AppSwitcherClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplyPolicyBasedSafemodeSettings implements BaseEvent {
  event_type = 'Apply Policy based safemode settings';
}

export class ApplyPolicyBasedSafemodeSettingsError implements BaseEvent {
  event_type = 'Apply Policy based safemode settings error';

  constructor(
    public event_properties: ApplyPolicyBasedSafemodeSettingsErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AssessmentDataProtectionExport implements BaseEvent {
  event_type = 'Assessment - Data Protection - Export';
}

export class AssetManagementExport implements BaseEvent {
  event_type = 'Asset Management - Export';
}

export class AssetManagementTagging implements BaseEvent {
  event_type = 'Asset Management - Tagging';

  constructor(
    public event_properties?: AssetManagementTaggingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BannerOpportunities implements BaseEvent {
  event_type = 'Banner Opportunities';

  constructor(
    public event_properties: BannerOpportunitiesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BottomTabsSelected implements BaseEvent {
  event_type = 'bottom tabs selected';

  constructor(
    public event_properties: BottomTabsSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CatalogUniversalCreditQuoteRequesting implements BaseEvent {
  event_type = 'catalog - universal credit quote requesting';

  constructor(
    public event_properties: CatalogUniversalCreditQuoteRequestingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeNotificationPreference implements BaseEvent {
  event_type = 'Change notification preference';

  constructor(
    public event_properties: ChangeNotificationPreferenceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CollapseLeftSideNavigationClicked implements BaseEvent {
  event_type = 'collapse left side navigation clicked';
}

export class CopilotChatDownloaded implements BaseEvent {
  event_type = 'copilot - chat downloaded';
}

export class CopilotChatSuggestionClicked implements BaseEvent {
  event_type = 'copilot - chat suggestion clicked';

  constructor(
    public event_properties: CopilotChatSuggestionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopilotChatSuggestionGroupBubbleClicked implements BaseEvent {
  event_type = 'copilot - chat suggestion group bubble clicked';

  constructor(
    public event_properties: CopilotChatSuggestionGroupBubbleClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopilotMessageFeedbackClicked implements BaseEvent {
  event_type = 'copilot - message feedback clicked';

  constructor(
    public event_properties: CopilotMessageFeedbackClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopilotOnboardingModalButtonClicked implements BaseEvent {
  event_type = 'copilot - onboarding modal button clicked';

  constructor(
    public event_properties: CopilotOnboardingModalButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopilotTopNavIconClicked implements BaseEvent {
  event_type = 'copilot - top nav icon clicked';
}

export class DataProtectionV2GfbKeyboardPillDelete implements BaseEvent {
  event_type = 'data-protection-v2 GFB keyboard pill delete';

  constructor(
    public event_properties?: DataProtectionV2GfbKeyboardPillDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ApplianceDetailClickToAction implements BaseEvent {
  event_type = 'DataProtection V2 - Appliance Detail - Click to action';

  constructor(
    public event_properties: DataProtectionV2ApplianceDetailClickToActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ApplianceDetailExcludeApplianceAction implements BaseEvent {
  event_type = 'DataProtection V2 - Appliance Detail - Exclude Appliance Action';
}

export class DataProtectionV2CloseApplianceDetail implements BaseEvent {
  event_type = 'DataProtection V2 - Close Appliance Detail';
}

export class DataProtectionV2DrrDialogClickToKbArticle implements BaseEvent {
  event_type = 'DataProtection V2 - DRR dialog - click to KB article';
}

export class DataProtectionV2DrrDialogCloseDialog implements BaseEvent {
  event_type = 'DataProtection V2 - DRR dialog - Close Dialog';
}

export class DataProtectionV2DrrDialogFeedbackButtonClick implements BaseEvent {
  event_type = 'DataProtection V2 - DRR dialog - Feedback button click';

  constructor(
    public event_properties: DataProtectionV2DrrDialogFeedbackButtonClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2DrrDialogFeedbackSent implements BaseEvent {
  event_type = 'DataProtection V2 - DRR dialog - Feedback sent';

  constructor(
    public event_properties: DataProtectionV2DrrDialogFeedbackSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2DrrDialogSortAction implements BaseEvent {
  event_type = 'DataProtection V2 - DRR Dialog - Sort Action';

  constructor(
    public event_properties?: DataProtectionV2DrrDialogSortActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2DrrDialogTooltipIsShown implements BaseEvent {
  event_type = 'DataProtection V2 - DRR Dialog - Tooltip is shown';
}

export class DataProtectionV2DrrDialogVolumeClick implements BaseEvent {
  event_type = 'DataProtection V2 - DRR dialog - Volume click';

  constructor(
    public event_properties: DataProtectionV2DrrDialogVolumeClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2EntityDetailDialogCloseDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Entity Detail Dialog - Close Dialog';
}

export class DataProtectionV2EntityDetailDialogFilterAction implements BaseEvent {
  event_type = 'DataProtection V2 - Entity Detail Dialog - Filter Action';

  constructor(
    public event_properties: DataProtectionV2EntityDetailDialogFilterActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2EntityDetailDialogHoverAction implements BaseEvent {
  event_type = 'DataProtection V2 - Entity Detail Dialog - Hover action';

  constructor(
    public event_properties: DataProtectionV2EntityDetailDialogHoverActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2EntityDetailDialogSortAction implements BaseEvent {
  event_type = 'DataProtection V2 - Entity Detail Dialog - Sort Action';

  constructor(
    public event_properties: DataProtectionV2EntityDetailDialogSortActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogAddRule implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Add rule';
}

export class DataProtectionV2ExclusionRulesDialogAddRuleToAGroup implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Add rule to a group';
}

export class DataProtectionV2ExclusionRulesDialogChangeDialogStep implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Change dialog step';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogChangeDialogStepProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogChangeTablePage implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Change table page';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogChangeTablePageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogChangeTableView implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Change table view';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogChangeTableViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogCloseDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Close dialog';
}

export class DataProtectionV2ExclusionRulesDialogErrorInGroup implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Error in group';
}

export class DataProtectionV2ExclusionRulesDialogFilterTable implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Filter  table';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogFilterTableProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogHoverOverHelpIcon implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Hover over help icon';
}

export class DataProtectionV2ExclusionRulesDialogOpenTheDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Open the dialog';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogOpenTheDialogProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogRemoveRule implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Remove rule';
}

export class DataProtectionV2ExclusionRulesDialogSaveRules implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Save rules';
}

export class DataProtectionV2ExclusionRulesDialogSortTable implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Sort table';

  constructor(
    public event_properties: DataProtectionV2ExclusionRulesDialogSortTableProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ExclusionRulesDialogStopToUseDefaultRules implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Stop to use default rules';
}

export class DataProtectionV2ExclusionRulesDialogSwitchOrg implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Switch org';
}

export class DataProtectionV2ExclusionRulesDialogUseDefaultRules implements BaseEvent {
  event_type = 'DataProtection V2 - Exclusion Rules Dialog - Use default rules';
}

export class DataProtectionV2HoneycombChartApplianceClick implements BaseEvent {
  event_type = 'DataProtection V2 - Honeycomb Chart - Appliance click';

  constructor(
    public event_properties: DataProtectionV2HoneycombChartApplianceClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2HoneycombChartHoverAction implements BaseEvent {
  event_type = 'DataProtection V2 - Honeycomb Chart - Hover action';

  constructor(
    public event_properties: DataProtectionV2HoneycombChartHoverActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2InsightsGoToApplianceDetail implements BaseEvent {
  event_type = 'DataProtection V2 - Insights - Go to Appliance Detail';

  constructor(
    public event_properties: DataProtectionV2InsightsGoToApplianceDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2InsightsHoverOverSection implements BaseEvent {
  event_type = 'DataProtection V2 - Insights - Hover over section';

  constructor(
    public event_properties: DataProtectionV2InsightsHoverOverSectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2InsightsSidebarClosed implements BaseEvent {
  event_type = 'DataProtection V2 - Insights - Sidebar Closed';

  constructor(
    public event_properties?: DataProtectionV2InsightsSidebarClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2InsightsSidebarOpened implements BaseEvent {
  event_type = 'DataProtection V2 - Insights - Sidebar Opened';

  constructor(
    public event_properties: DataProtectionV2InsightsSidebarOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2InsightsToggleCollapse implements BaseEvent {
  event_type = 'DataProtection V2 - Insights - Toggle collapse';

  constructor(
    public event_properties: DataProtectionV2InsightsToggleCollapseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2LocalSnapshotsOpenEntityDetailDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Local Snapshots - Open Entity Detail Dialog';

  constructor(
    public event_properties: DataProtectionV2LocalSnapshotsOpenEntityDetailDialogProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2OpenApplianceDetail implements BaseEvent {
  event_type = 'DataProtection V2 - Open Appliance Detail';
}

export class DataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Replicated Snapshots - Open Entity Detail Dialog';

  constructor(
    public event_properties: DataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialogProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2ResiliencyScorePanelHoverAction implements BaseEvent {
  event_type = 'DataProtection V2 - Resiliency Score Panel - Hover action';

  constructor(
    public event_properties: DataProtectionV2ResiliencyScorePanelHoverActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewApplianceClick implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Appliance click';

  constructor(
    public event_properties: DataProtectionV2TableViewApplianceClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewCapacityView implements BaseEvent {
  event_type = 'DataProtection V2 - Table View - Capacity View';

  constructor(
    public event_properties: DataProtectionV2TableViewCapacityViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewColumnSelection implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Column Selection';

  constructor(
    public event_properties: DataProtectionV2TableViewColumnSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewEntityCountView implements BaseEvent {
  event_type = 'DataProtection V2 - Table View - Entity Count View';

  constructor(
    public event_properties: DataProtectionV2TableViewEntityCountViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewHoverOverTable implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Hover over table';

  constructor(
    public event_properties: DataProtectionV2TableViewHoverOverTableProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewOpenEntityDetailDialog implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Open Entity Detail Dialog';

  constructor(
    public event_properties: DataProtectionV2TableViewOpenEntityDetailDialogProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewSortAction implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Sort Action';

  constructor(
    public event_properties: DataProtectionV2TableViewSortActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataProtectionV2TableViewSwitchTableView implements BaseEvent {
  event_type = 'DataProtection V2 - Table view - Switch Table View';

  constructor(
    public event_properties: DataProtectionV2TableViewSwitchTableViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DisplayOptionsApply implements BaseEvent {
  event_type = 'Display Options Apply';

  constructor(
    public event_properties?: DisplayOptionsApplyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DocumentationLinkClicked implements BaseEvent {
  event_type = 'documentation link clicked';

  constructor(
    public event_properties: DocumentationLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EmsValidatorAcknowledge implements BaseEvent {
  event_type = 'ems validator acknowledge';

  constructor(
    public event_properties: EmsValidatorAcknowledgeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EmsValidatorCancel implements BaseEvent {
  event_type = 'ems validator cancel';

  constructor(
    public event_properties: EmsValidatorCancelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EmsValidatorConfirm implements BaseEvent {
  event_type = 'ems validator confirm';

  constructor(
    public event_properties: EmsValidatorConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EmsValidatorStart implements BaseEvent {
  event_type = 'ems validator start';

  constructor(
    public event_properties: EmsValidatorStartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EolContactAccountTeamClickedApplianceCardView implements BaseEvent {
  event_type = 'eol - contact account team clicked - appliance card view';
}

export class EolContactAccountTeamClickedApplianceListView implements BaseEvent {
  event_type = 'eol - contact account team clicked - appliance list view';
}

export class EolContactAccountTeamClickedApplianceListViewPopover implements BaseEvent {
  event_type = 'eol - contact account team clicked - appliance list view popover';
}

export class EolViewRecommendationClicked implements BaseEvent {
  event_type = 'eol - view recommendation clicked';
}

export class ExpandLeftSideNavigationClicked implements BaseEvent {
  event_type = 'expand left side navigation clicked';
}

export class ExportApplianceGenealogy implements BaseEvent {
  event_type = 'export appliance genealogy';

  constructor(
    public event_properties: ExportApplianceGenealogyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportingOnAssetTab implements BaseEvent {
  event_type = 'exporting on asset tab';

  constructor(
    public event_properties: ExportingOnAssetTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GfbTagUsage implements BaseEvent {
  event_type = 'GFB tag usage';

  constructor(
    public event_properties: GfbTagUsageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HelpClicked implements BaseEvent {
  event_type = 'help clicked';

  constructor(
    public event_properties?: HelpClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IdentityCenterBannerLaunchClicked implements BaseEvent {
  event_type = 'Identity Center - banner launch clicked';
}

export class IdentityCenterCloseButtonP1MSplashClicked implements BaseEvent {
  event_type = 'Identity Center - close button p1m splash clicked';
}

export class IdentityCenterDontShowCheckboxSelected implements BaseEvent {
  event_type = 'Identity Center - dont show checkbox selected';
}

export class IdentityCenterLaunchPureIdentityCenterClicked implements BaseEvent {
  event_type = 'Identity Center - launch pure identity center clicked';
}

export class ImpersonateUserClicked implements BaseEvent {
  event_type = 'Impersonate User Clicked';
}

export class InvoiceManagementDisputeInvoice implements BaseEvent {
  event_type = 'invoice management - dispute invoice';

  constructor(
    public event_properties: InvoiceManagementDisputeInvoiceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InvoiceManagementSelectedTimeRangeFilter implements BaseEvent {
  event_type = 'invoice management - selected time range filter';
}

export class LogoutClicked implements BaseEvent {
  event_type = 'Logout Clicked';
}

export class ManageMyProfileClicked implements BaseEvent {
  event_type = 'Manage My Profile Clicked';
}

export class ManageOrganisationsClicked implements BaseEvent {
  event_type = 'manage organisations clicked';
}

export class ModalChartShown implements BaseEvent {
  event_type = 'modal chart shown';

  constructor(
    public event_properties: ModalChartShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NeedsAttentionDropdownSelected implements BaseEvent {
  event_type = 'needs attention dropdown selected';

  constructor(
    public event_properties: NeedsAttentionDropdownSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NotificationsClicked implements BaseEvent {
  event_type = 'notifications clicked';
}

export class OpenCaseFromAlerts implements BaseEvent {
  event_type = 'Open Case From Alerts';
}

export class OpenMpaRequestsSettings implements BaseEvent {
  event_type = 'Open MPA requests settings';
}

export class OpenNotificationPopup implements BaseEvent {
  event_type = 'Open Notification Popup';
}

export class OpenPreferencesOfNotificationCenter implements BaseEvent {
  event_type = 'Open preferences of notification center';

  constructor(
    public event_properties: OpenPreferencesOfNotificationCenterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrdersPageOrderRequestedTimeSelection implements BaseEvent {
  event_type = 'orders page - order requested time selection';

  constructor(
    public event_properties: OrdersPageOrderRequestedTimeSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrdersPageOrderStatusSelection implements BaseEvent {
  event_type = 'orders page - order status selection';

  constructor(
    public event_properties: OrdersPageOrderStatusSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrdersPageOrderTypeSelection implements BaseEvent {
  event_type = 'orders page - order type selection';

  constructor(
    public event_properties: OrdersPageOrderTypeSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrganisationSwitcherExpanded implements BaseEvent {
  event_type = 'organisation switcher expanded';

  constructor(
    public event_properties: OrganisationSwitcherExpandedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OrganisationToSwitchSelected implements BaseEvent {
  event_type = 'organisation to switch selected';

  constructor(
    public event_properties: OrganisationToSwitchSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PerformanceTableFilterChange implements BaseEvent {
  event_type = 'performance-table filter change';

  constructor(
    public event_properties: PerformanceTableFilterChangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Prorec6GCardClicked implements BaseEvent {
  event_type = 'prorec 6g card clicked';
}

export class ProrecCancelled implements BaseEvent {
  event_type = 'prorec cancelled';

  constructor(
    public event_properties: ProrecCancelledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecCapacityCardClicked implements BaseEvent {
  event_type = 'prorec capacity card clicked';

  constructor(
    public event_properties: ProrecCapacityCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecCombinedCardClicked implements BaseEvent {
  event_type = 'prorec combined card clicked';

  constructor(
    public event_properties: ProrecCombinedCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecControllerCardClicked implements BaseEvent {
  event_type = 'prorec controller card clicked';

  constructor(
    public event_properties: ProrecControllerCardClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecDismissIconClicked implements BaseEvent {
  event_type = 'prorec dismiss icon clicked';

  constructor(
    public event_properties: ProrecDismissIconClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecDismissed implements BaseEvent {
  event_type = 'prorec dismissed';

  constructor(
    public event_properties: ProrecDismissedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecEolC60R1CardClicked implements BaseEvent {
  event_type = 'prorec eol c60r1 card clicked';
}

export class ProrecEol11CardClicked implements BaseEvent {
  event_type = 'prorec eol1.1 card clicked';
}

export class ProrecEol20CardClicked implements BaseEvent {
  event_type = 'prorec eol2.0 card clicked';
}

export class ProrecEoscCardClicked implements BaseEvent {
  event_type = 'prorec eosc card clicked';
}

export class ProrecEvergreenOneCardClicked implements BaseEvent {
  event_type = 'prorec evergreen one card clicked';
}

export class ProrecForeverNowCardClicked implements BaseEvent {
  event_type = 'prorec forever now card clicked';
}

export class ProrecIconClicked implements BaseEvent {
  event_type = 'prorec icon clicked';
}

export class ProrecLeanSasToNvmeCardClicked implements BaseEvent {
  event_type = 'prorec lean sas to nvme card clicked';
}

export class ProrecM2XCardClicked implements BaseEvent {
  event_type = 'prorec m2x card clicked';
}

export class ProrecSettingsModalOpened implements BaseEvent {
  event_type = 'prorec settings modal opened';

  constructor(
    public event_properties: ProrecSettingsModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecSettingsModified implements BaseEvent {
  event_type = 'prorec settings modified';

  constructor(
    public event_properties: ProrecSettingsModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProrecSettingsSaved implements BaseEvent {
  event_type = 'prorec settings saved';
}

export class QuoteRequested implements BaseEvent {
  event_type = 'quote requested';

  constructor(
    public event_properties: QuoteRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RedirectToAlertsPageFromNotificationsPopup implements BaseEvent {
  event_type = 'RedirectToAlertsPageFromNotificationsPopup';

  constructor(
    public event_properties?: RedirectToAlertsPageFromNotificationsPopupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeRecCreateModalCancelled implements BaseEvent {
  event_type = 'se rec create modal cancelled';

  constructor(
    public event_properties: SeRecCreateModalCancelledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeRecCreateModalOpened implements BaseEvent {
  event_type = 'se rec create modal opened';

  constructor(
    public event_properties: SeRecCreateModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeRecCreated implements BaseEvent {
  event_type = 'se rec created';

  constructor(
    public event_properties: SeRecCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentApplianceUrlClick implements BaseEvent {
  event_type = 'Security Assessment - Appliance URL click';

  constructor(
    public event_properties: SecurityAssessmentApplianceUrlClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentCurrentAnomaliesDialogAnomalyTagSelect implements BaseEvent {
  event_type = 'Security Assessment - Current Anomalies dialog - Anomaly tag select';

  constructor(
    public event_properties: SecurityAssessmentCurrentAnomaliesDialogAnomalyTagSelectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentCurrentAnomaliesDialogApplianceSelect implements BaseEvent {
  event_type = 'Security Assessment - Current Anomalies dialog - Appliance select';

  constructor(
    public event_properties: SecurityAssessmentCurrentAnomaliesDialogApplianceSelectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentCurrentAnomaliesDialogTabSelect implements BaseEvent {
  event_type = 'Security Assessment - Current Anomalies dialog - Tab select';

  constructor(
    public event_properties: SecurityAssessmentCurrentAnomaliesDialogTabSelectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentCurrentAnomaliesDialogVolumeSelect implements BaseEvent {
  event_type = 'Security Assessment - Current Anomalies dialog - Volume select';

  constructor(
    public event_properties: SecurityAssessmentCurrentAnomaliesDialogVolumeSelectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentCurrentAnomaliesDialogVolumesTableSort implements BaseEvent {
  event_type = 'Security Assessment - Current Anomalies dialog - Volumes table sort';

  constructor(
    public event_properties: SecurityAssessmentCurrentAnomaliesDialogVolumesTableSortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentFleetHoneycombClick implements BaseEvent {
  event_type = 'Security Assessment - Fleet - Honeycomb click';

  constructor(
    public event_properties: SecurityAssessmentFleetHoneycombClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentFleetMetricClick implements BaseEvent {
  event_type = 'Security Assessment - Fleet - Metric click';

  constructor(
    public event_properties: SecurityAssessmentFleetMetricClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentFleetTooltipHover implements BaseEvent {
  event_type = 'Security Assessment - Fleet - Tooltip hover';

  constructor(
    public event_properties: SecurityAssessmentFleetTooltipHoverProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentFleetStatisticsHoverAction implements BaseEvent {
  event_type = 'Security Assessment - Fleet statistics - Hover action';

  constructor(
    public event_properties: SecurityAssessmentFleetStatisticsHoverActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentHistoricalDataDialogApplianceSelect implements BaseEvent {
  event_type = 'Security Assessment - Historical Data dialog - Appliance select';

  constructor(
    public event_properties: SecurityAssessmentHistoricalDataDialogApplianceSelectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentHistoricalDataDialogRedirectToDetails implements BaseEvent {
  event_type = 'Security Assessment - Historical Data dialog - Redirect to details';

  constructor(
    public event_properties: SecurityAssessmentHistoricalDataDialogRedirectToDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentHoneycombChartApplianceClick implements BaseEvent {
  event_type = 'Security Assessment - Honeycomb Chart - Appliance click';

  constructor(
    public event_properties: SecurityAssessmentHoneycombChartApplianceClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentHoneycombChartHoverAction implements BaseEvent {
  event_type = 'Security Assessment - Honeycomb Chart - Hover action';

  constructor(
    public event_properties: SecurityAssessmentHoneycombChartHoverActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentHoneycombChartLegendClick implements BaseEvent {
  event_type = 'Security Assessment - Honeycomb Chart - Legend Click';

  constructor(
    public event_properties: SecurityAssessmentHoneycombChartLegendClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsClickOnAction implements BaseEvent {
  event_type = 'Security Assessment - Insights - Click on action';

  constructor(
    public event_properties: SecurityAssessmentInsightsClickOnActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsGoToApplianceDetail implements BaseEvent {
  event_type = 'Security Assessment - Insights - Go to Appliance Detail';

  constructor(
    public event_properties: SecurityAssessmentInsightsGoToApplianceDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsHoverOverSection implements BaseEvent {
  event_type = 'Security Assessment - Insights - Hover over section';

  constructor(
    public event_properties: SecurityAssessmentInsightsHoverOverSectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsSidebarClosed implements BaseEvent {
  event_type = 'Security Assessment - Insights - Sidebar Closed';

  constructor(
    public event_properties?: SecurityAssessmentInsightsSidebarClosedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsSidebarOpened implements BaseEvent {
  event_type = 'Security Assessment - Insights - Sidebar Opened';

  constructor(
    public event_properties: SecurityAssessmentInsightsSidebarOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentInsightsToggleCollapse implements BaseEvent {
  event_type = 'Security Assessment - Insights - Toggle collapse';

  constructor(
    public event_properties: SecurityAssessmentInsightsToggleCollapseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentTableViewApplianceSelect implements BaseEvent {
  event_type = 'Security Assessment - Table view - Appliance select';
}

export class SecurityAssessmentTableViewChangeColumnSelection implements BaseEvent {
  event_type = 'Security Assessment - Table view - Change column selection';

  constructor(
    public event_properties: SecurityAssessmentTableViewChangeColumnSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentTableViewFilterAction implements BaseEvent {
  event_type = 'Security Assessment - Table view - Filter action';

  constructor(
    public event_properties: SecurityAssessmentTableViewFilterActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SecurityAssessmentTableViewSortAction implements BaseEvent {
  event_type = 'Security Assessment - Table view - Sort action';

  constructor(
    public event_properties: SecurityAssessmentTableViewSortActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ServiceCatalogClicked implements BaseEvent {
  event_type = 'service catalog clicked';

  constructor(
    public event_properties: ServiceCatalogClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetUpMfaClicked implements BaseEvent {
  event_type = 'Set Up MFA Clicked';
}

export class SortApplianceGenealogy implements BaseEvent {
  event_type = 'Sort Appliance Genealogy';

  constructor(
    public event_properties: SortApplianceGenealogyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionGenealogyEventsDisplayOptionsApplied implements BaseEvent {
  event_type = 'subscription genealogy - events display options applied';

  constructor(
    public event_properties: SubscriptionGenealogyEventsDisplayOptionsAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionGenealogyExportingApplied implements BaseEvent {
  event_type = 'subscription genealogy - exporting applied';

  constructor(
    public event_properties: SubscriptionGenealogyExportingAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionGenealogySortingApplied implements BaseEvent {
  event_type = 'subscription genealogy - sorting applied';

  constructor(
    public event_properties: SubscriptionGenealogySortingAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionGenealogySubscriptionNamesSelectionApplied implements BaseEvent {
  event_type = 'subscription genealogy - subscription names selection applied';

  constructor(
    public event_properties: SubscriptionGenealogySubscriptionNamesSelectionAppliedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportCaseAddAttachmentClicked implements BaseEvent {
  event_type = 'support case add attachment clicked';
}

export class SupportCaseAttachmentClicked implements BaseEvent {
  event_type = 'support case attachment clicked';
}

export class SupportCaseAttachmentSelected implements BaseEvent {
  event_type = 'support case attachment selected';
}

export class SupportCaseCommentClicked implements BaseEvent {
  event_type = 'support case comment clicked';
}

export class SupportCaseEditClicked implements BaseEvent {
  event_type = 'support case edit clicked';
}

export class SupportCaseEscalateClicked implements BaseEvent {
  event_type = 'support case escalate clicked';
}

export class SupportCaseModalCategorySelected implements BaseEvent {
  event_type = 'support case modal category selected';

  constructor(
    public event_properties: SupportCaseModalCategorySelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportCaseModalClosed implements BaseEvent {
  event_type = 'support case modal closed';
}

export class SupportCaseModalOpened implements BaseEvent {
  event_type = 'support case modal opened';
}

export class SupportCaseModalSeveritySelected implements BaseEvent {
  event_type = 'support case modal severity selected';

  constructor(
    public event_properties: SupportCaseModalSeveritySelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportCaseModalSubmitted implements BaseEvent {
  event_type = 'support case modal submitted';
}

export class SupportCaseReopenClicked implements BaseEvent {
  event_type = 'support case reopen clicked';
}

export class SupportCaseSelected implements BaseEvent {
  event_type = 'support case selected';

  constructor(
    public event_properties: SupportCaseSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportEscalateModalClosed implements BaseEvent {
  event_type = 'support escalate modal closed';
}

export class SupportEscalateModalEscalationReasonSelected implements BaseEvent {
  event_type = 'support escalate modal escalation reason selected';
}

export class SupportEscalateModalOpened implements BaseEvent {
  event_type = 'support escalate modal opened';
}

export class SupportEscalateModalSubmitted implements BaseEvent {
  event_type = 'support escalate modal submitted';
}

export class SupportFilterChanged implements BaseEvent {
  event_type = 'support filter changed';

  constructor(
    public event_properties?: SupportFilterChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportOpenSupportCaseClicked implements BaseEvent {
  event_type = 'support open support case clicked';
}

export class SupportRequestServiceClicked implements BaseEvent {
  event_type = 'support request service clicked';
}

export class SupportTabChanged implements BaseEvent {
  event_type = 'support tab changed';

  constructor(
    public event_properties: SupportTabChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportUpgradePuritySoftwareClicked implements BaseEvent {
  event_type = 'support upgrade purity software clicked';
}

export class SustainabilityOrgComparisonKbArticleClick implements BaseEvent {
  event_type = 'Sustainability - Org comparison - KB article click';
}

export class SustainabilityOrgComparisonRecommendationsClick implements BaseEvent {
  event_type = 'Sustainability - Org comparison - recommendations click';
}

export class SustainabilityOrgComparisonTooltipHover implements BaseEvent {
  event_type = 'Sustainability - Org comparison - tooltip hover';
}

export class SustainabilityViewSelectionMapView implements BaseEvent {
  event_type = 'Sustainability - View Selection - Map View';

  constructor(
    public event_properties?: SustainabilityViewSelectionMapViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SustainabilityViewSelectionTableView implements BaseEvent {
  event_type = 'Sustainability - View Selection - Table View';

  constructor(
    public event_properties?: SustainabilityViewSelectionTableViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UmBatchEditRoleSelected implements BaseEvent {
  event_type = 'UM - Batch Edit - Role selected';

  constructor(
    public event_properties?: UmBatchEditRoleSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UmBatchEditSelectionChanged implements BaseEvent {
  event_type = 'UM - Batch Edit - Selection changed';

  constructor(
    public event_properties: UmBatchEditSelectionChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UmBatchEditViewSelected implements BaseEvent {
  event_type = 'UM - Batch Edit - View selected';

  constructor(
    public event_properties?: UmBatchEditViewSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnsubscribeNotificationFromEmail implements BaseEvent {
  event_type = 'Unsubscribe notification from email';

  constructor(
    public event_properties: UnsubscribeNotificationFromEmailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnversalCreditDashboardFiltering implements BaseEvent {
  event_type = 'unversal credit dashboard - filtering';
}

export class UsageMetricsTypeSwitched implements BaseEvent {
  event_type = 'usage metrics type switched';

  constructor(
    public event_properties: UsageMetricsTypeSwitchedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsClickedInvestigateInTopology implements BaseEvent {
  event_type = 'vm assessments clicked investigate in topology';

  constructor(
    public event_properties: VmAssessmentsClickedInvestigateInTopologyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsSelectedCluster implements BaseEvent {
  event_type = 'vm assessments selected cluster';

  constructor(
    public event_properties: VmAssessmentsSelectedClusterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsSelectedTableItem implements BaseEvent {
  event_type = 'vm assessments selected table item';

  constructor(
    public event_properties: VmAssessmentsSelectedTableItemProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsSelectedVcenter implements BaseEvent {
  event_type = 'vm assessments selected vcenter';

  constructor(
    public event_properties: VmAssessmentsSelectedVcenterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsSplashClose implements BaseEvent {
  event_type = 'vm assessments splash close';

  constructor(
    public event_properties: VmAssessmentsSplashCloseProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmAssessmentsSplashGoToAssessments implements BaseEvent {
  event_type = 'vm assessments splash go to assessments';
}

export class VmAssessmentsSwitchedTableView implements BaseEvent {
  event_type = 'vm assessments switched table view';

  constructor(
    public event_properties: VmAssessmentsSwitchedTableViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyBannerLinkClicked implements BaseEvent {
  event_type = 'vm topology banner link clicked';

  constructor(
    public event_properties: VmTopologyBannerLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyChangedAggregation implements BaseEvent {
  event_type = 'vm topology changed aggregation';

  constructor(
    public event_properties: VmTopologyChangedAggregationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyChangedCluster implements BaseEvent {
  event_type = 'vm topology changed cluster';
}

export class VmTopologyChangedDatacenter implements BaseEvent {
  event_type = 'vm topology changed datacenter';
}

export class VmTopologyChangedOverlayMetric implements BaseEvent {
  event_type = 'vm topology changed overlay metric';

  constructor(
    public event_properties: VmTopologyChangedOverlayMetricProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyChangedTimeRange implements BaseEvent {
  event_type = 'vm topology changed time range';

  constructor(
    public event_properties: VmTopologyChangedTimeRangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyChangedVcenter implements BaseEvent {
  event_type = 'vm topology changed vcenter';
}

export class VmTopologyClickedCapacityChurnHelp implements BaseEvent {
  event_type = 'vm topology clicked capacity churn help';
}

export class VmTopologyClickedClearFilter implements BaseEvent {
  event_type = 'vm topology clicked clear filter';
}

export class VmTopologyClickedCollapseAllColumns implements BaseEvent {
  event_type = 'vm topology clicked collapse all columns';
}

export class VmTopologyClickedExpandAllColumns implements BaseEvent {
  event_type = 'vm topology clicked expand all columns';
}

export class VmTopologyClickedExpandTimeseries implements BaseEvent {
  event_type = 'vm topology clicked expand timeseries';
}

export class VmTopologyClickedFilterToSelected implements BaseEvent {
  event_type = 'vm topology clicked filter to selected';
}

export class VmTopologyClickedGenerateReport implements BaseEvent {
  event_type = 'vm topology clicked generate report';
}

export class VmTopologyClickedHighlightSeries implements BaseEvent {
  event_type = 'vm topology clicked highlight series';
}

export class VmTopologyClickedRemoveAllTimeseries implements BaseEvent {
  event_type = 'vm topology clicked remove all timeseries';
}

export class VmTopologyClickedRemoveTimeseries implements BaseEvent {
  event_type = 'vm topology clicked remove timeseries';
}

export class VmTopologyColumnCollapsed implements BaseEvent {
  event_type = 'vm topology column collapsed';

  constructor(
    public event_properties: VmTopologyColumnCollapsedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyColumnExpanded implements BaseEvent {
  event_type = 'vm topology column expanded';

  constructor(
    public event_properties: VmTopologyColumnExpandedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyColumnSort implements BaseEvent {
  event_type = 'vm topology column sort';

  constructor(
    public event_properties: VmTopologyColumnSortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyDownloadCsv implements BaseEvent {
  event_type = 'vm topology download csv';

  constructor(
    public event_properties: VmTopologyDownloadCsvProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyEntityBadgeClicked implements BaseEvent {
  event_type = 'vm topology entity badge clicked';

  constructor(
    public event_properties: VmTopologyEntityBadgeClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyGlobalSearchBottomMessageClicked implements BaseEvent {
  event_type = 'vm topology global search bottom message clicked';
}

export class VmTopologyGlobalSearchClear implements BaseEvent {
  event_type = 'vm topology global search clear';

  constructor(
    public event_properties: VmTopologyGlobalSearchClearProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyGlobalSearchSearching implements BaseEvent {
  event_type = 'vm topology global search searching';

  constructor(
    public event_properties: VmTopologyGlobalSearchSearchingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyGlobalSearchSelection implements BaseEvent {
  event_type = 'vm topology global search selection';

  constructor(
    public event_properties: VmTopologyGlobalSearchSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyGoToAssessment implements BaseEvent {
  event_type = 'vm topology go to assessment';
}

export class VmTopologyLinkClicked implements BaseEvent {
  event_type = 'vm topology link clicked';

  constructor(
    public event_properties: VmTopologyLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyNodeChecked implements BaseEvent {
  event_type = 'vm topology node checked';

  constructor(
    public event_properties: VmTopologyNodeCheckedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyNodeDeselected implements BaseEvent {
  event_type = 'vm topology node deselected';

  constructor(
    public event_properties: VmTopologyNodeDeselectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyNodeSelected implements BaseEvent {
  event_type = 'vm topology node selected';

  constructor(
    public event_properties: VmTopologyNodeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyOpenedManagementModal implements BaseEvent {
  event_type = 'vm topology opened management modal';
}

export class VmTopologyOpenedStatusModal implements BaseEvent {
  event_type = 'vm topology opened status modal';
}

export class VmTopologyReportGenerationCancelClicked implements BaseEvent {
  event_type = 'vm topology report generation cancel clicked';
}

export class VmTopologyReportGenerationGenerateClicked implements BaseEvent {
  event_type = 'vm topology report generation generate clicked';
}

export class VmTopologySearch implements BaseEvent {
  event_type = 'vm topology search';

  constructor(
    public event_properties: VmTopologySearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VmTopologyShiftedTimeBackward implements BaseEvent {
  event_type = 'vm topology shifted time backward';
}

export class VmTopologyShiftedTimeForward implements BaseEvent {
  event_type = 'vm topology shifted time forward';
}

export class VmTopologyToggleShowDeleted implements BaseEvent {
  event_type = 'vm topology toggle show deleted';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * advanced options clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/advanced%20options%20clicked)
   *
   * advanced planning button clicked
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. advanced options from)
   * @param options Amplitude event options.
   */
  advancedOptionsClicked(
    properties: AdvancedOptionsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AdvancedOptionsClicked(properties), options);
  }

  /**
   * ai copilot clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/ai%20copilot%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  aiCopilotClicked(
    options?: EventOptions,
  ) {
    return this.track(new AiCopilotClicked(), options);
  }

  /**
   * app switcher clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/app%20switcher%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. appId)
   * @param options Amplitude event options.
   */
  appSwitcherClicked(
    properties?: AppSwitcherClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppSwitcherClicked(properties), options);
  }

  /**
   * Apply Policy based safemode settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Apply%20Policy%20based%20safemode%20settings)
   *
   * Event to track the application of policy-based safemode settings
   *
   * @param options Amplitude event options.
   */
  applyPolicyBasedSafemodeSettings(
    options?: EventOptions,
  ) {
    return this.track(new ApplyPolicyBasedSafemodeSettings(), options);
  }

  /**
   * Apply Policy based safemode settings error
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Apply%20Policy%20based%20safemode%20settings%20error)
   *
   * Event to track errors related to applying policy-based safemode settings
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  applyPolicyBasedSafemodeSettingsError(
    properties: ApplyPolicyBasedSafemodeSettingsErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyPolicyBasedSafemodeSettingsError(properties), options);
  }

  /**
   * Assessment - Data Protection - Export
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Assessment%20-%20Data%20Protection%20-%20Export)
   *
   * Export Report action
   *
   * TODO: change prefix after deccomission of v1
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  assessmentDataProtectionExport(
    options?: EventOptions,
  ) {
    return this.track(new AssessmentDataProtectionExport(), options);
  }

  /**
   * Asset Management - Export
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Asset%20Management%20-%20Export)
   *
   * Owner: Carl Rodriguez
   *
   * @param options Amplitude event options.
   */
  assetManagementExport(
    options?: EventOptions,
  ) {
    return this.track(new AssetManagementExport(), options);
  }

  /**
   * Asset Management - Tagging
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Asset%20Management%20-%20Tagging)
   *
   * Owner: Carl Rodriguez
   *
   * @param properties The event's properties (e.g. tagging behavior)
   * @param options Amplitude event options.
   */
  assetManagementTagging(
    properties?: AssetManagementTaggingProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssetManagementTagging(properties), options);
  }

  /**
   * Banner Opportunities
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Banner%20Opportunities)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action details)
   * @param options Amplitude event options.
   */
  bannerOpportunities(
    properties: BannerOpportunitiesProperties,
    options?: EventOptions,
  ) {
    return this.track(new BannerOpportunities(properties), options);
  }

  /**
   * bottom tabs selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/bottom%20tabs%20selected)
   *
   * This event is for the selection on the bottom tabs on Fleet -> Subscription related pages, which refers to customer to select different tabs: Summary/ Utilization/ etc.
   *
   * @param properties The event's properties (e.g. subscription program type)
   * @param options Amplitude event options.
   */
  bottomTabsSelected(
    properties: BottomTabsSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BottomTabsSelected(properties), options);
  }

  /**
   * catalog - universal credit quote requesting
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/catalog%20-%20universal%20credit%20quote%20requesting)
   *
   * Quote requests related to Universal Credits
   *
   * @param properties The event's properties (e.g. quoteType)
   * @param options Amplitude event options.
   */
  catalogUniversalCreditQuoteRequesting(
    properties: CatalogUniversalCreditQuoteRequestingProperties,
    options?: EventOptions,
  ) {
    return this.track(new CatalogUniversalCreditQuoteRequesting(properties), options);
  }

  /**
   * Change notification preference
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Change%20notification%20preference)
   *
   * Event to track when a user updates their notification settings
   *
   * @param properties The event's properties (e.g. channel)
   * @param options Amplitude event options.
   */
  changeNotificationPreference(
    properties: ChangeNotificationPreferenceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeNotificationPreference(properties), options);
  }

  /**
   * collapse left side navigation clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/collapse%20left%20side%20navigation%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  collapseLeftSideNavigationClicked(
    options?: EventOptions,
  ) {
    return this.track(new CollapseLeftSideNavigationClicked(), options);
  }

  /**
   * copilot - chat downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20chat%20downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  copilotChatDownloaded(
    options?: EventOptions,
  ) {
    return this.track(new CopilotChatDownloaded(), options);
  }

  /**
   * copilot - chat suggestion clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20chat%20suggestion%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. question)
   * @param options Amplitude event options.
   */
  copilotChatSuggestionClicked(
    properties: CopilotChatSuggestionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopilotChatSuggestionClicked(properties), options);
  }

  /**
   * copilot - chat suggestion group bubble clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20chat%20suggestion%20group%20bubble%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. groupName)
   * @param options Amplitude event options.
   */
  copilotChatSuggestionGroupBubbleClicked(
    properties: CopilotChatSuggestionGroupBubbleClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopilotChatSuggestionGroupBubbleClicked(properties), options);
  }

  /**
   * copilot - message feedback clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20message%20feedback%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. messageFeedback)
   * @param options Amplitude event options.
   */
  copilotMessageFeedbackClicked(
    properties: CopilotMessageFeedbackClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopilotMessageFeedbackClicked(properties), options);
  }

  /**
   * copilot - onboarding modal button clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20onboarding%20modal%20button%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. onboardingButtonClicked)
   * @param options Amplitude event options.
   */
  copilotOnboardingModalButtonClicked(
    properties: CopilotOnboardingModalButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopilotOnboardingModalButtonClicked(properties), options);
  }

  /**
   * copilot - top nav icon clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/copilot%20-%20top%20nav%20icon%20clicked)
   *
   * customer clicks on the copilot icon on the top nav bar
   *
   * @param options Amplitude event options.
   */
  copilotTopNavIconClicked(
    options?: EventOptions,
  ) {
    return this.track(new CopilotTopNavIconClicked(), options);
  }

  /**
   * data-protection-v2 GFB keyboard pill delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/data-protection-v2%20GFB%20keyboard%20pill%20delete)
   *
   * Global Filter Bar delete filter
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  dataProtectionV2GfbKeyboardPillDelete(
    properties?: DataProtectionV2GfbKeyboardPillDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2GfbKeyboardPillDelete(properties), options);
  }

  /**
   * DataProtection V2 - Appliance Detail - Click to action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Appliance%20Detail%20-%20Click%20to%20action)
   *
   * Click on action button
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  dataProtectionV2ApplianceDetailClickToAction(
    properties: DataProtectionV2ApplianceDetailClickToActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ApplianceDetailClickToAction(properties), options);
  }

  /**
   * DataProtection V2 - Appliance Detail - Exclude Appliance Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Appliance%20Detail%20-%20Exclude%20Appliance%20Action)
   *
   * Click on Exclude Appliance checkbox
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ApplianceDetailExcludeApplianceAction(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ApplianceDetailExcludeApplianceAction(), options);
  }

  /**
   * DataProtection V2 - Close Appliance Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Close%20Appliance%20Detail)
   *
   * Sidebar with Appliance Details is closed
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2CloseApplianceDetail(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2CloseApplianceDetail(), options);
  }

  /**
   * DataProtection V2 - DRR dialog - click to KB article
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20dialog%20-%20click%20to%20KB%20article)
   *
   * User clicks on a help icon
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogClickToKbArticle(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogClickToKbArticle(), options);
  }

  /**
   * DataProtection V2 - DRR dialog - Close Dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20dialog%20-%20Close%20Dialog)
   *
   * Click on X or on a Done button
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogCloseDialog(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogCloseDialog(), options);
  }

  /**
   * DataProtection V2 - DRR dialog - Feedback button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20dialog%20-%20Feedback%20button%20click)
   *
   * User clicks on a feedback button if anomaly chart was usefull
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. isUseful)
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogFeedbackButtonClick(
    properties: DataProtectionV2DrrDialogFeedbackButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogFeedbackButtonClick(properties), options);
  }

  /**
   * DataProtection V2 - DRR dialog - Feedback sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20dialog%20-%20Feedback%20sent)
   *
   * User send feedback on DRR feature
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. feedback)
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogFeedbackSent(
    properties: DataProtectionV2DrrDialogFeedbackSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogFeedbackSent(properties), options);
  }

  /**
   * DataProtection V2 - DRR Dialog - Sort Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20Dialog%20-%20Sort%20Action)
   *
   * User changes the sort on volume table
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. field)
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogSortAction(
    properties?: DataProtectionV2DrrDialogSortActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogSortAction(properties), options);
  }

  /**
   * DataProtection V2 - DRR Dialog - Tooltip is shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20Dialog%20-%20Tooltip%20is%20shown)
   *
   * User hovers over drop part of the chart
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogTooltipIsShown(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogTooltipIsShown(), options);
  }

  /**
   * DataProtection V2 - DRR dialog - Volume click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20DRR%20dialog%20-%20Volume%20click)
   *
   * User displays DRR chart for the volume
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. selected)
   * @param options Amplitude event options.
   */
  dataProtectionV2DrrDialogVolumeClick(
    properties: DataProtectionV2DrrDialogVolumeClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2DrrDialogVolumeClick(properties), options);
  }

  /**
   * DataProtection V2 - Entity Detail Dialog - Close Dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Entity%20Detail%20Dialog%20-%20Close%20Dialog)
   *
   * Close event of Entity Detail Dialog
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2EntityDetailDialogCloseDialog(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2EntityDetailDialogCloseDialog(), options);
  }

  /**
   * DataProtection V2 - Entity Detail Dialog - Filter Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Entity%20Detail%20Dialog%20-%20Filter%20Action)
   *
   * Set Filters in Entity Detail Dialog
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. filter)
   * @param options Amplitude event options.
   */
  dataProtectionV2EntityDetailDialogFilterAction(
    properties: DataProtectionV2EntityDetailDialogFilterActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2EntityDetailDialogFilterAction(properties), options);
  }

  /**
   * DataProtection V2 - Entity Detail Dialog - Hover action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Entity%20Detail%20Dialog%20-%20Hover%20action)
   *
   * Hover action on Entity Detail Dialog
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. hoverOn)
   * @param options Amplitude event options.
   */
  dataProtectionV2EntityDetailDialogHoverAction(
    properties: DataProtectionV2EntityDetailDialogHoverActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2EntityDetailDialogHoverAction(properties), options);
  }

  /**
   * DataProtection V2 - Entity Detail Dialog - Sort Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Entity%20Detail%20Dialog%20-%20Sort%20Action)
   *
   * Sort in Table in Entity Detail Dialog
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. field)
   * @param options Amplitude event options.
   */
  dataProtectionV2EntityDetailDialogSortAction(
    properties: DataProtectionV2EntityDetailDialogSortActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2EntityDetailDialogSortAction(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Add rule
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Add%20rule)
   *
   * User adds a rule to the form
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogAddRule(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogAddRule(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Add rule to a group
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Add%20rule%20to%20a%20group)
   *
   * User adds a rule to rule group in the form
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogAddRuleToAGroup(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogAddRuleToAGroup(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Change dialog step
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Change%20dialog%20step)
   *
   * Close dialog action
   *
   * @param properties The event's properties (e.g. dialog step)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogChangeDialogStep(
    properties: DataProtectionV2ExclusionRulesDialogChangeDialogStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogChangeDialogStep(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Change table page
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Change%20table%20page)
   *
   * Change page in the verification table
   *
   * @param properties The event's properties (e.g. table)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogChangeTablePage(
    properties: DataProtectionV2ExclusionRulesDialogChangeTablePageProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogChangeTablePage(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Change table view
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Change%20table%20view)
   *
   * User switchs verification table
   *
   * @param properties The event's properties (e.g. table)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogChangeTableView(
    properties: DataProtectionV2ExclusionRulesDialogChangeTableViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogChangeTableView(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Close dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Close%20dialog)
   *
   * Close dialog action
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogCloseDialog(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogCloseDialog(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Error in group
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Error%20in%20group)
   *
   * User has an error while editing a rule, incompatible object types in groups
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogErrorInGroup(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogErrorInGroup(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Filter  table
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Filter%20%20table)
   *
   * Filter action on a verification table
   *
   * @param properties The event's properties (e.g. columnId)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogFilterTable(
    properties: DataProtectionV2ExclusionRulesDialogFilterTableProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogFilterTable(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Hover over help icon
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Hover%20over%20help%20icon)
   *
   * User hovers over help icon on honeycomb view when rules are applied just recently
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogHoverOverHelpIcon(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogHoverOverHelpIcon(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Open the dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Open%20the%20dialog)
   *
   * User opens the exclusion rules dialog
   *
   * @param properties The event's properties (e.g. source element)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogOpenTheDialog(
    properties: DataProtectionV2ExclusionRulesDialogOpenTheDialogProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogOpenTheDialog(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Remove rule
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Remove%20rule)
   *
   * User removes a rule from a group/form
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogRemoveRule(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogRemoveRule(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Save rules
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Save%20rules)
   *
   * Save rules action
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogSaveRules(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogSaveRules(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Sort table
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Sort%20table)
   *
   * Sort action on a verification table
   *
   * @param properties The event's properties (e.g. columnId)
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogSortTable(
    properties: DataProtectionV2ExclusionRulesDialogSortTableProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogSortTable(properties), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Stop to use default rules
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Stop%20to%20use%20default%20rules)
   *
   * User changes a rules so default rules are not used anymore
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogStopToUseDefaultRules(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogStopToUseDefaultRules(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Switch org
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Switch%20org)
   *
   * User switch the organisation (if see more)
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogSwitchOrg(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogSwitchOrg(), options);
  }

  /**
   * DataProtection V2 - Exclusion Rules Dialog - Use default rules
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Exclusion%20Rules%20Dialog%20-%20Use%20default%20rules)
   *
   * User toggles a switch to use daefult rules
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2ExclusionRulesDialogUseDefaultRules(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ExclusionRulesDialogUseDefaultRules(), options);
  }

  /**
   * DataProtection V2 - Honeycomb Chart - Appliance click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Honeycomb%20Chart%20-%20Appliance%20click)
   *
   * Event triggered by appliance clicked in honeycomb component
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. drr)
   * @param options Amplitude event options.
   */
  dataProtectionV2HoneycombChartApplianceClick(
    properties: DataProtectionV2HoneycombChartApplianceClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2HoneycombChartApplianceClick(properties), options);
  }

  /**
   * DataProtection V2 - Honeycomb Chart - Hover action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Honeycomb%20Chart%20-%20Hover%20action)
   *
   * Click action on Honeycomb Chart
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. hoverOn)
   * @param options Amplitude event options.
   */
  dataProtectionV2HoneycombChartHoverAction(
    properties: DataProtectionV2HoneycombChartHoverActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2HoneycombChartHoverAction(properties), options);
  }

  /**
   * DataProtection V2 - Insights - Go to Appliance Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Insights%20-%20Go%20to%20Appliance%20Detail)
   *
   * Open Appliance Detail from Insights sidebar
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  dataProtectionV2InsightsGoToApplianceDetail(
    properties: DataProtectionV2InsightsGoToApplianceDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2InsightsGoToApplianceDetail(properties), options);
  }

  /**
   * DataProtection V2 - Insights - Hover over section
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Insights%20-%20Hover%20over%20section)
   *
   * User hovers over a section in the Insights panel
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  dataProtectionV2InsightsHoverOverSection(
    properties: DataProtectionV2InsightsHoverOverSectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2InsightsHoverOverSection(properties), options);
  }

  /**
   * DataProtection V2 - Insights - Sidebar Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Insights%20-%20Sidebar%20Closed)
   *
   * Sidebar with Insights is closed
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. stepBack)
   * @param options Amplitude event options.
   */
  dataProtectionV2InsightsSidebarClosed(
    properties?: DataProtectionV2InsightsSidebarClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2InsightsSidebarClosed(properties), options);
  }

  /**
   * DataProtection V2 - Insights - Sidebar Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Insights%20-%20Sidebar%20Opened)
   *
   * Sidebar with Insights is opened
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. stepBack)
   * @param options Amplitude event options.
   */
  dataProtectionV2InsightsSidebarOpened(
    properties: DataProtectionV2InsightsSidebarOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2InsightsSidebarOpened(properties), options);
  }

  /**
   * DataProtection V2 - Insights - Toggle collapse
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Insights%20-%20Toggle%20collapse)
   *
   * Toggle action on Insight category
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  dataProtectionV2InsightsToggleCollapse(
    properties: DataProtectionV2InsightsToggleCollapseProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2InsightsToggleCollapse(properties), options);
  }

  /**
   * DataProtection V2 - Local Snapshots - Open Entity Detail Dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Local%20Snapshots%20-%20Open%20Entity%20Detail%20Dialog)
   *
   * Click on number of entities under certain rule level within Local Snapshots overview in Appliance Detail
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. ruleLevel)
   * @param options Amplitude event options.
   */
  dataProtectionV2LocalSnapshotsOpenEntityDetailDialog(
    properties: DataProtectionV2LocalSnapshotsOpenEntityDetailDialogProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2LocalSnapshotsOpenEntityDetailDialog(properties), options);
  }

  /**
   * DataProtection V2 - Open Appliance Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Open%20Appliance%20Detail)
   *
   * Sidebar with Appliance details is opened
   *
   * Owner: Julie Evseenko
   *
   * @param options Amplitude event options.
   */
  dataProtectionV2OpenApplianceDetail(
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2OpenApplianceDetail(), options);
  }

  /**
   * DataProtection V2 - Replicated Snapshots - Open Entity Detail Dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Replicated%20Snapshots%20-%20Open%20Entity%20Detail%20Dialog)
   *
   * Click on number of entities under certain rule level within Replicated Snapshots overview in Appliance Detail
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. ruleLevel)
   * @param options Amplitude event options.
   */
  dataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialog(
    properties: DataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialogProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ReplicatedSnapshotsOpenEntityDetailDialog(properties), options);
  }

  /**
   * DataProtection V2 - Resiliency Score Panel - Hover action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Resiliency%20Score%20Panel%20-%20Hover%20action)
   *
   * Hover action on Resiliency Score panel
   *
   * @param properties The event's properties (e.g. hoverOn)
   * @param options Amplitude event options.
   */
  dataProtectionV2ResiliencyScorePanelHoverAction(
    properties: DataProtectionV2ResiliencyScorePanelHoverActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2ResiliencyScorePanelHoverAction(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Appliance click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Appliance%20click)
   *
   * Event triggered by click on an appliance in table
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. selected)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewApplianceClick(
    properties: DataProtectionV2TableViewApplianceClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewApplianceClick(properties), options);
  }

  /**
   * DataProtection V2 - Table View - Capacity View
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20View%20-%20Capacity%20View)
   *
   * Statistics view type changed to "Capacity"
   *
   * Owner: Vojtech Sipek
   *
   * @param properties The event's properties (e.g. tableView)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewCapacityView(
    properties: DataProtectionV2TableViewCapacityViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewCapacityView(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Column Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Column%20Selection)
   *
   * Change column visibility action in Resiliency or Assessments table
   *
   * @param properties The event's properties (e.g. columnId)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewColumnSelection(
    properties: DataProtectionV2TableViewColumnSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewColumnSelection(properties), options);
  }

  /**
   * DataProtection V2 - Table View - Entity Count View
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20View%20-%20Entity%20Count%20View)
   *
   * Statistics view type changed to "Object Count"
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. tableView)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewEntityCountView(
    properties: DataProtectionV2TableViewEntityCountViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewEntityCountView(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Hover over table
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Hover%20over%20table)
   *
   * Hover over an Appliances Table
   *
   * @param properties The event's properties (e.g. tableView)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewHoverOverTable(
    properties: DataProtectionV2TableViewHoverOverTableProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewHoverOverTable(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Open Entity Detail Dialog
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Open%20Entity%20Detail%20Dialog)
   *
   * Click on Storage type in Appliances Table
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. tableView)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewOpenEntityDetailDialog(
    properties: DataProtectionV2TableViewOpenEntityDetailDialogProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewOpenEntityDetailDialog(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Sort Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Sort%20Action)
   *
   * Sort Action in Appliances Table
   *
   * Owner: Julie Evseenko
   *
   * @param properties The event's properties (e.g. key)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewSortAction(
    properties: DataProtectionV2TableViewSortActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewSortAction(properties), options);
  }

  /**
   * DataProtection V2 - Table view - Switch Table View
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/DataProtection%20V2%20-%20Table%20view%20-%20Switch%20Table%20View)
   *
   * Switch table view
   *
   * @param properties The event's properties (e.g. tableView)
   * @param options Amplitude event options.
   */
  dataProtectionV2TableViewSwitchTableView(
    properties: DataProtectionV2TableViewSwitchTableViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataProtectionV2TableViewSwitchTableView(properties), options);
  }

  /**
   * Display Options Apply
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Display%20Options%20Apply)
   *
   * Applying display options selections based on the genealogy event type
   *
   * Owner: Emily Tian
   *
   * @param properties The event's properties (e.g. genealogy event types)
   * @param options Amplitude event options.
   */
  displayOptionsApply(
    properties?: DisplayOptionsApplyProperties,
    options?: EventOptions,
  ) {
    return this.track(new DisplayOptionsApply(properties), options);
  }

  /**
   * documentation link clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/documentation%20link%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page pathname)
   * @param options Amplitude event options.
   */
  documentationLinkClicked(
    properties: DocumentationLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DocumentationLinkClicked(properties), options);
  }

  /**
   * ems validator acknowledge
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/ems%20validator%20acknowledge)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. org id)
   * @param options Amplitude event options.
   */
  emsValidatorAcknowledge(
    properties: EmsValidatorAcknowledgeProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmsValidatorAcknowledge(properties), options);
  }

  /**
   * ems validator cancel
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/ems%20validator%20cancel)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. org id)
   * @param options Amplitude event options.
   */
  emsValidatorCancel(
    properties: EmsValidatorCancelProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmsValidatorCancel(properties), options);
  }

  /**
   * ems validator confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/ems%20validator%20confirm)
   *
   * PES compliance check - completed
   *
   * @param properties The event's properties (e.g. org id)
   * @param options Amplitude event options.
   */
  emsValidatorConfirm(
    properties: EmsValidatorConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmsValidatorConfirm(properties), options);
  }

  /**
   * ems validator start
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/ems%20validator%20start)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. org id)
   * @param options Amplitude event options.
   */
  emsValidatorStart(
    properties: EmsValidatorStartProperties,
    options?: EventOptions,
  ) {
    return this.track(new EmsValidatorStart(properties), options);
  }

  /**
   * eol - contact account team clicked - appliance card view
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/eol%20-%20contact%20account%20team%20clicked%20-%20appliance%20card%20view)
   *
   * customer clicks contact account team for an eol array
   *
   * Owner: Jephthah Liddie
   *
   * @param options Amplitude event options.
   */
  eolContactAccountTeamClickedApplianceCardView(
    options?: EventOptions,
  ) {
    return this.track(new EolContactAccountTeamClickedApplianceCardView(), options);
  }

  /**
   * eol - contact account team clicked - appliance list view
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/eol%20-%20contact%20account%20team%20clicked%20-%20appliance%20list%20view)
   *
   * customer clicks contact account team for an eol array
   *
   * Owner: Jephthah Liddie
   *
   * @param options Amplitude event options.
   */
  eolContactAccountTeamClickedApplianceListView(
    options?: EventOptions,
  ) {
    return this.track(new EolContactAccountTeamClickedApplianceListView(), options);
  }

  /**
   * eol - contact account team clicked - appliance list view popover
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/eol%20-%20contact%20account%20team%20clicked%20-%20appliance%20list%20view%20popover)
   *
   * customer clicks contact account team for an eol array
   *
   * Owner: Jephthah Liddie
   *
   * @param options Amplitude event options.
   */
  eolContactAccountTeamClickedApplianceListViewPopover(
    options?: EventOptions,
  ) {
    return this.track(new EolContactAccountTeamClickedApplianceListViewPopover(), options);
  }

  /**
   * eol - view recommendation clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/eol%20-%20view%20recommendation%20clicked)
   *
   * Owner: Jephthah Liddie
   *
   * @param options Amplitude event options.
   */
  eolViewRecommendationClicked(
    options?: EventOptions,
  ) {
    return this.track(new EolViewRecommendationClicked(), options);
  }

  /**
   * expand left side navigation clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/expand%20left%20side%20navigation%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  expandLeftSideNavigationClicked(
    options?: EventOptions,
  ) {
    return this.track(new ExpandLeftSideNavigationClicked(), options);
  }

  /**
   * export appliance genealogy
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/export%20appliance%20genealogy)
   *
   * Owner: Emily Tian
   *
   * @param properties The event's properties (e.g. genealogy export category)
   * @param options Amplitude event options.
   */
  exportApplianceGenealogy(
    properties: ExportApplianceGenealogyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportApplianceGenealogy(properties), options);
  }

  /**
   * exporting on asset tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/exporting%20on%20asset%20tab)
   *
   * Exporting function on the asset tab for
   *
   * 1. asset management
   *
   * 2. appliance genealogy
   *
   * 3. subscription genealogy
   *
   * 4. quote management
   *
   * 5. invoice management
   *
   * @param properties The event's properties (e.g. assets tab page)
   * @param options Amplitude event options.
   */
  exportingOnAssetTab(
    properties: ExportingOnAssetTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportingOnAssetTab(properties), options);
  }

  /**
   * GFB tag usage
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/GFB%20tag%20usage)
   *
   * See what and how often tags/array properties are used in the GFB. Added for CLOUD-114887.
   *
   * Owner: Spodi McSpodiface
   *
   * @param properties The event's properties (e.g. array properties)
   * @param options Amplitude event options.
   */
  gfbTagUsage(
    properties: GfbTagUsageProperties,
    options?: EventOptions,
  ) {
    return this.track(new GfbTagUsage(properties), options);
  }

  /**
   * help clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/help%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. help item)
   * @param options Amplitude event options.
   */
  helpClicked(
    properties?: HelpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HelpClicked(properties), options);
  }

  /**
   * Identity Center - banner launch clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Identity%20Center%20-%20banner%20launch%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  identityCenterBannerLaunchClicked(
    options?: EventOptions,
  ) {
    return this.track(new IdentityCenterBannerLaunchClicked(), options);
  }

  /**
   * Identity Center - close button p1m splash clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Identity%20Center%20-%20close%20button%20p1m%20splash%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  identityCenterCloseButtonP1MSplashClicked(
    options?: EventOptions,
  ) {
    return this.track(new IdentityCenterCloseButtonP1MSplashClicked(), options);
  }

  /**
   * Identity Center - dont show checkbox selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Identity%20Center%20-%20dont%20show%20checkbox%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  identityCenterDontShowCheckboxSelected(
    options?: EventOptions,
  ) {
    return this.track(new IdentityCenterDontShowCheckboxSelected(), options);
  }

  /**
   * Identity Center - launch pure identity center clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Identity%20Center%20-%20launch%20pure%20identity%20center%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  identityCenterLaunchPureIdentityCenterClicked(
    options?: EventOptions,
  ) {
    return this.track(new IdentityCenterLaunchPureIdentityCenterClicked(), options);
  }

  /**
   * Impersonate User Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Impersonate%20User%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  impersonateUserClicked(
    options?: EventOptions,
  ) {
    return this.track(new ImpersonateUserClicked(), options);
  }

  /**
   * invoice management - dispute invoice
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/invoice%20management%20-%20dispute%20invoice)
   *
   * Tracking actions on disputing the invoices
   *
   * @param properties The event's properties (e.g. submit status)
   * @param options Amplitude event options.
   */
  invoiceManagementDisputeInvoice(
    properties: InvoiceManagementDisputeInvoiceProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceManagementDisputeInvoice(properties), options);
  }

  /**
   * invoice management - selected time range filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/invoice%20management%20-%20selected%20time%20range%20filter)
   *
   * Tracking actions on disputing the invoices
   *
   * @param options Amplitude event options.
   */
  invoiceManagementSelectedTimeRangeFilter(
    options?: EventOptions,
  ) {
    return this.track(new InvoiceManagementSelectedTimeRangeFilter(), options);
  }

  /**
   * Logout Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Logout%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  logoutClicked(
    options?: EventOptions,
  ) {
    return this.track(new LogoutClicked(), options);
  }

  /**
   * Manage My Profile Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Manage%20My%20Profile%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  manageMyProfileClicked(
    options?: EventOptions,
  ) {
    return this.track(new ManageMyProfileClicked(), options);
  }

  /**
   * manage organisations clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/manage%20organisations%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  manageOrganisationsClicked(
    options?: EventOptions,
  ) {
    return this.track(new ManageOrganisationsClicked(), options);
  }

  /**
   * modal chart shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/modal%20chart%20shown)
   *
   * User clicked on "view chart" on the recommendations modal
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. recommendation creator)
   * @param options Amplitude event options.
   */
  modalChartShown(
    properties: ModalChartShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalChartShown(properties), options);
  }

  /**
   * needs attention dropdown selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/needs%20attention%20dropdown%20selected)
   *
   * This event is for the selection on the upper summary section on Fleet -> Subscription related pages, which refers to customer to select Needs Attentions/ All Subscriptions.
   *
   * @param properties The event's properties (e.g. subscription program type)
   * @param options Amplitude event options.
   */
  needsAttentionDropdownSelected(
    properties: NeedsAttentionDropdownSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NeedsAttentionDropdownSelected(properties), options);
  }

  /**
   * notifications clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/notifications%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  notificationsClicked(
    options?: EventOptions,
  ) {
    return this.track(new NotificationsClicked(), options);
  }

  /**
   * Open Case From Alerts
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Open%20Case%20From%20Alerts)
   *
   * Event when user click on case inside Alerts page (from table or modal).
   *
   * @param options Amplitude event options.
   */
  openCaseFromAlerts(
    options?: EventOptions,
  ) {
    return this.track(new OpenCaseFromAlerts(), options);
  }

  /**
   * Open MPA requests settings
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Open%20MPA%20requests%20settings)
   *
   * Event to track when users open the MPA requests settings
   *
   * @param options Amplitude event options.
   */
  openMpaRequestsSettings(
    options?: EventOptions,
  ) {
    return this.track(new OpenMpaRequestsSettings(), options);
  }

  /**
   * Open Notification Popup
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Open%20Notification%20Popup)
   *
   * Event when user click on the notification bell on the top right of the screen.
   *
   * @param options Amplitude event options.
   */
  openNotificationPopup(
    options?: EventOptions,
  ) {
    return this.track(new OpenNotificationPopup(), options);
  }

  /**
   * Open preferences of notification center
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Open%20preferences%20of%20notification%20center)
   *
   * Event to track when a user opens the preferences of the notification center
   *
   * @param properties The event's properties (e.g. opened from popup)
   * @param options Amplitude event options.
   */
  openPreferencesOfNotificationCenter(
    properties: OpenPreferencesOfNotificationCenterProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPreferencesOfNotificationCenter(properties), options);
  }

  /**
   * orders page - order requested time selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/orders%20page%20-%20order%20requested%20time%20selection)
   *
   * The time range filters on the orders page for selecting the order requested time
   *
   * Owner: Emily Tian
   *
   * @param properties The event's properties (e.g. requestedAt)
   * @param options Amplitude event options.
   */
  ordersPageOrderRequestedTimeSelection(
    properties: OrdersPageOrderRequestedTimeSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrdersPageOrderRequestedTimeSelection(properties), options);
  }

  /**
   * orders page - order status selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/orders%20page%20-%20order%20status%20selection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. orderStatus)
   * @param options Amplitude event options.
   */
  ordersPageOrderStatusSelection(
    properties: OrdersPageOrderStatusSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrdersPageOrderStatusSelection(properties), options);
  }

  /**
   * orders page - order type selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/orders%20page%20-%20order%20type%20selection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. orderType)
   * @param options Amplitude event options.
   */
  ordersPageOrderTypeSelection(
    properties: OrdersPageOrderTypeSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrdersPageOrderTypeSelection(properties), options);
  }

  /**
   * organisation switcher expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/organisation%20switcher%20expanded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. totalOrgsCount)
   * @param options Amplitude event options.
   */
  organisationSwitcherExpanded(
    properties: OrganisationSwitcherExpandedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrganisationSwitcherExpanded(properties), options);
  }

  /**
   * organisation to switch selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/organisation%20to%20switch%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. currentOrgId)
   * @param options Amplitude event options.
   */
  organisationToSwitchSelected(
    properties: OrganisationToSwitchSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrganisationToSwitchSelected(properties), options);
  }

  /**
   * performance-table filter change
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/performance-table%20filter%20change)
   *
   * When the filter textbox on a performance-table component is changed directly by the user typing into it
   *
   * Owner: Spodi McSpodiface
   *
   * @param properties The event's properties (e.g. filter entity)
   * @param options Amplitude event options.
   */
  performanceTableFilterChange(
    properties: PerformanceTableFilterChangeProperties,
    options?: EventOptions,
  ) {
    return this.track(new PerformanceTableFilterChange(properties), options);
  }

  /**
   * prorec 6g card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%206g%20card%20clicked)
   *
   * Owner: Carl Rodriguez
   *
   * @param options Amplitude event options.
   */
  prorec6GCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new Prorec6GCardClicked(), options);
  }

  /**
   * prorec cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20cancelled)
   *
   * proactive recommendation modal - cancel button or cross icon on the top right clicked
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. recommendation creator)
   * @param options Amplitude event options.
   */
  prorecCancelled(
    properties: ProrecCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecCancelled(properties), options);
  }

  /**
   * prorec capacity card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20capacity%20card%20clicked)
   *
   * proactive recommendations capacity card clicked by the user
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. recommendation creator)
   * @param options Amplitude event options.
   */
  prorecCapacityCardClicked(
    properties: ProrecCapacityCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecCapacityCardClicked(properties), options);
  }

  /**
   * prorec combined card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20combined%20card%20clicked)
   *
   * proactive recommendations combined (controller and capacity) upgrade card clicked by the user
   *
   * Owner: Carl Rodriguez
   *
   * @param properties The event's properties (e.g. recommendation creator)
   * @param options Amplitude event options.
   */
  prorecCombinedCardClicked(
    properties: ProrecCombinedCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecCombinedCardClicked(properties), options);
  }

  /**
   * prorec controller card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20controller%20card%20clicked)
   *
   * proactive recommendations controller upgrade card clicked by the user
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. recommendation creator)
   * @param options Amplitude event options.
   */
  prorecControllerCardClicked(
    properties: ProrecControllerCardClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecControllerCardClicked(properties), options);
  }

  /**
   * prorec dismiss icon clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20dismiss%20icon%20clicked)
   *
   * user clicked on the dismiss icon or button
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. dismissal source)
   * @param options Amplitude event options.
   */
  prorecDismissIconClicked(
    properties: ProrecDismissIconClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecDismissIconClicked(properties), options);
  }

  /**
   * prorec dismissed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20dismissed)
   *
   * proactive recommendations dismissed
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. reason dismissed)
   * @param options Amplitude event options.
   */
  prorecDismissed(
    properties: ProrecDismissedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecDismissed(properties), options);
  }

  /**
   * prorec eol c60r1 card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20eol%20c60r1%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecEolC60R1CardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecEolC60R1CardClicked(), options);
  }

  /**
   * prorec eol1.1 card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20eol1.1%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecEol11CardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecEol11CardClicked(), options);
  }

  /**
   * prorec eol2.0 card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20eol2.0%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecEol20CardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecEol20CardClicked(), options);
  }

  /**
   * prorec eosc card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20eosc%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecEoscCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecEoscCardClicked(), options);
  }

  /**
   * prorec evergreen one card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20evergreen%20one%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecEvergreenOneCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecEvergreenOneCardClicked(), options);
  }

  /**
   * prorec forever now card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20forever%20now%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecForeverNowCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecForeverNowCardClicked(), options);
  }

  /**
   * prorec icon clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20icon%20clicked)
   *
   * proactive recommendations global-level icon clicked
   *
   * Owner: anpandey@purestorage.com
   *
   * @param options Amplitude event options.
   */
  prorecIconClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecIconClicked(), options);
  }

  /**
   * prorec lean sas to nvme card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20lean%20sas%20to%20nvme%20card%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  prorecLeanSasToNvmeCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecLeanSasToNvmeCardClicked(), options);
  }

  /**
   * prorec m2x card clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20m2x%20card%20clicked)
   *
   * Owner: Carl Rodriguez
   *
   * @param options Amplitude event options.
   */
  prorecM2XCardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ProrecM2XCardClicked(), options);
  }

  /**
   * prorec settings modal opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20settings%20modal%20opened)
   *
   * user clicks to open the proactive recommendation settings modal
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. prorec settings modal opened from)
   * @param options Amplitude event options.
   */
  prorecSettingsModalOpened(
    properties: ProrecSettingsModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecSettingsModalOpened(properties), options);
  }

  /**
   * prorec settings modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20settings%20modified)
   *
   * values on the settings modal changed
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. capacity threshold days changed)
   * @param options Amplitude event options.
   */
  prorecSettingsModified(
    properties: ProrecSettingsModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProrecSettingsModified(properties), options);
  }

  /**
   * prorec settings saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/prorec%20settings%20saved)
   *
   * proactive recommendations settings changed & save button clicked by user
   *
   * Owner: anpandey@purestorage.com
   *
   * @param options Amplitude event options.
   */
  prorecSettingsSaved(
    options?: EventOptions,
  ) {
    return this.track(new ProrecSettingsSaved(), options);
  }

  /**
   * quote requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/quote%20requested)
   *
   * This is when the user clicks to request a quote for an appliance or subscription. It is used on multiple pages and results in an opportunity being created (and sometimes updating and existing one) in salesforce.
   *
   * Owner: Sipian Wang
   *
   * @param properties The event's properties (e.g. quote requested from)
   * @param options Amplitude event options.
   */
  quoteRequested(
    properties: QuoteRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuoteRequested(properties), options);
  }

  /**
   * RedirectToAlertsPageFromNotificationsPopup
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/RedirectToAlertsPageFromNotificationsPopup)
   *
   * When user click on notification center popup then he can see number of alert and he can also redirect to alerts page.
   *
   * @param properties The event's properties (e.g. eventType)
   * @param options Amplitude event options.
   */
  redirectToAlertsPageFromNotificationsPopup(
    properties?: RedirectToAlertsPageFromNotificationsPopupProperties,
    options?: EventOptions,
  ) {
    return this.track(new RedirectToAlertsPageFromNotificationsPopup(properties), options);
  }

  /**
   * se rec create modal cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/se%20rec%20create%20modal%20cancelled)
   *
   * An systems engineer has cancelled out of the modal to create a recommendation
   *
   * Owner: Carl Rodriguez
   *
   * @param properties The event's properties (e.g. recommendation type)
   * @param options Amplitude event options.
   */
  seRecCreateModalCancelled(
    properties: SeRecCreateModalCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeRecCreateModalCancelled(properties), options);
  }

  /**
   * se rec create modal opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/se%20rec%20create%20modal%20opened)
   *
   * An SE has made a simulation in the modify hardware modal and has clicked the button to "Save and Recommend" an SE recommendation. This opens a preview modal before sendind the recommendation.
   *
   * Owner: Carl Rodriguez
   *
   * @param properties The event's properties (e.g. recommendation type)
   * @param options Amplitude event options.
   */
  seRecCreateModalOpened(
    properties: SeRecCreateModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeRecCreateModalOpened(properties), options);
  }

  /**
   * se rec created
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/se%20rec%20created)
   *
   * Owner: Carl Rodriguez
   *
   * @param properties The event's properties (e.g. recommendation type)
   * @param options Amplitude event options.
   */
  seRecCreated(
    properties: SeRecCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeRecCreated(properties), options);
  }

  /**
   * Security Assessment - Appliance URL click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Appliance%20URL%20click)
   *
   * Redirect to appliance page
   *
   * @param properties The event's properties (e.g. sourcePlace)
   * @param options Amplitude event options.
   */
  securityAssessmentApplianceUrlClick(
    properties: SecurityAssessmentApplianceUrlClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentApplianceUrlClick(properties), options);
  }

  /**
   * Security Assessment - Current Anomalies dialog - Anomaly tag select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Current%20Anomalies%20dialog%20-%20Anomaly%20tag%20select)
   *
   * Select an other anomaly using tag
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  securityAssessmentCurrentAnomaliesDialogAnomalyTagSelect(
    properties: SecurityAssessmentCurrentAnomaliesDialogAnomalyTagSelectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentCurrentAnomaliesDialogAnomalyTagSelect(properties), options);
  }

  /**
   * Security Assessment - Current Anomalies dialog - Appliance select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Current%20Anomalies%20dialog%20-%20Appliance%20select)
   *
   * Select an  appliance to check current anomalies
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  securityAssessmentCurrentAnomaliesDialogApplianceSelect(
    properties: SecurityAssessmentCurrentAnomaliesDialogApplianceSelectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentCurrentAnomaliesDialogApplianceSelect(properties), options);
  }

  /**
   * Security Assessment - Current Anomalies dialog - Tab select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Current%20Anomalies%20dialog%20-%20Tab%20select)
   *
   * Select an anomaly tab in the dialog
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  securityAssessmentCurrentAnomaliesDialogTabSelect(
    properties: SecurityAssessmentCurrentAnomaliesDialogTabSelectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentCurrentAnomaliesDialogTabSelect(properties), options);
  }

  /**
   * Security Assessment - Current Anomalies dialog - Volume select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Current%20Anomalies%20dialog%20-%20Volume%20select)
   *
   * Select a volume to see anomaly data
   *
   * @param properties The event's properties (e.g. selected)
   * @param options Amplitude event options.
   */
  securityAssessmentCurrentAnomaliesDialogVolumeSelect(
    properties: SecurityAssessmentCurrentAnomaliesDialogVolumeSelectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentCurrentAnomaliesDialogVolumeSelect(properties), options);
  }

  /**
   * Security Assessment - Current Anomalies dialog - Volumes table sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Current%20Anomalies%20dialog%20-%20Volumes%20table%20sort)
   *
   * Sort event for volumes table
   *
   * @param properties The event's properties (e.g. key)
   * @param options Amplitude event options.
   */
  securityAssessmentCurrentAnomaliesDialogVolumesTableSort(
    properties: SecurityAssessmentCurrentAnomaliesDialogVolumesTableSortProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentCurrentAnomaliesDialogVolumesTableSort(properties), options);
  }

  /**
   * Security Assessment - Fleet - Honeycomb click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Fleet%20-%20Honeycomb%20click)
   *
   * (Un)select filter using metric honeycomb click
   *
   * @param properties The event's properties (e.g. metric)
   * @param options Amplitude event options.
   */
  securityAssessmentFleetHoneycombClick(
    properties: SecurityAssessmentFleetHoneycombClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentFleetHoneycombClick(properties), options);
  }

  /**
   * Security Assessment - Fleet - Metric click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Fleet%20-%20Metric%20click)
   *
   * Click on metric. Might open a dialog or redirect to different page
   *
   * @param properties The event's properties (e.g. metric)
   * @param options Amplitude event options.
   */
  securityAssessmentFleetMetricClick(
    properties: SecurityAssessmentFleetMetricClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentFleetMetricClick(properties), options);
  }

  /**
   * Security Assessment - Fleet - Tooltip hover
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Fleet%20-%20Tooltip%20hover)
   *
   * Hover over tooltip for a metric
   *
   * @param properties The event's properties (e.g. complianceTooltip)
   * @param options Amplitude event options.
   */
  securityAssessmentFleetTooltipHover(
    properties: SecurityAssessmentFleetTooltipHoverProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentFleetTooltipHover(properties), options);
  }

  /**
   * Security Assessment - Fleet statistics - Hover action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Fleet%20statistics%20-%20Hover%20action)
   *
   * Fleet statistick tooltips hover on
   *
   * @param properties The event's properties (e.g. tooltip)
   * @param options Amplitude event options.
   */
  securityAssessmentFleetStatisticsHoverAction(
    properties: SecurityAssessmentFleetStatisticsHoverActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentFleetStatisticsHoverAction(properties), options);
  }

  /**
   * Security Assessment - Historical Data dialog - Appliance select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Historical%20Data%20dialog%20-%20Appliance%20select)
   *
   * Select an appliance to see historical data
   *
   * @param properties The event's properties (e.g. metric)
   * @param options Amplitude event options.
   */
  securityAssessmentHistoricalDataDialogApplianceSelect(
    properties: SecurityAssessmentHistoricalDataDialogApplianceSelectProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentHistoricalDataDialogApplianceSelect(properties), options);
  }

  /**
   * Security Assessment - Historical Data dialog - Redirect to details
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Historical%20Data%20dialog%20-%20Redirect%20to%20details)
   *
   * Redirect to audit or alerts page
   *
   * @param properties The event's properties (e.g. redirect)
   * @param options Amplitude event options.
   */
  securityAssessmentHistoricalDataDialogRedirectToDetails(
    properties: SecurityAssessmentHistoricalDataDialogRedirectToDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentHistoricalDataDialogRedirectToDetails(properties), options);
  }

  /**
   * Security Assessment - Honeycomb Chart - Appliance click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Honeycomb%20Chart%20-%20Appliance%20click)
   *
   * Event triggered by appliance clicked in honeycomb component
   *
   * @param properties The event's properties (e.g. drr)
   * @param options Amplitude event options.
   */
  securityAssessmentHoneycombChartApplianceClick(
    properties: SecurityAssessmentHoneycombChartApplianceClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentHoneycombChartApplianceClick(properties), options);
  }

  /**
   * Security Assessment - Honeycomb Chart - Hover action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Honeycomb%20Chart%20-%20Hover%20action)
   *
   * Click action on Honeycomb Chart
   *
   * @param properties The event's properties (e.g. hoverOn)
   * @param options Amplitude event options.
   */
  securityAssessmentHoneycombChartHoverAction(
    properties: SecurityAssessmentHoneycombChartHoverActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentHoneycombChartHoverAction(properties), options);
  }

  /**
   * Security Assessment - Honeycomb Chart - Legend Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Honeycomb%20Chart%20-%20Legend%20Click)
   *
   * Click honeycomb legend
   *
   * @param properties The event's properties (e.g. assessmentLevel)
   * @param options Amplitude event options.
   */
  securityAssessmentHoneycombChartLegendClick(
    properties: SecurityAssessmentHoneycombChartLegendClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentHoneycombChartLegendClick(properties), options);
  }

  /**
   * Security Assessment - Insights - Click on action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Click%20on%20action)
   *
   * Insight action or link clicked from the appliance details
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsClickOnAction(
    properties: SecurityAssessmentInsightsClickOnActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsClickOnAction(properties), options);
  }

  /**
   * Security Assessment - Insights - Go to Appliance Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Go%20to%20Appliance%20Detail)
   *
   * Open Appliance Detail from Insights sidebar
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsGoToApplianceDetail(
    properties: SecurityAssessmentInsightsGoToApplianceDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsGoToApplianceDetail(properties), options);
  }

  /**
   * Security Assessment - Insights - Hover over section
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Hover%20over%20section)
   *
   * User hovers over a section in the Insights panel
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsHoverOverSection(
    properties: SecurityAssessmentInsightsHoverOverSectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsHoverOverSection(properties), options);
  }

  /**
   * Security Assessment - Insights - Sidebar Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Sidebar%20Closed)
   *
   * Sidebar with Insights is closed
   *
   * @param properties The event's properties (e.g. stepBack)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsSidebarClosed(
    properties?: SecurityAssessmentInsightsSidebarClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsSidebarClosed(properties), options);
  }

  /**
   * Security Assessment - Insights - Sidebar Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Sidebar%20Opened)
   *
   * Sidebar with Insights is opened
   *
   * @param properties The event's properties (e.g. stepBack)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsSidebarOpened(
    properties: SecurityAssessmentInsightsSidebarOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsSidebarOpened(properties), options);
  }

  /**
   * Security Assessment - Insights - Toggle collapse
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Insights%20-%20Toggle%20collapse)
   *
   * Toggle action on Insight category
   *
   * @param properties The event's properties (e.g. insight)
   * @param options Amplitude event options.
   */
  securityAssessmentInsightsToggleCollapse(
    properties: SecurityAssessmentInsightsToggleCollapseProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentInsightsToggleCollapse(properties), options);
  }

  /**
   * Security Assessment - Table view - Appliance select
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Table%20view%20-%20Appliance%20select)
   *
   * Selection of an appliance in table view
   *
   * @param options Amplitude event options.
   */
  securityAssessmentTableViewApplianceSelect(
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentTableViewApplianceSelect(), options);
  }

  /**
   * Security Assessment - Table view - Change column selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Table%20view%20-%20Change%20column%20selection)
   *
   * Change column visibility action
   *
   * @param properties The event's properties (e.g. key)
   * @param options Amplitude event options.
   */
  securityAssessmentTableViewChangeColumnSelection(
    properties: SecurityAssessmentTableViewChangeColumnSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentTableViewChangeColumnSelection(properties), options);
  }

  /**
   * Security Assessment - Table view - Filter action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Table%20view%20-%20Filter%20action)
   *
   * Filter action in table view
   *
   * @param properties The event's properties (e.g. key)
   * @param options Amplitude event options.
   */
  securityAssessmentTableViewFilterAction(
    properties: SecurityAssessmentTableViewFilterActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentTableViewFilterAction(properties), options);
  }

  /**
   * Security Assessment - Table view - Sort action
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Security%20Assessment%20-%20Table%20view%20-%20Sort%20action)
   *
   * Sort action in table view
   *
   * @param properties The event's properties (e.g. key)
   * @param options Amplitude event options.
   */
  securityAssessmentTableViewSortAction(
    properties: SecurityAssessmentTableViewSortActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SecurityAssessmentTableViewSortAction(properties), options);
  }

  /**
   * service catalog clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/service%20catalog%20clicked)
   *
   * user clicked on something to navigate to the service catalog
   *
   * Owner: anpandey@purestorage.com
   *
   * @param properties The event's properties (e.g. catalog navigation source)
   * @param options Amplitude event options.
   */
  serviceCatalogClicked(
    properties: ServiceCatalogClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ServiceCatalogClicked(properties), options);
  }

  /**
   * Set Up MFA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Set%20Up%20MFA%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  setUpMfaClicked(
    options?: EventOptions,
  ) {
    return this.track(new SetUpMfaClicked(), options);
  }

  /**
   * Sort Appliance Genealogy
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sort%20Appliance%20Genealogy)
   *
   * Owner: Emily Tian
   *
   * @param properties The event's properties (e.g. Appliance Genealogy Sorting Category)
   * @param options Amplitude event options.
   */
  sortApplianceGenealogy(
    properties: SortApplianceGenealogyProperties,
    options?: EventOptions,
  ) {
    return this.track(new SortApplianceGenealogy(properties), options);
  }

  /**
   * subscription genealogy - events display options applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/subscription%20genealogy%20-%20events%20display%20options%20applied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. subscription genealogy event types)
   * @param options Amplitude event options.
   */
  subscriptionGenealogyEventsDisplayOptionsApplied(
    properties: SubscriptionGenealogyEventsDisplayOptionsAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionGenealogyEventsDisplayOptionsApplied(properties), options);
  }

  /**
   * subscription genealogy - exporting applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/subscription%20genealogy%20-%20exporting%20applied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. genealogy export category)
   * @param options Amplitude event options.
   */
  subscriptionGenealogyExportingApplied(
    properties: SubscriptionGenealogyExportingAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionGenealogyExportingApplied(properties), options);
  }

  /**
   * subscription genealogy - sorting applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/subscription%20genealogy%20-%20sorting%20applied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Subscription Genealogy Sorting Category)
   * @param options Amplitude event options.
   */
  subscriptionGenealogySortingApplied(
    properties: SubscriptionGenealogySortingAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionGenealogySortingApplied(properties), options);
  }

  /**
   * subscription genealogy - subscription names selection applied
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/subscription%20genealogy%20-%20subscription%20names%20selection%20applied)
   *
   * Applied the dropdown for switching between currentSubscriptionName and initialSubscriptionName
   *
   * @param properties The event's properties (e.g. subscription name)
   * @param options Amplitude event options.
   */
  subscriptionGenealogySubscriptionNamesSelectionApplied(
    properties: SubscriptionGenealogySubscriptionNamesSelectionAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionGenealogySubscriptionNamesSelectionApplied(properties), options);
  }

  /**
   * support case add attachment clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20add%20attachment%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseAddAttachmentClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseAddAttachmentClicked(), options);
  }

  /**
   * support case attachment clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20attachment%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseAttachmentClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseAttachmentClicked(), options);
  }

  /**
   * support case attachment selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20attachment%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseAttachmentSelected(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseAttachmentSelected(), options);
  }

  /**
   * support case comment clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20comment%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseCommentClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseCommentClicked(), options);
  }

  /**
   * support case edit clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20edit%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseEditClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseEditClicked(), options);
  }

  /**
   * support case escalate clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20escalate%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseEscalateClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseEscalateClicked(), options);
  }

  /**
   * support case modal category selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20modal%20category%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  supportCaseModalCategorySelected(
    properties: SupportCaseModalCategorySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseModalCategorySelected(properties), options);
  }

  /**
   * support case modal closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20modal%20closed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseModalClosed(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseModalClosed(), options);
  }

  /**
   * support case modal opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20modal%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseModalOpened(), options);
  }

  /**
   * support case modal severity selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20modal%20severity%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  supportCaseModalSeveritySelected(
    properties: SupportCaseModalSeveritySelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseModalSeveritySelected(properties), options);
  }

  /**
   * support case modal submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20modal%20submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseModalSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseModalSubmitted(), options);
  }

  /**
   * support case reopen clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20reopen%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportCaseReopenClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseReopenClicked(), options);
  }

  /**
   * support case selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20case%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. caseId)
   * @param options Amplitude event options.
   */
  supportCaseSelected(
    properties: SupportCaseSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportCaseSelected(properties), options);
  }

  /**
   * support escalate modal closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20escalate%20modal%20closed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportEscalateModalClosed(
    options?: EventOptions,
  ) {
    return this.track(new SupportEscalateModalClosed(), options);
  }

  /**
   * support escalate modal escalation reason selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20escalate%20modal%20escalation%20reason%20selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportEscalateModalEscalationReasonSelected(
    options?: EventOptions,
  ) {
    return this.track(new SupportEscalateModalEscalationReasonSelected(), options);
  }

  /**
   * support escalate modal opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20escalate%20modal%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportEscalateModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new SupportEscalateModalOpened(), options);
  }

  /**
   * support escalate modal submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20escalate%20modal%20submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportEscalateModalSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new SupportEscalateModalSubmitted(), options);
  }

  /**
   * support filter changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20filter%20changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. value)
   * @param options Amplitude event options.
   */
  supportFilterChanged(
    properties?: SupportFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportFilterChanged(properties), options);
  }

  /**
   * support open support case clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20open%20support%20case%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportOpenSupportCaseClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportOpenSupportCaseClicked(), options);
  }

  /**
   * support request service clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20request%20service%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportRequestServiceClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportRequestServiceClicked(), options);
  }

  /**
   * support tab changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20tab%20changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  supportTabChanged(
    properties: SupportTabChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportTabChanged(properties), options);
  }

  /**
   * support upgrade purity software clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/support%20upgrade%20purity%20software%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  supportUpgradePuritySoftwareClicked(
    options?: EventOptions,
  ) {
    return this.track(new SupportUpgradePuritySoftwareClicked(), options);
  }

  /**
   * Sustainability - Org comparison - KB article click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sustainability%20-%20Org%20comparison%20-%20KB%20article%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sustainabilityOrgComparisonKbArticleClick(
    options?: EventOptions,
  ) {
    return this.track(new SustainabilityOrgComparisonKbArticleClick(), options);
  }

  /**
   * Sustainability - Org comparison - recommendations click
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sustainability%20-%20Org%20comparison%20-%20recommendations%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sustainabilityOrgComparisonRecommendationsClick(
    options?: EventOptions,
  ) {
    return this.track(new SustainabilityOrgComparisonRecommendationsClick(), options);
  }

  /**
   * Sustainability - Org comparison - tooltip hover
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sustainability%20-%20Org%20comparison%20-%20tooltip%20hover)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sustainabilityOrgComparisonTooltipHover(
    options?: EventOptions,
  ) {
    return this.track(new SustainabilityOrgComparisonTooltipHover(), options);
  }

  /**
   * Sustainability - View Selection - Map View
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sustainability%20-%20View%20Selection%20-%20Map%20View)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  sustainabilityViewSelectionMapView(
    properties?: SustainabilityViewSelectionMapViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new SustainabilityViewSelectionMapView(properties), options);
  }

  /**
   * Sustainability - View Selection - Table View
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Sustainability%20-%20View%20Selection%20-%20Table%20View)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  sustainabilityViewSelectionTableView(
    properties?: SustainabilityViewSelectionTableViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new SustainabilityViewSelectionTableView(properties), options);
  }

  /**
   * UM - Batch Edit - Role selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/UM%20-%20Batch%20Edit%20-%20Role%20selected)
   *
   * Event to track when a user selects a role during batch editing
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  umBatchEditRoleSelected(
    properties?: UmBatchEditRoleSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UmBatchEditRoleSelected(properties), options);
  }

  /**
   * UM - Batch Edit - Selection changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/UM%20-%20Batch%20Edit%20-%20Selection%20changed)
   *
   * Event to track when a user changes their selection in batch editing mode (rows in the table)
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  umBatchEditSelectionChanged(
    properties: UmBatchEditSelectionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UmBatchEditSelectionChanged(properties), options);
  }

  /**
   * UM - Batch Edit - View selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/UM%20-%20Batch%20Edit%20-%20View%20selected)
   *
   * Event to track when a user views selected items during batch editing process
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  umBatchEditViewSelected(
    properties?: UmBatchEditViewSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UmBatchEditViewSelected(properties), options);
  }

  /**
   * Unsubscribe notification from email
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/Unsubscribe%20notification%20from%20email)
   *
   * Event indicating that a user has opted out of receiving email notifications on Pure1 url /unsubscribe-notifications
   *
   * @param properties The event's properties (e.g. topic)
   * @param options Amplitude event options.
   */
  unsubscribeNotificationFromEmail(
    properties: UnsubscribeNotificationFromEmailProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnsubscribeNotificationFromEmail(properties), options);
  }

  /**
   * unversal credit dashboard - filtering
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/unversal%20credit%20dashboard%20-%20filtering)
   *
   * Quote requests related to Universal Credits
   *
   * @param options Amplitude event options.
   */
  unversalCreditDashboardFiltering(
    options?: EventOptions,
  ) {
    return this.track(new UnversalCreditDashboardFiltering(), options);
  }

  /**
   * usage metrics type switched
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/usage%20metrics%20type%20switched)
   *
   * This event is for the usage metrics type selection on the license utilization tab on Fleet -> Subscription related pages, which refers to customer to select different metrics: License/Appliance/LogicalManaged/Cluster Metrics
   *
   * @param properties The event's properties (e.g. subscription usage metrics types)
   * @param options Amplitude event options.
   */
  usageMetricsTypeSwitched(
    properties: UsageMetricsTypeSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UsageMetricsTypeSwitched(properties), options);
  }

  /**
   * vm assessments clicked investigate in topology
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20clicked%20investigate%20in%20topology)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. entityId)
   * @param options Amplitude event options.
   */
  vmAssessmentsClickedInvestigateInTopology(
    properties: VmAssessmentsClickedInvestigateInTopologyProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsClickedInvestigateInTopology(properties), options);
  }

  /**
   * vm assessments selected cluster
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20selected%20cluster)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. clusterId)
   * @param options Amplitude event options.
   */
  vmAssessmentsSelectedCluster(
    properties: VmAssessmentsSelectedClusterProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSelectedCluster(properties), options);
  }

  /**
   * vm assessments selected table item
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20selected%20table%20item)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  vmAssessmentsSelectedTableItem(
    properties: VmAssessmentsSelectedTableItemProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSelectedTableItem(properties), options);
  }

  /**
   * vm assessments selected vcenter
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20selected%20vcenter)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. vcenterId)
   * @param options Amplitude event options.
   */
  vmAssessmentsSelectedVcenter(
    properties: VmAssessmentsSelectedVcenterProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSelectedVcenter(properties), options);
  }

  /**
   * vm assessments splash close
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20splash%20close)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. doNotShowAgain)
   * @param options Amplitude event options.
   */
  vmAssessmentsSplashClose(
    properties: VmAssessmentsSplashCloseProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSplashClose(properties), options);
  }

  /**
   * vm assessments splash go to assessments
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20splash%20go%20to%20assessments)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  vmAssessmentsSplashGoToAssessments(
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSplashGoToAssessments(), options);
  }

  /**
   * vm assessments switched table view
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20assessments%20switched%20table%20view)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. viewName)
   * @param options Amplitude event options.
   */
  vmAssessmentsSwitchedTableView(
    properties: VmAssessmentsSwitchedTableViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmAssessmentsSwitchedTableView(properties), options);
  }

  /**
   * vm topology banner link clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20banner%20link%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyBannerLinkClicked(
    properties: VmTopologyBannerLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyBannerLinkClicked(properties), options);
  }

  /**
   * vm topology changed aggregation
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20aggregation)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyChangedAggregation(
    properties: VmTopologyChangedAggregationProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedAggregation(properties), options);
  }

  /**
   * vm topology changed cluster
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20cluster)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyChangedCluster(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedCluster(), options);
  }

  /**
   * vm topology changed datacenter
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20datacenter)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyChangedDatacenter(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedDatacenter(), options);
  }

  /**
   * vm topology changed overlay metric
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20overlay%20metric)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyChangedOverlayMetric(
    properties: VmTopologyChangedOverlayMetricProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedOverlayMetric(properties), options);
  }

  /**
   * vm topology changed time range
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20time%20range)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyChangedTimeRange(
    properties: VmTopologyChangedTimeRangeProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedTimeRange(properties), options);
  }

  /**
   * vm topology changed vcenter
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20changed%20vcenter)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyChangedVcenter(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyChangedVcenter(), options);
  }

  /**
   * vm topology clicked capacity churn help
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20capacity%20churn%20help)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedCapacityChurnHelp(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedCapacityChurnHelp(), options);
  }

  /**
   * vm topology clicked clear filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20clear%20filter)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedClearFilter(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedClearFilter(), options);
  }

  /**
   * vm topology clicked collapse all columns
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20collapse%20all%20columns)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedCollapseAllColumns(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedCollapseAllColumns(), options);
  }

  /**
   * vm topology clicked expand all columns
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20expand%20all%20columns)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedExpandAllColumns(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedExpandAllColumns(), options);
  }

  /**
   * vm topology clicked expand timeseries
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20expand%20timeseries)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedExpandTimeseries(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedExpandTimeseries(), options);
  }

  /**
   * vm topology clicked filter to selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20filter%20to%20selected)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedFilterToSelected(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedFilterToSelected(), options);
  }

  /**
   * vm topology clicked generate report
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20generate%20report)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedGenerateReport(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedGenerateReport(), options);
  }

  /**
   * vm topology clicked highlight series
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20highlight%20series)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedHighlightSeries(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedHighlightSeries(), options);
  }

  /**
   * vm topology clicked remove all timeseries
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20remove%20all%20timeseries)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedRemoveAllTimeseries(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedRemoveAllTimeseries(), options);
  }

  /**
   * vm topology clicked remove timeseries
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20clicked%20remove%20timeseries)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyClickedRemoveTimeseries(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyClickedRemoveTimeseries(), options);
  }

  /**
   * vm topology column collapsed
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20column%20collapsed)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyColumnCollapsed(
    properties: VmTopologyColumnCollapsedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyColumnCollapsed(properties), options);
  }

  /**
   * vm topology column expanded
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20column%20expanded)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyColumnExpanded(
    properties: VmTopologyColumnExpandedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyColumnExpanded(properties), options);
  }

  /**
   * vm topology column sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20column%20sort)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyColumnSort(
    properties: VmTopologyColumnSortProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyColumnSort(properties), options);
  }

  /**
   * vm topology download csv
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20download%20csv)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. aggregation)
   * @param options Amplitude event options.
   */
  vmTopologyDownloadCsv(
    properties: VmTopologyDownloadCsvProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyDownloadCsv(properties), options);
  }

  /**
   * vm topology entity badge clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20entity%20badge%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. entity type)
   * @param options Amplitude event options.
   */
  vmTopologyEntityBadgeClicked(
    properties: VmTopologyEntityBadgeClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyEntityBadgeClicked(properties), options);
  }

  /**
   * vm topology global search bottom message clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20global%20search%20bottom%20message%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyGlobalSearchBottomMessageClicked(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyGlobalSearchBottomMessageClicked(), options);
  }

  /**
   * vm topology global search clear
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20global%20search%20clear)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyGlobalSearchClear(
    properties: VmTopologyGlobalSearchClearProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyGlobalSearchClear(properties), options);
  }

  /**
   * vm topology global search searching
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20global%20search%20searching)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyGlobalSearchSearching(
    properties: VmTopologyGlobalSearchSearchingProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyGlobalSearchSearching(properties), options);
  }

  /**
   * vm topology global search selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20global%20search%20selection)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyGlobalSearchSelection(
    properties: VmTopologyGlobalSearchSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyGlobalSearchSelection(properties), options);
  }

  /**
   * vm topology go to assessment
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20go%20to%20assessment)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  vmTopologyGoToAssessment(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyGoToAssessment(), options);
  }

  /**
   * vm topology link clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20link%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyLinkClicked(
    properties: VmTopologyLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyLinkClicked(properties), options);
  }

  /**
   * vm topology node checked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20node%20checked)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyNodeChecked(
    properties: VmTopologyNodeCheckedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyNodeChecked(properties), options);
  }

  /**
   * vm topology node deselected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20node%20deselected)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyNodeDeselected(
    properties: VmTopologyNodeDeselectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyNodeDeselected(properties), options);
  }

  /**
   * vm topology node selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20node%20selected)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologyNodeSelected(
    properties: VmTopologyNodeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyNodeSelected(properties), options);
  }

  /**
   * vm topology opened management modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20opened%20management%20modal)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyOpenedManagementModal(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyOpenedManagementModal(), options);
  }

  /**
   * vm topology opened status modal
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20opened%20status%20modal)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyOpenedStatusModal(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyOpenedStatusModal(), options);
  }

  /**
   * vm topology report generation cancel clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20report%20generation%20cancel%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyReportGenerationCancelClicked(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyReportGenerationCancelClicked(), options);
  }

  /**
   * vm topology report generation generate clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20report%20generation%20generate%20clicked)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyReportGenerationGenerateClicked(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyReportGenerationGenerateClicked(), options);
  }

  /**
   * vm topology search
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20search)
   *
   * Owner: Roman Peshkov
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  vmTopologySearch(
    properties: VmTopologySearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new VmTopologySearch(properties), options);
  }

  /**
   * vm topology shifted time backward
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20shifted%20time%20backward)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyShiftedTimeBackward(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyShiftedTimeBackward(), options);
  }

  /**
   * vm topology shifted time forward
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20shifted%20time%20forward)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyShiftedTimeForward(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyShiftedTimeForward(), options);
  }

  /**
   * vm topology toggle show deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/purestorage/Pure1/events/main/latest/vm%20topology%20toggle%20show%20deleted)
   *
   * Owner: Roman Peshkov
   *
   * @param options Amplitude event options.
   */
  vmTopologyToggleShowDeleted(
    options?: EventOptions,
  ) {
    return this.track(new VmTopologyToggleShowDeleted(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
