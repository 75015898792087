import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { DisasterRecoveryVirtualMachine } from '../models/disaster-recovery-virtual-machine';
import { ListParams } from '../interfaces/list-params';
import { isArray } from 'lodash';
import { DraasApiConfig } from './disaster-recovery-constants';
import { Observable } from 'rxjs';
import { DataPage } from '../interfaces/data-page';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryPlanVirtualMachinesService extends GenericService<DisasterRecoveryVirtualMachine> {
    constructor(
        protected http: HttpClient,
        private throttlingHttpClient: DisasterRecoveryThrottlingHttpClient,
    ) {
        super({
            resourceClass: DisasterRecoveryVirtualMachine,
            endpoint: `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/:clusterId/recovery-plans/:planId/protection-groups/:protectionGroupId/vms`,
            list: true,
            create: false,
            update: false,
            delete: false,
        });
    }

    private getEndpoint(clusterId: string, planId: string, protectionGroupId: string): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}/recovery-plans/${planId}/protection-groups/${protectionGroupId}/vms`;
    }

    override list(
        params?: ListParams<DisasterRecoveryVirtualMachine>,
    ): Observable<DataPage<DisasterRecoveryVirtualMachine>> {
        return this.throttlingHttpClient.queueRequest(super.list(params));
    }

    override getListRequest(params: ListParams<DisasterRecoveryVirtualMachine>): { url: string } {
        const queryParams: string[] = [];
        const clusterId: string = params.filter.clusterId as string;
        const planId: string = params.filter.planId as string;
        const protectionGroupId: string = params.filter.protectionGroupId as string;

        queryParams.push(`page_number=${Math.floor(params.pageStart / params.pageSize)}`);
        queryParams.push(`page_size=${params.pageSize}`);

        if (params.filter || params.defaultFilter) {
            const filter = { ...params.defaultFilter, ...params.filter };
            Object.keys(filter)
                .filter(k => k != null && k !== 'clusterId' && k !== 'planId' && k !== 'protectionGroupId')
                .forEach(k => queryParams.push(`${k}=${encodeURIComponent(filter[k] as string)}`));
        }

        if (params.sort) {
            if (isArray(params.sort)) {
                params.sort.forEach(s => queryParams.push(`sort=${s.key},${s.order}`));
            } else {
                queryParams.push(`sort=${params.sort.key},${params.sort.order}`);
            }
        }
        if (!queryParams.find(p => p.indexOf('sort=name') === 0)) {
            queryParams.push(`sort=name,ASC`); // Append name sort if not already contained to stabilize sorts by host and datacenter
        }

        const url =
            this.getEndpoint(clusterId, planId, protectionGroupId) +
            (queryParams.length > 0 ? `?${queryParams.join('&')}` : '');
        return { url };
    }
}
