import {
    DraasApiDeploymentExtended,
    DraasApiDeployment,
    DraasApiDeploymentProtectionSummary,
} from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryDeploymentExtended {
    deployment: DisasterRecoveryDeployment;
    protectionSummary: DisasterRecoveryDeploymentProtectionSummary;

    constructor(json: DraasApiDeploymentExtended) {
        this.deployment = new DisasterRecoveryDeployment(json.deployment);
        this.protectionSummary = new DisasterRecoveryDeploymentProtectionSummary(json.protection_summary);
    }
}

export class DisasterRecoveryDeployment {
    id: string;
    licenseKey: string;
    status: DisasterRecoveryDeploymentStatus;
    name: string;
    description?: string;

    constructor(json: DraasApiDeployment) {
        this.id = json.id;
        this.licenseKey = json.license_key;
        this.status = DisasterRecoveryDeploymentStatus[json.status] || DisasterRecoveryDeploymentStatus.UNKNOWN;
        this.name = json.name;
        this.description = json.description;
    }
}

export class DisasterRecoveryDeploymentProtectionSummary {
    protectedVms: number;
    discoveredVms: number;
    protectedBytes: number;

    constructor(json: DraasApiDeploymentProtectionSummary) {
        this.protectedVms = json.protected_vms;
        this.discoveredVms = json.discovered_vms;
        this.protectedBytes = json.protected_bytes;
    }
}

export enum DisasterRecoveryDeploymentStatus {
    HEALTHY = 'HEALTHY',
    UNHEALTHY = 'UNHEALTHY',
    UNKNOWN = 'UNKNOWN',
}
