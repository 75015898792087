import { escape } from 'lodash';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'autocomplete-match',
    template: '<span [innerHTML]="matchString"></span>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteMatchComponent implements OnInit, OnChanges {
    @Input() readonly value: string;
    @Input() readonly matchRegex: RegExp;

    // non-bindings
    matchString = '';

    private escapedValue: string;

    ngOnInit(): void {
        this.createMatchString();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.escapedValue = escape(this.value || '');
        }

        if (changes.value || changes.matchRegex) {
            this.createMatchString();
        }
    }

    private createMatchString(): void {
        this.matchString = this.escapedValue;
        if (this.escapedValue && this.matchRegex) {
            this.matchString = this.escapedValue.replace(this.matchRegex, '<span class="match-region">$1</span>');
        }
    }
}
