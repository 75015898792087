import { Injectable } from '@angular/core';
import { DisasterRecoveryVmJobExecution } from '../models/disaster-recovery-vm-job-execution';
import { DisasterRecoveryJobExecutionWithSteps } from '../models/disaster-recovery-job-execution-with-steps';
import { exhaustMap, map, NEVER, Observable, pairwise, repeatWhen, Subject, tap } from 'rxjs';
import {
    DraasApiJobExecutionInfo,
    DraasApiProtectionGroupExecutionInfo,
    DraasApiRecoveryPlanExecutionInfo,
    DraasApiVmJobExecutionInfo,
} from '@pure/paas-api-gateway-client-ts';
import {
    DisasterRecoveryJobExecution,
    DisasterRecoveryJobExecutionStatus,
    DraasApiProtectionOrRecoveryJobExecutionInfo,
} from '../models/disaster-recovery-job-execution';
import { IRestResponse } from '../interfaces/collection';
import { catchError, startWith, takeUntil } from 'rxjs/operators';
import { JobExecutionType } from '../../disaster-recovery/monitor/job-execution-monitor/job-execution-monitor.component';
import moment from 'moment';
import { DisasterRecoveryProtectionGroupExecution } from '../models/disaster-recovery-protection-group-execution';
import { DisasterRecoveryRecoveryPlanExecution } from '../models/disaster-recovery-recovery-plan-execution';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DataPage } from '../interfaces/data-page';
import { smartTimer } from '@pstg/smart-timer';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryJobExecutionsService {
    private readonly RUNNING_JOBS_REFRESH_INTERVAL_MS = 10000;
    private readonly FINISHED_JOBS_REFRESH_INTERVAL_MS = 60000;

    private RUNNING_PATH = '/running';
    private FINISHED_PATH = '/history';
    private CANCEL_PATH = '/cancel';
    private VM_JOB_EXECUTIONS_PATH = '/vm-job-executions';
    private NON_VM_JOB_EXECUTIONS_PATH = '/non-vm-job-executions';

    private refreshRunningJobs$ = new Subject<void>();
    private stopFinishedJobs$ = new Subject<void>();
    private refreshFinishedJobs$ = new Subject<void>();

    constructor(private http: DisasterRecoveryThrottlingHttpClient) {}

    private getServiceEndpoint(clusterId: String): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${clusterId}`;
    }

    private getProtectionPath(clusterId: String): string {
        return this.getServiceEndpoint(clusterId) + '/job-execution-plans/protection';
    }

    private getRecoveryPath(clusterId: String): string {
        return this.getServiceEndpoint(clusterId) + '/job-execution-plans/recovery';
    }

    getRunningProtectionJobExecutions(clusterId: string): Observable<DraasApiProtectionGroupExecutionInfo[]> {
        const queryParams = [`page_number=0`, `page_size=1000`];
        const url = this.getPlansEndpointUrl(clusterId, JobExecutionType.Protection, this.RUNNING_PATH, queryParams);

        return this.http
            .get<IRestResponse<DraasApiProtectionGroupExecutionInfo>>(url)
            .pipe(map(response => response.items));
    }

    getRunningJobExecutions(clusterId: string, type: JobExecutionType): Observable<DisasterRecoveryJobExecution[]> {
        const url = this.getPlansEndpointUrl(clusterId, type, this.RUNNING_PATH);

        return smartTimer(0, this.RUNNING_JOBS_REFRESH_INTERVAL_MS).pipe(
            tap(() => this.refreshRunningJobs$.next()),
            exhaustMap(() =>
                this.getPageObjects(url, (json: DraasApiProtectionOrRecoveryJobExecutionInfo) =>
                    this.buildJobExecutionFromJson(type, json),
                ),
            ),
            // set an initial value so the first received value gets through the pairwise operator
            startWith(null),
            pairwise(),
            tap(([previous, current]) => this.refreshFinishedJobsIfRunningJobsChanged(previous, current)),
            map(([_, current]) => current),
        );
    }

    getFinishedJobExecutions(
        clusterId: string,
        type: JobExecutionType,
        pageSize: number,
        pageNumber: number,
        from: moment.Moment,
        to?: moment.Moment,
    ): Observable<DataPage<DisasterRecoveryJobExecution>> {
        const queryParams = [
            `page_number=${pageNumber}`,
            `page_size=${pageSize}`,
            `dateTimeFrom=${from.toISOString()}`,
            'sort=start,desc',
        ];
        if (to) {
            queryParams.push(`dateTimeTo=${to.toISOString()}`);
        }
        const url = this.getPlansEndpointUrl(clusterId, type, this.FINISHED_PATH, queryParams);

        // refresh after the specified time interval passes or when triggered by a change in running job executions
        return smartTimer(0, this.FINISHED_JOBS_REFRESH_INTERVAL_MS).pipe(
            exhaustMap(() =>
                this.getDataPage(url, (json: DraasApiProtectionOrRecoveryJobExecutionInfo) =>
                    this.buildJobExecutionFromJson(type, json),
                ),
            ),
            takeUntil(this.stopFinishedJobs$),
            repeatWhen(() => this.refreshFinishedJobs$),
        );
    }

    getVmJobExecutions(
        type: JobExecutionType,
        jobExecution: DisasterRecoveryJobExecution,
        groupId?: string,
        sourceProviderId?: string,
    ): Observable<DisasterRecoveryVmJobExecution[]> {
        const queryParams: string[] = [];
        if (type === JobExecutionType.Recovery && groupId) {
            queryParams.push('protectionGroupId=' + groupId);
        }
        if (type === JobExecutionType.Protection && sourceProviderId) {
            queryParams.push('sourceProviderId=' + sourceProviderId);
        }
        const url = this.getPlansEndpointUrl(
            jobExecution.clusterId,
            type,
            '/' + jobExecution.id + this.VM_JOB_EXECUTIONS_PATH,
            queryParams,
        );

        return this.getListObjects(
            url,
            (json: DraasApiVmJobExecutionInfo) => new DisasterRecoveryVmJobExecution(json, jobExecution),
        ).pipe(
            // if this is a part of a running job execution plan, refresh synchronously with it
            repeatWhen(() => (this.isRunningStatus(jobExecution.status) ? this.refreshRunningJobs$ : NEVER)),
        );
    }

    getProtectionJobExecution(clusterId: string, id: string): Observable<DisasterRecoveryProtectionGroupExecution> {
        const url = this.getPlansEndpointUrl(clusterId, JobExecutionType.Protection, '/' + id);
        return this.http.get<DraasApiProtectionGroupExecutionInfo>(url).pipe(
            // TODO: XAAS-13171 remove this once we use hivified job steps
            tap(() => this.refreshRunningJobs$.next()),
            map(response => new DisasterRecoveryProtectionGroupExecution(response)),
            catchError(err => {
                console.error('Error fetching from ' + url, err);
                return NEVER;
            }),
        );
    }

    getRecoveryJobExecution(clusterId: string, id: string): Observable<DisasterRecoveryRecoveryPlanExecution> {
        const url = this.getPlansEndpointUrl(clusterId, JobExecutionType.Recovery, '/' + id);
        return this.http.get<DraasApiRecoveryPlanExecutionInfo>(url).pipe(
            // TODO: XAAS-13171 remove this once we use hivified job steps
            tap(() => this.refreshRunningJobs$.next()),
            map(response => new DisasterRecoveryRecoveryPlanExecution(response)),
            catchError(err => {
                console.error('Error fetching from ' + url, err);
                return NEVER;
            }),
        );
    }

    getJobExecutionsWithSteps(
        jobExecution: DisasterRecoveryJobExecution,
        vmId: string | null,
    ): Observable<DisasterRecoveryJobExecutionWithSteps[]> {
        const url = vmId
            ? `${this.getServiceEndpoint(jobExecution.clusterId)}${this.VM_JOB_EXECUTIONS_PATH}/${jobExecution.id}/${vmId}`
            : `${this.getServiceEndpoint(jobExecution.clusterId)}${this.NON_VM_JOB_EXECUTIONS_PATH}/${jobExecution.id}`;

        return this.getListObjects(
            url,
            (json: DraasApiJobExecutionInfo) => new DisasterRecoveryJobExecutionWithSteps(json),
        ).pipe(
            // if this is a part of a running job execution plan, refresh synchronously with it
            repeatWhen(() => (this.isRunningStatus(jobExecution.status) ? this.refreshRunningJobs$ : NEVER)),
        );
    }

    cancelJobExecution(clusterId: String, type: JobExecutionType, jobExecutionId: string): Observable<void> {
        const url = this.getPlansEndpointUrl(clusterId, type, '/' + jobExecutionId + this.CANCEL_PATH);
        return this.http.post<void>(url, null);
    }

    private getPlansEndpointUrl(
        clusterId: String,
        type: JobExecutionType,
        path: string,
        queryParams?: string[],
    ): string {
        const query = queryParams?.length > 0 ? '?' + queryParams.join('&') : '';
        return (
            (type === JobExecutionType.Protection
                ? this.getProtectionPath(clusterId)
                : this.getRecoveryPath(clusterId)) +
            path +
            query
        );
    }

    private buildJobExecutionFromJson(
        type: JobExecutionType,
        json: DraasApiProtectionOrRecoveryJobExecutionInfo,
    ): DisasterRecoveryJobExecution {
        if (type === JobExecutionType.Protection) {
            return new DisasterRecoveryProtectionGroupExecution(json as DraasApiProtectionGroupExecutionInfo);
        } else {
            return new DisasterRecoveryRecoveryPlanExecution(json as DraasApiRecoveryPlanExecutionInfo);
        }
    }

    private refreshFinishedJobsIfRunningJobsChanged(
        previous: DisasterRecoveryJobExecution[],
        current: DisasterRecoveryJobExecution[],
    ): void {
        // if any job execution disappeared from the running list, refresh the list of finished jobs
        if (
            previous &&
            !previous.every(searchedJobExecution =>
                current.find(jobExecution => jobExecution.id === searchedJobExecution.id),
            )
        ) {
            this.stopFinishedJobs$.next();
            this.refreshFinishedJobs$.next();
        }
    }

    private getDataPage<JsonType, EntityType>(
        url: string,
        fromJson: (json: JsonType) => EntityType,
    ): Observable<DataPage<EntityType>> {
        return this.http.get<IRestResponse<JsonType>>(url).pipe(
            map(response => this.makeDataPage(response, fromJson)),
            catchError(err => {
                console.error(err);
                return NEVER;
            }),
        );
    }

    private getPageObjects<JsonType, EntityType>(
        url: string,
        fromJson: (json: JsonType) => EntityType,
    ): Observable<EntityType[]> {
        return this.http.get<IRestResponse<JsonType>>(url).pipe(
            map(response => response.items.map(fromJson)),
            catchError(err => {
                console.error(err);
                return NEVER;
            }),
        );
    }

    private getListObjects<JsonType, EntityType>(
        url: string,
        fromJson: (json: JsonType) => EntityType,
    ): Observable<EntityType[]> {
        return this.http.get<JsonType[]>(url).pipe(
            map(response => response.map(fromJson)),
            catchError(err => {
                console.error('Error fetching from ' + url, err);
                return NEVER;
            }),
        );
    }

    private isRunningStatus(jobExecutionStatus: DisasterRecoveryJobExecutionStatus): boolean {
        return (
            jobExecutionStatus === DisasterRecoveryJobExecutionStatus.IN_PROGRESS ||
            jobExecutionStatus === DisasterRecoveryJobExecutionStatus.PENDING
        );
    }

    private makeDataPage<EntityType, JsonType>(
        response: IRestResponse<JsonType>,
        fromJson: (json: JsonType) => EntityType,
    ): DataPage<EntityType> {
        return {
            total: response.total_item_count,
            response: response.items.map(item => fromJson(item)),
        };
    }
}
