import moment from 'moment';

import { Resource } from '../interfaces/resource';
import { IAggregationMethod, IArrayAggregationMethod } from '../services/metrics-history.service';

export enum Metric {
    ARRAY_EFFECTIVE_USED_SPACE = 'array_effective_used_space',
    LICENSE_EFFECTIVE_USED_SPACE = 'license_effective_used_space',
    LICENSE_ON_DEMAND_USAGE = 'license_on_demand_usage',
    LICENSE_VM_INSTANCE_COUNT = 'license_vm_instance_count',
    VM_CLUSTER_VM_INSTANCE_COUNT = 'vm_cluster_vm_instance_count',
    LICENSE_EUC_BREAKDOWN = 'license_euc_breakdown',
    DRAAS_CLUSTER_LOGICAL_MANAGED_CAPACITY = 'draas_cluster_logical_managed_capacity',
    LICENSE_LOGICAL_MANAGED_CAPACITY = 'license_logical_managed_capacity',
    READ_LATENCY = 'read_latency',
    WRITE_LATENCY = 'write_latency',
    MIRROR_WRITE_LATENCY = 'mirror_write_latency',
    READ_BANDWIDTH = 'read_bandwidth',
    WRITE_BANDWIDTH = 'write_bandwidth',
    READ_WRITE_BANDWIDTH = 'read_write_bandwidth',
    MIRROR_WRITE_BANDWIDTH = 'mirror_write_bandwidth',
    TOTAL_EFFECTIVE_USED_SPACE = 'total_effective_used_space',
    LICENSE_POWER_EFFICIENCY = 'license_power_efficiency',
    BM_TOTAL_BUSYNESS = 'bm_total_busyness',
    READ_IOPS = 'read_iops',
    WRITE_IOPS = 'write_iops',
    READ_WRITE_IOPS = 'read_write_iops',
    ARRAY_USABLE_USED_CAPACITY = 'array_usable_used_capacity',
    LICENSE_USABLE_USED_CAPACITY = 'license_usable_used_capacity',
}

export interface DataPoint {
    x: moment.Moment;
    y: number;
    isCalculated?: boolean;
}

export type AggregationMethod = IAggregationMethod | IArrayAggregationMethod;

export class MetricsHistory implements Resource {
    id: string;
    name: string;
    metric_name: Metric;
    data: DataPoint[];
    aggregation: AggregationMethod;
    resolution: number;

    constructor(json: any) {
        this.id = json.entity.id;
        this.name = null;
        this.metric_name = json.metric_name;
        this.data = json.data
            .filter(point => point.length === 2 || point.length === 3)
            .map(point => {
                return {
                    x: moment(point[0]).utc(),
                    y: point[1],
                    isCalculated: point?.[2],
                };
            });
        this.aggregation = json.aggregation;
        this.resolution = json.resolution;
    }
}
