import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GenericService } from './generic.service';
import { SortParams } from '../interfaces/list-params';
import { ArraySpace } from '../models/ArraySpace';

const fields: getArraysFields[] = [
    'array_id',
    'hostname',
    'fqdn',
    'last_updated',
    'capacity_analysis',
    'utility_usage_summary',
    'purearray_list_space',
    'product',
    'not_installed_blades',
    'total_blades',
    'used_blades',
    'model',
    'active_status',
];

@Injectable({ providedIn: 'root' })
export class ArraySpaceService extends GenericService<ArraySpace> {
    constructor(protected http: HttpClient) {
        super({
            resourceClass: ArraySpace,
            endpoint: '/rest/v3/arrays',
            defaultParams: {
                pageStart: 0,
                pageSize: 3000,
                sort: <SortParams>{
                    key: 'hostname',
                    order: 'asc',
                },
                fields: fields,
            },
        });
    }
}
