import { Resource } from '../interfaces/resource';

const REPORT_TEMPLATE_TYPES = [
    'array_cap',
    'array_perf',
    'array_pods_perf',
    'array_vol_perf',
    'array_forecast',
    'audit_logs',
    'bucket_perf',
    'directory_perf',
    'file_system_cap',
    'filesystem_perf',
    'last_24_array',
    'subscription',
    'subscription_dashboard',
    'support_contract',
    'subscription_consolidated',
    'dpa',
    'security_assessment',
    'evergreen_one_resilience',
    'euc',
    'esg',
    'esg_executive',
    'asset_management',
    'array_eol',
    'array_eosc',
    'appliance_genealogy',
    'subscription_genealogy',
    'invoice',
    'quote_management',
    'volume_cap',
] as const;

export type ReportTemplateType = (typeof REPORT_TEMPLATE_TYPES)[number];

export type ReportTemplateFormat = 'pdf' | 'csv' | 'xlsx';

export type ReportDefaultEmail = { defaultBody: string; defaultSubject: string };

export function isValidReportTemplateType(inputType: string): boolean {
    return REPORT_TEMPLATE_TYPES.includes(inputType as ReportTemplateType);
}

export class ReportTemplate implements Resource {
    description: string;
    defaultEmail: ReportDefaultEmail;
    format: ReportTemplateFormat;
    id: string;
    maxTimeRange: number;
    name: string;
    type: ReportTemplateType;

    constructor(json: any) {
        this.description = json.description;
        this.defaultEmail = json.email;
        this.format = json.format;
        this.id = json.id;
        this.maxTimeRange = json.max_time_range;
        this.name = json.name;
        this.type = json.name_internal;
    }
}
