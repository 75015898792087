import { ChangeDetectorRef, Component, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { AuthorizationService } from '@pure/authz-authorizer';
import {
    AlohaArray,
    ArrayContractStatus,
    DataPage,
    DpaSafeMode,
    DpaSafeModeService,
    FeatureFlagDxpService,
    Mitigation,
    MitigationStatus,
    MitigationType,
    PurityOptimizationV2Service,
    SecurityPatchFb2025AService,
    UnifiedArrayService,
} from '@pure1/data';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { FeatureNames } from '../../model/FeatureNames';
import { UserNotification } from '../../model/user-notification';
import { SafemodeMultipartyAuthorizationService } from '../../safemode/services/safemode-multiparty-authorization.service';
import { UserNotificationService } from '../../services/user-notification.service';
import { WarningBannerNotification } from '../base-banner';
import { OpportunityCreationConfig } from '../opportunity-creation-banner/opportunity-creation-banner.component';

// See the following wiki for more info on adding a banner
// https://wiki.purestorage.com/pages/viewpage.action?pageId=121482791

// For use in the template
export const NPS_SURVEY_BANNER_ID = 'A000028';
export const SAFE_MODE_BANNER_ID = 'A000040';
export const CDU_BANNER_ID = 'A000061';
export const X_TO_XL_UPGRADE_BANNER_ID = 'A000071';
export const DPA_BANNER_ID = 'A000074';
export const DRR_BANNER_ID = 'A000080';
export const AUTO_ON_SM_BANNER_ID = 'A000079';
export const HW_EOL_BANNER_ID = 'A000081';
export const EOSC_BANNER_ID = 'A000117';
export const EOSC_AND_EOL_BANNER_ID = 'A000116';
export const SDFC_UPDATE_BANNER_ID = 'A000088';
export const ENHANCED_SAFEMODE_BANNER_ID = 'A000093';
export const SAFEMODE_ON_MOBILE_BANNER_ID = 'A000096';
export const FB_EOL_BANNER_ID = 'A000098';
export const FA_C_NON_FAN_IN_BANNER_ID = 'A000100';
export const FA_C_FAN_IN_BANNER_ID = 'A000101';
export const OPTIMIZE_CAPACITY_BANNER_ID = 'A000105';
export const IAM_BANNER_ID = 'A000106';
export const IAM_PROMOTION_BANNER_ID = 'A000109';
export const USER_RESEARCH_BANNER_ID = 'A000111';
export const PURE1_REPATRATION_BANNER_ID = 'A000114';

export const OPPORTUNITY_BANNER_CONFIGS: Map<string, OpportunityCreationConfig> = new Map([
    [FB_EOL_BANNER_ID, { badgeSvg: 'recommendation-carousel-badge.svg', controllerModel: 'FB//S' }],
    [
        FA_C_NON_FAN_IN_BANNER_ID,
        {
            badgeSvg: 'take-action-carousel-badge.svg',
            controllerModel: 'FA-C',
            comment: 'User request for Add FA//C for snapshots',
        },
    ],
    [
        FA_C_FAN_IN_BANNER_ID,
        {
            badgeSvg: 'take-action-carousel-badge.svg',
            controllerModel: 'FA-C',
            comment: 'User request for Add FA//C for fan-in',
        },
    ],
    [
        PURE1_REPATRATION_BANNER_ID,
        {
            badgeSvg: 'new-big-badge.svg',
            controllerModel: 'FA-FlashArray', // Weird hack since it's a "general" request
            comment: 'User request for #salesplay-repatriation',
        },
    ],
]);

@Component({
    selector: 'banner-carousel',
    templateUrl: 'banner-carousel.component.html',
})
export class BannerCarouselComponent implements OnInit {
    // Custom banner ID's, for use in the template
    readonly npsSurveyBannerId = NPS_SURVEY_BANNER_ID;
    readonly safeModeBannerId = SAFE_MODE_BANNER_ID;
    readonly hwEolBannerId = HW_EOL_BANNER_ID;
    readonly eoscBannerId = EOSC_BANNER_ID;
    readonly eoscAndEolBannerId = EOSC_AND_EOL_BANNER_ID;
    readonly cduBannerId = CDU_BANNER_ID;
    readonly dpaBannerId = DPA_BANNER_ID;
    readonly drrBannerId = DRR_BANNER_ID;
    readonly autoOnSMBannerId = AUTO_ON_SM_BANNER_ID;
    readonly xToXlUpgradeBannerId = X_TO_XL_UPGRADE_BANNER_ID;
    readonly sdfcUpdateBannerId = SDFC_UPDATE_BANNER_ID;
    readonly enhancedSafemodeBannerId = ENHANCED_SAFEMODE_BANNER_ID;
    readonly fbEolBannerId = FB_EOL_BANNER_ID;
    readonly faCNonFanInBannerId = FA_C_NON_FAN_IN_BANNER_ID;
    readonly faCFanInBannerId = FA_C_FAN_IN_BANNER_ID;
    readonly optimizeCapacityBannerId = OPTIMIZE_CAPACITY_BANNER_ID;
    readonly userResearchBannerId = USER_RESEARCH_BANNER_ID;
    readonly pure1RepatriationBannerId = PURE1_REPATRATION_BANNER_ID;

    readonly opportunityBannerConfigs = OPPORTUNITY_BANNER_CONFIGS;

    isEolOnlyWarningBannerVisible: boolean;
    isFBEolBannerVisible: boolean;
    isEoscOnlyBannerVisible: boolean;
    isEoscANDEolBannerVisible: boolean;
    warningBannerNotification: WarningBannerNotification = null;
    safemodeOrgNonCompliantBannerNotification: WarningBannerNotification = null;
    securityPatchFb2025ANotification: WarningBannerNotification = null;
    atomSecurityPatchNotification: WarningBannerNotification = null;

    notifications: UserNotification[] = [];
    // CLOUD-130151 - include both "standard" notifications and warning banner notifications, since those are not
    // included in the notifications array by default
    fullNotifications: (UserNotification | WarningBannerNotification)[] = [];

    isOrgSafeModeCompliant = true;

    @ViewChildren('bannerSlides') slides: QueryList<TemplateRef<any>>;

    constructor(
        private cdr: ChangeDetectorRef,
        private userNotificationService: UserNotificationService,
        private authorizationService: AuthorizationService,
        private featureFlagDxpService: FeatureFlagDxpService,
        private unifiedArrayService: UnifiedArrayService,
        private safemodeMultipartyAuthorizationService: SafemodeMultipartyAuthorizationService,
        private safeModeService: DpaSafeModeService,
        protected securityPatchFb2025AService: SecurityPatchFb2025AService,
        protected purityOptimizationService: PurityOptimizationV2Service,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.getSecurityPatchFb2025AWarningBanner(),
            this.getIsEOLWarningBannerVisible(),
            this.getIsEOSCBannerVisible(),
            this.getIsFBEOLBannerVisible(),
            this.getIsCapacityDownLicensingBannerVisible(),
            this.getAtomSecurityPatchesBanner(),
            this.getSafemodeOrgNonCompliantBanner(),
            this.userNotificationService.getAllNotifications(),
        ])
            .pipe(take(1))
            .subscribe(
                ([
                    securityPatchFb2025ANotification,
                    isEOLWarningBannerVisible,
                    isEOSCBannerVisible,
                    isFBEolBannerVisible,
                    isCapacityDownLicensingBannerVisible,
                    atomSecurityPatchNotification,
                    safemodeOrgNonCompliantNotification,
                    notifications,
                ]) => {
                    this.securityPatchFb2025ANotification = securityPatchFb2025ANotification;
                    this.isEolOnlyWarningBannerVisible = isEOLWarningBannerVisible && !isEOSCBannerVisible;
                    this.isEoscOnlyBannerVisible = isEOSCBannerVisible && !isEOLWarningBannerVisible;
                    this.isEoscANDEolBannerVisible = isEOSCBannerVisible && isEOLWarningBannerVisible;
                    this.isFBEolBannerVisible = isFBEolBannerVisible;
                    this.atomSecurityPatchNotification = atomSecurityPatchNotification;
                    this.safemodeOrgNonCompliantBannerNotification = safemodeOrgNonCompliantNotification;

                    // Get only notifications that haven't been acknowledged
                    this.notifications = notifications.filter(notification => !notification.read).slice();
                    // Don't show hw-eol notification if the banner is invisible to this user
                    this.notifications = this.notifications.filter(
                        notification => this.isEolOnlyWarningBannerVisible || notification.id !== HW_EOL_BANNER_ID,
                    );
                    // Don't show eosc notification if the banner is invisible to this user
                    this.notifications = this.notifications.filter(
                        notification => this.isEoscOnlyBannerVisible || notification.id !== EOSC_BANNER_ID,
                    );
                    // Don't show eosc and eol notification if the banner is invisible to this user
                    this.notifications = this.notifications.filter(
                        notification => this.isEoscANDEolBannerVisible || notification.id !== EOSC_AND_EOL_BANNER_ID,
                    );
                    // Don't show fb-eol notification if the banner is invisible to this user
                    this.notifications = this.notifications.filter(
                        notification => isFBEolBannerVisible || notification.id !== FB_EOL_BANNER_ID,
                    );
                    // show enhanced safemode banner only if the org is non-compliant and the warning banner is not visible
                    this.notifications = this.notifications.filter(
                        notification =>
                            notification.id !== ENHANCED_SAFEMODE_BANNER_ID ||
                            (!safemodeOrgNonCompliantNotification && !this.isOrgSafeModeCompliant),
                    );
                    // don't show the safemode on mobile banner on the appliance page at all
                    this.notifications = this.notifications.filter(
                        notification => notification.id !== SAFEMODE_ON_MOBILE_BANNER_ID,
                    );

                    // Don't show capacity-down-licensing banner if the banner is invisible to this user
                    this.notifications = this.notifications.filter(
                        notification =>
                            isCapacityDownLicensingBannerVisible || notification.id !== OPTIMIZE_CAPACITY_BANNER_ID,
                    );

                    // CLOUD-130151
                    // Make sure the warning notifications are included in the fullNotifications array
                    const warningNotifications: WarningBannerNotification[] = [];
                    if (this.securityPatchFb2025ANotification) {
                        warningNotifications.push(securityPatchFb2025ANotification);
                    }
                    if (this.atomSecurityPatchNotification) {
                        warningNotifications.push(this.atomSecurityPatchNotification);
                    }
                    if (this.safemodeOrgNonCompliantBannerNotification) {
                        warningNotifications.push(this.safemodeOrgNonCompliantBannerNotification);
                    }

                    // show promote IAM banner only if the IAM banner is not visible
                    const iamBanner = this.notifications.find(notification => notification.id === IAM_BANNER_ID);
                    if (iamBanner) {
                        this.notifications = this.notifications.filter(
                            notification => notification.id !== IAM_PROMOTION_BANNER_ID,
                        );
                    }
                    this.fullNotifications = [...warningNotifications, ...this.notifications];

                    // `@ViewChildren(..)` is only populated after view init. This also means it's after all change detection of this and its children.
                    // So, triggering the change detection again.
                    this.cdr.detectChanges();
                },
            );
    }

    getIsEOLWarningBannerVisible(): Observable<boolean> {
        return this.unifiedArrayService.list({ fields: ['has_end_of_life_hardware'] }).pipe(
            take(1),
            map(data => data.response.some(array => array.has_end_of_life_hardware)),
        );
    }

    getIsFBEOLBannerVisible(): Observable<boolean> {
        return this.featureFlagDxpService.getFeatureFlag(FeatureNames.FB_EOL).pipe(
            switchMap(feature => {
                if (!feature?.enabled) {
                    return of(false);
                } else {
                    return this.unifiedArrayService
                        .list({
                            filter: { model: `model='FlashBlade'` },
                            fields: ['product', 'model'],
                        })
                        .pipe(map(data => data.response.some(array => array.flashBladeNorway)));
                }
            }),
            take(1),
        );
    }

    getIsEOSCBannerVisible(): Observable<boolean> {
        return this.featureFlagDxpService.getFeatureFlag(FeatureNames.EOC).pipe(
            switchMap(feature => {
                if (!feature?.enabled) {
                    return of(false);
                } else {
                    return this.unifiedArrayService
                        .list({
                            supportStatusFilterOption: 'include_arrays_in_courtesy_period',
                            fields: ['contract_status'],
                        })
                        .pipe(
                            map(data =>
                                data.response.some(array => array.contract_status === ArrayContractStatus.EXPIRED),
                            ),
                        );
                }
            }),
            take(1),
        );
    }

    getIsCapacityDownLicensingBannerVisible(): Observable<boolean> {
        return this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.CAPACITY_DOWN_LICENSING_PLANNER)
            .pipe(map(feature => feature?.enabled === true));
    }

    getSecurityPatchFb2025AWarningBanner(): Observable<WarningBannerNotification> {
        return this.authorizationService.isAllowed('PURE1:read:security_patching').pipe(
            take(1),
            switchMap(isAllowed => {
                if (!isAllowed) {
                    return of<WarningBannerNotification>(null);
                }

                return this.featureFlagDxpService.getFeatureFlag(FeatureNames.SECURITY_PATCH_FB_2025_A).pipe(
                    switchMap(feature => {
                        if (feature?.enabled) {
                            return this.securityPatchFb2025AService.list();
                        } else {
                            return of(null);
                        }
                    }),
                    take(1),
                    map((alohaData: DataPage<AlohaArray>) => {
                        if (
                            alohaData &&
                            alohaData.total > 0 &&
                            alohaData.response.some((array: AlohaArray) => array.needsPatch && !array.isOptedIn)
                        ) {
                            return {
                                body: 'Pure Storage has identified that a vulnerability patch is required on one or more of your Pure Storage FlashBlade Systems.  Please click "Get Started" for step-by-step instructions to apply the patch.',
                                url: '/administration/security-patch-fb-2025-a',
                                color: 'warning',
                            };
                        }
                        return null;
                    }),
                    catchError(err => {
                        console.error('getSecurityPatchFb2025AWarningBanner() failed', err);
                        return of<WarningBannerNotification>(null);
                    }),
                );
            }),
        );
    }

    getAtomSecurityPatchesBanner(): Observable<WarningBannerNotification> {
        return this.authorizationService.isAllowed('PURE1:read:purity_optimization').pipe(
            take(1),
            switchMap(isAllowed => {
                if (!isAllowed) {
                    return of<WarningBannerNotification>(null);
                }

                return this.purityOptimizationService.list(null, { showCompleted: false, criticalOnly: true }).pipe(
                    take(1),
                    map((optimizations: DataPage<Mitigation>) => {
                        if (optimizations?.total > 0) {
                            const atomSecurityPatchOptimizations = optimizations.response.filter(
                                optimization =>
                                    optimization.type === MitigationType.SECURITY_PATCH &&
                                    optimization.optimizationStatus === MitigationStatus.AVAILABLE,
                            ) as Mitigation[];
                            if (atomSecurityPatchOptimizations.length > 0) {
                                const kbUrl = atomSecurityPatchOptimizations[0].optimizationKbLink;
                                let bannerBodyText = `Security patches are available for your appliances.`;
                                if (kbUrl) {
                                    bannerBodyText += ` <a target="_blank" href="${kbUrl}">Learn More</a>`;
                                }
                                return {
                                    title: 'Security Patches Available',
                                    body: bannerBodyText,
                                    url: '/dashboard/software-lifecycle/purity-optimizations',
                                    buttonLabel: 'Opt-In',
                                    icon: 'summary-bar-security-patches.svg',
                                };
                            }
                        }
                        return null;
                    }),
                    catchError(err => {
                        console.error('getAtomSecurityPatchesBanner() failed', err);
                        return of<WarningBannerNotification>(null);
                    }),
                );
            }),
        );
    }

    getSafemodeOrgNonCompliantBanner(): Observable<WarningBannerNotification> {
        return this.featureFlagDxpService.getFeatureFlag(FeatureNames.SAFE_MODE_MULTIPARTY_AUTH).pipe(
            switchMap(feature => {
                if (!feature?.enabled) {
                    return of(null);
                } else {
                    return forkJoin([
                        this.safemodeMultipartyAuthorizationService.isOrgCompliant(),
                        this.safeModeService.list().pipe(
                            catchError(error => {
                                const errorMsg = error && (error.data?.message || error.statusText);
                                console.error('Error updating safemode: ' + errorMsg);
                                return of<DataPage<DpaSafeMode>>(null);
                            }),
                        ),
                    ]).pipe(
                        take(1),
                        tap(
                            ([isOrgCompliant, appliances]) =>
                                (this.isOrgSafeModeCompliant = isOrgCompliant.hasEnoughApprovers),
                        ),
                        map(([isOrgCompliant, appliances]) => {
                            if (!this.isOrgSafeModeCompliant && this.isSafemodeUsedInOrg(appliances?.response || [])) {
                                return {
                                    body:
                                        '<h3><strong>Your Organization is Non-Compliant</strong></h3>' +
                                        '<strong>Your organization is non-compliant.</strong> You should have a minimum of two SafeMode approvers with ' +
                                        'the step-up authentication enabled for the organization to be in compliant mode.' +
                                        '   <a target="_blank" href="https://support.purestorage.com/Pure1/Pure1_Manage/006a_Pure1_Manage_-_Fleet/Pure_1_Manage_-_SafeMode">Documentation</a>',
                                    url: '/administration/users',
                                    hideAction: true,
                                    color: 'warning',
                                };
                            } else {
                                return null;
                            }
                        }),
                    );
                }
            }),
            take(1),
        );
    }

    onDismissNotification(notificationToDismiss: UserNotification): void {
        this.removeNotification(notificationToDismiss);

        this.userNotificationService.ackNotification(notificationToDismiss.id).subscribe(
            () => {},
            err => console.error(err),
        );
    }

    onDismissNps(notificationToDismiss: UserNotification, surveyId: string): void {
        this.removeNotification(notificationToDismiss);

        this.userNotificationService.ackNotification(notificationToDismiss.id, surveyId).subscribe(
            () => {},
            err => console.error(err),
        );
    }

    private removeNotification(notificationToDismiss: UserNotification): void {
        const indexToDismiss = this.notifications.findIndex(
            notification => notification.id === notificationToDismiss.id,
        );
        this.notifications.splice(indexToDismiss, 1);
    }

    private isSafemodeUsedInOrg(appliances: DpaSafeMode[]): boolean {
        return appliances.some(array => {
            return (
                array.blockSafeModeStatus === 'ENABLED' ||
                array.blockSafeModeStatus === 'GRANULAR_ENABLED' ||
                array.fileSafeModeStatus === 'ENABLED' ||
                array.objectSafeModeStatus === 'ENABLED'
            );
        });
    }
}
