import { Pipe, PipeTransform } from '@angular/core';
import { ServiceCatalogOffering } from '@pure1/data';
import { OfferingOrdersCount } from '../../service-catalog/services/service-catalog-offerings.service';

// gets quote orders using compoundedName from a ServiceCatalogOffering
@Pipe({
    name: 'getQuoteOrdersByCompoundName',
    pure: false,
})
export class GetQuoteOrdersByCompoundNamePipe implements PipeTransform {
    transform(offering: ServiceCatalogOffering, ordersCountByCompoundName: Map<string, OfferingOrdersCount>): number {
        if (offering.groupName === '//Flex') {
            // Because the product name for Flex orders is shortened from 'FlashArray' or 'FlashBlade' to 'FA' or 'FB' (see: orders page),
            //     we need to shorten the default product name to calculate the correct compounded name
            // Therefore, the format should look like: 'Evergreen//FlexFAEvergreen//Flex'
            const compoundName =
                offering.serviceName + (offering.productName === 'FlashArray' ? 'FA' : 'FB') + offering.programType;
            return ordersCountByCompoundName?.get(compoundName)?.quoteOrders;
        }
        return ordersCountByCompoundName?.get(offering.compoundedName)?.quoteOrders;
    }
}
