import moment from 'moment';
import { Resource } from '../interfaces/resource';

export class PeakLicense implements Resource {
    id: string;
    name: string;
    subscriptionId: string;
    orgId: number;
    operationalCompanyId: string;
    licenseType: string;
    salesforceId: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    lastUpdated: moment.Moment;
    userCount: number;
    renewalRequested: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.subscriptionId = json.subscription_id;
        this.orgId = json.org_id;
        this.operationalCompanyId = json.operational_company_id;
        this.licenseType = json.license_type;
        this.salesforceId = json.salesforce_id;
        this.startDate = json.start_date ? moment.utc(json.start_date) : null;
        this.endDate = json.end_date ? moment.utc(json.end_date) : null;
        this.lastUpdated = json.last_updated ? moment.utc(json.last_updated) : null;
        this.userCount = json.user_count;
        this.renewalRequested = json.renewal_requested;
    }
}
