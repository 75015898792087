import { Injectable } from '@angular/core';

import { DisasterRecoveryPlan, DisasterRecoveryPlanExtended, FilterParams } from '@pure1/data';
import { DraasApiRecoveryPlanExtended } from '@pure/paas-api-gateway-client-ts';
import { DraasApiConfig } from './disaster-recovery-constants';
import { DisasterRecoveryBaseService } from './disaster-recovery-base.service';
import { DisasterRecoveryThrottlingHttpClient } from './disaster-recovery-throttling-http-client.service';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryPlansExtendedService extends DisasterRecoveryBaseService<
    DisasterRecoveryPlanExtended,
    DraasApiRecoveryPlanExtended
> {
    protected resourceClass = DisasterRecoveryPlanExtended;
    protected pathParams = ['clusterId'];

    constructor(protected http: DisasterRecoveryThrottlingHttpClient) {
        super();
    }

    protected getEndpoint(filter: FilterParams<DisasterRecoveryPlan>): string {
        return `${DraasApiConfig.getUrlPrefix()}/api/2.0/clusters/${filter.clusterId}/recovery-plans/extended`;
    }
}
