<div
    class="card support-case"
    [ngClass]="sizeClass"
    (click)="markAsRead()"
    *ngIf="caseDetails"
    [class.case-attachment-dragover]="dragoverActive"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    [pureshadowscroll]="{ scrollElem: '.case-feed-items-wrapper', shadowElem: '.case-details' }"
>
    <div class="card-header">
        <div *ngIf="!hasLimit && hasSNOWContact" class="case-controls">
            <span
                ngbTooltip="{{
                    caseDetails.isClosed
                        ? 'Closed cases cannot be edited'
                        : caseDetails.isUpgrade
                          ? 'Purity Upgrade cases cannot be edited'
                          : 'Edit'
                }}"
                placement="left"
                tooltipClass="tooltip-left"
                class="float-xs-right open-edit-case"
                [ngStyle]="caseDetails.isUpgrade || caseDetails.isClosed ? { cursor: 'not-allowed' } : {}"
            >
                <button
                    class="btn btn-secondary with-action-icon"
                    [disabled]="caseDetails.isUpgrade || caseDetails.isClosed"
                    (click)="openEditCaseModal(createEditSupportCaseModal)"
                    [ngStyle]="caseDetails.isUpgrade || caseDetails.isClosed ? { 'pointer-events': 'none' } : {}"
                >
                    <pureui-svg class="pstg-action-icon" svg="edit.svg" height="16"></pureui-svg>
                </button>
            </span>
            <escalate-button
                *ngIf="!caseDetails.isUpgrade && (!caseDetails.isClosed || caseDetails.isReopenable)"
                [user]="currentUser"
                [supportCase]="caseDetails"
                (onEscalationCreated)="escalationCreated($event)"
                class="support-escalate-button float-xs-right"
            ></escalate-button>
        </div>

        <!-- TODO: Change the [attr.href] to uiSref / uiParams (or now, routerLink). https://jira.purestorage.com/browse/CLOUD-64269 -->
        <a *ngIf="!standalone" [attr.href]="[caseDetails.url]" class="case-name">
            {{ caseDetails.subject }}
        </a>
        <span *ngIf="standalone" class="case-name">{{ caseDetails.subject }}</span>
    </div>

    <div class="case-attachment-drop-area"></div>

    <div class="card-block case-details container-fluid">
        <div class="row flex-items-xs-center">
            <div class="case-array col-xs-3 text-xs-center">
                <div class="case-header-title">Appliance</div>
                <strong
                    *ngIf="caseDetails.productLine === 'Portworx'; else applianceWithTooltip"
                    placement="top"
                    tooltipClass="tooltip-top"
                    container="body"
                >
                    {{ caseDetails.arrayId }}
                </strong>
                <ng-template #applianceWithTooltip>
                    <strong
                        [ngbTooltip]="getFQDNFromId(caseDetails.arrayId, caseDetails.array?.hostname)"
                        placement="top"
                        tooltipClass="tooltip-top"
                        container="body"
                    >
                        {{ caseDetails.array?.hostname ? caseDetails.array.hostname : '-' }}
                    </strong>
                </ng-template>
            </div>
            <div class="case-severity col-xs-3 text-xs-center">
                <div class="case-header-title">Case Severity</div>
                <strong>{{ caseDetails.severity }}</strong>
            </div>
            <div class="case-cadence col-xs-3 text-xs-center">
                <div class="case-header-title">Update Frequency</div>
                <strong>{{ caseDetails.cadence || '-' }}</strong>
            </div>
            <div class="case-status col-xs-3 col-xl-3 text-xs-center">
                <div class="case-header-title">Status</div>
                <strong>{{ caseDetails.status }}</strong>
            </div>
        </div>
    </div>

    <div class="case-feed-items-wrapper" #caseFeedItemsWrapper (scroll)="scrollHandler()">
        <div id="case-feed-items-{{ caseId }}" class="list-group list-group-flush case-feed-items">
            <div
                *ngIf="!hasLimit"
                class="list-group-item case-feed-item case-feed-description-item"
                id="case-feed-description-item-{{ caseId }}"
            >
                <pure-avatar [height]="25" [user]="supportUser" class="item-avatar"></pure-avatar>
                <div class="item-body">
                    <div class="item-header">
                        <span>Details</span>
                    </div>
                    <div class="item-content">
                        <div>
                            <span class="item-subtitle">Case Number:</span>
                            <span>{{ caseDetails.caseNumber }}</span>
                        </div>
                        <div>
                            <span class="item-subtitle">Created:</span>
                            <span>{{ caseDetails.createdDate | moment2date | date: 'YY/MM/dd h:mm a' }}</span>
                        </div>
                        <div *ngIf="caseDetails.maintenanceExecutionDateLocal">
                            <span class="item-subtitle">Maintenance execution date:</span>
                            <span>{{ caseDetails.maintenanceExecutionDateLocal }}</span>
                        </div>
                        <div>
                            <span class="item-subtitle">Primary contact:</span>
                            <span>{{ caseDetails.contact?.name }}</span>
                        </div>
                        <div *ngIf="caseDetails.alternateContact">
                            <span class="item-subtitle">Secondary contact:</span>
                            <span>{{ caseDetails.alternateContact.name }}</span>
                        </div>
                        <div>
                            <span class="item-subtitle">Description:</span>
                            <span
                                class="description-content item-content-multiline"
                                [innerHTML]="caseDetails.description"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="list-group-item case-feed-item"
                id="case-feed-item-{{ item.id }}"
                [ngClass]="[
                    item.itemType,
                    item.tmp ? ' item-tmp' : '',
                    getItemCollapsedState(item.id) ? ' collapsed' : '',
                ]"
                *ngFor="let item of items; trackBy: trackById"
                [ngSwitch]="item.itemType"
            >
                <ng-template #unknownUser>
                    <span class="author">Unknown User</span>
                </ng-template>
                <div *ngSwitchCase="'comment'">
                    <pure-support-avatar
                        [item]="item"
                        class="item-avatar"
                        [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                        (click)="toggleExpandCollapse(item.id)"
                    ></pure-support-avatar>
                    <div class="item-body">
                        <div
                            class="item-header"
                            [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                            (click)="toggleExpandCollapse(item.id)"
                        >
                            <span class="author">{{ getItemUserNameForCommentOrAttachment($any(item)) }}</span>
                            <span class="time">{{
                                item.tmp
                                    ? $any(item).tmpMessage
                                    : (item.createdDate | moment2date | date: 'YY/MM/dd h:mm a')
                            }}</span>
                        </div>
                        <div class="item-content">
                            <p class="content item-content-multiline" [innerHTML]="$any(item).body"></p>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="'attachment'">
                    <pure-support-avatar
                        [item]="item"
                        class="item-avatar"
                        [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                        (click)="toggleExpandCollapse(item.id)"
                    ></pure-support-avatar>
                    <div class="item-body">
                        <div
                            class="item-header"
                            [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                            (click)="toggleExpandCollapse(item.id)"
                        >
                            <span class="author">{{ getItemUserNameForCommentOrAttachment($any(item)) }}</span>
                            <span class="time">{{
                                item.tmp
                                    ? $any(item).tmpMessage
                                    : (item.createdDate | moment2date | date: 'YY/MM/dd h:mm a')
                            }}</span>
                        </div>
                        <div class="item-content">
                            <div
                                [ngClass]="{ 'btn-link attachment-link': !item.tmp }"
                                (click)="!item.tmp && downloadFile(caseDetails.id, $any(item))"
                            >
                                <pureui-spinner [pureuiShow]="downloadingAttachment[item.id]"></pureui-spinner>
                                {{ $any(item).name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="'email'">
                    <pure-support-avatar
                        [item]="item"
                        class="item-avatar"
                        [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                        (click)="toggleExpandCollapse(item.id)"
                    ></pure-support-avatar>
                    <div class="item-body">
                        <div
                            class="item-header"
                            [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                            (click)="toggleExpandCollapse(item.id)"
                        >
                            <span ng-if="item.from" class="author"
                                >From: {{ getEmail($any(item).from) | emailFrom }}</span
                            >
                            <span class="time">{{ item.createdDate | moment2date | date: 'YY/MM/dd h:mm a' }}</span>
                            <div
                                class="to-address"
                                [ngClass]="{ 'hide-when-collapsed': getItemCollapsedState(item.id) }"
                                >To: &lt;{{ $any(item).to.join(', ') }}&gt;</div
                            >
                            <div
                                class="cc-address"
                                [ngClass]="{ 'hide-when-collapsed': getItemCollapsedState(item.id) }"
                                *ngIf="$any(item).cc"
                                >Cc: &lt;{{ $any(item).cc.join(', ') }}&gt;</div
                            >
                        </div>
                        <div *ngIf="$any(item).htmlBody" class="item-content" [innerHTML]="$any(item).htmlBody"></div>
                        <div *ngIf="!$any(item).htmlBody" class="item-content text-body">{{ $any(item).body }}</div>
                    </div>
                </div>
                <div *ngSwitchCase="'escalation'">
                    <pure-support-avatar
                        [item]="item"
                        class="item-avatar"
                        [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                        (click)="toggleExpandCollapse(item.id)"
                    ></pure-support-avatar>
                    <div class="item-body">
                        <div
                            class="item-header"
                            [ngClass]="{ expandable: getIfItemNeedCollapsing(item.id) }"
                            (click)="toggleExpandCollapse(item.id)"
                        >
                            <span ng-if="item.contactName" class="author">{{ $any(item).contactName }}</span>
                            <span class="time">{{ item.createdDate | moment2date | date: 'YY/MM/dd h:mm a' }}</span>
                        </div>
                        <div class="item-content">
                            <div class="content">
                                <span class="item-subtitle">Customer escalation request</span><br />
                                <span class="item-subtitle">Phone:</span> {{ $any(item).contactPhone }}<br />
                                <span class="item-subtitle">Reason:</span> {{ $any(item).reason | escalationReason
                                }}<br />
                                <span *ngIf="$any(item).comment && $any(item).comment.length > 0"
                                    ><span class="item-subtitle">Comment:</span>
                                    <span>{{ $any(item).comment }}</span></span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="fadeout-gradient"
                    *ngIf="getIfItemNeedCollapsing(item.id) && getItemCollapsedState(item.id)"
                    (click)="toggleExpandCollapse(item.id)"
                >
                    <div class="fadeout-gradient-ellipsis">...</div>
                </div>
                <div
                    class="back-to-top-button-container"
                    *ngIf="showBackToTopButtonForItemId === item.id"
                    [ngClass]="{ 'scroll-with-item': bottomOfItemWithBackToTopButtonShown }"
                >
                    <button class="back-to-top-button" (click)="scrollToItem(item.id)">
                        <pureui-svg class="pstg-action-icon" svg="chevron-up.svg" height="15"></pureui-svg>
                    </button>
                    <div class="back-to-top-bottom" *ngIf="!bottomOfItemWithBackToTopButtonShown"></div>
                </div>
            </div>
            <div
                *ngIf="caseDetails.isClosed && caseDetails.resolutionSummary"
                class="case-feed-item list-group-item"
                id="case-feed-resolution-item-{{ caseId }}"
            >
                <div class="item-avatar">
                    <svg viewBox="0 0 49.7 49.7" class="result-icon-success">
                        <circle class="result-icon-success-circle" cx="24.9" cy="24.9" r="24.9" />
                        <path
                            class="result-icon-success-mark"
                            d="M36.9,17.3L22.8,35.7c-0.5,0.6-1.1,0.9-1.9,1h0h-0.1c-0.7,0-1.4-0.3-1.9-0.9l-6-7.1c-0.4-0.5-0.6-1.1-0.6-1.8 s0.4-1.2,0.9-1.7c1-0.8,2.6-0.7,3.4,0.3l4.1,4.8l12.3-16c0.8-1,2.4-1.2,3.4-0.5c0.5,0.4,0.8,1,0.9,1.6 C37.5,16.1,37.3,16.7,36.9,17.3z"
                        />
                    </svg>
                </div>
                <div class="item-body">
                    <div class="item-header">
                        <span class="author">Resolution Summary</span>
                        <span class="time">{{ caseDetails.closedDate }}</span>
                    </div>
                    <div class="item-content">
                        <p class="content item-content-multiline" [innerText]="caseDetails.resolutionSummary"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-footer case-comment" *ngIf="(!caseDetails.isClosed || caseDetails.isReopenable) && hasSNOWContact">
        <div class="comment-text-container">
            <!-- Stop (paste) event propagation here so that the file upload doesn't interfere and result in nothing pasted, caused issues in FireFox. -->
            <textarea
                class="form-control comment-text"
                [ngClass]="{ 'comment-text-sm': hasLimit && collapseControls }"
                placeholder="{{ caseDetails.isClosed ? 'Explain reason to reopen this case' : 'Add a comment' }}"
                [(ngModel)]="commentText"
                (focus)="collapseControls = false; markAsRead()"
                (ngModelChange)="commentTextChange($event)"
                (paste)="stopPasteTextEvent($event)"
                [disabled]="caseDetails.isClosed && submittingComment"
            >
            </textarea>
        </div>
        <div class="comment-controls btn-toolbar" [ngbCollapse]="hasLimit && collapseControls">
            <input type="file" multiple="multiple" #inputFile (change)="uploadFile($any($event.target).files)" />
            <button
                [disabled]="
                    commentText.trim().length === 0 || commentTextUtf8Length > maxCommentLength || submittingComment
                "
                pure-read-only
                (click)="caseDetails.isClosed ? reopen() : submitComment()"
                class="btn btn-primary support-spinner-button support-comment-button float-xs-left"
            >
                <pureui-spinner [pureuiShow]="submittingComment" class="support-button-spinner"></pureui-spinner>
                {{ caseDetails.isClosed ? 'Reopen' : 'Comment' }}
            </button>
            <button
                class="btn btn-secondary with-action-icon file-upload-control float-xs-left"
                *ngIf="!caseDetails.isClosed"
                (click)="markAsRead(); inputFile.click(); ampli.supportCaseAddAttachmentClicked()"
            >
                <pureui-spinner [pureuiShow]="submittingAttachment"></pureui-spinner>
                <i class="fa fa-paperclip fa-lg" [hidden]="submittingAttachment"></i>
            </button>
        </div>
    </div>
    <div class="case-comment do-not-reopen" *ngIf="caseDetails.isClosed && !caseDetails.isReopenable && hasSNOWContact">
        This support case is too old to be reopened. Please open a new case.
    </div>
    <div class="case-comment do-not-reopen" *ngIf="!hasSNOWContact">
        You have read-only access. First time access to the Pure1 Manage Support Cases page requires a verification
        step. This process requires a minimum of 45 minutes to resolve. You can retry accessing the page again later. If
        this is not your first time, work with your Administrator to validate your access.
    </div>
</div>

<ng-template #createEditSupportCaseModal let-modal>
    <create-edit-support-case [activeModal]="modal" [caseId]="caseId"> </create-edit-support-case>
</ng-template>
