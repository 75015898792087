import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { smartTimer } from '@pstg/smart-timer';
import {
    ArrayTagService,
    CachedCurrentUserService,
    DataPage,
    DpaSafeMode,
    DpaSafeModeService,
    FeatureFlagDxpService,
    FilterParams,
    GFBEntity,
    Incident,
    IncidentService,
    SortParams,
    SubscriptionAsset,
    SubscriptionAssetService,
    Tag,
} from '@pure1/data';
import { ampli } from 'core/src/ampli';
import moment from 'moment';
import { Action } from 'redux';
import { combineLatest, EMPTY, iif, Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExportOptionIds } from '../../export/export-button/export-button.component';
import { PagedDataComponent2 } from '../../gui/paged-data2.component';
import { FeatureNames } from '../../model/FeatureNames';
import { ImmutableTimeRange } from '../../model/ImmutableTimeRange';
import {
    addFilter,
    addSelection,
    ASSET_APPLIANCE_ENTITY,
    ASSET_LICENSE_ENTITY,
    ASSET_SUBSCRIPTION_ENTITY,
    DEFAULT_NAMESPACE,
    GLOBAL_ENTITY,
    LOCAL_BAR_ASSET_MANAGEMENT,
    LOCAL_BAR_ASSET_MANAGEMENT_HIDDEN,
    NAME_KEY,
    removeAllSelection,
    removeFiltersByKey,
    SUBSCRIPTION_ASSET_ENTITY,
    SUBSCRIPTION_END_DATE,
    SUBSCRIPTION_START_DATE,
    TIME_END_KEY,
    TIME_START_KEY,
} from '../../redux/actions';
import { NgRedux } from '../../redux/ng-redux.service';
import { IState } from '../../redux/pure-redux.service';
import { UrlReaderWriter } from '../../redux/url-reader-writer';
import { UrlService } from '../../redux/url.service';
import { createFilter } from '../../redux/utils';
import { TableColumn } from '../../subscription/subscription-page-table/subscription-page-table.component';
import { SelectableColumnGroup } from '../../subscription/subscription/subscription-column-selector/subscription-column-selector.component';
import { ManageTagsModalComponent } from '../../tags/manage-tags/manage-tags-modal.component';
import { MultiResourceTag, MultiResourceTagBuilder } from '../../tags/multi-resource-tag';
import { CustomTimeRange } from '../../ui/components/calendar-time-range-select/calendar-time-range-select.component';
import { DpaSafeModeUtils, SAFEMODE_REFRESH_INTERVAL } from '../../utils/dpa-safe-mode-utils';
import { AssetManagementService } from '../services/asset-management.service';
import { AuthorizationService } from '@pure/authz-authorizer';

const DEFAULT_SORT_KEY = 'subscription.initial_subscription_name';

@Component({
    selector: 'asset-management-view',
    templateUrl: 'asset-management-view.component.html',
    providers: [AssetManagementService],
})
export class AssetManagementViewComponent extends PagedDataComponent2<SubscriptionAsset> implements OnInit, OnDestroy {
    // Gfb related fields
    readonly barId = LOCAL_BAR_ASSET_MANAGEMENT;
    readonly hiddenBarId = LOCAL_BAR_ASSET_MANAGEMENT_HIDDEN; // Store time range filters
    readonly DEFAULT_KEY = NAME_KEY;
    readonly DEFAULT_ENTITY = ASSET_APPLIANCE_ENTITY;
    readonly GFB_ENTITIES: GFBEntity[] = [
        ASSET_SUBSCRIPTION_ENTITY,
        ASSET_LICENSE_ENTITY,
        ASSET_APPLIANCE_ENTITY,
        GLOBAL_ENTITY,
    ];
    readonly listViewExportOptions: IExportButtonOption[] = [
        { id: ExportOptionIds.filtered, text: 'Export filtered assets', count: null },
        { id: ExportOptionIds.all, text: 'Export all assets', count: null },
    ];
    readonly ampli = ampli;

    @ViewChild('assetExportModal', { static: true }) readonly assetExportModal: TemplateRef<any>;
    @ViewChild('columnSelector') readonly columnSelector: TemplateRef<any>;

    loading = false;
    lastMetricRefresh: moment.Moment = null;
    summaryLoading = true;

    assets: SubscriptionAsset[];
    filteredAssets: SubscriptionAsset[];

    columnOptionGroups: SelectableColumnGroup<SubscriptionAsset>;
    columns: TableColumn<SubscriptionAsset>[];

    // Total number of assets, filtered and unfiltered
    totalFiltered: number = null;
    totalUnfiltered: number = null;
    assetFilter: string;
    selectedExportOption: IExportButtonOption;
    pageSize = 25;

    //Calendar picker
    extremeTimeRange: ImmutableTimeRange = new ImmutableTimeRange(
        moment.utc('2010-01-01'),
        moment.utc().add('3', 'year'),
    );
    selectedStartTimeRangeOption: CustomTimeRange;
    selectedRenewalTimeRangeOption: CustomTimeRange;

    // Tag related fields
    selectedAssets: SubscriptionAsset[] = [];
    resourceIdToTagMap = new Map<string, Tag[]>();
    editableTagOrganizationId: number;

    // Sorting
    defaultSortKey = DEFAULT_SORT_KEY;
    defaultSort: SortParams = {
        key: DEFAULT_SORT_KEY,
        order: 'asc',
    };

    // Top summary feature flag
    topSummaryFlag$: Observable<boolean>;
    topSummaryFlag = FeatureNames.ASSET_MANAGEMENT_TOP_SUMMARY;

    // Safemode Feature flag
    safeModeFeatureFlag$: Observable<boolean>;

    // Safemode
    safeModeData: DpaSafeMode[];

    //recommendation related fields
    incidentMap: Map<string, Incident>;

    // Tagging enablement
    taggingEnabled$: Observable<boolean>;

    // Redux related
    private readonly subscriptionStartDateStartKey = `${SUBSCRIPTION_START_DATE}.${TIME_START_KEY}`;
    private readonly subscriptionStartDateEndKey = `${SUBSCRIPTION_START_DATE}.${TIME_END_KEY}`;
    private readonly subscriptionRenewalDateStartKey = `${SUBSCRIPTION_END_DATE}.${TIME_START_KEY}`;
    private readonly subscriptionRenewalDateEndKey = `${SUBSCRIPTION_END_DATE}.${TIME_END_KEY}`;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private arrayTagService: ArrayTagService,
        public assetManagementService: AssetManagementService,
        private cachedCurrentUserService: CachedCurrentUserService,
        private dpaSafeModeService: DpaSafeModeService,
        private featureFlagDxpService: FeatureFlagDxpService,
        private authzService: AuthorizationService,
        protected subscriptionAssetService: SubscriptionAssetService,
        private ngbModal: NgbModal,
        private modalService: NgbModal,
        private incidentService: IncidentService,
        protected ngRedux: NgRedux<IState>,
        url: UrlService,
    ) {
        super(
            subscriptionAssetService,
            ngRedux,
            url,
            new AssetManagementUrlReaderWriter(),
            LOCAL_BAR_ASSET_MANAGEMENT,
            SUBSCRIPTION_ASSET_ENTITY,
            DEFAULT_NAMESPACE,
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        const currentUser$ = this.cachedCurrentUserService.get().pipe(take(1));

        const tags$ = this.assetManagementService.getUpdateTags().pipe(
            switchMap(() => {
                return this.arrayTagService.list({ filter: {} }).pipe(
                    map(result => result.response),
                    take(1),
                );
            }),
            takeUntil(this.destroy$),
        );

        combineLatest([currentUser$, tags$]).subscribe(([cu, tagsData]) => {
            this.editableTagOrganizationId = cu.organization_id;

            // Store tags
            this.resourceIdToTagMap.clear();
            tagsData.forEach(tag => {
                if (!this.resourceIdToTagMap.has(tag.resource.id)) {
                    this.resourceIdToTagMap.set(tag.resource.id, []);
                }
                this.resourceIdToTagMap.get(tag.resource.id).push(new Tag(tag));
            });
            this.resourceIdToTagMap.forEach(tags => tags.sort((a: Tag, b: Tag) => this.tagCompare(a, b)));
        });

        this.topSummaryFlag$ = this.featureFlagDxpService
            .getFeatureFlag(FeatureNames.ASSET_MANAGEMENT_TOP_SUMMARY)
            .pipe(
                take(1),
                map(feature => feature?.enabled === true),
            );

        this.safeModeFeatureFlag$ = this.featureFlagDxpService.getFeatureFlag(FeatureNames.RANSOMWARE_SAFEMODE).pipe(
            take(1),
            map(feature => feature?.enabled === true),
        );

        this.taggingEnabled$ = combineLatest([
            this.authzService.isAllowed('PURE1:write:tags'),
            this.authzService.isAllowed('PURE1:write:operator_tags'),
            this.featureFlagDxpService.getFeatureFlag(FeatureNames.ASSET_MANAGEMENT_TAGGING),
            this.featureFlagDxpService.getFeatureFlag(FeatureNames.REMOVE_VIEWS),
        ]).pipe(
            map(
                ([writeTags, writeOperatorTags, assetManagementTaggingFlag, removeViewsFlag]) =>
                    (writeTags || (writeOperatorTags && removeViewsFlag?.enabled === true)) &&
                    assetManagementTaggingFlag?.enabled === true,
            ),
        );

        /*
         * We assume that we have full SafeMode information from the beginning.
         * No filters are needed, we need this data only for enriching the already pre-filtered data
         * and therefore we do not need to refresh when filters / sorting is updated
         *
         * Don't bother making the backend call if the feature flag is off
         */
        this.safeModeFeatureFlag$
            .pipe(
                exhaustMap(flag =>
                    iif(
                        () => flag,
                        smartTimer(0, SAFEMODE_REFRESH_INTERVAL).pipe(
                            exhaustMap(() =>
                                this.dpaSafeModeService.list().pipe(
                                    take(1),
                                    catchError(error => {
                                        const errorMsg = error && (error.data?.message || error.statusText);
                                        console.error('Error updating safemode: ' + errorMsg);
                                        return of<DataPage<DpaSafeMode>>(null);
                                    }),
                                ),
                            ),
                        ),
                        EMPTY,
                    ),
                ),
            )
            .subscribe(result => {
                if (result?.response) {
                    this.safeModeData = result.response;
                    this.updateData();
                } else {
                    this.safeModeData = [];
                }
            });

        this.assetManagementService
            .getOpenTagsModal()
            .pipe(takeUntil(this.destroy$))
            .subscribe(selectedAsset => {
                this.openTagsModal(selectedAsset ? [selectedAsset] : this.selectedAssets);
            });

        this.assetManagementService
            .getUpdateAssets()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.updateData();
            });

        this.subscriptionAssetService
            .list()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                result => {
                    this.assets = result.response;
                    this.totalUnfiltered = result.total;
                    this.setLastUpdatedMetricRefresh();
                    this.summaryLoading = false;
                },
                _err => {
                    this.assets = [];
                    this.totalUnfiltered = 0;
                    this.summaryLoading = false;
                },
            );
        this.getRecommendations();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    onBeforeFetchData(): void {
        if (this.listCallParameters) {
            this.listCallParameters.filter = this.appendTimeFilters(this.listCallParameters.filter);
        }

        const listParams = {
            filter: this.listCallParameters.filter,
            extra: this.listCallParameters.extra,
        };

        // Get a full list of filtered assets to handle gfb and contains logic issue in the report
        this.subscriptionAssetService
            .list(listParams)
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
                this.filteredAssets = response.response;
                this.totalFiltered = response.total;
                let filterString = '';
                if (!!this.listCallParameters?.filter && this.filteredAssets.length > 0) {
                    filterString = "id=('" + this.filteredAssets.map(asset => asset.id).join("','") + "')";
                }
                this.assetFilter = filterString;
            });
    }

    selectStartTimeRange(timeRangeOption: CustomTimeRange): void {
        this.selectedStartTimeRangeOption = timeRangeOption;
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, this.subscriptionStartDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.subscriptionStartDateEndKey, DEFAULT_NAMESPACE),
        ];
        if (timeRangeOption) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.subscriptionStartDateStartKey,
                        timeRangeOption.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.subscriptionStartDateEndKey,
                        timeRangeOption.end.valueOf().toString(),
                    ),
                ),
            );
        }
        this.ngRedux.dispatch(actions);
    }

    selectRenewalTimeRange(timeRangeOption: CustomTimeRange): void {
        this.selectedRenewalTimeRangeOption = timeRangeOption;
        const actions: Action[] = [
            removeFiltersByKey(this.hiddenBarId, this.subscriptionRenewalDateStartKey, DEFAULT_NAMESPACE),
            removeFiltersByKey(this.hiddenBarId, this.subscriptionRenewalDateEndKey, DEFAULT_NAMESPACE),
        ];
        if (timeRangeOption) {
            actions.push(
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.subscriptionRenewalDateStartKey,
                        timeRangeOption.start.valueOf().toString(),
                    ),
                ),
                addFilter(
                    this.hiddenBarId,
                    createFilter(
                        null,
                        DEFAULT_NAMESPACE,
                        this.subscriptionRenewalDateEndKey,
                        timeRangeOption.end.valueOf().toString(),
                    ),
                ),
            );
        }
        this.ngRedux.dispatch(actions);
    }

    onSelectionsChange(newSelections: SubscriptionAsset[]): void {
        this.selectedAssets = newSelections;
    }

    onClickExport(option: IExportButtonOption): void {
        this.selectedExportOption = option;
        this.ngbModal.open(this.assetExportModal);
    }

    openColumnOptions(): void {
        this.ngbModal.open(this.columnSelector, {
            windowClass: 'asset-management-column-selector-modal',
        });
    }

    onColumnOptionsGroupChange(newOptions: SelectableColumnGroup<SubscriptionAsset>): void {
        this.columnOptionGroups = newOptions;
        this.saveColumns();
    }

    onColumnsInit(newOptions: SelectableColumnGroup<SubscriptionAsset>): void {
        this.columnOptionGroups = newOptions;
        this.handleRedux();
    }

    onBeforeUpdateParams(): void {
        this.handleRedux();
    }

    onAfterFetchFailed(_: Error): void {
        this.loading = false;
        this.data = [];
        this.total = 0;
        this.onDataChange(this.data);
    }

    onDataChange(data: SubscriptionAsset[]): void {
        this.data = DpaSafeModeUtils.getAssetsWithDpaSafeMode(data, this.safeModeData || []);
    }

    updateExportOptionCounts(optionsMap: Map<number, IExportButtonOption>): void {
        const filteredOpt = optionsMap.get(ExportOptionIds.filtered);
        this.updateExportOption(filteredOpt, this.totalFiltered);

        if (this.totalUnfiltered !== null) {
            const allOpt = optionsMap.get(ExportOptionIds.all);
            this.updateExportOption(allOpt, this.totalUnfiltered);
        }
    }

    updateTableCounts(count: number): void {
        this.totalFiltered = count;
    }

    updateFilterString(filterString: string): void {
        this.assetFilter = filterString;
    }

    openTagsModal(selectedAssets: SubscriptionAsset[]): void {
        // Have to use the old way of calling this modal because it was built during the stone ages
        const modalRef = this.modalService.open(ManageTagsModalComponent, { backdrop: 'static' });
        const manageTagsModal = modalRef.componentInstance as ManageTagsModalComponent;
        manageTagsModal.arrays = selectedAssets;
        manageTagsModal.tags = this.buildTagsForModal(selectedAssets);
        manageTagsModal.tagOrganizationId = this.editableTagOrganizationId;
        modalRef.result.then(
            result => {
                this.assetManagementService.updateAssets();
                this.assetManagementService.updateTags();
            },
            () => {
                // Do nothing on dismiss/cancel
            },
        );
    }

    setLastUpdatedMetricRefresh(): void {
        const lastAssetRefreshed = this.assets
            ?.filter(asset => asset.lastMetricRefresh)
            .sort((a1, a2) => a1.lastMetricRefresh.valueOf() - a2.lastMetricRefresh.valueOf())[0];
        this.lastMetricRefresh = lastAssetRefreshed?.lastMetricRefresh;
    }

    private getRecommendations(): void {
        this.loading = true;
        this.incidentService
            .list()
            .pipe(take(1))
            .subscribe((incidents: DataPage<Incident>) => {
                if (incidents?.total > 0) {
                    const incidentMap = new Map<string, Incident>();
                    incidents.response.forEach(incident => {
                        incidentMap.set(incident.applianceId, incident);
                    });
                    this.incidentMap = incidentMap;
                }
            });
    }

    private handleRedux(): void {
        const state = this.ngRedux.getState();
        // Need hidden since the regular barId is managed by PagedData. Would prefer to not introduce a new slice
        const filters = state.filters[this.hiddenBarId];

        if (filters) {
            const startDateStartFilter = filters.find(filter => filter.key === this.subscriptionStartDateStartKey);
            const renewalDateStartFilter = filters.find(filter => filter.key === this.subscriptionRenewalDateStartKey);
            if (startDateStartFilter) {
                this.selectedStartTimeRangeOption = {
                    start: moment.utc(Number(startDateStartFilter.value)),
                    end: moment.utc(
                        Number(filters.find(filter => filter.key === this.subscriptionStartDateEndKey).value),
                    ),
                };
            }
            if (renewalDateStartFilter) {
                this.selectedRenewalTimeRangeOption = {
                    start: moment.utc(Number(renewalDateStartFilter.value)),
                    end: moment.utc(
                        Number(filters.find(filter => filter.key === this.subscriptionRenewalDateEndKey).value),
                    ),
                };
            }
        }

        if (this.columnOptionGroups) {
            this.buildColumns(state.selection[this.barId]);
        }
    }

    private saveColumns(): void {
        const actions: Action[] = [removeAllSelection(this.barId)];

        const selectedColumns = Object.values(this.columnOptionGroups)
            .flat()
            .filter(option => option.selected)
            .map(option => option.name);

        actions.push(addSelection(this.barId, selectedColumns));

        this.ngRedux.dispatch(actions);
    }

    private buildColumns(selectedColumns?: string[]): void {
        const columnOptions = Object.values(this.columnOptionGroups).flat();

        if (selectedColumns?.length > 0) {
            columnOptions.forEach(column => {
                if (!column.readonly) {
                    column.selected = selectedColumns.includes(column.name);
                }
            });
        }

        this.columns = columnOptions.filter(option => option.selected).map(option => option.column);
    }

    private buildTagsForModal(selectedAssets: SubscriptionAsset[]): MultiResourceTag[] {
        const allTags = new Map<string, MultiResourceTagBuilder>();

        selectedAssets.forEach(asset => {
            const tags = (this.resourceIdToTagMap.get(asset.id) || []).filter(
                tag => tag.tag_organization_id === this.editableTagOrganizationId,
            );
            tags.forEach(tag => {
                const uniqueKey = this.getTagUniqueKey(tag);
                if (!allTags.has(uniqueKey)) {
                    allTags.set(uniqueKey, new MultiResourceTagBuilder(tag, selectedAssets.length));
                }
                allTags.get(uniqueKey).add(tag.key, tag.value, asset.id);
            });
        });
        return Array.from(allTags.values()).map(builder => builder.build());
    }

    private getTagUniqueKey(tag: Tag): string {
        return `${tag.namespace}:${tag.key.toLowerCase()}`;
    }

    private tagCompare(a: Tag, b: Tag): number {
        if (
            a.tag_organization_id === b.tag_organization_id ||
            (a.tag_organization_id !== this.editableTagOrganizationId &&
                b.tag_organization_id !== this.editableTagOrganizationId)
        ) {
            return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        } else {
            return a.tag_organization_id === this.editableTagOrganizationId ? -1 : 1;
        }
    }

    private updateExportOption(option: IExportButtonOption, count: number) {
        option.disabled = count === 0;
        // Don't want to show 0, just disable it
        option.count = count ? count : null;
    }

    private appendTimeFilters(existingFilters: FilterParams<SubscriptionAsset>): FilterParams<SubscriptionAsset> {
        const newFilters: FilterParams<SubscriptionAsset> = Object.assign({}, existingFilters);

        if (this.selectedStartTimeRangeOption) {
            newFilters[SUBSCRIPTION_START_DATE] =
                `${SUBSCRIPTION_START_DATE}>${this.selectedStartTimeRangeOption.start.valueOf() - 1} and ${SUBSCRIPTION_START_DATE}<${this.selectedStartTimeRangeOption.end.valueOf() + 1}`;
        }

        if (this.selectedRenewalTimeRangeOption) {
            newFilters[SUBSCRIPTION_END_DATE] =
                `${SUBSCRIPTION_END_DATE}>${this.selectedRenewalTimeRangeOption.start.valueOf() - 1} and ${SUBSCRIPTION_END_DATE}<${this.selectedRenewalTimeRangeOption.end.valueOf() + 1}`;
        }

        return newFilters;
    }
}

class AssetManagementUrlReaderWriter extends UrlReaderWriter {
    path = /^\/assets\/asset-management/;
    localBarId = LOCAL_BAR_ASSET_MANAGEMENT;

    constructor() {
        super();
    }
}
