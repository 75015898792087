import { importProvidersFrom, NgModule } from '@angular/core';
import {
    NotificationCenterPageComponent as NcNotificationCenterPageComponent,
    NotificationPreferencesComponent as NcNotificationPreferencesComponent,
} from '@pure/notification-center';
import { StepUpModule } from '@pure/step-up-angular';
import { ampli } from '../ampli';
import { NotificationCenterPageComponent } from './notification-center-page/notification-center-page.component';
import { NotificationCenterRoutingModule } from './notification-center-routing.module';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';

@NgModule({
    imports: [NotificationCenterRoutingModule, NcNotificationCenterPageComponent, NcNotificationPreferencesComponent],
    declarations: [NotificationCenterPageComponent, NotificationPreferencesComponent],
    providers: [
        importProvidersFrom(
            StepUpModule.forRoot({
                amplitudeTrackCallback: (event, options) => {
                    ampli.track(event, options);
                },
            }),
        ),
    ],
})
export class NotificationCenterModule {}
