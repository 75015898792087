import { Injectable } from '@angular/core';
import { from, map, mergeMap, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DisasterRecoveryEncryptionService {
    private readonly aesAlgorithm = 'AES-CBC';
    private readonly aesIvLength = 16;

    private readonly rsaAlgorithm = 'RSA-OAEP';
    private readonly rsaKeyLength = 3072;
    private readonly rsaPublicExponent = new Uint8Array([0x01, 0x00, 0x01]);
    private readonly rsaHash = 'SHA-256';

    generateRSAKeyPair(): Observable<CryptoKeyPair> {
        return from(
            crypto.subtle.generateKey(
                {
                    name: this.rsaAlgorithm,
                    modulusLength: this.rsaKeyLength,
                    publicExponent: this.rsaPublicExponent,
                    hash: this.rsaHash,
                },
                true,
                ['encrypt', 'decrypt'],
            ),
        );
    }

    exportRSAPublicKey(publicKey: CryptoKey): Observable<string> {
        return from(crypto.subtle.exportKey('spki', publicKey)).pipe(map(key => this.encodeBase64(key)));
    }

    decryptAESKeyWithRSA(encryptedAesKeyBase64: string, rsaPrivateKey: CryptoKey): Observable<CryptoKey> {
        return this.decrypt({ name: this.rsaAlgorithm }, rsaPrivateKey, this.decodeBase64(encryptedAesKeyBase64)).pipe(
            mergeMap(key => this.importAESKey(key)),
        );
    }

    decryptWithAES(encryptedDataBase64: string, key: CryptoKey): Observable<string> {
        // initialization vector is prepended to the data
        const [iv, data] = this.splitIvAndData(encryptedDataBase64);
        return from(crypto.subtle.decrypt({ name: this.aesAlgorithm, iv }, key, data)).pipe(
            map(decrypted => new TextDecoder().decode(decrypted)),
        );
    }

    encryptWithRSA(plaintextData: string, keyBase64: string): Observable<string> {
        return this.importRSAPublicKey(keyBase64).pipe(
            mergeMap(publicKey =>
                from(this.encrypt(this.rsaAlgorithm, publicKey, new TextEncoder().encode(plaintextData).buffer)),
            ),
            map(buffer => this.encodeBase64(buffer)),
        );
    }

    private decrypt(algorithm: AlgorithmIdentifier, key: CryptoKey, data: ArrayBuffer): Observable<ArrayBuffer> {
        return from(crypto.subtle.decrypt(algorithm, key, data));
    }

    private encrypt(algorithm: AlgorithmIdentifier, key: CryptoKey, data: ArrayBuffer): Observable<ArrayBuffer> {
        return from(crypto.subtle.encrypt(algorithm, key, data));
    }

    private importAESKey(key: ArrayBuffer): Observable<CryptoKey> {
        return from(crypto.subtle.importKey('raw', key, { name: this.aesAlgorithm }, false, ['decrypt']));
    }

    private importRSAPublicKey(publicKey: string): Observable<CryptoKey> {
        const keyBuffer = this.decodeBase64(publicKey);
        return from(
            crypto.subtle.importKey('spki', keyBuffer.buffer, { name: this.rsaAlgorithm, hash: this.rsaHash }, false, [
                'encrypt',
            ]),
        );
    }

    private splitIvAndData(encryptedDataBase64: string): Uint8Array[] {
        const dataWithIvPrefix = this.decodeBase64(encryptedDataBase64);
        return [dataWithIvPrefix.slice(0, this.aesIvLength), dataWithIvPrefix.slice(this.aesIvLength)];
    }

    private encodeBase64(binary: ArrayBuffer): string {
        return btoa(String.fromCharCode(...new Uint8Array(binary)));
    }

    private decodeBase64(base64: string) {
        return new Uint8Array(Array.from(atob(base64)).map(c => c.charCodeAt(0)));
    }
}
