import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PeakSummary } from '../models/peak-summary';

const PEAK_SUMMARY_ENDPOINT = '/rest/v1/peak-subscriptions/summaries';

@Injectable({ providedIn: 'root' })
export class PeakSummaryService {
    constructor(protected http: HttpClient) {}

    get(): Observable<PeakSummary> {
        return this.http.get(PEAK_SUMMARY_ENDPOINT).pipe(map(res => new PeakSummary(res)));
    }
}
