import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import {
    convertToAmpliQuoteRequestConfig,
    FeatureFlagDxpService,
    LicenseType,
    LicenseUpdate,
    Partner,
    PartnerRequest,
    ProgramType,
    QuoteType,
    ServiceCatalogOffering,
    ServiceCatalogQuote,
    ServiceCatalogQuoteServiceV3,
    ServiceCatalogQuotingOptions,
    ServiceCatalogStructuredPricing,
    WorkloadStats,
    WorkloadStatsService,
} from '@pure1/data';

import {
    OfferingOrdersCount,
    ServiceCatalogOfferingsService,
} from '../../../service-catalog/services/service-catalog-offerings.service';
import { emailFormatValidator, getDuplicateEntryValidator } from '../../../utils/form-validators';
import { OrdersUpdateService } from '../../../orders/services/orders-update.service';
import { ProductLine } from '../../../forecast/hardware-capacity/models/array-capacity-config';
import { StorageService } from '../../../services/storage.service';
import { PartnerInfoService } from '../partner-info/partner-info.service';
import { ampli } from '../../../ampli';
import {
    convertToDate,
    disableWeekends,
    minDate,
    SUNDAY,
} from '../../../subscription/subscription-utils/subscription-utils';
import { DatePipe } from '@angular/common';
import { FeatureNames } from '../../../model/FeatureNames';
import { Observable } from 'rxjs';

interface LicenseUpdateWithId {
    id: number;
    licenseUpdate: LicenseUpdate;
}

@Component({
    selector: 'quote-request-modal',
    templateUrl: './quote-request-modal.component.html',
})
export class QuoteRequestModalComponent implements OnInit, OnChanges {
    @ViewChild('requestConfirmedModal') readonly requestConfirmedModal: TemplateRef<any>;

    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly serviceCatalog: ServiceCatalogOffering;
    @Input() readonly activeOrder: ServiceCatalogQuote;
    @Input() readonly isEditOrder: boolean = false; //Default value is false, except from Order Page

    readonly DESCRIPTION_MAX_LENGTH = 3000;
    readonly EMAIL_MAX_LENGTH = 128;
    readonly CREDIT_MIN = 1_000_000;
    readonly CREDIT_MAX = 100_000_000; // Large limit to prevent breaking the input
    readonly moreDetailsDescription: UntypedFormControl = new UntypedFormControl(
        '',
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
    );
    readonly analyticsLabel = 'Service-Catalog-QuoteRequestModal - Request Service Catalog Quote';
    readonly updateAnalyticsLabel = 'Service-Catalog-QuoteRequestModal - Update Service Catalog Quote';
    // The list of groupNames of the products,
    // which only send confirmation emails to Sales rather than create opty on SFDC when requesting quote
    readonly groupNamesOnlySendingEmails = ['//UDR'];

    isLoading = false;
    hasError = false;
    productLine: ProductLine;
    reserveStructuredPricing: ServiceCatalogStructuredPricing;
    performanceStructuredPricing: ServiceCatalogStructuredPricing;
    options: ServiceCatalogQuotingOptions[];
    selectedOption: ServiceCatalogQuotingOptions;
    ordersCountByCompoundName: Map<string, OfferingOrdersCount>;
    displayName: string;
    newSites: LicenseUpdateWithId[] = [];
    allCurrentFieldsFilled = false;
    totalRequestedAmount = 0;
    totalPerformance = 0;
    displayedEstimatedPrice: string;
    usableTiB: number;
    termInMonths: number;
    monthOptions: number[];
    emailInput: UntypedFormControl = new UntypedFormControl('', [
        Validators.maxLength(this.EMAIL_MAX_LENGTH),
        emailFormatValidator,
        getDuplicateEntryValidator(),
    ]);
    hasEnteredFields = false; // Used only for analytics
    workloadStats: WorkloadStats[];
    isFieldChanged = false;
    partnersInfos: Partner[];
    partnerRequest: PartnerRequest;
    isAICatalogCard = false;
    // Universal Credit related fields
    ucMonthOptions = [12, 24];
    ucForm = this.fb.group({
        termsInMonths: [12, Validators.required],
        creditAmount: [
            this.CREDIT_MIN,
            [Validators.required, Validators.min(this.CREDIT_MIN), Validators.max(this.CREDIT_MAX)],
        ],
        startDate: [null, Validators.required],
    });
    totalCreditValue = this.CREDIT_MIN;
    minDate = minDate;
    isUUCEnabled$: Observable<boolean>;

    private incrementalSiteId = 0; // Used only for rendering
    private minReserve: number;

    readonly disableWeekends = disableWeekends;
    readonly SUNDAY = SUNDAY;

    constructor(
        private serviceCatalogQuoteService: ServiceCatalogQuoteServiceV3,
        private serviceCatalogOfferingsService: ServiceCatalogOfferingsService,
        private angulartics2: Angulartics2,
        private workloadStatsService: WorkloadStatsService,
        private ordersUpdateService: OrdersUpdateService,
        private partnerInfoService: PartnerInfoService,
        private storageService: StorageService,
        private router: Router,
        private fb: FormBuilder,
        private featureFlagService: FeatureFlagDxpService,
        public modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.serviceCatalogOfferingsService.ordersCountByCompoundedName$.pipe(take(1)).subscribe(ordersCountMap => {
            this.ordersCountByCompoundName = ordersCountMap;
        });

        this.isLoading = true;
        this.partnerInfoService.partnersInfos$.pipe(take(1)).subscribe(data => {
            this.partnersInfos = data;
            this.isLoading = false;
        });

        this.displayName = this.serviceCatalog.groupName + ' - ' + this.serviceCatalog.productName;
        this.workloadStatsService
            .list()
            .pipe(take(1))
            .subscribe(response => {
                this.workloadStats = response.response;
            });
        this.isAICatalogCard = this.serviceCatalog.licenseType === LicenseType.E_ONE_FOR_AI;

        this.ucForm.valueChanges.subscribe(value => {
            const { creditAmount, termsInMonths } = value;
            this.totalCreditValue = (creditAmount * termsInMonths) / 12;
        });

        this.isUUCEnabled$ = this.featureFlagService
            .getFeatureFlag(FeatureNames.E_FLEX_UUC)
            .pipe(map(feature => feature?.enabled === true));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeOrder) {
            this.newSites = [];
            this.activeOrder?.licenseUpdates?.forEach(licenseUpdate => {
                this.newSites.push({
                    id: Number(licenseUpdate.id),
                    licenseUpdate: {
                        id: licenseUpdate.id,
                        siteName: licenseUpdate.siteName,
                        licenseType: licenseUpdate.licenseType,
                        productSku: licenseUpdate.productSku,
                        originalAmount: licenseUpdate.originalAmount,
                        additionalAmount: licenseUpdate.additionalAmount,
                        newSite: licenseUpdate.newSite,
                        startDate: licenseUpdate.startDate,
                        workload: {
                            name: licenseUpdate.workload?.name,
                        },
                        isPre: false,
                    },
                });
            });
            this.validateFields();
        }
        if (changes.serviceCatalog) {
            if (this.newSites.length === 0) {
                this.newSites.push({
                    id: this.incrementalSiteId++,
                    licenseUpdate: new LicenseUpdate({}),
                });
            }
            if (this.serviceCatalog?.quotingOptions?.length > 0) {
                this.options = this.serviceCatalog.quotingOptions;
                this.selectedOption = this.options[0];

                // Options past this will only be used for Pure-as-a-Service
                const capacityOptions = this.serviceCatalog.quotingOptions[0].requestAmounts;
                this.minReserve = capacityOptions?.[0];
                this.monthOptions = this.serviceCatalog.quotingOptions[0].terms;
                this.requiredMonthAndAmount();
            }

            if (this.serviceCatalog?.structuredPricings) {
                this.reserveStructuredPricing = this.serviceCatalog.structuredPricings.find(
                    strPrice => strPrice.pricingType === 'reserved',
                );
                this.performanceStructuredPricing = this.serviceCatalog.structuredPricings.find(
                    strPrice => strPrice.pricingType === 'performance',
                );
                this.calculateEstimatedPrice();
            }
        }
    }

    isSubmitButtonDisabled(): boolean {
        if (this.serviceCatalog.productName === 'Universal Credits') {
            return this.ucForm.invalid || this.isLoading;
        }

        if (
            this.groupNamesOnlySendingEmails.includes(this.serviceCatalog.groupName) ||
            (this.serviceCatalog.serviceName !== 'Pure as-a-Service' &&
                this.serviceCatalog.serviceName !== 'Evergreen//One' &&
                this.serviceCatalog.serviceName !== 'Evergreen//Flex')
        ) {
            return false;
        }

        return this.newSites.length === 0 || this.emailInput.invalid || this.isLoading || !this.allCurrentFieldsFilled;
    }

    //Whether showing capacity or terms options on the modal
    //Or only showing comment field
    isQuoteOptionsVisible(): boolean {
        return (
            !this.groupNamesOnlySendingEmails.includes(this.serviceCatalog.groupName) &&
            (this.serviceCatalog.serviceName === 'Pure as-a-Service' ||
                this.serviceCatalog.serviceName === 'Evergreen//One')
        );
    }

    addSite(): void {
        if (!(this.serviceCatalog.serviceName === 'Evergreen//Flex' && this.newSites.length >= 10)) {
            this.newSites.push({
                id: this.incrementalSiteId++,
                licenseUpdate: new LicenseUpdate({}),
            });
            //this.allCurrentFieldsFilled as false when requesting a new order or add a new site for existing order
            //delete a site for existing order before submitting, it could be added back.
            if (!this.activeOrder || this.activeOrder.licenseUpdates.length < this.newSites.length) {
                this.allCurrentFieldsFilled = false;
            }
            this.totalRequestedAmount += this.minReserve;
            this.calculateEstimatedPrice();
        }
    }

    onSiteInfoChanged(newSiteInfo: LicenseUpdate, index: number): void {
        this.newSites[index].licenseUpdate = newSiteInfo;
        if (!this.hasEnteredFields) {
            // Check if any fields have changed from default.
            // We only care to track if it has happened even a single time.
            // We use this to see if the user ends up closing the modal without submitting despite entering fields
            if (
                newSiteInfo.siteName ||
                newSiteInfo.workload ||
                newSiteInfo.startDate ||
                newSiteInfo.additionalAmount !== this.minReserve ||
                newSiteInfo.isPre
            ) {
                this.hasEnteredFields = true;
            }
        }
        this.hasEnteredFields = true;
        this.validateFields();
        this.calculateTotalAmount();
    }

    onDeleteTriggered(index: number): void {
        this.newSites.splice(index, 1);
        if (this.newSites.length === 0) {
            this.allCurrentFieldsFilled = false;
            this.totalRequestedAmount = 0;
            this.totalPerformance = 0;
            this.displayedEstimatedPrice = '$0';
            this.allCurrentFieldsFilled = true;
        } else {
            this.validateFields();
            this.calculateTotalAmount();
        }
    }

    onMonthChange(selectedTerm: number): void {
        this.termInMonths = selectedTerm;
        this.calculateEstimatedPrice();
    }

    requiredMonthAndAmount(): void {
        if (this.activeOrder) {
            this.termInMonths = this.activeOrder.termInMonth;
            this.totalRequestedAmount = this.activeOrder.reservedAmount;
        } else {
            this.termInMonths = this.monthOptions?.[0];
            this.totalRequestedAmount = this.minReserve;
        }
    }

    onPartnerRequestChanged(newPartnerInfo: PartnerRequest): void {
        this.partnerRequest = newPartnerInfo;
    }

    isValidPermission(): string {
        if (this.activeOrder) {
            //If there is an existing salesflow, it is for modifying net new quote.
            return 'PURE1:write:update_quote';
        } else {
            //For quote-request-modal. If there is no existing salesflow, it is for net new Opex requests.
            return 'PURE1:write:request_net_new_quote';
        }
    }

    requestAQuote(): void {
        this.isLoading = true;
        this.hasError = false;
        const params: Partial<ServiceCatalogQuote> = ServiceCatalogQuote.fromQuoteRequestJson({
            quote_type: QuoteType.REGULAR_QUOTE,
            request_type: 'Quote',
            program_type: this.serviceCatalog.programType ? this.serviceCatalog.programType : null,
            license_type: this.serviceCatalog.licenseType ? this.serviceCatalog.licenseType : null,
            comment: this.moreDetailsDescription.value,
            products: [
                {
                    product_sku: this.selectedOption?.productSku,
                    service_name: this.serviceCatalog.serviceName,
                    group_name: this.serviceCatalog.groupName,
                    product_name: this.serviceCatalog.productName,
                    quoting_option_name: this.selectedOption?.name,
                },
            ],
            partner_request: this.partnerRequest,
        });
        // Pure-as-a-Service related params
        if (
            !this.groupNamesOnlySendingEmails.includes(this.serviceCatalog.groupName) &&
            (this.serviceCatalog.serviceName === 'Pure as-a-Service' ||
                this.serviceCatalog.serviceName === 'Evergreen//One' ||
                this.serviceCatalog.serviceName === 'Evergreen//Flex')
        ) {
            params.quoteCustomerEmails = this.emailInput.value.length > 0 ? this.emailInput.value.split(',') : [];
            params.licenseUpdates = this.newSites.map(site => site.licenseUpdate);
            params.termInMonth = this.termInMonths;
            params.reservedAmount = this.totalRequestedAmount;
            params.reservedUnit = 'TiB';
            params.orderCategory = 'New Subscription';
        }
        // Evergreen//Flex specific param overrides
        if (this.serviceCatalog.serviceName === 'Evergreen//Flex') {
            params.quoteType = QuoteType.FLEX;
            params.programType = ProgramType.EVERGREEN_FLEX;
            params.licenseType = LicenseType.FLEX;
        }
        // Universal Credits specific params
        if (this.serviceCatalog.productName === 'Universal Credits') {
            const datePipe = new DatePipe('en-US');
            const formattedDate = datePipe.transform(convertToDate(this.ucForm.get('startDate').value), 'yyyy-MM-dd');
            params.termInMonth = this.ucForm.get('termsInMonths').value;
            params.reservedAmount = this.totalCreditValue;
            params.reservedUnit = 'Credits';
            params.orderCategory = 'Universal Credits';
            params.comment = `Requested Start Date: ${formattedDate}\n${this.moreDetailsDescription.value}`;
            ampli.catalogUniversalCreditQuoteRequesting({
                quoteType: 'request universal credits',
            });
        }
        // Related params for products only sending confirmation emails
        if (this.groupNamesOnlySendingEmails.includes(this.serviceCatalog.groupName)) {
            params.quoteCustomerEmails = this.emailInput.value.length > 0 ? this.emailInput.value.split(',') : [];
            params.reservedAmount = 0.0;
            params.onDemand = false;
            params.reservedUnit = 'TiB';
            params.orderCategory = 'New Subscription';
        }
        //triggering Amplitude events to track quote requested actions
        const programType = params.licenseType ? params.programType : null;
        ampli.quoteRequested({
            'quote requested from': 'catalog detail page',
            'requested configuration': convertToAmpliQuoteRequestConfig(
                programType,
                QuoteType.REGULAR_QUOTE,
                'catalog detail page',
            ),
        });
        //processing the quote requests.
        if (this.activeOrder) {
            params.id = this.activeOrder.id;
            this.serviceCatalogQuoteService
                .update(params)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.ordersUpdateService.refreshQuotes$.next(null);
                        this.activeModal.close();
                        this.isLoading = false;
                        this.modalService.open(this.requestConfirmedModal, {
                            backdrop: 'static',
                            windowClass: 'request-confirmed-modal',
                        });
                        this.angulartics2.eventTrack.next({
                            action: this.updateAnalyticsLabel,
                            properties: {
                                category: 'Action',
                                label: 'Edit New Quote Order for ' + this.serviceCatalog.immutableName + 'Successfully',
                            },
                        });
                    },
                    error: error => {
                        this.isLoading = false;
                        this.hasError = true;
                        console.error('error updating service catalog quote', error);
                        this.angulartics2.eventTrack.next({
                            action: this.updateAnalyticsLabel,
                            properties: {
                                category: 'Action',
                                label: 'Error Updating Quote Requested for ' + this.serviceCatalog.immutableName,
                            },
                        });
                    },
                });
        } else {
            this.serviceCatalogQuoteService
                .create(params)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.ordersUpdateService.refreshQuotes$.next(null);
                        this.activeModal.close();
                        this.isLoading = false;
                        this.modalService.open(this.requestConfirmedModal, {
                            backdrop: 'static',
                            windowClass: 'request-confirmed-modal',
                        });
                        const additionalLabelInfo = this.hasEnteredFields ? 'with' : 'without';
                        this.angulartics2.eventTrack.next({
                            action: this.analyticsLabel,
                            properties: {
                                category: 'Action',
                                label:
                                    `Submit ${additionalLabelInfo} license level info for ` +
                                    this.serviceCatalog.immutableName,
                            },
                        });
                    },
                    error: error => {
                        this.isLoading = false;
                        this.hasError = true;
                        console.error('error submitting service catalog quote', error);
                        this.angulartics2.eventTrack.next({
                            action: this.analyticsLabel,
                            properties: {
                                category: 'Action',
                                label: 'Error Submitting Quote Requested for ' + this.serviceCatalog.immutableName,
                            },
                        });
                    },
                });
        }
    }

    // Email related functions
    getValidationError(): string {
        if (this.emailInput.errors?.email) {
            return 'Please verify that all emails are properly formatted';
        } else if (this.emailInput.errors.duplicate) {
            return 'At least one entered email has been entered twice';
        } else {
            return '';
        }
    }

    private validateFields(): void {
        this.allCurrentFieldsFilled = !this.newSites.some(newSite => {
            // Note: Quote can be submitted without any additional fields being added
            // This is used only for adding additional licenses. Then, we require siteName
            // For Flex, we also require that a capacity is actually selected
            const site = newSite.licenseUpdate;
            let validFlexCapacity = true;
            if (this.serviceCatalog.serviceName === 'Evergreen//Flex') {
                validFlexCapacity = site.additionalAmount > 0;
            }
            return !site.siteName || !validFlexCapacity;
        });

        if (this.serviceCatalog.productName === 'Universal Credits') {
            this.allCurrentFieldsFilled = !!this.ucForm.invalid;
        }
    }

    private calculateTotalAmount(): void {
        this.totalRequestedAmount = this.newSites
            .map(site => site.licenseUpdate)
            .reduce((prev, curr) => prev + (curr.additionalAmount || 0), 0);
        this.totalPerformance = this.newSites
            .map(site => site.licenseUpdate)
            .reduce((prev, curr) => prev + (curr.performance || 0), 0);
        this.calculateEstimatedPrice();
    }

    private calculateEstimatedPrice(): void {
        // No pricing for //Flex
        if (this.serviceCatalog.serviceName === 'Evergreen//Flex') {
            return;
        }
        const estimatedPriceDefault = '-';

        if (Number.isNaN(this.totalRequestedAmount) || Number.isNaN(this.termInMonths)) {
            console.warn('Invalid term length / size amount');
            this.displayedEstimatedPrice = estimatedPriceDefault;
            return;
        }

        if (!this.reserveStructuredPricing) {
            console.warn('No matching pricing scheme found');
            this.displayedEstimatedPrice = estimatedPriceDefault;
            return;
        }

        let estimatedPrice: number;
        if (this.serviceCatalog.licenseType === LicenseType.E_ONE_FOR_AI) {
            estimatedPrice = this.newSites
                .map(site => {
                    const reservedPricing = this.reserveStructuredPricing.getPerformanceBasedPrice(
                        this.termInMonths,
                        site.licenseUpdate.additionalAmount,
                        site.licenseUpdate.performance,
                    );
                    const performancePricing = this.performanceStructuredPricing.getPerformanceBasedPrice(
                        this.termInMonths,
                        site.licenseUpdate.additionalAmount,
                        site.licenseUpdate.performance,
                    );
                    return reservedPricing + performancePricing;
                })
                .reduce((prev, curr) => prev + curr, 0);
        } else {
            estimatedPrice = this.reserveStructuredPricing.getPrice(this.termInMonths, this.totalRequestedAmount);
        }

        if (Number.isNaN(estimatedPrice) || estimatedPrice < 0) {
            console.warn('Failed to get price from specified term length / size amount');
            this.displayedEstimatedPrice = estimatedPriceDefault;
            return;
        }

        this.displayedEstimatedPrice = estimatedPrice
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            .split('.')[0];
    }
}
