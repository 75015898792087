export function isFlashblade(model: string): boolean {
    return isFBS(model) || isFBE(model) || isLegacyFB(model);
}

export function isFBS(model: string): boolean {
    return model && (model.toLowerCase().startsWith('fb-s') || model.toLowerCase().startsWith('flashblade//s'));
}

export function isFBE(model: string): boolean {
    return model && (model.toLowerCase().startsWith('fb-e') || model.toLowerCase().startsWith('flashblade//e'));
}

export function areInSameProductLine(model1: string, model2: string): boolean {
    return model1 && model2 && model1.slice(0, 4).toLowerCase() === model2.slice(0, 4).toLowerCase();
}

export function isLegacyFB(model: string): boolean {
    return model && !!model.toLowerCase().match(/^flash-?blade$/);
}

export function isPhantomFBS(model: string): boolean {
    return model && model.toLowerCase() === 'fb-sphantom';
}

export function isPhantomFBE(model: string): boolean {
    return model && model.toLowerCase() === 'fb-ephantom';
}

export function isPhantomFB(model: string): boolean {
    return isPhantomFBS(model) || isPhantomFBE(model);
}

export function isXCR4(model: string): boolean {
    return model && model.toLowerCase().endsWith('r4');
}

export function isFAE(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-e');
}

export function isFA3xx(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-3');
}

export function isFA4xx(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-4');
}

export function isFAXL(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-xl');
}

/**
 * Gets if the model is for any CBS type
 */
export function isCBS(model: string): boolean {
    return isPAWS(model) || isPAZ(model);
}

export function isFAC(model: string): boolean {
    return model && (model.toLowerCase().startsWith('fa-c') || model.toLowerCase().startsWith('fa-rc'));
}

export function isFAM(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-m');
}

export function isFAX(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-x') && !isFAXL(model);
}

export function isFAST(model: string): boolean {
    return model && model.toLowerCase().startsWith('fa-st');
}

export function isFormattedFAC(model: string): boolean {
    return model?.toLowerCase().startsWith('//c');
}

export function isFormattedFAX(model: string): boolean {
    return model?.toLowerCase().startsWith('//x') && !isFormattedFAXL(model);
}

export function isFormattedFAXL(model: string): boolean {
    return model?.toLowerCase().startsWith('//xl');
}

export function isFAXFamily(model: string): boolean {
    const FAXFamilyPrefixes = ['fa-x', 'fa-m', 'fa-4', 'fa-3'];
    return model && FAXFamilyPrefixes.some(prefix => model.toLowerCase().startsWith(prefix)) && !isFAXL(model);
}

/**
 *  CBS varieties
 *  AWS                 {CBS-V10AR1, CBS-V20AR1, CBS-V30AR1}
 *  Microsoft Azure     All Ultra: {CBS-V10MUR1, CBS-V20MUR1}, Ultra/Premium: {CBS-V2MP2R2, CBS-V10MP2R2, CBS-V20MP2R2}
 */

/**
 * Gets if the given model is for PAWS.
 */
export function isPAWS(model: string): boolean {
    return model && model.toLowerCase().startsWith('cbs') && model.toLowerCase().includes('ar');
}

/**
 * Gets if the given model is for PAzure.
 */
export function isPAZ(model: string): boolean {
    const CBSModelRegex = /^cbs-v\d+m\w*r\d+$/;
    return model && CBSModelRegex.test(model.toLowerCase());
}

export function isVMware(model: string): boolean {
    return model && model.toLowerCase().startsWith('vmware');
}
