import { DraasApiCluster } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryCluster {
    id: string;
    name: string;
    description: string;
    licenseKey: string;
    status:
        | 'NOT_STARTED'
        | 'PENDING_DEPLOYMENT'
        | 'DEPLOYED'
        | 'PENDING_SEED_NODE_CONNECTION'
        | 'PENDING_TARGET_NODE_CONNECTION'
        | 'PENDING_VPN'
        | 'READY'
        | 'HEALTHY'
        | 'UNHEALTHY'
        | 'UNKNOWN';
    isV2: boolean;

    constructor(json: DraasApiCluster) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.licenseKey = json.license_key;
        this.status = json.status;
        this.isV2 = json.is_v2;
    }

    isPending(): boolean {
        return (
            this.status === 'NOT_STARTED' ||
            this.status === 'PENDING_DEPLOYMENT' ||
            this.status === 'DEPLOYED' ||
            this.status === 'PENDING_SEED_NODE_CONNECTION' ||
            this.status === 'PENDING_TARGET_NODE_CONNECTION' ||
            this.status === 'PENDING_VPN'
        );
    }
}
