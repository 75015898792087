<div class="banner-icon">
    <pureui-svg [svg]="config.badgeSvg" [height]="83.25"></pureui-svg>
</div>
<button
    class="dismiss-button svg-hover-dropshadow"
    (click)="
        dismissNotification.emit();
        ampli.bannerOpportunities({
            'banner name': notification.title,
            'banner opportunity action': 'Dismiss Banner',
        })
    "
    pure-read-only
>
    <pureui-svg svg="close-button.svg" [height]="12.39"></pureui-svg>
</button>
<div class="banner-content">
    <div class="banner-graphic">
        <pureui-svg [svg]="notification.image" width="60"></pureui-svg>
    </div>
    <div class="banner-text">
        <div class="banner-title">
            {{ notification.title }}
        </div>
        <div class="banner-body">
            {{ notification.body }}
            <span class="kb-links">
                <ng-container *ngIf="notification.kbArticleLink">
                    <a
                        [href]="notification.kbArticleLink"
                        target="_blank"
                        (click)="
                            ampli.bannerOpportunities({
                                'banner name': notification.title,
                                'banner opportunity action': 'Clicked Read More',
                            })
                        "
                    >
                        Learn More
                    </a>
                </ng-container>
                <ng-container *ngFor="let link of notification.customLinks; let i = index">
                    <ng-container *ngIf="notification.kbArticleLink || i !== 0">|</ng-container>
                    <a
                        [href]="link.url"
                        [target]="link.openInNewTab ? '_blank' : '_self'"
                        (click)="
                            ampli.bannerOpportunities({
                                'banner name': notification.title,
                                'banner opportunity action': 'Clicked Custom Link',
                                'action details': link.displayName,
                            })
                        "
                    >
                        {{ link.displayName }}
                    </a>
                </ng-container>
            </span>
        </div>
    </div>
    <div class="banner-actions">
        <ng-container *ngIf="notification.buttonTemplate; else contactTeamButton">
            <ng-container *ngTemplateOutlet="notification.buttonTemplate"></ng-container>
        </ng-container>
        <ng-template #contactTeamButton>
            <button
                class="btn btn-primary try-button"
                (click)="onButtonClick()"
                [disabled]="('PURE1:write:request_net_new_quote' | isNotAllowedImpure) || isSubmitting"
            >
                <ng-container *ngIf="isSubmitting">Requesting...</ng-container>
                <ng-container *ngIf="!isSubmitting">Contact Account Team</ng-container>
            </button>
        </ng-template>
    </div>
</div>

<ng-template #requestConfirmedModal let-modal>
    <request-confirmed-modal [canTrackOrder]="true" [activeModal]="modal"> </request-confirmed-modal>
</ng-template>
