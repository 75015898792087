import { DraasApiVmWarePortGroup } from '@pure/paas-api-gateway-client-ts';

export class DisasterRecoveryVmwarePortGroup {
    clusterId: string;
    providerId: string;
    id: string;
    key: string | null;
    moref: string | null;
    name: string;
    type: string;
    switch: {
        assetId: string;
    };
    hosts: {
        moref: string;
        name: string;
    }[];

    constructor(json: DraasApiVmWarePortGroup) {
        this.clusterId = json.cluster_id;
        this.providerId = json.provider_id;
        this.id = json.id;
        this.key = json.key;
        this.name = json.name;
        this.moref = json.moref;
        this.type = json.type;
        this.switch = json.switch ? { assetId: json.switch.asset_id } : null;
        this.hosts = json.hosts.map(host => ({
            moref: host.moref,
            name: host.name,
        }));
    }
}
