import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnifiedArray, UnifiedArrayService } from '@pure1/data';
import moment from 'moment/moment';

@Component({
    selector: 'contact-renewals-modal',
    templateUrl: './contact-renewals-modal.component.html',
})
export class ContactRenewalsModalComponent implements OnChanges {
    @Input() readonly selectedArray: UnifiedArray;
    @Input() readonly activeModal: NgbActiveModal;
    @Input() readonly recommendedSku: string;
    @Input() readonly customerSelectedContractRenewalOption: string;
    @Output() readonly submitChangesComplete = new EventEmitter<void>();

    isSubmitted = false;
    loading = false;
    requestFailed = false;
    isValidToRequest = false;

    constructor(private unifiedArrayService: UnifiedArrayService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedArray) {
            //If a renewal request has never been made OR if the renewal request was submitted more than 7 days ago, it is valid to make the request
            this.isValidToRequest =
                !this.isSubmitted &&
                (!this.selectedArray.contract_last_renew_requested ||
                    moment(this.selectedArray.contract_last_renew_requested).isBefore(
                        moment.utc().subtract(7, 'days'),
                    ));
        }
    }

    submitInquiry(): void {
        this.loading = true;
        const request = {
            contract_last_renew_requested: moment.utc().valueOf(),
            // temporary workaround to pass in fields related to proactive recommendation
            recommended_sku: this.recommendedSku,
            customer_selected_contract_renewal_option: this.customerSelectedContractRenewalOption,
        } as Partial<UnifiedArray>;
        const id = [this.selectedArray.cloud_array_id];
        this.unifiedArrayService.update(request, id).subscribe(
            () => {
                this.loading = false;
                this.isSubmitted = true;
                this.submitChangesComplete.emit();
            },
            (err: HttpErrorResponse) => {
                this.requestFailed = true;
                console.error(
                    'Failed to send contact renewals team email by updating array contract_last_renew_requested',
                    err,
                );
                this.loading = false;
            },
        );
    }
}
