import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ArrayEolComponentInfo } from '@pure1/data';
import { PureArray } from '../../model/PureArray';
import { ToastService, ToastType } from '../../services/toast.service';
import { EolHardwareService, EolOpportunityStatus } from '../../services/eol-hardware.service';

@Component({
    selector: 'eol-overlay',
    templateUrl: 'eol-overlay.component.html',
})
export class EolOverlayComponent implements OnInit, OnChanges, OnDestroy {
    @Input() readonly array: PureArray;
    @Input() readonly hovering: boolean;

    loading = true;
    overlayDismissed = false;
    opportunityStatus: EolOpportunityStatus = 'unknown';
    incidentOpened = false;
    hwEolInfo: ArrayEolComponentInfo;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private eolHardwareService: EolHardwareService,
        private toast: ToastService,
    ) {}

    ngOnInit(): void {
        this.eolHardwareService.opportunitiesUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.opportunityStatus = this.eolHardwareService.isEolOpportunityOpened(this.array.cloud_array_id);
        });

        this.eolHardwareService.incidentsUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.incidentOpened = this.eolHardwareService.isEolIncidentOpened(this.array.cloud_array_id);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.array) {
            this.eolHardwareService.getEolComponentInfo(this.array.cloud_array_id).subscribe({
                next: (info: ArrayEolComponentInfo) => {
                    this.hwEolInfo = info;
                    this.loading = false;
                },
                error: error => {
                    console.warn('failed to get hardware eol info' + error);
                    this.loading = false;
                },
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    dismissOverlay(): void {
        this.overlayDismissed = true;
    }

    clickContactAccountTeam(): void {
        this.loading = true;
        this.eolHardwareService
            .createEolOpportunity(
                this.array.cloud_array_id,
                this.array.name,
                'Pure1 Eol Appliance Card',
                this.hwEolInfo.recommendationAction.capacityConfig,
            )
            .subscribe({
                next: () => {
                    this.opportunityStatus = 'opened';
                    this.loading = false;
                },
                error: err => {
                    this.toast.add(ToastType.error, 'Failed to submit request, please try again.');
                    console.log(err);
                    this.loading = false;
                },
            });
    }

    hasPermissionToViewRecommendation(): boolean {
        return this.eolHardwareService.hasPermissionToViewRecommendation();
    }

    clickViewRecommendation(): void {
        this.eolHardwareService.viewRecommendation(this.array.cloud_array_id);
    }

    hasEolController(): boolean {
        return this.array.end_of_life_hardware?.components?.some(component => component.type === 'ct');
    }
}
